export const reportsManagementConstants = {
    GET_REPORTS_FILTERS_REQUEST: 'GET_REPORTS_FILTERS_REQUEST',
    GET_REPORTS_FILTERS_SUCCESS: 'GET_REPORTS_FILTERS_SUCCESS',
    GET_REPORTS_FILTERS_ERROR: 'GET_REPORTS_FILTERS_ERROR',

    CHANGE_USER_FILTER: 'CHANGE_USER_FILTER',
    CHANGE_GROUP_FILTER: 'CHANGE_GROUP_FILTER',
    CHANGE_TEMPLATE_FILTER: 'CHANGE_TEMPLATE_FILTER',

    GET_REPORTS_REQUEST: 'GET_REPORTS_REQUEST',
    GET_REPORTS_SUCCESS: 'GET_REPORTS_SUCCESS',
    GET_REPORTS_ERROR: 'GET_REPORTS_ERROR',

    GET_SUB_REPORTS_REQUEST: 'GET_SUB_REPORTS_REQUEST',
    GET_SUB_REPORTS_SUCCESS: 'GET_SUB_REPORTS_SUCCESS',
    GET_SUB_REPORTS_ERROR: 'GET_SUB_REPORTS_ERROR',

    GET_SUB_SUB_REPORTS_REQUEST: 'GET_SUB_SUB_REPORTS_REQUEST',
    GET_SUB_SUB_REPORTS_SUCCESS: 'GET_SUB_SUB_REPORTS_SUCCESS',
    GET_SUB_SUB_REPORTS_ERROR: 'GET_SUB_SUB_REPORTS_ERROR',

    SET_REPORTS_DATE_ACTION: 'SET_REPORTS_DATE_ACTION',
    SET_FILTERS_ORDER: 'SET_FILTERS_ORDER',

    CLEAR_REPORTS: 'CLEAR_REPORTS',

    CHANGE_REPORTS_PAGE: 'CHANGE_REPORTS_PAGE',
    CHANGE_REPORTS_SIZE: 'CHANGE_REPORTS_SIZE',

    CLOSE_SUB_REPORTS: 'CLOSE_SUB_REPORTS',

    GET_EMPTY_VALUE: 'GET_EMPTY_VALUE',
    TOGGLE_INCLUDE_TEST_DATA: 'TOGGLE_INCLUDE_TEST_DATA',

    SET_EXPANDED: 'SET_EXPANDED',

    OPEN_REPORTS_DETAILS_MODAL: 'OPEN_REPORTS_DETAILS_MODAL',
    CLOSE_REPORTS_DETAILS_MODAL: 'CLOSE_REPORTS_DETAILS_MODAL',
    GET_REPORTS_DETAILS_REQUEST: 'GET_REPORTS_DETAILS_REQUEST',
    GET_REPORTS_DETAILS_SUCCESS: 'GET_REPORTS_DETAILS_REQUEST_SUCCESS',
    GET_REPORTS_DETAILS_ERROR: 'GET_REPORTS_DETAILS_ERROR',
};
