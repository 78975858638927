import { errorRequest, notificationModalActions, seminarConstants } from '../../Constants';
import { seminarService, userService } from '../../Services';
import {notifierActions} from "../notifier.actions";

const {enqueueNotification} = notifierActions;

export const seminarActions = {
    getAllSeminars,
    openDeleteSeminarModal,
    deleteSeminar,
    openEndSeminarModal,
    changeSeminarStatus,
    getAllSeminarsGA,
    loadAllSeminarsGA,
    changeSeminarStatusGA,
    openEndSeminarModalGA,
    changePage,
    changeSize,
    changeSearch,
    performSearch,
};

function getAllSeminars(page, size, search) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .getAll(page, size, search)
            .then(response => {
                dispatch(success(response.data.content, response.data.totalElements));
            })
            .catch(error => {
                dispatch(failure());
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: seminarConstants.REQUEST,
        };
    }
    function success(seminars, totalElements) {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_SUCCESS,
            payload: {
                seminars,
                totalElements,
            },
        };
    }
    function failure() {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_ERROR,
        };
    }
}

function openDeleteSeminarModal(seminarID) {
    return {
        type: seminarConstants.DELETE_SEMINAR_SHOW_NOTIFICATION_MODAL,
        payload: {
            seminarID,
            text: 'Are you sure you want to delete seminar?',
            method: notificationModalActions.DELETE_SEMINAR,
        },
    };
}

function deleteSeminar(seminarID, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .remove(seminarID)
            .then(() => {
                dispatch(successDelete());

                dispatch(enqueueNotification('Seminar deleted successfully', {variant: 'success'}));

                seminarService
                    .getAll(page, size)
                    .then(response => {
                        dispatch(successGetAll(response.data.content, response.data.totalElements));
                    })
                    .catch(() => {
                        dispatch(failureGetAll());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureDelete());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarConstants.REQUEST,
        };
    }
    function successDelete() {
        return {
            type: seminarConstants.DELETE_SEMINAR_SUCCESS,
        };
    }
    function failureDelete() {
        return {
            type: seminarConstants.DELETE_SEMINAR_ERROR,
        };
    }
    function successGetAll(seminars, totalElements) {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_SUCCESS,
            payload: {
                seminars,
                totalElements,
            },
        };
    }
    function failureGetAll() {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_ERROR,
        };
    }
}

function openEndSeminarModal(seminarID) {
    return {
        type: seminarConstants.EMD_SEMINAR_SHOW_NOTIFICATION_MODAL,
        payload: {
            seminarID,
            text:
                'Seminar end date will be updated. Seminar will not be editable, only removable by system admin. Are your sure you want to end seminar?',
            method: notificationModalActions.END_SEMINAR,
        },
    };
}

function changeSeminarStatus(seminarID, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .changeStatus(seminarID)
            .then(() => {
                dispatch(successChange());

                dispatch(enqueueNotification('Seminar ended successfully', {variant: 'success'}));

                seminarService
                    .getAll(page, size)
                    .then(response => {
                        dispatch(successGetAll(response.data.content, response.data.totalElements));
                    })
                    .catch(() => {
                        dispatch(failureGetAll());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureChange());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarConstants.REQUEST,
        };
    }
    function successChange() {
        return {
            type: seminarConstants.CHANGE_STATUS_SEMINAR_SUCCESS,
        };
    }
    function failureChange() {
        return {
            type: seminarConstants.CHANGE_STATUS_SEMINAR_ERROR,
        };
    }
    function successGetAll(seminars, totalElements) {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_SUCCESS,
            payload: {
                seminars,
                totalElements,
            },
        };
    }
    function failureGetAll() {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_ERROR,
        };
    }
}

function getAllSeminarsGA(page, size, search) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .getAllSeminarGA(page, size, search)
            .then(response => {
                dispatch(success(response.data.content, response.data.totalElements));
            })
            .catch(error => {
                dispatch(failure());
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: seminarConstants.REQUEST,
        };
    }
    function success(seminars, totalElements) {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_SUCCESS,
            payload: {
                seminars,
                totalElements,
            },
        };
    }
    function failure() {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_ERROR,
        };
    }
}

function loadAllSeminarsGA() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .getAllSeminarGA()
            .then(response => {
                dispatch(success(response.data.content));
            })
            .catch(error => {
                dispatch(failure());
                // if (error === errorRequest.UNAUTHORIZED) {
                //     userService
                //         // .refreshToken()
                //         .then(() => main(dispatch));
                // } else {
                //     dispatch(failure());
                // }
            });
    }
    function request() {
        return {
            type: seminarConstants.REQUEST,
        };
    }
    function success(seminars) {
        return {
            type: seminarConstants.LOAD_ALL_SEMINARS_SUCCESS,
            payload: {
                seminars,
            },
        };
    }
    function failure() {
        return {
            type: seminarConstants.LOAD_ALL_SEMINARS_ERROR,
        };
    }
}

function changeSeminarStatusGA(seminarID, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .changeStatusGA(seminarID)
            .then(() => {
                dispatch(successChange());

                dispatch(enqueueNotification('Seminar ended successfully', {variant: 'success'}));

                seminarService
                    .getAllSeminarGA(page, size)
                    .then(response => {
                        dispatch(successGetAll(response.data.content, response.data.totalElements));
                    })
                    .catch(() => {
                        dispatch(failureGetAll());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureChange());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarConstants.REQUEST,
        };
    }
    function successChange() {
        return {
            type: seminarConstants.CHANGE_STATUS_SEMINAR_SUCCESS,
        };
    }
    function failureChange() {
        return {
            type: seminarConstants.CHANGE_STATUS_SEMINAR_ERROR,
        };
    }
    function successGetAll(seminars, totalElements) {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_SUCCESS,
            payload: {
                seminars,
                totalElements,
            },
        };
    }
    function failureGetAll() {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_ERROR,
        };
    }
}

function openEndSeminarModalGA(seminarID) {
    return {
        type: seminarConstants.END_SEMINAR_GROUP_ADMIN_SHOW_NOTIFICATION_MODAL,
        payload: {
            seminarID,
            text:
                'Seminar end date will be updated. Seminar will not be editable, only removable by system admin. Are your sure you want to end seminar?',
            method: notificationModalActions.END_SEMINAR_GA,
        },
    };
}

function changePage(value) {
    return {
        type: seminarConstants.CHANGE_SEMINAR_PAGE,
        payload: {
            value,
        },
    };
}

function changeSize(value) {
    return {
        type: seminarConstants.CHANGE_SEMINAR_SIZE,
        payload: {
            value,
        },
    };
}

function changeSearch(value) {
    return {
        type: seminarConstants.CHANGE_SEMINAR_SEARCH,
        payload: {
            value,
        },
    };
}

function performSearch() {
    return {
        type: seminarConstants.PERFORM_SEMINAR_SEARCH,
        payload: {},
    };
}
