import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

function actionsToShow(actions) {
    let result = [];
    actions.forEach(item => {
        switch (item) {
            case 'SET_PASSWORD':
            case 'SIGN_IN':
            case 'SIGN_OUT':
            case 'CHANGE_USER_DATA':
            case 'LAB_START':
            case 'LAB_STARTING':
            case 'LAB_CREATING':
            case 'LAB_CREATE':
            case 'LAB_RECREATE':
            case 'LAB_STOPPING':
            case 'LAB_STOP':
            case 'VM_RESTART':
            case 'FAILED_TO_CREATING':
            case 'FAILED_TO_DELETING':
            case 'FAILED_TO_RESTARTING':
            case 'CHANGE_SEMINAR_DATA':
            case 'SEMINAR_FINISHED':
            case 'ADD_USER_TO_SEMINAR':
            case 'LAB_DELETING':
            case 'LAB_DELETE':
            case 'DELETE_LAB_FROM_SYSTEM':
                result.push(item);
                break;
            default:
                break;
        }
    });
    return result;
}

export { actionsToShow };
