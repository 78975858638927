import { urlConstant } from '../Constants';
import { userErrorRequests } from '../Handlers';
import Maintenance from '../Modules/maintenance.module';
import  {AxiosRequest} from "./AxiosApi";

const isRepair = Maintenance.getMaintenance();

export const usersAssignmentService = {
    getAll,
    getFromAllGroups,
    deleteUserFromGroup,
    updateUsersInGroup,
    openShowUsersModal,
    getUsersByGroupAndUsername,
    getAllUsersGroupAdmin,
};

function getAll(groupID, page, size) {

    const groupIDSend = typeof groupID === 'object' ? groupID : [groupID];
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/groups/users?page=${page}&size=${size}`,
            data: {
                groupIds: groupIDSend,
            },
        })
            .then(response => {
                // isRepair && console.log('Users of group', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('get all users by group', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getFromAllGroups() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/groups/users`,
        })
            .then(response => {
                // isRepair && console.log('Users of all groups', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('get all users by all groups', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function deleteUserFromGroup(groupID, userID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/admin/groups/user`,
            data: {
                groupId: groupID,
                userId: userID,
            },
        })
            .then(response => {
                // isRepair && console.log('User from group deleted successfully', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('delete user from group', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function updateUsersInGroup(addUserArray, removeUserArray, groupID) {
    const body = {
        groupId: groupID,
        addUsers: addUserArray,
        removeUsers: removeUserArray,
    };


    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/groups/assignUsers`,
            data: body,
        })
            .then(response => {
                // isRepair && console.log('Successful update users in group', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('update users in group', error);
                isRepair && console.error(errorMessage, error);
                reject(error.response.data.cause);
            });
    });
}

function openShowUsersModal(groupID) {

    return new Promise((resolve, reject) => {
        // if (!userName) userName = '';
        // if (!groupName) groupName = '';
        // const body = {groupName, query: userName};
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/groups/${groupID}/assignment`,
            // data: body
        })
            .then(response => {
                // isRepair && console.log('Successful getUsersByGroupAndUsername', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('get users by group and username', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getUsersByGroupAndUsername(groupIds, filterGroupId, userName) {

    return new Promise((resolve, reject) => {
        // if (!userName) userName = '';
        // if (!groupName) groupName = '';
        // const body = {groupName, query: userName};
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/groups/assignment`,
            data: {
                groupIds: [groupIds],
                filterGroupId,
                query: userName,
                // "groupIds": group,
                temporaryList: false,
            },
        })
            .then(response => {
                // isRepair && console.log('Successful getUsersByGroupAndUsername', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('get users by group and username', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getAllUsersGroupAdmin(groupID, page, size) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/groups/users?page=${page}&size=${size}`,
            data: {
                groupIds: groupID,
            },
        })
            .then(response => {
                // isRepair && console.log('Users of group', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('get all users by group', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}
