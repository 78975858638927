import React, { Component } from 'react';
import { Router } from 'react-router';
import { Route, Redirect, Switch } from 'react-router-dom';
import Auth from '../../Modules/auth.module';
import { history } from '../../Utils';
import {notifierActions, userProfileActions, webSocketActions} from '../../Actions';
import { connect } from 'react-redux';
import WebSocket from '../Dashboards/Shared/WebSocket/WebSocket';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers'
// import { render } from 'react-dom';



/* eslint-disable import/first */
import Async from 'react-code-splitting';

// Dashboards
const SignInContainer = () => <Async load={import('../SignInContainer/SignInContainer')} />;
const TeacherDashboard = () => <Async load={import('../Dashboards/Teacher/TeacherDashboard/TeacherDashboard')} />;
const GroupAdminDashboard = () => <Async load={import('../Dashboards/GroupAdmin/GroupAdminDashboard/GroupAdminDashboard')} />;
const StudentDashboard = () => <Async load={import('../Dashboards/Student/StudentDashboard/StudentDashboard')} />;

// Password
const ChangePassword = () => <Async load={import('../ChangePassword/ChangePassword')} />;
const ForgotPassword = () => <Async load={import('../ForgotPassword/ForgotPassword')} />;

// Modals
const StatusModal = () => <Async load={import('../Dashboards/Shared/Modals/StatusModal/StatusModal')} />;
const NotificationModal = () => <Async load={import('../Dashboards/Shared/Modals/NotificationModal/NotificationModal')} />;
const ConfirmModal = () => <Async load={import('../Dashboards/Shared/Modals/ConfirmModal/ConfirmModal')} />;

// import darkBaseTheme from 'mui/styles/baseThemes/darkBaseTheme';
// import getMuiTheme from 'mui/styles/getMuiTheme';
import { StyledEngineProvider  } from '@mui/material';
import './App.css';
import './App.media.css';
import Notifier from "../Dashboards/Shared/Notifier/Notifier";
import {userService} from "../../Services";
import moment from "moment/moment";
// import MomentUtils from '@date-io/moment';
import {AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { darkBaseThemeConst } from '../../Constants';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';

const redirectTo = () => {
    switch (Auth.getUserRole()) {
        case 'ROLE_ADMIN':
            return <Redirect to="/teacher-dashboard" />;
        case 'ROLE_GROUP_ADMIN':
            return <Redirect to="/group-admin-dashboard" />;
        case 'ROLE_USER':
            return <Redirect to="/student-dashboard" />;
        default:
            return <Redirect to="/sign-in" />;
    }
};

moment.updateLocale("en", {
    week: {
        dow: 1
    }
});

class App extends Component {
    componentDidMount = () => {
        console.log("component mount")
        this.props.getMaintenanceStatus();
        if (Auth.isUserAuthenticated() && Auth.getUserRole() !== 'ROLE_SEMINAR_USER') {
            this.props.getUserInfo();
        }
        this.props.setRefreshTokenInterval();
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        console.log("component update")
        if ((nextProps.webSocket.type === 'CHANGE_USER_ROLE' && Auth.getUserId() === nextProps.webSocket.userId)) {
            if (Auth.isUserAuthenticated()) {
                userService.refreshToken().then(res => {
                    this.props.enqueueNotification('Your role was changed. The page will be reloaded soon', {variant: 'info'});
                    setTimeout(() => window.location.reload(), 3000);
                })
            }

            nextProps.cleanReducer();
        }
        return true;
    }

    theme = createTheme({...darkBaseThemeConst});


    

    render = () => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
        <StyledEngineProvider  injectFirst={true}>
            <ThemeProvider theme={this.theme}>
                <div>
                    <Router history={history}>
                        <Switch>
                            <Route exact path="/" render={redirectTo} />
                            <Route path="/sign-in" render={() => (Auth.isUserAuthenticated() ? redirectTo() : <SignInContainer />)} />
                            <Route
                                path="/teacher-dashboard"
                                render={() => (Auth.isUserAuthenticated() && Auth.isUserAdmin() ? <TeacherDashboard /> : redirectTo())}
                            />
                            <Route
                                path="/group-admin-dashboard"
                                render={() =>
                                    Auth.isUserAuthenticated() && Auth.isUserGroupAdmin() ? <GroupAdminDashboard /> : redirectTo()
                                }
                            />
                            <Route
                                path="/student-dashboard"
                                render={() =>
                                    Auth.isUserAuthenticated() && !Auth.isUserGroupAdmin() && !Auth.isUserAdmin() ? (
                                        <StudentDashboard />
                                    ) : (
                                        redirectTo()
                                    )
                                }
                            />
                            <Route path="/change-password" render={() => <ChangePassword />} />
                            <Route path="/forgot-password" render={() => <ForgotPassword />} />
                        </Switch>
                    </Router>
                    <Notifier />
                    <StatusModal />
                    <NotificationModal />
                    <ConfirmModal/>
                    {/* <canvas id="demo-canvas"></canvas> */}
                    <WebSocket/>
                </div>
            </ThemeProvider >
        </StyledEngineProvider >
        </LocalizationProvider>
    );
}

const connectedApp = connect(
    state => {
        const { groups } = state.groupsManagement;

        return {
            groups,
            userInfo: state.userProfile.userInfo,
            webSocket: state.webSocket,
        };
    },
    {
        getMaintenanceStatus: userProfileActions.getMaintenanceStatus,
        getUserInfo: userProfileActions.getUserInfo,
        setRefreshTokenInterval: userProfileActions.setRefreshTokenInterval,
        cleanReducer: webSocketActions.cleanReducer,
        enqueueNotification: notifierActions.enqueueNotification,
    },
)(App);

export default App = connectedApp;
