export const seminarLabsConstants = {
    REQUEST: 'SEMINAR_LABS_REQUEST',
    GET_ALL_LABS_SUCCESS: 'SEMINAR_LABS_GET_ALL_LABS_SUCCESS',
    GET_ALL_LABS_ERROR: 'SEMINAR_LABS_GET_ALL_LABS_ERROR',
    CHANGE_SEMINAR: 'SEMINAR_LABS_CHANGE_SEMINAR',
    OPEN_DELETE_LAB_MODAL: 'SEMINAR_LABS_OPEN_DELETE_LAB_MODAL',
    SET_CURRENT_SEMINAR: 'SEMINAR_LABS_SET_CURRENT_SEMINAR',
    HANDLE_CLICK_LAB: 'HANDLE_CLICK_LAB',
    HANDLE_SELECT_ALL_CLICK_LAB: 'HANDLE_SELECT_ALL_CLICK_LAB',
    CHANGE_LABS_PAGE: 'SEMINAR_LABS_CHANGE_PAGE',
    CHANGE_LABS_SIZE: 'SEMINAR_LABS_CHANGE_SIZE',
    RECREATE_SEMINAR_LAB_SHOW_NOTIFICATION_MODAL: 'RECREATE_SEMINAR_LAB_SHOW_NOTIFICATION_MODAL',

    DELETE_LABS_FROM_SYSTEM_REQUEST: 'DELETE_LABS_FROM_SYSTEM_REQUEST',
    DELETE_LABS_FROM_SYSTEM_SUCCESS: 'DELETE_LABS_FROM_SYSTEM_SUCCESS',
    DELETE_LABS_FROM_SYSTEM_ERROR: 'DELETE_LABS_FROM_SYSTEM_ERROR',

    CHANGE_SEARCH_SEMINAR_LABS: 'CHANGE_SEARCH_SEMINAR_LABS',
    PERFORM_SEARCH_SEMINAR_LABS: 'PERFORM_SEARCH_SEMINAR_LABS',

    CLEAR_CHECKBOXES: 'CLEAR_CHECKBOXES',
    SAVE_CURRENT_SEMINAR_PAGE: 'SAVE_CURRENT_SEMINAR_PAGE'
};
