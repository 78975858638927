import { combineReducers } from 'redux';
import { userProfile } from './Others/userProfile.reducer';
import { currentGroup } from './Tables/currentGroup.reducer';
import { groupsManagement } from './Tables/groupsManagement.reducer';
import { userAssigning } from './Tables/userAssigning.reducer';
import { userManagement } from './Tables/userManagement.reducer';
import { addUserModal } from './Modals/addUserModal.reducer';
import { dropUsersFileModal } from './Modals/dropUsersFileModal.reducer';
import { signOutModal } from './Others/signOutModal.reducer';
import { signInSeminar } from './Others/signInSeminar.reducer';
import { statusModal } from './Modals/statusModal.reducer';
import { labsManagement } from './Tables/labsManagement.reducer';
import { historyManagement } from './Tables/historyManagement.reducer';
import { instancesTypesManagement } from './Tables/instancesTypesManagement.reducer';
import { labDetails } from './Tables/labDetails.reduser';
import { seminar } from './Tables/seminar.reducer';
import seminarHistory from './Tables/seminarHistory.reducer';
import { seminarUsers } from './Tables/seminarUsers.reducer';
import { seminarUsersPage } from './Tables/seminarUsersPage.reducer';
import { seminarLabs } from './Tables/seminarLabs.reducer';
import { addGroupModal } from './Modals/addGroupModal.reducer';
import { updateGroupModal } from './Modals/updateGroupModal.reducer';
import { addLabTemplateModal } from './Modals/addLabTemplateModal.reducer';
import { updateLabTemplateModal } from './Modals/updateLabTemplateModal.reducer';
import { addLabModal } from './Modals/addLabModal.reducer';
import { showUsersModal } from './Modals/showUsersModal.reducer';
import { editUserModal } from './Modals/editUserModal.reducer';
import { addRoleModal } from './Modals/addRoleModal.reducer';
import { addSeminarModal } from './Modals/addSeminarModal.reducer';
import { teacherProfileEditEmailModal } from './Modals/teacherProfileEditEmailModal.reducer';
import { assignGroupModal } from './Modals/assignGroupModal.reducer';
import { assignLabTypeModal } from './Modals/assignLabTypeModal.reducer';
import { countdownModal } from './Modals/countdownModal.reducer';
import { editSeminarModal } from './Modals/editSeminarModal.reducer';
import { labLimitationModal } from './Modals/labLimitationModal.reducer';
import { timeFrameModal } from './Modals/timeFrameModal.reducer';
import { reportsDetailsModal } from './Modals/reportsDetailsModal.reducer';
import { templateVersionModal } from './Modals/templateVersionModal.reducer';
import { studentSeminarLabs } from './Tables/studentSeminarLabs.reducer';
import { reportsManagement } from './Tables/reportsManagement.reducer';
import { addLabTemplate } from './Tables/addLabTemplate.reducer';
import { templatesDifference } from './Tables/templatesDifference.reducer';
import blueprints from './Tables/blueprints.reducer.js';
import labTemplates from './Tables/labTemplates.reducer';
import editInstance from './Tables/editInstance.reducer';
import { webSocket } from './Others/webSocket.reducer';
import {notification} from "./Modals/notifier.reducer";
import {confirmModal} from "./Modals/confirmModal.reducer";
import {templateShareVersionModal} from "./Modals/templateShareVersionModal.reducer";
import {RESET_APP} from "../Constants";
import {labTemplateInstances} from "./Modals/labTemplateInstances.reducer";
import {groupListModal} from "./Modals/groupListModal.reducer";
import {assignedAdminsModal} from "./Modals/assignedAdminsModal.reducer";

const rootReducer = combineReducers({
    blueprints,
    labTemplates,
    editInstance,
    userProfile,
    currentGroup,
    groupsManagement,
    userAssigning,
    userManagement,
    addUserModal,
    dropUsersFileModal,
    signOutModal,
    signInSeminar,
    statusModal,
    labsManagement,
    historyManagement,
    instancesTypesManagement,
    labDetails,
    seminar,
    seminarHistory,
    seminarUsers,
    seminarUsersPage,
    seminarLabs,
    addGroupModal,
    updateGroupModal,
    addLabTemplateModal,
    updateLabTemplateModal,
    addLabModal,
    showUsersModal,
    editUserModal,
    addRoleModal,
    addSeminarModal,
    teacherProfileEditEmailModal,
    assignGroupModal,
    assignLabTypeModal,
    countdownModal,
    editSeminarModal,
    labLimitationModal,
    timeFrameModal,
    reportsDetailsModal,
    templateVersionModal,
    studentSeminarLabs,
    reportsManagement,
    addLabTemplate,
    templatesDifference,
    webSocket,
    confirmModal,
    notification,
    templateShareVersionModal,
    labTemplateInstances,
    groupListModal,
    assignedAdminsModal
});

const appReducer = (state, action) => {
    if (action.type === RESET_APP) {
        state = undefined;
    }

    return rootReducer(state, action);
}

export default appReducer;