import { errorRequest } from '../Constants';
import Auth from '../Modules/auth.module';

export const historyErrorRequests = (requestType, error) => {
    if (error.response) {
        const errorRes = error.response;

        switch (requestType) {
            case 'get computer ip':
            case 'get all history':
            case 'get action list': {
                switch (errorRes.status) {
                    case 400: {
                        return `Error to ${requestType}`;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }

            case 'get seminar history':
                switch (errorRes.status) {
                    case 500:
                        return errorRes.data.error;
                    default:
                        return errorRes.DEFAULT;
                }

            default: {
                switch (errorRes.status) {
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
        }
    } else {
        Auth.deauthenticateUser();
        return errorRequest.DEFAULT;
    }
};
