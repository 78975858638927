import { studentSeminarLabsConstants } from '../../Constants';
import { arraysUtil } from '../../Utils';
import _ from "lodash";

const initialState = {
    labs: [],
    requestIsActive: false,
    allStudentLabsStatus: {
        started: false,
        stopped: false,
        pending: false,
        inProgress: false,
    },
    checkedID: [],
    size: 10,
    sizeList: [10, 20, 30],
    page: 0,
    totalElements: 0,
};

export function studentSeminarLabs(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case studentSeminarLabsConstants.REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case studentSeminarLabsConstants.GET_ALL_LABS_SUCCESS: {
            const seminarLabs = action.payload.labs.content;
            const labs = seminarLabs.map(item => ({
                ...item,
                seminarEndTime: item.stopTime,
                seminarStartTime: item.startTime,
                seminarName: item.name,
                seminarState: item.status,
            }));

            return {
                ...state,
                requestIsActive: false,
                labs,
                allStudentLabsStatus: arraysUtil.labsStatusSetter(labs),
                totalElements: action.payload.labs.totalElements
            };
        }
        case studentSeminarLabsConstants.GET_ALL_LABS_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case studentSeminarLabsConstants.HANDLE_CLICK_LAB: {
            const { checkedID } = state;
            const { id } = action.payload;
            const selectedIndex = checkedID.indexOf(id);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(checkedID, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(checkedID.slice(1));
            } else if (selectedIndex === checkedID.length - 1) {
                newSelected = newSelected.concat(checkedID.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(checkedID.slice(0, selectedIndex), checkedID.slice(selectedIndex + 1));
            }
            return {
                ...state,
                checkedID: newSelected,
            };
        }
        case studentSeminarLabsConstants.HANDLE_SELECT_ALL_CLICK_LAB: {
            const { checked } = action.payload;
            const { labs } = state;
            if (!checked) {
                return {
                    ...state,
                    checkedID: labs.map(n => n.id),
                };
            } else {
                return {
                    ...state,
                    checkedID: [],
                };
            }
        }

        case studentSeminarLabsConstants.CHANGE_PAGE:
            return {
                ...state,
                page: action.payload.value
            }

        case studentSeminarLabsConstants.CHANGE_PAGE_SIZE:
            return {
                ...state,
                size: action.payload.value,
                page: 0
            }
        default:
            return state;
    }
}
