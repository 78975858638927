import { instancesTypesManagementService, userService } from '../Services';
import { dataListsManagementConstants, errorRequest, modalConstants, notificationModalActions } from '../Constants';
import {notifierActions} from "./notifier.actions";

const {enqueueNotification} = notifierActions;

export const instancesTypesActions = {
    getAllInstancesTypes,
    getAllInstancesTypesDropdown,
    showErrorWrongFile,
    changeLabTemplateName,
    addLabTemplate,
    uploadFile,
    deleteLabTypeModalShow,
    deleteLabType,
    updateLabTemplateModalChangeForm,
    updateLabType,
    updateLabTypeNoChanges,
    updateLabTypeShowErrorMessage,
    updateLabTypeHideErrorMessage,
    getGroupAdminInstancesTypes,
    getGroupAdminInstancesTypesDropdown,
    addNewUserShowErrorMessage,
    addNewUserHideErrorMessage,
    getAllBlueprintsList,
    deleteBlueprintModalShow,
    deleteBlueprint,
    changeLabTemplatePage,
    changeLabTemplatePageSize,
    changeBlueprintPage,
    changeBlueprintPageSize,
    getLabTemplateById,
    getBlueprintById,
    changeLabTemplateSearch,
    performSearch,
    disableSearch,
    setSharedFilter
};

function getAllInstancesTypes(page, size, search, shared, sendPending = true) {
    return dispatch => {
        // Requset pending
        if (sendPending) {
            dispatch({ type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_REQUEST });
        }

        // Init request
        main(dispatch);

        // Request
        function main(dispatch) {
            instancesTypesManagementService
                .getAllInstancesTypes(page, size, search, shared)
                .then(response => {
                    // Request success
                    dispatch({
                        type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_SUCCESS,
                        payload: response.data,
                    });
                })
                .catch(error => {
                    if (error === errorRequest.UNAUTHORIZED) {
                        // Retry
                        userService.refreshToken().then(() => main(dispatch));
                    } else {
                        // Request failed
                        dispatch({ type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_ERROR });
                    }
                });
        }
    };
}

function getAllInstancesTypesDropdown() {
    return dispatch => {
        // Request pending
        dispatch({ type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_DROPDOWN_REQUEST });

        // Init request
        main(dispatch);

        // Request
        function main(dispatch) {
            instancesTypesManagementService
                .getAllInstancesTypesDropdown()
                .then(instancesTypes => {
                    // Request success
                    dispatch({
                        type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_DROPDOWN_SUCCESS,
                        payload: instancesTypes.data,
                    });
                })
                .catch(error => {
                    if (error === errorRequest.UNAUTHORIZED) {
                        // Retry
                        userService.refreshToken().then(() => main(dispatch));
                    } else {
                        // Request failed
                        dispatch({ type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_DROPDOWN_ERROR });
                    }
                });
        }
    };
}

function showErrorWrongFile() {
    return {
        type: modalConstants.SHOW_ERROR_WRONG_FILE,
        payload: {
            message: 'Wrong file type',
        },
    };
}

function changeLabTemplateName(name) {
    return {
        type: modalConstants.CHANGE_LAB_TEMPLATE_NAME,
        payload: {
            name,
        },
    };
}

function addLabTemplate(acceptedFile, name, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        instancesTypesManagementService
            .addLabTemplate(acceptedFile, name)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Lab template created successfully', {variant: 'success'}));
                dispatch(requestLabTemplates());
                instancesTypesManagementService
                    .getAllInstancesTypes(page, size)
                    .then(instancesTypes => {
                        dispatch(successLabTemplates(instancesTypes.data));
                    })
                    .catch(() => {
                        dispatch(failureLabTemplates());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.ADD_LAB_TEMPLATE_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.ADD_LAB_TEMPLATE_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.ADD_LAB_TEMPLATE_ERROR,
        };
    }
    function requestLabTemplates() {
        return {
            type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_REQUEST,
        };
    }
    function successLabTemplates(instancesTypes) {
        return {
            type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_SUCCESS,
            payload: {
                instancesTypes,
            },
        };
    }
    function failureLabTemplates() {
        return {
            type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_ERROR,
        };
    }
}

function uploadFile(acceptedFile) {
    return {
        type: modalConstants.UPLOAD_LAB_TEMPLATE_FILE,
        payload: {
            acceptedFile,
        },
    };
}

function deleteLabTypeModalShow(labTemplateID) {
    return {
        type: modalConstants.DELETE_LAB_TEMPLATE_MODAL_SHOW,
        payload: {
            text: 'Are you sure, you want to delete lab template?',
            method: notificationModalActions.DELETE_LAB_TEMPLATE,
            labTemplateID,
        },
    };
}

function deleteLabType(id) {
    return (dispatch, getState) => {
        //Request pending
        dispatch({ type: modalConstants.DELETE_LAB_TEMPLATE_REQUEST });

        // Init request
        main(dispatch, getState);

        // Request
        function main(dispatch, getState) {
            instancesTypesManagementService
                .deleteLabTemplate(id)
                .then(() => {
                    // Request success
                    const { page, size } = getState().labTemplates.pagination;
                    const {search} = getState().labTemplates;
                    dispatch({ type: modalConstants.DELETE_LAB_TEMPLATE_SUCCESS });

                    dispatch(enqueueNotification('Lab template deleted successfully', {variant: 'success'}));
                    dispatch({ type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_REQUEST });

                    // Update lab templates table data
                    getAllInstancesTypes(page, size, search)(dispatch);
                })
                .catch(error => {
                    if (error === errorRequest.UNAUTHORIZED) {
                        // Retry
                        userService.refreshToken().then(() => main(dispatch, getState));
                    } else {
                        // Request failed
                        dispatch({ type: modalConstants.DELETE_LAB_TEMPLATE_ERROR });
                        dispatch(enqueueNotification(String(error), {variant: 'error'}));
                    }
                });
        }
    };
}

function updateLabTemplateModalChangeForm(event) {
    return {
        type: modalConstants.UPDATE_LAB_TEMPLATE_MODAL_CHANGE_FORM,
        payload: {
            event,
        },
    };
}

function updateLabType(instanceTypeId, newName, oldName, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        instancesTypesManagementService
            .updateLabTemplate(instanceTypeId, newName, oldName)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Lab template updated successfully', {variant: 'success'}));
                dispatch(requestLabTemplates());

                instancesTypesManagementService
                    .getAllInstancesTypes(page, size)
                    .then(instancesTypes => {
                        dispatch(successLabTemplates(instancesTypes.data));
                    })
                    .catch(() => {
                        dispatch(failureLabTemplates());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.UPDATE_LAB_TEMPLATE_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.UPDATE_LAB_TEMPLATE_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.UPDATE_LAB_TEMPLATE_ERROR,
        };
    }
    function requestLabTemplates() {
        return {
            type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_REQUEST,
        };
    }
    function successLabTemplates(instancesTypes) {
        return {
            type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_SUCCESS,
            payload: {
                instancesTypes,
            },
        };
    }
    function failureLabTemplates() {
        return {
            type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_ERROR,
        };
    }
}

function updateLabTypeNoChanges() {
    return {
        type: modalConstants.UPDATE_LAB_TEMPLATE_NO_CHANGES,
        payload: {
            errorMessage: 'Lab type name has not changed',
        },
    };
}

function updateLabTypeShowErrorMessage(event) {
    return {
        type: modalConstants.UPDATE_LAB_TEMPLATE_SHOW_ERROR_MESSAGE,
        payload: {
            event,
        },
    };
}

function updateLabTypeHideErrorMessage() {
    return {
        type: modalConstants.UPDATE_LAB_TEMPLATE_HIDE_ERROR_MESSAGE,
    };
}

function getGroupAdminInstancesTypes(page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        instancesTypesManagementService
            .getGroupAdminInstancesTypes(page, size)
            .then(instancesTypes => {
                dispatch(success(instancesTypes.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_REQUEST,
        };
    }
    function success(instancesTypes) {
        return {
            type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_SUCCESS,
            payload: {
                instancesTypes,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_ERROR,
        };
    }
}

function getGroupAdminInstancesTypesDropdown() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        instancesTypesManagementService
            .getGroupAdminInstancesTypesDropdown()
            .then(instancesTypes => {
                dispatch(success(instancesTypes.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_DROPDOWN_REQUEST,
        };
    }
    function success(instancesTypesDropdown) {
        return {
            type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_DROPDOWN_SUCCESS,
            payload: instancesTypesDropdown,
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_DROPDOWN_ERROR,
        };
    }
}

function addNewUserShowErrorMessage(event) {
    return {
        type: modalConstants.ADD_LAB_TEMEPLATE_SHOW_ERROR_MESSAGE,
        payload: {
            event,
        },
    };
}

function addNewUserHideErrorMessage() {
    return {
        type: modalConstants.ADD_LAB_TEMEPLATE_HIDE_ERROR_MESSAGE,
    };
}

function changeLabTemplatePage(page) {
    return {
        type: dataListsManagementConstants.CHANGE_LAB_TEMPLATE_PAGE,
        payload: page,
    };
}

function changeLabTemplatePageSize(size) {
    return {
        type: dataListsManagementConstants.CHANGE_LAB_TEMPLATE_PAGE_SIZE,
        payload: size,
    };
}

function changeBlueprintPage(page) {
    return {
        type: dataListsManagementConstants.CHANGE_BLUEPRINT_PAGE,
        payload: page,
    };
}

function changeBlueprintPageSize(size) {
    return {
        type: dataListsManagementConstants.CHANGE_BLUEPRINT_PAGE_SIZE,
        payload: size,
    };
}

function getAllBlueprintsList(page, size, search) {
    return dispatch => {
        // Request pending
        dispatch({ type: dataListsManagementConstants.GET_ALL_BLUEPRINTS_LIST_REQUEST });

        // Init request
        main(dispatch);

        // Request
        function main(dispatch) {
            instancesTypesManagementService
                .getBlueprintsList(page, size, search)
                .then(response => {
                    // Success
                    dispatch({
                        type: dataListsManagementConstants.GET_ALL_BLUEPRINTS_LIST_SUCCESS,
                        payload: response.data,
                    });
                })
                .catch(error => {
                    if (error === errorRequest.UNAUTHORIZED) {
                        // Retry
                        userService.refreshToken().then(() => main(dispatch));
                    } else {
                        // Request failed
                        dispatch({
                            type: dataListsManagementConstants.GET_ALL_BLUEPRINTS_LIST_ERROR,
                            payload: {
                                text: error,
                            },
                        });
                    }
                });
        }
    };
}

function deleteBlueprintModalShow(labTemplateID) {
    return {
        type: modalConstants.DELETE_BLUEPRINT_MODAL_SHOW,
        payload: {
            text: 'Are you sure, you want to delete blueprint?',
            method: notificationModalActions.DELETE_BLUEPRINT,
            labTemplateID,
        },
    };
}

function deleteBlueprint(id) {
    return (dispatch, getState) => {
        // Request pending
        dispatch({ type: dataListsManagementConstants.DELETE_BLUEPRINT_REQUEST });

        // Init request
        main(dispatch, getState);

        // Request
        function main(dispatch, getState) {
            instancesTypesManagementService
                .deleteBlueprint(id)
                .then(response => {
                    // Request success
                    const { page, size } = getState().blueprints.pagination;
                    const {search} = getState().labTemplates;
                    dispatch({
                        type: dataListsManagementConstants.DELETE_BLUEPRINT_SUCCESS,
                        payload: response.data,
                    });

                    dispatch(enqueueNotification('Blueprint deleted successfully', {variant: 'success'}));
                    // Update Blueprints table data
                    getAllBlueprintsList(page, size, search)(dispatch);
                })
                .catch(error => {
                    if (error === errorRequest.UNAUTHORIZED) {
                        // Retry
                        userService.refreshToken().then(() => main(dispatch, getState));
                    } else {
                        // Request failed
                        dispatch({
                            type: dataListsManagementConstants.DELETE_BLUEPRINT_ERROR,
                            payload: { text: error },
                        });
                    }
                });
        }
    };
}

function getLabTemplateById(id, onSuccess) {
    return dispatch => {
        instancesTypesManagementService
            .getEditableTemplate(id)
            .then(res => {
                dispatch({ type: dataListsManagementConstants.GET_LAB_TEMPLATE_BY_ID_SUCCESS, payload: res.data });
                onSuccess(res.data);
            })
            .catch(err => {
                // TODO: что делать во время ошибки?
                dispatch({ type: dataListsManagementConstants.GET_LAB_TEMPLATE_BY_ID_ERROR });
                console.log(err);
            });
    };
}

function getBlueprintById(id, onSuccess) {
    return dispatch => {
        instancesTypesManagementService
            .getEditableBlueprint(id)
            .then(res => {
                dispatch({ type: dataListsManagementConstants.GET_BLUEPRINT_BY_ID_SUCCESS, payload: res.data });
                onSuccess(res.data);
            })
            .catch(err => {
                // TODO: что делать во время ошибки?
                dispatch({ type: dataListsManagementConstants.GET_BLUEPRINT_BY_ID_ERROR });
                console.log(err);
            });
    };
}

function changeLabTemplateSearch(search) {
    return {
        type: dataListsManagementConstants.CHANGE_LAB_TEMPLATE_SEARCH,
        payload: search
    }
}

function performSearch() {
    return {
        type: dataListsManagementConstants.PERFORM_INSTANCES_TYPES_SEARCH
    }
}

function disableSearch() {
    return {
        type: dataListsManagementConstants.DISABLE_INSTANCES_TYPES_SEARCH
    }
}

function setSharedFilter(shared) {
    return {
        type: dataListsManagementConstants.SET_SHARED_FILTER,
        payload: shared
    }
}