import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../Reducers';

const middlewares = [];
middlewares.push(thunkMiddleware);

if (process.env.NODE_ENV === `development`) {
    const { createLogger } = require(`redux-logger`);

    const logger = createLogger({
        collapsed: true
    })

    middlewares.push(logger);
}

export const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(...middlewares),
        window.devToolsExtension ? window.devToolsExtension() : f => f,
    ),
);
