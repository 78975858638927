export const labDetailsConstants = {
    SAVE_LAB_DETAILS_INFO: 'SAVE_LAB_DETAILS_INFO',
    GET_LAB_DETAILS_INFO_BY_ID_REQUEST: 'GET_LAB_DETAILS_INFO_BY_ID_REQUEST',
    GET_LAB_DETAILS_INFO_BY_ID_SUCCESS: 'GET_LAB_DETAILS_INFO_BY_ID_SUCCESS',
    GET_LAB_DETAILS_INFO_BY_ID_ERROR: 'GET_LAB_DETAILS_INFO_BY_ID_ERROR',

    HIDE_BUTTONS: 'LAB_DETAILS_HIDE_BUTTONS',
    SET_HIDE_BUTTONS: 'SET_LAB_DETAILS_HIDE_BUTTONS',
    SHOW_LOADER: 'LAB_DETAILS_SHOW_LOADER',
    SHOW_THUMBNAIL: 'SHOW_THUMBNAIL',
    HIDE_THUMBNAIL: 'HIDE_THUMBNAIL',

    SET_IMAGE_URL_REQUEST: 'SET_IMAGE_URL_REQUEST',
    SET_IMAGE_URL_SUCCESS: 'SET_IMAGE_URL_SUCCESS',
    SET_IMAGE_URL_ERROR: 'SET_IMAGE_URL_ERROR',

    INSTANCE_RESET_SUCCESS: 'INSTANCE_RESET_SUCCESS',

    RESTART_INSTANCE_BY_ADMIN_REQUEST: 'RESTART_INSTANCE_BY_ADMIN_REQUEST',
    RESTART_INSTANCE_BY_ADMIN_SUCCESS: 'RESTART_INSTANCE_BY_ADMIN_SUCCESS',
    RESTART_INSTANCE_BY_ADMIN_ERROR: 'RESTART_INSTANCE_BY_ADMIN_ERROR',
    CLEAN_INSTANCE: 'CLEAN_INSTANCE'
};
