import Auth from '../Modules/auth.module';
import { urlConstant } from '../Constants';
import { userErrorRequests, formatingValidatedCsvData } from '../Handlers';
import Maintenance from '../Modules/maintenance.module';
import {AxiosRequest} from "./AxiosApi";

const isRepair = Maintenance.getMaintenance();

export const userManagementService = {
    addNew,
    getAll,
    deleteUsersStatus,
    deleteUsers,
    deleteUser,
    editUser,
    editMaintenance,
    resetPassword,
    setUserRole,
    getAssignedLabTypeToGroupAdmin,
    assignGroupsToGroupAdmin,
    getAssignedGroupsToGroupAdmin,
    assignLabTypesToGroupAdmin,
    validateCSVFile,
    sendValidatedDataFromCsvFile,
    editUserEmail,
    getGroupAdmins,
    getGroupAdminsGA,
    getUserGroups
};

function sendValidatedDataFromCsvFile(userData, groupIdList) {
    return new Promise((resolve, reject) => {
        const bodyFormData = formatingValidatedCsvData(userData, groupIdList);
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/users/batch`,
            data: bodyFormData,
        })
            .then(response => {
                // isRepair && console.log('Users from svg file have been sent successfully', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('sign up batch status', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}
function validateCSVFile(file) {

    return new Promise((resolve, reject) => {
        let bodyFormData = new FormData();
        bodyFormData.set('file', file);
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/users/batch`,
            data: bodyFormData,
        })
            .then(response => {
                // isRepair && console.log('Users from svg file have been validated successfully', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('sign up batch status', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function addNew(user, groupIds) {
    const { email, username, limitationMinutes } = user;


    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/users`,
            data: {
                email,
                username,
                groupIdList: groupIds,
                limitationMinutes: +limitationMinutes,
            },
        })
            .then(response => {
                // isRepair && console.log('User registered successfully', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('add user', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getAll(page, size, search, group, roleFilter, maintenanceFilter) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/users?page=${page}&size=${size}`,
            data: {
                query: `${search}`,
                groupIds: group,
                authorityName: roleFilter,
                maintenance: maintenanceFilter,
                temporaryList: false,
            },
        })
            .then(response => {
                // isRepair && console.log('Users', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('get all', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function deleteUsersStatus(groupID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/groups/users/${groupID}`,
        })
            .then(response => {
                // isRepair && console.log('Users delete status', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('delete users status', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function deleteUsers(groupId, usersId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/admin/groups/users`,
            data: {
                groupId,
                usersId,
            },
        })
            .then(response => {
                // isRepair && console.log('Users deleted successfully');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('delete users', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function deleteUser(userID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/admin/users`,
            data: userID
        })
            .then(response => {
                // isRepair && console.log('User deleted successful', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('delete user', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function editUser(userId, newName, newEmail, limitation) {

    const userRole = Auth.getUserRole();
    let role = '';
    if (userRole === 'ROLE_ADMIN') {
        role = 'admin';
    } else if (userRole === 'ROLE_GROUP_ADMIN') {
        role = 'groupAdmin';
    }
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/${role}/users/edit`,
            data: {
                userId,
                newName,
                newEmail,
                limitationMinutes: +limitation,
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('edit users', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function editMaintenance(userId, maintenance) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/users/maintenance`,
            data: {
                userId,
                maintenance,
            },
        })
            .then(response => {
                // isRepair && console.log('Edit maintenance', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('edit maintenance', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function resetPassword(userID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/users/password`,
            data: {
                usersId: userID,
            },
        })
            .then(response => {
                // isRepair && console.log('Password reset status', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('password reset status', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function setUserRole(userId, groupId, roleName) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/users/role`,
            data: {
                usersId: userId,
                groupId,
                roleName: roleName,
            },
        })
            .then(response => {
                // isRepair && console.log('User role set successfully', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('set role', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getAssignedLabTypeToGroupAdmin(userId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/templates/assign/${userId}`,
        })
            .then(response => {
                // isRepair && console.log('getAssignedLabTypeToGroupAdmin', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('instance type', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function assignGroupsToGroupAdmin(userId, groupIds) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/users/group`,
            data: { userId, groupIds },
        })
            .then(response => {
                // isRepair && console.log('assignGroupsToGroupAdmin', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('add group', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getAssignedGroupsToGroupAdmin(groupAdminId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/users/group/${groupAdminId}`,

        })
            .then(response => {
                // isRepair && console.log('getAssignedGroupsToGroupAdmin', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('get group', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function assignLabTypesToGroupAdmin(userId, labTypeIDs) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/templates/assign`,
            data: {
                userId: userId,
                templatesId: labTypeIDs,
            },
        })
            .then(response => {
                // isRepair && console.log('Lab types added successfully', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('add lab type', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function editUserEmail(newEmail) {
    const userRole = Auth.getUserRole();
    let role = '';
    if (userRole === 'ROLE_ADMIN') {
        role = 'admin';
    } else if (userRole === 'ROLE_GROUP_ADMIN') {
        role = 'groupAdmin';
    } else if (userRole === 'ROLE_USER') {
        role = 'user';
    }

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/${role}/email`,
            data: {
                newEmail,
            },
        })
            .then(response => {
                // isRepair && console.log('Edit email', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('edit email', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupAdmins() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/seminar/admins`,
        })
            .then(response => {
                // isRepair && console.log('getGroupAdmins', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('get group admins', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupAdminsGA() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/groupAdmin/seminar/admins`,
        })
            .then(response => {
                // isRepair && console.log('getGroupAdmins', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('get group admins', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getUserGroups(id) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/users/assignedGroups/${id}`
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('get user', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    })
}