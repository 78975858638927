import { modalConstants } from '../../Constants';
import { reducersUtil } from '../../Utils';
import _ from "lodash";

const initialState = {
    updateLabTemplateModalIsOpen: false,
    info: {
        labTemplateID: '',
        labName: '',
        oldLabName: '',
    },
    isRequestActive: false,
    errorMessage: '',
};

export function updateLabTemplateModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.TOGGLE_UPDATE_LAB_TEMPLATE_MODAL: {
            if (state.updateLabTemplateModalIsOpen) {
                return initialState;
            } else {
                return {
                    ...state,
                    updateLabTemplateModalIsOpen: true,
                    info: {
                        labTemplateID: action.payload.labTemplateID,
                        labName: action.payload.labName,
                        oldLabName: action.payload.labName,
                    },
                };
            }
        }
        case modalConstants.UPDATE_LAB_TEMPLATE_MODAL_CHANGE_FORM:
            const { value } = action.payload.event.target;
            return {
                ...state,
                info: {
                    ...state.info,
                    labName: value,
                },
            };
        case modalConstants.UPDATE_LAB_TEMPLATE_REQUEST:
            return {
                ...state,
                isRequestActive: true,
            };
        case modalConstants.UPDATE_LAB_TEMPLATE_SUCCESS:
            return initialState;
        case modalConstants.UPDATE_LAB_TEMPLATE_ERROR:
            return {
                ...state,
                isRequestActive: false,
                updateLabTemplateModalIsOpen: false,
            };
        case modalConstants.UPDATE_LAB_TEMPLATE_NO_CHANGES:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
            };
        case modalConstants.UPDATE_LAB_TEMPLATE_SHOW_ERROR_MESSAGE: {
            const { value } = action.payload.event.target;
            return {
                ...state,
                errorMessage: reducersUtil.generateLabTemplateManagementErrorMessage(value),
            };
        }
        case modalConstants.UPDATE_LAB_TEMPLATE_HIDE_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: '',
            };
        default:
            return state;
    }
}
