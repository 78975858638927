import { reportsManagementConstants } from '../../Constants';

const initialState = {
    reportsDetails: '',
    isOpen: false,
    requestIsActive: false,
};

export function reportsDetailsModal(state = initialState, action) {
    switch (action.type) {
        case reportsManagementConstants.OPEN_REPORTS_DETAILS_MODAL:
            return {
                ...state,
                isOpen: true,
            };
        case reportsManagementConstants.CLOSE_REPORTS_DETAILS_MODAL:
            return {
                initialState,
            };
        case reportsManagementConstants.GET_REPORTS_DETAILS_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case reportsManagementConstants.GET_REPORTS_DETAILS_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                reportsDetails: action.payload.reportsDetails,
            };
        default:
            return state;
    }
}
