import {groupListModalConstants} from "../../Constants/Actions/groupListModal.constants";
import _ from "lodash";

const initialState = {
    open: false,
    user: null
};

export function groupListModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case groupListModalConstants.OPEN_GROUP_LIST_MODAL:
            return {
                ...state,
                open: true,
                user: action.payload
            }

        case groupListModalConstants.CLOSE_GROUP_LIST_MODAL:
            return {
                ...state,
                open: false
            }

        default:
            return state;
    }
}