import { dataListsManagementConstants, templateEditorConstants } from '../../Constants';

const initialState = {
    isRequestActive: false,
    blueprintName: '',
    blueprintId: '',
    templateId: null,
    path: '',
    json: {},
    fileName: '',
    comment: '',
    status: undefined,
    isJsonEdited: false,
    editable: true,
    currentVersion: null,
    currentVersionNumber: null,
    currentVersionComment: '',
    versions: [],
    instancesTypesDropdown: [],
    instanceVersion: null,
    instanceTypeId: null,
    declinedMessage: null,
    declinedTime: null,
    description: ''
};

const editInstance = (state = initialState, { type, payload }) => {
    switch (type) {
        case dataListsManagementConstants.GET_LAB_TEMPLATE_BY_ID_SUCCESS: {
            const json = (payload.currentVersion ? payload.currentVersion.json : "{}");
            return {
                ...state,
                templateId: payload.id,
                blueprintName: payload.name,
                path: payload.path,
                fileName: payload.fileName,
                currentVersion: payload.currentVersion ? payload.currentVersion.id : null,
                currentVersionNumber: payload.currentVersion ? payload.currentVersion.versionNumber : null,
                currentVersionComment: payload.currentVersion ? payload.currentVersion.comment : '',
                instanceVersion: null,
                instanceTypeId: null,
                isJsonEdited: false,
                status: payload.currentVersion ? payload.currentVersion.validationState : null,
                editable: true,
                comment: '',
                json,
                description: payload.currentVersion ? payload.currentVersion.description || '' : '',
                declinedMessage: payload.currentVersion ? payload.currentVersion.declinedMessage || '' : ''
            };
        }
        case dataListsManagementConstants.GET_LAB_TEMPLATE_BY_ID_ERROR:
            return state;
        case dataListsManagementConstants.GET_BLUEPRINT_BY_ID_SUCCESS: {
            const json = JSON.parse(payload.json, null, 2);
            // FIXME: костыль потому что parentVersion может приходить обьектом,а может
            // просто null
            payload.parentVersion = payload.parentVersion
                ? payload.parentVersion
                : { versionId: null, versionNumber: null, comment: '' };
            return {
                ...state,
                blueprintId: payload.blueprintId,
                templateId: payload.templateId,
                blueprintName: payload.name,
                path: payload.path,
                fileName: payload.fileName,
                currentVersion: payload.parentVersion.versionId,
                currentVersionNumber: payload.parentVersion.versionNumber,
                currentVersionComment: payload.parentVersion.comment,
                instanceVersion: null,
                instanceTypeId: null,
                isJsonEdited: false,
                status: payload.state,
                editable: false,
                comment: '',
                json,
                declinedMessage: payload.declinedMessage,
                declinedTime: payload.declinedTime,
                description: payload.description || ''
            };
        }
        case dataListsManagementConstants.GET_BLUEPRINT_BY_ID_ERROR:
            return state;
        case templateEditorConstants.SAVE_INSTANCE_AS_DRAFT_SUCCESS: {
            const json = JSON.parse(payload.currentVersion.json, null, 2);
            return {
                ...state,
                blueprintId: payload.blueprintId,
                templateId: payload.id,
                blueprintName: payload.name,
                path: payload.path,
                fileName: payload.fileName,
                status: payload.currentVersion.validationState,
                isJsonEdited: false,
                json,
            };
        }
        case dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_DROPDOWN_REQUEST:
            return state;
        case dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_DROPDOWN_SUCCESS:
            return { ...state, instancesTypesDropdown: payload };
        case dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_DROPDOWN_ERROR:
            return state;
        case templateEditorConstants.CHANGE_INSTANCE: {
            const json = JSON.parse(payload.currentVersion ? payload.currentVersion.json : '{}');
            return {
                ...state,
                instanceTypeId: payload.id,
                fileName: payload.fileName,
                path: payload.path,
                versions: payload.versions,
                instanceVersion: payload.currentVersion.id,
                isJsonEdited: true,
                json,
            };
        }
        case templateEditorConstants.CHANGE_INSTANCE_VERSION: {
            let versionObj = state.versions.find(version => version.id === payload);
            let newJson = JSON.parse(versionObj.json, null, 2);
            return {
                ...state,
                instanceVersion: payload,
                json: newJson,
                isJsonEdited: true,
            };
        }
        case templateEditorConstants.CHANGE_INSTANCE_NAME_JSON_EDITED:
            return {
                ...state,
                isJsonEdited: payload.isJsonEdited
            }
        case templateEditorConstants.CHANGE_INSTANCE_NAME:
            return {
                ...state,
                blueprintName: payload.name
            };
        case templateEditorConstants.UPLOAD_NEW_INSTANCE:
            return {
                ...state,
                path: payload.path,
                fileName: payload.fileName,
                isJsonEdited: true,
                instanceVersion: null,
                instanceTypeId: null,
                json: payload.json,
            };
        // FIXME: add error handling
        case templateEditorConstants.SAVE_INSTANCE_AS_DRAFT_ERROR:
            return state;

        case templateEditorConstants.EDIT_INSTANCE_JSON_FILE:
            return { ...state, json: payload, isJsonEdited: true };

        case templateEditorConstants.EDIT_INSTANCE_COMMENT:
            return { ...state, comment: payload, isJsonEdited: true };

        case templateEditorConstants.EDIT_INSTANCE_DESCRIPTION:
            return {
                ...state,
                description: payload,
                isJsonEdited: true
            }

        case templateEditorConstants.CLEAR_INSTANCE_JSON_FILE:
            return { ...state, json: {}, isJsonEdited: true };

        case templateEditorConstants.DISCARD_BLUEPRINT:
            return initialState;

        case templateEditorConstants.CLEAR_INSTANCE_SELECTION:
            return {
                ...state,
                versions: [],
                instanceVersion: null
            }

        default:
            return state;
    }
};

export default editInstance;
