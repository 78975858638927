import { urlConstant } from '../Constants';
import {labErrorRequests} from '../Handlers';
import Maintenance from '../Modules/maintenance.module';
import {AxiosRequest} from "./AxiosApi";
import _ from 'lodash';

const isRepair = Maintenance.getMaintenance();

export const labsManagementService = {
    getAllLabs,
    addForGroup,
    getLabsInGroup,
    getUserLabs,
    getUserLabsInGroup,
    getCurrentLabsInGroupGA,
    getMyLabsInGroupGA,
    addForGroupAdmin,
};

function getAllLabs() {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/labs`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get all', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getLabsInGroup(query, groupID, page, size, statusFilter, templateName, userName, labId, sort) {
    let filteredSortId = '';
    switch (sort && sort.id) {
        case 'id':
        case 'group.name':
        case 'template.name':
        case 'templateVersion':
        case 'status':
            filteredSortId = sort.id;
            break;

        case 'user.userName':
            filteredSortId = _.toLower(sort.id);
            break;

        case 'user.userId':
            filteredSortId = 'user.id';
            break;

        case 'countdown_timer':
            filteredSortId = 'stopTime';
            break;

        case 'instance_ip':
            filteredSortId = 'ipAddress';
            break;

        case 'last_action':
            filteredSortId = 'updatedTime';
            break;

        default:
    }

    let sortPart = filteredSortId ? `${(sort.id)? `&sort=${filteredSortId},${sort.desc ? 'desc' : 'asc'}` : ''}` : '';
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/labs/?page=${page}&size=${size}${sortPart}`,
            data: {
                query: query,
                groupIds: groupID[0] === 'All groups' ? [] : groupID,
                templateNames: [],
                status: statusFilter === 'All statuses' ? null : statusFilter,
                templateName,
                userName,
                labId
            },
        })
            .then(response => {
                response.data.content = response.data.content.map(lab => {
                    if (lab.group.name === 'local-Freelance' || lab.group.name === 'dev-Freelance' || lab.group.name === 'Freelance') {
                        lab.group.name = '(no group)';
                    }
                    return lab;
                })
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get all', error);
                reject(errorMessage);
            });
    });
}

function addForGroup(groupID, labTypeID) {
    // const body = {
    //     groupId: groupID,
    //     instanceTypeId: labTypeID
    // };

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/groups/addLabs`,
            data: {
                labTypeId: labTypeID,
                groupId: groupID,
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('add lab for group', error);
                reject(errorMessage);
            });
    });
}

function addForGroupAdmin(groupID, labTypeID) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/groupAdmin/addLabs`,
            data: {
                labTypeId: labTypeID,
                groupId: groupID,
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('add lab for group', error);
                reject(errorMessage);
            });
    });
}

function getUserLabs() {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/user/labs`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get all', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getUserLabsInGroup(userName, groupID, page, size) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/user/labs/?page=${page}&size=${size}`,
            data: {
                query: userName,
                groupIds: groupID,
                templateNames: [],
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('get all', error);
                // reject(errorMessage);
            });
    });
}

function getCurrentLabsInGroupGA(groupID, page, size, username, statusFilter) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/labs?page=${page}&size=${size}`,
            data: {
                groupIds: groupID,
                query: username,
                status: statusFilter
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get current labs for GA', error);
                reject(errorMessage);
            });
    });
}

function getMyLabsInGroupGA(userID, page, size, groupID) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/user/labs?page=${page}&size=${size}`,
            data: {
                userId: userID,
                groupIds: groupID
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get current labs for GA', error);
                reject(errorMessage);
            });
    });
}
