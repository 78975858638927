import { historyManagementService, userService } from '../Services';
import { dataListsManagementConstants, errorRequest, seminarHistoryConstants } from '../Constants';
import {notifierActions} from "./notifier.actions";

const {enqueueNotification} = notifierActions;

export const historyManagementActions = {
    setDateAction,
    changeSearch,
    changeAction,
    changeSize,
    changePage,
    changeSorting,
    changeSeminarSorting,
    toggleHistoryDetailsModal,
    clearPageCounter,
    performSearch,
    disableSearch,
    cleanUserHistoryReducer,
    setCurrentSize,
    changeTab,
    getUserHistory,
    getLabsHistory,
    getGroupsHistory,
    getLabTemplatesHistory,
    getUserActionList,
    getLabsActionList,
    getGroupsActionList,
    getTemplatesActionList,
    getSeminarsActionList,
    getSeminarsHistory,
    changeSeminarHistoryPage,
    changeSeminarHistorySize,
    toggleIncludeTestData
};

function changeSeminarHistorySize(value) {
    return {
        type: seminarHistoryConstants.CHANGE_SEMINAR_HISTORY_SIZE,
        payload: {
            value,
        },
    };
}

function changeSeminarHistoryPage(value) {
    return {
        type: seminarHistoryConstants.CHANGE_SEMINAR_HISTORY_PAGE,
        payload: {
            value,
        },
    };
}

function getSeminarsActionList() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        historyManagementService
            .getSeminarsActionList()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                }
            });
    }
    function success(list) {
        return {
            type: dataListsManagementConstants.GET_SEMINARS_ACTIONS,
            payload: {
                list,
            },
        };
    }
}

function getSeminarsHistory(info, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        historyManagementService
            .getSeminarsHistory(info.body, page, size)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarHistoryConstants.GET_SEMINARS_HISTORY_REQUEST,
        };
    }
    function success(history) {
        return {
            type: seminarHistoryConstants.GET_SEMINARS_HISTORY_SUCCESS,
            payload: {
                history,
            },
        };
    }
    function failure() {
        return {
            type: seminarHistoryConstants.GET_SEMINARS_HISTORY_ERROR,
        };
    }
}

function getUserActionList() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        historyManagementService
            .getUserActionList()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                }
            });
    }
    function success(list) {
        return {
            type: dataListsManagementConstants.GET_USER_ACTIONS,
            payload: {
                list,
            },
        };
    }
}

function getLabsActionList() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        historyManagementService
            .getLabsActionList()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                }
            });
    }
    function success(list) {
        return {
            type: dataListsManagementConstants.GET_LABS_ACTIONS,
            payload: {
                list,
            },
        };
    }
}

function getGroupsActionList() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        historyManagementService
            .getGroupsActionList()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                }
            });
    }
    function success(list) {
        return {
            type: dataListsManagementConstants.GET_GROUPS_ACTIONS,
            payload: {
                list,
            },
        };
    }
}

function getTemplatesActionList() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        historyManagementService
            .getTemplatesActionList()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                }
            });
    }
    function success(list) {
        return {
            type: dataListsManagementConstants.GET_TEMPLATES_ACTIONS,
            payload: {
                list,
            },
        };
    }
}

function setDateAction(action, value, date) {
    return {
        type: dataListsManagementConstants.SET_DATE_ACTION,
        payload: {
            action,
            value,
            date,
        },
    };
}

function changeSearch(value) {
    return {
        type: dataListsManagementConstants.CHANGE_SEARCH,
        payload: {
            value,
        },
    };
}

function changeAction(value) {
    return {
        type: dataListsManagementConstants.CHANGE_ACTION,
        payload: {
            value,
        },
    };
}

function changeSize(value) {
    return {
        type: dataListsManagementConstants.CHANGE_SIZE,
        payload: {
            value,
        },
    };
}

function changePage(value) {
    return {
        type: dataListsManagementConstants.CHANGE_PAGE,
        payload: {
            value,
        },
    };
}

function changeSorting(field, direction) {
    return {
        type: dataListsManagementConstants.CHANGE_SORTING,
        payload: {
            field,
            direction,
        },
    };
}

function changeSeminarSorting(field, direction) {
    return {
        type: seminarHistoryConstants.CHANGE_SEMINAR_SORTING,
        payload: {
            field,
            direction,
        },
    };
}

function toggleHistoryDetailsModal(item) {
    return {
        type: dataListsManagementConstants.TOGGLE_HISTORY_DETAILS_MODAL,
        payload: {
            item,
        },
    };
}

function clearPageCounter() {
    return {
        type: dataListsManagementConstants.CLEAR_PAGE_COUNTER,
    };
}

function performSearch() {
    return {
        type: dataListsManagementConstants.PERFORM_HISTORY_SEARCH,
    };
}

function disableSearch() {
    return {
        type: dataListsManagementConstants.DISABLE_HISTORY_SEARCH,
    };
}

function cleanUserHistoryReducer() {
    return {
        type: dataListsManagementConstants.CLEAN_USER_HISTORY_REDUCER,
    };
}

function setCurrentSize(value) {
    return {
        type: dataListsManagementConstants.SET_CURRENT_HISTORY_SIZE,
        payload: {
            value,
        },
    };
}

function changeTab(value) {
    return {
        type: dataListsManagementConstants.CHANGE_TAB,
        payload: {
            value,
        },
    };
}

function getUserHistory(info, page, size, sortField, sortDirection) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        historyManagementService
            .getUserHistory(info.body, page, size, sortField, sortDirection)
            .then(response => {
                // console.log('Seccess in action', response.data);
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_USER_HISTORY_REQUEST,
        };
    }
    function success(history) {
        return {
            type: dataListsManagementConstants.GET_USER_HISTORY_SUCCESS,
            payload: {
                history,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_USER_HISTORY_ERROR,
        };
    }
}

function getLabsHistory(info, page, size, sortField, sortDirection) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        historyManagementService
            .getLabsHistory(info.body, page, size, sortField, sortDirection)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_LABS_HISTORY_REQUEST,
        };
    }
    function success(history) {
        return {
            type: dataListsManagementConstants.GET_LABS_HISTORY_SUCCESS,
            payload: {
                history,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_LABS_HISTORY_ERROR,
        };
    }
}

function getGroupsHistory(info, page, size, sortField, sortDirection) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        historyManagementService
            .getGroupsHistory(info.body, page, size, sortField, sortDirection)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_GROUPS_HISTORY_REQUEST,
        };
    }
    function success(history) {
        return {
            type: dataListsManagementConstants.GET_GROUPS_HISTORY_SUCCESS,
            payload: {
                history,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_GROUPS_HISTORY_ERROR,
        };
    }
}

function getLabTemplatesHistory(info, page, size, sortField, sortDirection) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        historyManagementService
            .getTemplatesHistory(info.body, page, size, sortField, sortDirection)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_TEMPLATE_HISTORY_REQUEST,
        };
    }
    function success(history) {
        return {
            type: dataListsManagementConstants.GET_TEMPLATE_HISTORY_SUCCESS,
            payload: {
                history,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_TEMPLATE_HISTORY_ERROR,
        };
    }
}

function toggleIncludeTestData() {
    return {
        type: dataListsManagementConstants.TOGGLE_INCLUDE_TEST_DATA
    }
}