import { modalConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    modalIsOpen: false,
    requestIsActive: false,
    info: {
        userId: '',
        name: [],
        groupId: [],
        userGroups: []
    },
};

export function assignGroupModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.TOGGLE_ASSIGN_GROUP_MODAL: {
            if (state.modalIsOpen) {
                return initialState;
            } else {
                return {
                    ...state,
                    modalIsOpen: true,
                    info: {
                        ...state.info,
                        userId: action.payload.userId,
                    },
                };
            }
        }
        case modalConstants.ASSIGN_GROUP_CHANGE_FORM: {
            const { name, value } = action.payload.event.target;
            let resultArray = [];

            value.forEach(item => {
                if (!(value.length > 1 && item === 'default')) {
                    resultArray.push(item);
                }
            });

            return {
                ...state,
                info: {
                    ...state.info,
                    [name]: resultArray,
                },
            };
        }
        case modalConstants.ASSIGN_GROUP_SET_GROUP_NAME:
            return {
                ...state,
                info: {
                    ...state.info,
                    name: [action.payload.groupName],
                },
            };
        case modalConstants.ASSIGN_GROUP_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case modalConstants.ASSIGN_GROUP_SUCCESS:
            return initialState;
        case modalConstants.GET_ASSIGNED_GROUPS_SUCCESS: {
            const array = action.payload.groups.groupDtosWhereGA.map(item => item.name);
            return {
                ...state,
                info: {
                    ...state.info,
                    userId: action.payload.userId,
                    name: array,
                    userGroups: action.payload.groups.groupDtosWhereGA
                },
                modalIsOpen: true,
            };
        }
        default:
            return state;
    }
}
