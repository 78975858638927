import { dataListsManagementConstants, modalConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    groupUsers: [],
    userID: '',
    requestIsActive: false,
    notificationModal: {
        isOpen: false,
        text: '',
        method: '',
    },
    needToGetUsers: true,
    addLabModal: {
        isOpen: false,
    },
    groupID: '',
    size: 20,
    sizeList: [10, 20, 30],
    page: 0,
    total: 3,
    totalElements: 0,
};

export function userAssigning(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.REMOVE_USER_FROM_GROUP_NOTIF_MODAL_SHOW:
            return {
                ...state,
                notificationModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                },
                userID: action.payload.userId,
                groupID: action.payload.groupID,
            };
        case modalConstants.CLOSE_NOTIFICATION_MODAL:
            return {
                ...state,
                notificationModal: {
                    ...initialState.notificationModal,
                },
                userID: '',
                groupID: '',
            };
        case dataListsManagementConstants.GET_USERS_BY_GROUP_REQUEST:
        case dataListsManagementConstants.GET_USERS_ALL_GROUPS_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case dataListsManagementConstants.GET_USERS_BY_GROUP_SUCCESS:
        case dataListsManagementConstants.GET_USERS_ALL_GROUPS_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                groupUsers: action.payload.groupUsers.content,
                needToGetUsers: false,
                totalElements: action.payload.groupUsers.totalElements,
            };
        case dataListsManagementConstants.GET_USERS_BY_GROUP_ERROR:
        case dataListsManagementConstants.GET_USERS_ALL_GROUPS_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case dataListsManagementConstants.SET_CURRENT_GROUP:
            return {
                ...state,
                needToGetUsers: true,
                page: 0,
            };
        case modalConstants.REMOVE_USER_FROM_GROUP_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case modalConstants.REMOVE_USER_FROM_GROUP_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                notificationModal: {
                    ...initialState.notificationModal,
                },
                needToGetUsers: true,
            };
        case modalConstants.REMOVE_USER_FROM_GROUP_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case modalConstants.GET_USERS_BELONGS_SUCCESS_WITHOUT_REOPEN_MODAL:
            return {
                ...state,
                requestIsActive: false,
                groupUsers: action.payload.users,
            };
        case dataListsManagementConstants.CHANGE_USER_ASSIGN_PAGE:
            return {
                ...state,
                page: action.payload.value,
            };
        case dataListsManagementConstants.CHANGE_USER_ASSIGN_SIZE:
            return {
                ...state,
                size: action.payload.value,
                page: 0,
            };
        case dataListsManagementConstants.USER_ASSIGN_RESET_PAGE:
            return {
                ...state,
                page: 0,
            };
        default:
            return state;
    }
}
