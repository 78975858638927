export const editSeminarModalConstants = {
    OPEN_MODAL: 'OPEN_EDIT_SEMINAR_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL_EDIT_SEMINAR_MODAL',

    REQUEST: 'EDIT_SEMINAR_MODAL_REQUEST',

    CHANGE_LAB_TEMPLATE: 'EDIT_SEMINAR_MODAL_CHANGE_LAB_TEMPLATE',
    CHANGE_FORM_DATA: 'EDIT_SEMINAR_MODAL_CHANGE_FORM_DATA',
    SHOW_ERROR: 'EDIT_SEMINAR_MODAL_SHOW_ERROR',
    HIDE_ERROR: 'EDIT_SEMINAR_MODAL_HIDE_ERROR',
    CHANGE_DATE: 'EDIT_SEMINAR_MODAL_CHANGE_DATE',
    CHANGE_GROUP_ADMIN: 'EDIT_SEMINAR_MODAL_CHANGE_GROUP_ADMIN',
    CHANGE_FIXED_NET_ID: 'CHANGE_FIXED_NET_ID',

    GET_ALL_GROUP_ADMINS_SUCCESS: 'EDIT_SEMINAR_MODAL_GET_ALL_GROUP_ADMINS_SUCCESS',
    GET_ALL_GROUP_ADMINS_ERROR: 'EDIT_SEMINAR_MODAL_GET_ALL_GROUP_ADMINS_ERROR',

    EDIT_SEMINAR_SUCCESS: 'EDIT_SEMINAR_SUCCESS',
    EDIT_SEMINAR_ERROR: 'EDIT_SEMINAR_ERROR',
    SHOW_DATE_OVERFLOW_ERROR: 'EDIT_SEMINAR_MODAL_SHOW_DATE_OVERFLOW_ERROR',

    GET_SEMINARS_IN_MODAL_SUCCESS: 'GET_SEMINARS_IN_MODAL_SUCCESS',
    GET_SEMINARS_IN_MODAL_ERROR: 'GET_SEMINARS_IN_MODAL_ERROR',
};
