import { errorRequest, notificationModalActions, seminarUsersConstants } from '../../Constants';
import { seminarService, userService } from '../../Services';
import { seminarUtil } from '../../Utils';
import Seminars from '../../Modules/seminar.module';
import {notifierActions} from "../notifier.actions";

import {seminarConstants} from "../../Constants/Actions/seminar/seminar.constants";
const { enqueueNotification} = notifierActions;

export const seminarUsersPageActions = {
    getSeminars,
    getSeminarsGA,
    getSeminarUsersByID,
    getGroupAdminSeminarUsersByID,
    changeSeminar,
    openDeleteSeminarPageUserModal,
    openDeleteSeminarPageUserModalGroupAdmin,
    deleteUserSeminarUserPage,
    deleteUserSeminarUserPageGroupAdmin,
    handleClick,
    handleSelectAllClick,
    resetPasswordOpenModal,
    resetPasswordOpenModalGroupAdmin,
    resetPasswordsOpenModal,
    resetPasswordsOpenModalGroupAdmin,
    resetPassword,
    resetPasswordGroupAdmin,
    openDeleteCheckedUsers,
    openDeleteCheckedUsersGroupAdmin,
    changePage,
    changeSize,
    changeSearch,
    performSearch,
};

function getSeminars(page, size, search) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .getAllUsersPage()
            .then(response => {
                dispatch(success(response.data));
                let seminarID;
                if (Seminars.getSeminarId() !== null && seminarUtil.isSeminarExits(response.data, Number(Seminars.getSeminarId()))) {
                    seminarID = Number(Seminars.getSeminarId());
                } else {
                    // seminarID = response.data.length ? response.data[0].id : null;
                    seminarID = 'all';
                    Seminars.setSeminarId(seminarID);
                }

                if (seminarID !== null) {
                    seminarService
                        .getSeminarUsersByID(seminarID !== 'all' ? [seminarID] : null, page, size, search)
                        .then(response => {
                            dispatch(successGetUsers(response.data, seminarID));
                        })
                        .catch(error => {
                            // dispatch(failureGetAll(error));
                        });
                } else {
                    console.log('Failure');
                    // dispatch(failureGetAll('no seminars'));
                }
            })
            .catch(error => {
                dispatch(failure());
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: seminarUsersConstants.GET_ALL_SEMINARS_USERS_PAGE_REQUEST,
        };
    }
    function success(seminars) {
        return {
            type: seminarUsersConstants.GET_ALL_SEMINARS_USERS_PAGE_SUCCESS,
            payload: {
                seminars,
            },
        };
    }
    function failure() {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_ERROR,
        };
    }
    function successGetUsers(data, seminarID) {
        return {
            type: seminarUsersConstants.GET_USERS_SEMINARS_USERS_PAGE_SUCCESS,
            payload: {
                users: data.content,
                seminarID,
                data,
            },
        };
    }
}

function getSeminarsGA(page, size, search) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .getAllSeminarGA(page, size, search)
            .then(response => {
                dispatch(success(response.data.content));
                let seminarID;
                if (
                    Seminars.getSeminarId() !== null &&
                    seminarUtil.isSeminarExits(response.data.content, Number(Seminars.getSeminarId()))
                ) {
                    seminarID = Number(Seminars.getSeminarId());
                } else {
                    // seminarID = response.data.content.length ? response.data.content[0].id : null;
                    seminarID = 'all';
                    Seminars.setSeminarId(seminarID);
                }

                if (seminarID !== null) {
                    seminarService
                        .getGroupAdminSeminarUsersByID(seminarID !== 'all' ? [seminarID] : null, page, size, search)
                        .then(response => {
                            dispatch(successUsers(response.data, seminarID));
                        })
                        .catch(error => {
                            // dispatch(failureGetAll(error));
                        });
                } else {
                    console.log('Failure');
                    // dispatch(failureGetAll('no seminars'));
                }
            })
            .catch(error => {
                dispatch(failure());
                // if (error === errorRequest.UNAUTHORIZED) {
                //     userService
                //         // .refreshToken()
                //         .then(() => main(dispatch));
                // } else {
                //     dispatch(failure());
                // }
            });
    }
    function request() {
        return {
            type: seminarUsersConstants.GET_ALL_SEMINARS_USERS_PAGE_REQUEST,
        };
    }
    function success(seminars) {
        return {
            type: seminarUsersConstants.GET_ALL_SEMINARS_USERS_PAGE_SUCCESS,
            payload: {
                seminars,
            },
        };
    }
    function failure() {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_ERROR,
        };
    }
    function successUsers(data, seminarID) {
        return {
            type: seminarUsersConstants.GET_USERS_SEMINARS_USERS_PAGE_SUCCESS,
            payload: {
                users: data.content,
                seminarID,
                data,
            },
        };
    }
}

function getSeminarUsersByID(seminarID, page, size, search) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .getSeminarUsersByID(seminarID !== 'all' ? [seminarID] : null, page, size, search)
            .then(response => {
                dispatch(success(response.data, seminarID));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: seminarUsersConstants.REQUEST,
        };
    }
    function success(data, seminarID) {
        return {
            type: seminarUsersConstants.GET_USERS_SEMINARS_USERS_PAGE_SUCCESS,
            payload: {
                users: data.content,
                seminarID,
                data,
            },
        };
    }
    function failure() {
        return {
            type: seminarUsersConstants.GET_USERS_SEMINARS_USERS_PAGE_ERROR,
        };
    }
}

function getGroupAdminSeminarUsersByID(seminarID, page, size, search) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .getGroupAdminSeminarUsersByID((seminarID !== 'all' ? [seminarID] : null), page, size, search)
            .then(response => {
                dispatch(success(response.data, seminarID));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: seminarUsersConstants.REQUEST,
        };
    }
    function success(data, seminarID) {
        return {
            type: seminarUsersConstants.GET_USERS_SEMINARS_USERS_PAGE_SUCCESS,
            payload: {
                users: data.content,
                seminarID,
                data,
            },
        };
    }
    function failure() {
        return {
            type: seminarUsersConstants.GET_USERS_SEMINARS_USERS_PAGE_ERROR,
        };
    }
}

function changeSeminar(event, index, value) {
    return {
        type: seminarUsersConstants.CHANGE_SEMINAR_USERS_PAGE,
        payload: {
            seminarID: value,
        },
    };
}

function openDeleteSeminarPageUserModal(userID) {
    return {
        type: seminarUsersConstants.DELETE_USER_MODAL_SEMINAR_PAGE,
        payload: {
            userID,
            text: 'Are you sure, you want to delete user?',
            method: notificationModalActions.DELETE_SEMINAR_PAGE_USER,
        },
    };
}

function openDeleteSeminarPageUserModalGroupAdmin(userID) {
    return {
        type: seminarUsersConstants.DELETE_USER_MODAL_SEMINAR_PAGE,
        payload: {
            userID,
            text: 'Are you sure, you want to delete user?',
            method: notificationModalActions.DELETE_SEMINAR_PAGE_USER_GROUP_ADMIN,
        },
    };
}

function deleteUserSeminarUserPage(seminarID, userID, page, size) {
    console.log('page', page);
    console.log('size', size);
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .deleteUser(userID)
            .then(() => {
                dispatch(successDelete());

                dispatch(enqueueNotification('User deleted successfully', {variant: 'success'}));

                seminarService
                    .getSeminarUsersByID((seminarID && seminarID[0] === 'all') ? null : seminarID, page, size)
                    .then(response => {
                        dispatch(successGetAll(response.data, seminarID[0]));
                    })
                    .catch(() => {
                        dispatch(failureGetAll());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureDelete(error));
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarUsersConstants.DELETE_USER_SEMINAR_USER_PAGE_REQUEST,
        };
    }
    function successDelete() {
        return {
            type: seminarUsersConstants.DELETE_USER_SEMINAR_USER_PAGE_SUCCESS,
        };
    }
    function failureDelete() {
        return {
            type: seminarUsersConstants.DELETE_USER_SEMINAR_USER_PAGE_ERROR,
        };
    }
    function successGetAll(data, seminarID) {
        return {
            type: seminarUsersConstants.GET_USERS_SEMINARS_USERS_PAGE_SUCCESS,
            payload: {
                users: data.content,
                seminarID,
                data,
            },
        };
    }
    function failureGetAll() {
        return {
            type: seminarUsersConstants.GET_USERS_SEMINARS_USERS_PAGE_ERROR,
        };
    }
}

function deleteUserSeminarUserPageGroupAdmin(seminarID, userID, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .deleteUserGroupAdmin(userID)
            .then(() => {
                dispatch(successDelete());

                dispatch(enqueueNotification('User deleted successfully', {variant: 'success'}));

                seminarService
                    .getGroupAdminSeminarUsersByID(seminarID, page, size)
                    .then(response => {
                        dispatch(successGetAll(response.data, seminarID[0]));
                    })
                    .catch(() => {
                        dispatch(failureGetAll());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureDelete(error));
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarUsersConstants.DELETE_USER_SEMINAR_USER_PAGE_REQUEST,
        };
    }
    function successDelete() {
        return {
            type: seminarUsersConstants.DELETE_USER_SEMINAR_USER_PAGE_SUCCESS,
        };
    }
    function failureDelete() {
        return {
            type: seminarUsersConstants.DELETE_USER_SEMINAR_USER_PAGE_ERROR,
        };
    }
    function successGetAll(users, seminarID) {
        return {
            type: seminarUsersConstants.GET_USERS_SEMINARS_USERS_PAGE_SUCCESS,
            payload: {
                users: users.content,
                seminarID,
            },
        };
    }
    function failureGetAll() {
        return {
            type: seminarUsersConstants.GET_USERS_SEMINARS_USERS_PAGE_ERROR,
        };
    }
}

function handleClick(id) {
    return {
        type: seminarUsersConstants.HANDLE_CLICK_USERS_PAGE,
        payload: {
            id,
        },
    };
}

function handleSelectAllClick(checked) {
    return {
        type: seminarUsersConstants.HANDLE_CLICK_ALL_USERS_PAGE,
        payload: {
            checked,
        },
    };
}

function resetPasswordOpenModal(userID) {
    return {
        type: seminarUsersConstants.RESET_PASSWORD_USER_PAGE_SHOW_NOTIFICATION_MODAL,
        payload: {
            text: 'Are you sure, you want to reset password?',
            method: notificationModalActions.RESET_PASSWORD_SEMINAR_USER,
            userID,
        },
    };
}

function resetPasswordOpenModalGroupAdmin(userID) {
    return {
        type: seminarUsersConstants.RESET_PASSWORD_USER_PAGE_SHOW_NOTIFICATION_MODAL,
        payload: {
            text: 'Are you sure, you want to reset password for this user?',
            method: notificationModalActions.RESET_PASSWORD_SEMINAR_USER_GROUP_ADMIN,
            userID,
        },
    };
}

function resetPasswordsOpenModal() {
    return {
        type: seminarUsersConstants.RESET_PASSWORDS_USER_PAGE_SHOW_NOTIFICATION_MODAL,
        payload: {
            text: 'Are you sure, you want to reset passwords for these users?',
            method: notificationModalActions.RESET_PASSWORDS_SEMINAR_USERS,
        },
    };
}

function resetPasswordsOpenModalGroupAdmin() {
    return {
        type: seminarUsersConstants.RESET_PASSWORDS_USER_PAGE_SHOW_NOTIFICATION_MODAL,
        payload: {
            text: 'Are you sure, you want to reset passwords for these users?',
            method: notificationModalActions.RESET_PASSWORDS_SEMINAR_USERS_GROUP_ADMIN,
        },
    };
}

function resetPassword(userID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .resetPasswordSeminarUser(userID)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('User password will be stored on next sign into the seminar', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    console.log('error', error);
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarUsersConstants.RESET_PASSWORD_SEMINAR_USER_REQUEST,
        };
    }
    function success() {
        return {
            type: seminarUsersConstants.RESET_PASSWORD_SEMINAR_USER_SUCCESS,
        };
    }
    function failure() {
        return {
            type: seminarUsersConstants.RESET_PASSWORD_SEMINAR_USER_ERROR,
        };
    }
}

function resetPasswordGroupAdmin(userID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .resetPasswordSeminarUserGroupAdmin(userID)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('User password will be stored on next sign into the seminar', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    console.log('error', error);
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarUsersConstants.RESET_PASSWORD_SEMINAR_USER_REQUEST,
        };
    }
    function success() {
        return {
            type: seminarUsersConstants.RESET_PASSWORD_SEMINAR_USER_SUCCESS,
        };
    }
    function failure() {
        return {
            type: seminarUsersConstants.RESET_PASSWORD_SEMINAR_USER_ERROR,
        };
    }
}

function openDeleteCheckedUsers(userID) {
    return {
        type: seminarUsersConstants.DELETE_CHECKED_USERS_MODAL_SEMINAR_PAGE,
        payload: {
            userID,
            text: 'Are you sure, you want to delete users?',
            method: notificationModalActions.DELETE_CHECKED_USERS_SEMINAR_PAGE_USER,
        },
    };
}

function openDeleteCheckedUsersGroupAdmin(userID) {
    return {
        type: seminarUsersConstants.DELETE_CHECKED_USERS_MODAL_SEMINAR_PAGE,
        payload: {
            userID,
            text: 'Are you sure, you want to delete users?',
            method: notificationModalActions.DELETE_CHECKED_USERS_SEMINAR_PAGE_USER_GROUP_ADMIN,
        },
    };
}

function changePage(value) {
    return {
        type: seminarUsersConstants.CHANGE_PAGE_SEMINAR_USERS_TAB,
        payload: {
            value,
        },
    };
}

function changeSize(value) {
    return {
        type: seminarUsersConstants.CHANGE_SIZE_SEMINAR_USERS_TAB,
        payload: {
            value,
        },
    };
}

function changeSearch(value) {
    return {
        type: seminarUsersConstants.CHANGE_SEARCH_SEMINAR_USERS_TAB,
        payload: {
            value,
        },
    };
}

function performSearch() {
    return {
        type: seminarUsersConstants.PERFORM_SEARCH_SEMINAR_USERS_TAB,
        payload: {},
    };
}
