import { userManagementService, userService } from '../Services';
import { dataListsManagementConstants, errorRequest, modalConstants, notificationModalActions } from '../Constants';
import {notifierActions} from "./notifier.actions";

const {enqueueNotification} = notifierActions;

export const usersManagementActions = {
    getAllUsers,
    addNewUser,
    addNewUserChangeForm,
    deleteUserShowNotificationModal,
    addNewUserSetGroupName,
    deleteUser,
    editUser,
    editUserChangeForm,
    editUserNoChanges,
    editUserShowErrorMessage,
    editUserHideErrorMessage,
    resetPasswordShowNotificationModal,
    resetPassword,
    handleClick,
    onSelectAllClick,
    toggleAddRoleModal,
    addRoleChangeForm,
    setUserRole,
    performSearch,
    disableSearch,
    clearCheckboxes,
    changePage,
    changeSize,
    uncheckAll,
    assignGroupChangeForm,
    assignGroupSetGroupName,
    addNewUserShowErrorMessage,
    addNewUserHideErrorMessage,
    submitAssignGroup,
    getAssignedGroupsToGroupAdmin,
    setMaintenanceFilter,
    changeSearch,
    assignLabTypeChangeForm,
    assignLabTemplateSetLabName,
    submitAssignLabType,
    getBulkFileStatus,
    addNewBulkUsersSetGroupName,
    addNewBulkUsersChangeForm,
    addNewBulkUsers,
};

function addNewUserHideErrorMessage(event) {
    return {
        type: modalConstants.ADD_NEW_USER_HIDE_ERROR_MESSAGE,
        payload: {
            event,
        },
    };
}

function addNewBulkUsersChangeForm(name = [], id = []) {
    return {
        type: modalConstants.ADD_BULK_FILE_CHANGE_FORM,
        payload: {
            name,
            id,
        },
    };
}

function addNewBulkUsersSetGroupName(groupName) {
    return {
        type: modalConstants.ADD_BULK_FILE_SET_GROUP_NAME,
        payload: {
            groupName,
        },
    };
}

function addNewBulkUsers(acceptedFile, groupID, page, size, search, group) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .sendValidatedDataFromCsvFile(acceptedFile, groupID)
            // addCSVFile(acceptedFile, groupID)
            .then(() => {
                dispatch(success());
                dispatch(requestGetAllUsers());
                userManagementService
                    .getAll(page, size, search, group)
                    .then(users => {
                        dispatch(successGetAllUsers(users.data));
                    })
                    .catch(() => {
                        dispatch(failureGetAllUsers());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.ADD_BULK_FILE_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.ADD_BULK_FILE_SUCCESS,
        };
    }
    function failure(error) {
        return {
            type: modalConstants.ADD_BULK_FILE_ERROR,
            payload: {
                text: error,
            },
        };
    }
    function requestGetAllUsers() {
        return {
            type: dataListsManagementConstants.GET_ALL_USERS_REQUEST,
        };
    }

    function successGetAllUsers(users) {
        return {
            type: dataListsManagementConstants.GET_ALL_USERS_SUCCESS,
            payload: {
                users,
            },
        };
    }

    function failureGetAllUsers() {
        return {
            type: dataListsManagementConstants.GET_ALL_USERS_ERROR,
        };
    }
}

function getBulkFileStatus(acceptedFile) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .validateCSVFile(acceptedFile)
            .then(response => {
                dispatch(success(response.data, acceptedFile));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.GET_BULK_FILE_STATUS_REQUEST,
        };
    }
    function success(successValidation, acceptedFile) {
        return {
            type: modalConstants.GET_BULK_FILE_STATUS_SUCCESS,
            payload: {
                successValidation,
                acceptedFile,
            },
        };
    }
    function failure(error) {
        return {
            type: modalConstants.GET_BULK_FILE_STATUS_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function getAllUsers(page, size, search, group, roleFilter, maintenanceFilter) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .getAll(page, size, search, group, roleFilter, maintenanceFilter)
            .then(users => {
                dispatch(success(users.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                    dispatch(failure());
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_ALL_USERS_REQUEST,
        };
    }
    function success(users) {
        return {
            type: dataListsManagementConstants.GET_ALL_USERS_SUCCESS,
            payload: {
                users,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_ALL_USERS_ERROR,
        };
    }
}

function addNewUser(data, page, size, search, group, roleFilter, maintenanceFilter, groupIds) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .addNew(data, groupIds)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('New user added successfully', {variant: 'success'}));

                dispatch(requestGetAllUsers());
                userManagementService
                    .getAll(page, size, search, group, roleFilter, maintenanceFilter)
                    .then(users => {
                        dispatch(successGetAllUsers(users.data));
                    })
                    .catch(() => {
                        dispatch(failureGetAllUsers());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.ADD_NEW_USER_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.ADD_NEW_USER_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.ADD_NEW_USER_ERROR,
        };
    }
    function requestGetAllUsers() {
        return {
            type: dataListsManagementConstants.GET_ALL_USERS_REQUEST,
        };
    }
    function successGetAllUsers(users) {
        return {
            type: dataListsManagementConstants.GET_ALL_USERS_SUCCESS,
            payload: {
                users,
            },
        };
    }
    function failureGetAllUsers() {
        return {
            type: dataListsManagementConstants.GET_ALL_USERS_ERROR,
        };
    }
}

function addNewUserChangeForm(event) {
    return {
        type: modalConstants.ADD_NEW_USER_CHANGE_FORM,
        payload: {
            event,
        },
    };
}

function deleteUserShowNotificationModal(userID) {
    return {
        type: modalConstants.DELETE_USER_SHOW_NOTIFICATION_MODAL,
        payload: {
            userID,
            text: 'Are you sure you want to delete user?',
            method: notificationModalActions.DELETE_USER,
        },
    };
}

function addNewUserSetGroupName(groupName) {
    return {
        type: modalConstants.ADD_NEW_USER_SET_GROUP_NAME,
        payload: {
            groupName,
        },
    };
}

function deleteUser(userID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .deleteUser(userID)
            .then(() => {
                dispatch(success());
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.DELETE_USER_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.DELETE_USER_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.DELETE_USER_ERROR,
        };
    }
}

function editUser(data, page, size, search, groupID, roleFilter, maintenanceFilter) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .editUser(data.userID, data.userName, data.email, data.limitationMinutes)
            .then(() => {
                userManagementService
                    .editMaintenance(data.userID, data.maintenance)
                    .then(() => {
                        dispatch(success());

                        dispatch(enqueueNotification('User edit successfully', {variant: 'success'}));
                        dispatch(requestGetAllUsers());

                        userManagementService
                            .getAll(page, size, search, groupID, roleFilter, maintenanceFilter)
                            .then(users => {
                                dispatch(successGetAllUsers(users.data));
                            })
                            .catch(() => {
                                dispatch(failureGetAllUsers());
                            });
                    })
                    .catch(() => {
                        console.log('Edit maintenance error');
                    });
            })
            .catch(error => {
                dispatch(failure());
                dispatch(enqueueNotification(String(error), {variant: 'error'}));
            });
    }

    function request() {
        return {
            type: modalConstants.EDIT_USER_MODAL_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.EDIT_USER_MODAL_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.EDIT_USER_MODAL_ERROR,
        };
    }
    function requestGetAllUsers() {
        return {
            type: dataListsManagementConstants.GET_ALL_USERS_REQUEST,
        };
    }
    function successGetAllUsers(users) {
        return {
            type: dataListsManagementConstants.GET_ALL_USERS_SUCCESS,
            payload: {
                users,
            },
        };
    }
    function failureGetAllUsers() {
        return {
            type: dataListsManagementConstants.GET_ALL_USERS_ERROR,
        };
    }
}

function editUserChangeForm(event) {
    return {
        type: modalConstants.EDIT_USER_MODAL_CHANGE_FORM,
        payload: {
            event,
        },
    };
}

function editUserNoChanges() {
    return {
        type: modalConstants.EDIT_USER_MODAL_EMPTY_FIELDS,
        payload: {
            emailMessage: 'Email has not changed',
            userNameMessage: 'User name has not changed',
        },
    };
}

function editUserShowErrorMessage(event) {
    return {
        type: modalConstants.EDIT_USER_MODAL_SHOW_ERROR_MESSAGE,
        payload: {
            event,
        },
    };
}

function editUserHideErrorMessage(event) {
    return {
        type: modalConstants.EDIT_USER_MODAL_HIDE_ERROR_MESSAGE,
        payload: {
            event,
        },
    };
}

function resetPasswordShowNotificationModal(userID) {
    return {
        type: modalConstants.RESET_PASSWORD_SHOW_NOTIFICATION_MODAL,
        payload: {
            text: 'Are you sure you want to reset password?',
            method: notificationModalActions.RESET_PASSWORD,
            userID: userID,
        },
    };
}

function resetPassword(userID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .resetPassword(userID)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Password reset link was sent to email', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    console.log('error', error);
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.RESET_USER_PASSWORD_REQUEST,
        };
    }
    function success() {
        return {
            type: dataListsManagementConstants.RESET_USER_PASSWORD_SUCCESS,
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.RESET_USER_PASSWORD_ERROR,
        };
    }
}

function handleClick(id) {
    return {
        type: dataListsManagementConstants.HANDLE_CLICK,
        payload: {
            id,
        },
    };
}

function onSelectAllClick(checked) {
    return {
        type: dataListsManagementConstants.HANDLE_SELECT_ALL_CLICK,
        payload: {
            checked,
        },
    };
}

function toggleAddRoleModal(userID, role, groupID) {
    const getRole = typeof role === 'object' ? role[0] : role;
    return {
        type: modalConstants.TOGGLE_ADD_ROLE_MODAL,
        payload: {
            userID,
            role: getRole,
            groupID,
        },
    };
}

function addRoleChangeForm(value) {
    return {
        type: modalConstants.ADD_ROLE_CHANGE_FORM,
        payload: {
            value,
        },
    };
}

function setUserRole(userId, roleType, page, size, search, group, roleFilter, maintenanceFilter) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .setUserRole(userId, group[0], roleType)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('User role has been set successfully', {variant: 'success'}));

                userManagementService
                    .getAll(page, size, search, group, roleFilter, maintenanceFilter)
                    .then(users => {
                        dispatch(successGetAllUsers(users.data));
                    })
                    .catch(() => {
                        dispatch(failureGetAllUsers());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error.cause), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.SET_USER_ROLE_REQUEST,
        };
    }
    function success() {
        return {
            type: dataListsManagementConstants.SET_USER_ROLE_SUCCESS,
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.SET_USER_ROLE_ERROR,
        };
    }

    function successGetAllUsers(users) {
        return {
            type: dataListsManagementConstants.GET_ALL_USERS_SUCCESS,
            payload: {
                users,
            },
        };
    }
    function failureGetAllUsers() {
        return {
            type: dataListsManagementConstants.GET_ALL_USERS_ERROR,
        };
    }
}

function performSearch() {
    return {
        type: dataListsManagementConstants.PERFORM_USERS_SEARCH,
    };
}

function disableSearch() {
    return {
        type: dataListsManagementConstants.DISABLE_USERS_SEARCH,
    };
}

function clearCheckboxes() {
    return {
        type: dataListsManagementConstants.CLEAN_CHECKBOXES,
    };
}

function changePage(value) {
    return {
        type: dataListsManagementConstants.CHANGE_USERS_PAGE,
        payload: {
            value,
        },
    };
}

function changeSize(value) {
    return {
        type: dataListsManagementConstants.CHANGE_USERS_SIZE,
        payload: {
            value,
        },
    };
}

function uncheckAll() {
    return {
        type: dataListsManagementConstants.CLEAR_USERS_PAGE_CHECKED_ID,
    };
}

function assignGroupChangeForm(event) {
    return {
        type: modalConstants.ASSIGN_GROUP_CHANGE_FORM,
        payload: {
            event,
        },
    };
}

function assignGroupSetGroupName(groupName) {
    return {
        type: modalConstants.ASSIGN_GROUP_SET_GROUP_NAME,
        payload: {
            groupName,
        },
    };
}

function addNewUserShowErrorMessage(event) {
    return {
        type: modalConstants.ADD_LAB_TEMEPLATE_SHOW_ERROR_MESSAGE,
        payload: {
            event,
        },
    };
}

function submitAssignGroup(userId, groupIds) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .assignGroupsToGroupAdmin(userId, groupIds)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Groups assigned successfully', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: modalConstants.ASSIGN_GROUP_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.ASSIGN_GROUP_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.ASSIGN_GROUP_ERROR,
        };
    }
}

function getAssignedGroupsToGroupAdmin() {}

function setMaintenanceFilter(maintenance) {
    // console.log('Maintenance', maintenance);
    return {
        type: dataListsManagementConstants.SET_MAINTENANCE_FILTER,
        payload: {
            maintenance,
        },
    };
}

function changeSearch(value) {
    return {
        type: dataListsManagementConstants.CHANGE_USERS_SEARCH,
        payload: {
            value,
        },
    };
}

function assignLabTypeChangeForm(info) {
    return {
        type: modalConstants.ASSIGN_LAB_TYPE_CHANGE_FORM,
        payload: {
            info,
        },
    };
}

function assignLabTemplateSetLabName(data) {
    return {
        type: modalConstants.ASSIGN_LAB_TYPE_SET_LAB_NAME,
        payload: {
            data: [data],
        },
    };
}

function submitAssignLabType(userId, labTypeIDs) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .assignLabTypesToGroupAdmin(userId, labTypeIDs)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Lab Templates assigned successfully', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: modalConstants.ASSIGN_LAB_TYPE_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.ASSIGN_LAB_TYPE_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.ASSIGN_LAB_TYPE_ERROR,
        };
    }
}
