import { modalConstants, seminarUsersConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    users: [],
    seminars: [],
    seminarID: '',
    requestIsActive: false,
    needToSearch: false,
    checkedIDs: [],
    deleteSeminarPageUserModal: {
        isOpen: false,
        text: '',
        method: '',
        userID: '',
    },
    resetPasswordModalUserPage: {
        isOpen: false,
        text: '',
        method: '',
        userID: '',
    },
    deleteUsersNotificationModalUserPage: {
        isOpen: false,
        type: '',
        userID: [],
    },
    resetPasswordsModalUserPage: {
        isOpen: false,
        text: '',
        method: '',
    },
    size: 20,
    sizeList: [10, 20, 30],
    page: 0,
    total: 3,
    totalElements: 0,
    search: '',
};

export function seminarUsersPage(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case seminarUsersConstants.REQUEST:
            return {
                ...state,
                requestIsActive: true,
                needToSearch: false,
            };
        case seminarUsersConstants.GET_ALL_SEMINARS_USERS_PAGE_SUCCESS:
            return {
                ...state,
                seminars: action.payload.seminars,
                seminarID: action.payload.seminars.id,
            };
        case seminarUsersConstants.GET_USERS_SEMINARS_USERS_PAGE_ERROR:
            return {
                ...state,
                requestIsActive: false
            }
        case seminarUsersConstants.GET_USERS_SEMINARS_USERS_PAGE_SUCCESS:
            return {
                ...state,
                users: action.payload.users,
                seminarID: action.payload.seminarID,
                size: action.payload.data.size,
                totalElements: action.payload.data.totalElements,
                needToSearch: false,
                requestIsActive: false
            };
        case seminarUsersConstants.CHANGE_SEMINAR_USERS_PAGE:
            return {
                ...state,
                seminarID: action.payload.seminarID,
                page: 0
            };
        case seminarUsersConstants.HANDLE_CLICK_USERS_PAGE: {
            const { checkedIDs } = state;
            const { id } = action.payload;
            const selectedIndex = checkedIDs.indexOf(id);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(checkedIDs, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(checkedIDs.slice(1));
            } else if (selectedIndex === checkedIDs.length - 1) {
                newSelected = newSelected.concat(checkedIDs.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(checkedIDs.slice(0, selectedIndex), checkedIDs.slice(selectedIndex + 1));
            }
            return {
                ...state,
                checkedIDs: newSelected,
            };
        }

        case seminarUsersConstants.HANDLE_CLICK_ALL_USERS_PAGE: {
            const { checked } = action.payload;
            const { users } = state;
            if (!checked) {
                return {
                    ...state,
                    checkedIDs: users.map(n => n.id),
                };
            } else {
                return {
                    ...state,
                    checkedIDs: [],
                };
            }
        }

        /* ====== DELETE_USER ====== */
        case seminarUsersConstants.CLEAR_CHECKBOXES:
            return {
                ...state,
                checkedIDs: [],
            };
        case seminarUsersConstants.DELETE_USER_MODAL_SEMINAR_PAGE:
            return {
                ...state,
                deleteSeminarPageUserModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    userID: action.payload.userID,
                },
            };
        case seminarUsersConstants.DELETE_USER_SEMINAR_USER_PAGE_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                checkedIDs: [],
                deleteSeminarPageUserModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    userID: '',
                },
                deleteUsersNotificationModalUserPage: {
                    isOpen: false,
                    type: '',
                    userID: [],
                },
            };
        case seminarUsersConstants.DELETE_USER_SEMINAR_USER_PAGE_ERROR:
            return {
                ...state,
                requestIsActive: false,
                deleteSeminarPageUserModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    userID: '',
                },
            };
        case seminarUsersConstants.DELETE_CHECKED_USERS_MODAL_SEMINAR_PAGE:
            return {
                ...state,
                requestIsActive: false,
                deleteUsersNotificationModalUserPage: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    userID: action.payload.userID,
                },
            };

        /* ====== CLOSE_MODAL ====== */
        case modalConstants.CLOSE_NOTIFICATION_MODAL:
            return {
                ...state,
                deleteSeminarPageUserModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    userID: '',
                },
                resetPasswordModalUserPage: {
                    isOpen: false,
                    text: '',
                    method: '',
                    userID: '',
                },
                deleteUsersNotificationModalUserPage: {
                    isOpen: false,
                    type: '',
                    userID: [],
                },
                resetPasswordsModalUserPage: {
                    isOpen: false,
                    text: '',
                    method: '',
                },
            };

        /* ====== RESET_PASSWORDS ====== */
        case seminarUsersConstants.RESET_PASSWORDS_USER_PAGE_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                resetPasswordsModalUserPage: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    userID: action.payload.userID,
                },
            };
        case seminarUsersConstants.RESET_PASSWORDS_SEMINAR_USERS_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                resetPasswordsModalUserPage: {
                    isOpen: false,
                    text: '',
                    method: '',
                },
            };
        case seminarUsersConstants.RESET_PASSWORDS_SEMINAR_USERS_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case seminarUsersConstants.RESET_PASSWORD_USER_PAGE_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                resetPasswordModalUserPage: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    userID: action.payload.userID,
                },
            };
        case seminarUsersConstants.RESET_PASSWORD_SEMINAR_USER_SUCCESS:
            return {
                ...state,
                resetPasswordModalUserPage: {
                    ...initialState.resetPasswordModalUserPage,
                },
                resetPasswordsModalUserPage: {
                    ...initialState.resetPasswordsModalUserPage,
                },
                isRequestActive: false,
            };
        case seminarUsersConstants.CHANGE_PAGE_SEMINAR_USERS_TAB:
            return {
                ...state,
                page: action.payload.value,
            };
        case seminarUsersConstants.CHANGE_SIZE_SEMINAR_USERS_TAB:
            return {
                ...state,
                size: action.payload.value,
            };
        case seminarUsersConstants.CHANGE_SEARCH_SEMINAR_USERS_TAB:
            return {
                ...state,
                search: action.payload.value,
            };
        case seminarUsersConstants.PERFORM_SEARCH_SEMINAR_USERS_TAB:
            return {
                ...state,
                needToSearch: true,
            };
        default:
            return state;
    }
}
