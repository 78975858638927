import { errorRequest, studentSeminarLabsConstants } from '../../Constants';
import { seminarService, userService } from '../../Services';

export const studentSeminarLabsAction = {
    getSeminarsLabs,
    loadAllLabs,
    handleClick,
    onSelectAllClick,
    changePage,
    changeSize,
};

function getSeminarsLabs(seminarId, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .getStudentAll(seminarId, page, size)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: studentSeminarLabsConstants.REQUEST,
        };
    }
    function success(labs) {
        console.log('Labs', labs);
        return {
            type: studentSeminarLabsConstants.GET_ALL_LABS_SUCCESS,
            payload: {
                labs,
            },
        };
    }
    function failure() {
        return {
            type: studentSeminarLabsConstants.GET_ALL_LABS_ERROR,
        };
    }
}

function loadAllLabs(seminarId, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        seminarService
            .getStudentAll(seminarId, page, size)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function success(labs) {
        return {
            type: studentSeminarLabsConstants.GET_ALL_LABS_SUCCESS,
            payload: {
                labs,
            },
        };
    }
    function failure() {
        return {
            type: studentSeminarLabsConstants.GET_ALL_LABS_ERROR,
        };
    }
}

function handleClick(id) {
    return {
        type: studentSeminarLabsConstants.HANDLE_CLICK_LAB,
        payload: {
            id,
        },
    };
}

function onSelectAllClick(checked) {
    return {
        type: studentSeminarLabsConstants.HANDLE_SELECT_ALL_CLICK_LAB,
        payload: {
            checked,
        },
    };
}

function changePage(value) {
    return {
        type: studentSeminarLabsConstants.CHANGE_PAGE,
        payload: {
            value
        }
    }
}

function changeSize(value) {
    return {
        type: studentSeminarLabsConstants.CHANGE_PAGE_SIZE,
        payload: {
            value
        }
    }
}