import { dataListsManagementConstants, modalConstants } from '../../Constants';
import { sortUtil, arraysUtil } from '../../Utils';
import _ from "lodash";

const initialState = {
    usersOfGroup: [],
    checkedUsersOfGroup: [],
    usersFilter: [],
    checkedUsersFilter: [],
    users: [],
    requestIsActive: false,
    showUsersModalIsOpen: false,
    userName: '',
    filterGroupId: null,
    needToGetUsers: true,
    softStuff: {
        filterUserMail: undefined,
        filterUserName: undefined,
        groupUserName: undefined,
        groupUserMail: undefined,
    },
    allGroupUsers: [],
    usersCurrentGroup: [],
    initialGroupUsers: []
};

export function showUsersModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case dataListsManagementConstants.GET_USERS_BELONGS_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        // case dataListsManagementConstants.GET_USERS_BELONGS_SUCCESS:
        case modalConstants.TOGGLE_SHOW_USERS_MODAL:
            return {
                ...state,
                showUsersModalIsOpen: true,
                requestIsActive: false,
                // users: action.payload.usersBelongs
            };
        case dataListsManagementConstants.GET_All_USERS_FILTER_ERROR:
        case dataListsManagementConstants.GET_USERS_BY_GROUP_ERROR:
        case dataListsManagementConstants.GET_USERS_BELONGS_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case modalConstants.UPDATE_USERS_IN_GROUP_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case modalConstants.SHOW_USERS_MODAL_CLOSE:
        case modalConstants.UPDATE_USERS_IN_GROUP_SUCCESS:
            return initialState;
        case modalConstants.UPDATE_USERS_IN_GROUP_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case dataListsManagementConstants.GET_All_USERS_FILTER_SUCCESS:
            let newUsers = arraysUtil.getFilterArray(action.payload.users, state.usersCurrentGroup);
            if (!state.filterGroupId) {
                const { removedUsers } = arraysUtil.getAssignUsersResultArrays(state.allGroupUsers, state.usersCurrentGroup, state.initialGroupUsers);
                newUsers = [...newUsers, ...removedUsers];
            }
            return {
                ...state,
                allGroupUsers: newUsers,
                needToGetUsers: false,
                requestIsActive: false,
            };
        case dataListsManagementConstants.GET_USERS_BY_GROUP_SUCCESS:
            return {
                ...state,
                // usersOfGroup: action.payload.groupUsers,
                requestIsActive: false,
            };
        case modalConstants.SHOW_USER_MODAL_SET_USER_NAME:
            return {
                ...state,
                userName: action.payload.userName,
                needToGetUsers: true,
            };
        case modalConstants.SHOW_USER_MODAL_SET_FILTER_GROUP_ID:
            return {
                ...state,
                filterGroupId: action.payload.groupId,
                needToGetUsers: true,
            };
        case modalConstants.CHECK_FILTER_USERS: {
            const { checkedUsersFilter } = state;
            const item = action.payload.item;

            let selectedIndex;

            let notExist = true;
            checkedUsersFilter.forEach(user => {
                if (user.id === item.id) {
                    selectedIndex = 0;
                    notExist = false;
                }
            });
            if (notExist) {
                selectedIndex = -1;
            }

            let newSelected = [];
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(checkedUsersFilter, item);
            } else if (selectedIndex === 0) {
                checkedUsersFilter.forEach(user => {
                    if (user.id !== item.id) {
                        newSelected.push(user);
                    }
                });
            }
            return {
                ...state,
                checkedUsersFilter: newSelected,
            };
        }
        case modalConstants.CHECK_GROUP_USERS: {
            const { checkedUsersOfGroup } = state;
            const id = action.payload.id;
            const selectedIndex = checkedUsersOfGroup.indexOf(id);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(checkedUsersOfGroup, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(checkedUsersOfGroup.slice(1));
            } else if (selectedIndex === checkedUsersOfGroup.length - 1) {
                newSelected = newSelected.concat(checkedUsersOfGroup.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    checkedUsersOfGroup.slice(0, selectedIndex),
                    checkedUsersOfGroup.slice(selectedIndex + 1),
                );
            }
            return {
                ...state,
                checkedUsersOfGroup: newSelected,
            };
        }
        case modalConstants.HANDLE_SELECT_ALL_CLICK: {
            const checked = action.payload.checked;
            const allGroupUsers = state.allGroupUsers;
            if (!checked) {
                return {
                    ...state,
                    checkedUsersFilter: allGroupUsers,
                };
            } else {
                return {
                    ...state,
                    checkedUsersFilter: [],
                };
            }
        }
        case modalConstants.HANDLE_SELECT_ALL_IN_GROUP_CLICK: {
            const checked = action.payload.checked;
            const usersCurrentGroup = state.usersCurrentGroup;
            if (!checked) {
                return {
                    ...state,
                    checkedUsersOfGroup: usersCurrentGroup,
                };
            } else {
                return {
                    ...state,
                    checkedUsersOfGroup: [],
                };
            }
        }
        case modalConstants.ASSIGN_USERS_MOVE: {
            const { usersCurrentGroup, checkedUsersFilter, allGroupUsers } = state;
            let resultArray = [];
            allGroupUsers.forEach(item => {
                let isExist = false;
                checkedUsersFilter.forEach(filterUser => {
                    if (item.id === filterUser.id) {
                        isExist = true;
                    }
                });
                if (!isExist) {
                    resultArray.push(item);
                }
            });
            return {
                ...state,
                usersCurrentGroup: [...usersCurrentGroup, ...checkedUsersFilter],
                allGroupUsers: resultArray,
                checkedUsersFilter: [],
            };
        }
        case modalConstants.UNASSIGN_USERS_MOVE: {
            const { checkedUsersOfGroup, allGroupUsers, usersCurrentGroup } = state;
            return {
                ...state,
                allGroupUsers: [...allGroupUsers, ...checkedUsersOfGroup],
                usersCurrentGroup: usersCurrentGroup.filter(item => !checkedUsersOfGroup.includes(item)),
                checkedUsersOfGroup: [],
            };
        }

        case modalConstants.UNASSIGN_USER_BY_ID:
            const { usersCurrentGroup } = state;
            return {
                ...state,
                usersCurrentGroup: usersCurrentGroup.filter( u => u.id !== action.payload),
                needToGetUsers: true
            }

        // -- sort stuff ---

        case modalConstants.SHOW_USERS_MODAL_SORT_UP_FILTER_USER_NAME:
            return {
                ...state,
                usersFilter: sortUtil.sortUp(state.usersFilter, 'userName'),
                softStuff: {
                    ...state.softStuff,
                    filterUserName: true,
                    filterUserMail: undefined,
                },
            };
        case modalConstants.SHOW_USERS_MODAL_SORT_DOWN_FILTER_USER_NAME:
            return {
                ...state,
                usersFilter: sortUtil.sortDown(state.usersFilter, 'userName'),
                softStuff: {
                    ...state.softStuff,
                    filterUserName: false,
                    filterUserMail: undefined,
                },
            };
        case modalConstants.SHOW_USERS_MODAL_SORT_UP_FILTER_USER_EMAIL:
            return {
                ...state,
                usersFilter: sortUtil.sortUp(state.usersFilter, 'email'),
                softStuff: {
                    ...state.softStuff,
                    filterUserMail: true,
                    filterUserName: undefined,
                },
            };
        case modalConstants.SHOW_USERS_MODAL_SORT_DOWN_FILTER_USER_EMAIL:
            return {
                ...state,
                usersFilter: sortUtil.sortDown(state.usersFilter, 'email'),
                softStuff: {
                    ...state.softStuff,
                    filterUserMail: false,
                    filterUserName: undefined,
                },
            };
        case modalConstants.SHOW_USERS_MODAL_SORT_UP_GROUP_USER_NAME:
            return {
                ...state,
                usersOfGroup: sortUtil.sortUp(state.usersOfGroup, 'userName'),
                softStuff: {
                    ...state.softStuff,
                    groupUserName: true,
                    groupUserMail: undefined,
                },
            };
        case modalConstants.SHOW_USERS_MODAL_SORT_DOWN_GROUP_USER_NAME:
            return {
                ...state,
                usersOfGroup: sortUtil.sortDown(state.usersOfGroup, 'userName'),
                softStuff: {
                    ...state.softStuff,
                    groupUserName: false,
                    groupUserMail: undefined,
                },
            };
        case modalConstants.SHOW_USERS_MODAL_SORT_UP_GROUP_USER_EMAIL:
            return {
                ...state,
                usersOfGroup: sortUtil.sortUp(state.usersOfGroup, 'email'),
                softStuff: {
                    ...state.softStuff,
                    groupUserMail: true,
                    groupUserName: undefined,
                },
            };
        case modalConstants.SHOW_USERS_MODAL_SORT_DOWN_GROUP_USER_EMAIL:
            return {
                ...state,
                usersOfGroup: sortUtil.sortDown(state.usersOfGroup, 'email'),
                softStuff: {
                    ...state.softStuff,
                    groupUserMail: false,
                    groupUserName: undefined,
                },
            };

        // ------
        case dataListsManagementConstants.TOGGLE_SHOW_USER_MODAL_REQUEST:
            return {
                ...state,
                // usersFilter: arraysUtil.getFilterArray(action.payload.users, state.usersOfGroup),
                requestIsActive: true,
            };
        case dataListsManagementConstants.TOGGLE_SHOW_USER_MODAL_SUCCESS:
            return {
                ...state,
                // usersFilter: arraysUtil.getFilterArray(action.payload.users, state.usersOfGroup),
                needToGetUsers: false,
                requestIsActive: false,
                showUsersModalIsOpen: true,
                allGroupUsers: action.payload.data.allGroupUsers,
                usersCurrentGroup: action.payload.data.usersCurrentGroup,
                initialGroupUsers: action.payload.data.usersCurrentGroup
            };
        default:
            return state;
    }
}
