import { errorRequest, signInSeminarConstants } from '../../Constants';
import { userService } from '../../Services';
import {notifierActions} from "../notifier.actions";

const {enqueueNotification} = notifierActions;

export const signInSeminarActions = {
    closeSeminarSignIn,
    showPassword,
    /* first step */
    submitFirstStep,
    changeFormDataFirstStep,
    showErrorFirstStep,
    hideErrorFirstStep,
    /* second step */
    submitSecondStep,
    changeFormDataSecondStep,
    showErrorSecondStep,
    hideErrorSecondStep,
    verifyCaptcha,
    showCaptchaError,
};

function closeSeminarSignIn() {
    return {
        type: signInSeminarConstants.CLOSE_SEMINAR_SIGN_IN,
    };
}

function showPassword() {
    return {
        type: signInSeminarConstants.SHOW_PASSWORD,
        payload: {},
    };
}

/* ====== FIRST STEP ====== */

function submitFirstStep() {
    return (dispatch, getState) => {
        main(dispatch, getState());
    };
    function main(dispatch, state) {
        const { firstStep } = state.signInSeminar;
        dispatch(request());
        userService
            .verifySeminar(firstStep)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    if (typeof error === 'object') {
                        dispatch(enqueueNotification(String(error.message), {variant: 'error'}));
                    } else {
                        dispatch(failure(error));
                    }
                }
            });
    }
    function request() {
        return {
            type: signInSeminarConstants.VERIFY_SEMINAR_REQUEST,
        };
    }
    function success(seminarID) {
        return {
            type: signInSeminarConstants.VERIFY_SEMINAR_SUCCESS,
            payload: {
                seminarID,
            },
        };
    }
    function failure(error) {
        return {
            type: signInSeminarConstants.VERIFY_SEMINAR_ERROR,
            payload: {
                error,
            },
        };
    }
}

function changeFormDataFirstStep(target) {
    return {
        type: signInSeminarConstants.CHANGE_FORM_DATA_FIRST_STEP,
        payload: {
            target,
        },
    };
}

function showErrorFirstStep(target) {
    return {
        type: signInSeminarConstants.SHOW_ERROR_FIRST_STEP,
        payload: {
            target,
        },
    };
}

function hideErrorFirstStep() {
    return {
        type: signInSeminarConstants.HIDE_ERROR_FIRST_STEP,
    };
}

/* ====== SECOND STEP ====== */

function submitSecondStep() {
    return (dispatch, getState) => {
        main(dispatch, getState);
    };
    function main(dispatch, getState) {
        let state = getState();
        const { secondStep, seminarID } = state.signInSeminar;

        dispatch(request());
        userService
            .verifyUser(secondStep, seminarID)
            .then(response => {
                const { userPassword } = state.signInSeminar.secondStep;
                sessionStorage.setItem('activeSession', 'true');
                userService
                    .login(response.data.email, userPassword)
                    .then(() => {
                        dispatch(success());
                    })
                    .catch(error => {
                        dispatch(failure(error));
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    if (typeof error === 'object') {
                        dispatch(enqueueNotification(String(error.message), {variant: 'error'}));
                    } else {
                        dispatch(failure(error));
                    }
                }
            });
    }
    function request() {
        return {
            type: signInSeminarConstants.VERIFY_USER_REQUEST,
        };
    }

    function success() {
        return {
            type: signInSeminarConstants.LOGIN_USER_TO_SEMINAR_SUCCESS,
        };
    }
    function failure(error) {
        return {
            type: signInSeminarConstants.LOGIN_USER_TO_SEMINAR_ERROR,
            payload: {
                error,
            },
        };
    }
}

function changeFormDataSecondStep(target) {
    return {
        type: signInSeminarConstants.CHANGE_FORM_DATA_SECOND_STEP,
        payload: {
            target,
        },
    };
}

function showErrorSecondStep(target) {
    return {
        type: signInSeminarConstants.SHOW_ERROR_SECOND_STEP,
        payload: {
            target,
        },
    };
}

function hideErrorSecondStep() {
    return {
        type: signInSeminarConstants.HIDE_ERROR_SECOND_STEP,
    };
}

function verifyCaptcha() {
    return {
        type: signInSeminarConstants.VERIFY_CAPTCHA_SECOND_STEP,
    };
}

function showCaptchaError() {
    return {
        type: signInSeminarConstants.SHOW_CAPTURE_ERROR_SECOND_STEP,
    };
}
