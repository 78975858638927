export const modalConstants = {
    ADD_NEW_USER_REQUEST: 'ADD_NEW_USER_REQUEST',
    ADD_NEW_USER_SUCCESS: 'ADD_NEW_USER_SUCCESS',
    ADD_NEW_USER_ERROR: 'ADD_NEW_USER_ERROR',
    ADD_NEW_USER_CHANGE_FORM: 'ADD_NEW_USER_CHANGE_FORM',
    ADD_NEW_USER_SHOW_ERROR_MESSAGE: 'ADD_NEW_USER_SHOW_ERROR_MESSAGE',
    ADD_NEW_USER_HIDE_ERROR_MESSAGE: 'ADD_NEW_USER_HIDE_ERROR_MESSAGE',
    ADD_NEW_USER_SET_GROUP_NAME: 'ADD_NEW_USER_SET_GROUP_NAME',
    TOGGLE_ADD_USER_MODAL: 'TOGGLE_ADD_USER_MODAL',

    ADD_NEW_GROUP_REQUEST: 'ADD_NEW_GROUP_REQUEST',
    ADD_NEW_GROUP_SUCCESS: 'ADD_NEW_GROUP_SUCCESS',
    ADD_NEW_GROUP_ERROR: 'ADD_NEW_GROUP_ERROR',
    ADD_NEW_GROUP_CHANGE_FORM: 'ADD_NEW_GROUP_CHANGE_FORM',
    ADD_NEW_GROUP_SHOW_ERROR_MESSAGE: 'ADD_NEW_GROUP_SHOW_ERROR_MESSAGE',
    ADD_NEW_GROUP_HIDE_ERROR_MESSAGE: 'ADD_NEW_GROUP_HIDE_ERROR_MESSAGE',
    ADD_NEW_GROUP_SET_GROUP_NAME: 'ADD_NEW_GROUP_SET_GROUP_NAME',
    TOGGLE_ADD_GROUP_MODAL: 'TOGGLE_ADD_GROUP_MODAL',
    ADD_GROUP_MODAL_SET_SUBSCRIPTION: 'ADD_GROUP_MODAL_SET_SUBSCRIPTION',
    ADD_GROUP_MODAL_CHANGE_FIXED_ID: 'ADD_GROUP_MODAL_CHANGE_FIXED_ID',

    TOGGLE_ADD_USERS_BULK_MODAL: 'TOGGLE_ADD_USERS_BULK_MODAL',
    GET_BULK_FILE_STATUS_REQUEST: 'GET_BULK_FILE_STATUS_REQUEST',
    GET_BULK_FILE_STATUS_SUCCESS: 'GET_BULK_FILE_STATUS_SUCCESS',
    GET_BULK_FILE_STATUS_ERROR: 'GET_BULK_FILE_STATUS_ERROR',
    ADD_BULK_FILE_REQUEST: 'ADD_BULK_FILE_REQUEST',
    ADD_BULK_FILE_SUCCESS: 'ADD_BULK_FILE_SUCCESS',
    ADD_BULK_FILE_ERROR: 'ADD_BULK_FILE_ERROR',
    ADD_BULK_FILE_SET_GROUP_NAME: 'ADD_BULK_FILE_SET_GROUP_NAME',
    ADD_NEW_USER_SET_GROUP_NAME_TO_BULK: 'ADD_NEW_USER_SET_GROUP_NAME_TO_BULK',
    ADD_BULK_FILE_CHANGE_FORM: 'ADD_BULK_FILE_CHANGE_FORM',
    ADD_BULK_FILE_CHANGE_GROUP_NAME: 'ADD_BULK_FILE_CHANGE_GROUP_NAME',

    DELETE_USER_SHOW_NOTIFICATION_MODAL: 'DELETE_USER_SHOW_NOTIFICATION_MODAL',
    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_ERROR: 'DELETE_USER_ERROR',

    CLOSE_NOTIFICATION_MODAL: 'CLOSE_NOTIFICATION_MODAL',
    SHOW_SIGN_OUT_NOTIFICATION_MODAL: 'SHOW_SIGN_OUT_NOTIFICATION_MODAL',
    CLOSE_SIGN_OUT_NOTIFICATION_MODAL: 'CLOSE_SIGN_OUT_NOTIFICATION_MODAL',

    TOGGLE_UPDATE_GROUP_MODAL: 'TOGGLE_UPDATE_GROUP_MODAL',
    CHECK_GROUP_REQUEST: 'CHECK_GROUP_REQUEST',
    CHECK_GROUP_SUCCESS: 'CHECK_GROUP_SUCCESS',
    CHECK_GROUP_ERROR: 'CHECK_GROUP_ERROR',
    UPDATE_GROUP_REQUEST: 'UPDATE_GROUP_REQUEST',
    UPDATE_GROUP_SUCCESS: 'UPDATE_GROUP_SUCCESS',
    UPDATE_GROUP_ERROR: 'UPDATE_GROUP_ERROR',
    UPDATE_GROUP_CHANGE_FORM: 'UPDATE_GROUP_CHANGE_FORM',
    UPDATE_GROUP_SHOW_ERROR_MESSAGE: 'UPDATE_GROUP_SHOW_ERROR_MESSAGE',
    UPDATE_GROUP_HIDE_ERROR_MESSAGE: 'UPDATE_GROUP_HIDE_ERROR_MESSAGE',
    UPDATE_GROUP_MODAL_EMPTY_FIELDS: 'UPDATE_GROUP_MODAL_EMPTY_FIELDS',
    UPDATE_GROUP_MODAL_CHANGE_FIXED_ID: 'UPDATE_GROUP_MODAL_CHANGE_FIXED_ID',
    UPDATE_GROUP_SHOW_NOTIFICATION_MODAL: 'UPDATE_GROUP_SHOW_NOTIFICATION_MODAL',

    DELETE_GROUP_SHOW_NOTIFICATION_MODAL: 'DELETE_GROUP_SHOW_NOTIFICATION_MODAL',
    DELETE_GROUP_REQUEST: 'DELETE_GROUP_REQUEST',
    DELETE_GROUP_SUCCESS: 'DELETE_GROUP_SUCCESS',
    DELETE_GROUP_ERROR: 'DELETE_GROUP_ERROR',

    DELETE_USERS_STATUS_REQUEST: 'DELETE_USERS_STATUS_REQUEST',
    DELETE_USERS_STATUS_SUCCESS: 'DELETE_USERS_STATUS_SUCCESS',
    DELETE_USERS_STATUS_ERROR: 'DELETE_USERS_STATUS_ERROR',

    DELETE_USERS_REQUEST: 'DELETE_USERS_REQUEST',
    DELETE_USERS_SUCCESS: 'DELETE_USERS_SUCCESS',
    DELETE_USERS_ERROR: 'DELETE_USERS_ERROR',
    DELETE_USERS_CLOSE_STATUS_MODAL: 'DELETE_USERS_CLOSE_STATUS_MODAL',

    RESET_PASSWORD_SHOW_NOTIFICATION_MODAL: 'RESET_PASSWORD_SHOW_NOTIFICATION_MODAL',
    RESET_PASSWORD_CLOSE_STATUS_MODAL: 'RESET_PASSWORD_CLOSE_STATUS_MODAL',

    ADD_LAB_TOGGLE_MODAL: 'ADD_LAB_TOGGLE_MODAL',
    ADD_LAB_CHANGE_FORM: 'ADD_LAB_CHANGE_FORM',
    ADD_LAB_FOR_USER_REQUEST: 'ADD_LAB_FOR_USER_REQUEST',
    ADD_LAB_FOR_USER_SUCCESS: 'ADD_LAB_FOR_USER_SUCCESS',
    ADD_LAB_FOR_USER_ERROR: 'ADD_LAB_FOR_USER_ERROR',
    ADD_LAB_FOR_GROUP_REQUEST: 'ADD_LAB_FOR_GROUP_REQUEST',
    ADD_LAB_FOR_GROUP_SUCCESS: 'ADD_LAB_FOR_GROUP_SUCCESS',
    ADD_LAB_FOR_GROUP_ERROR: 'ADD_LAB_FOR_GROUP_ERROR',

    TOGGLE_LAB_LIMITATION_MODAL: 'TOGGLE_LAB_LIMITATION_MODAL',
    CHANGE_LAB_LIMITATION: 'CHANGE_LAB_LIMITATION',
    SET_LAB_LIMITATION_REQUEST: 'SET_LAB_LIMITATION_REQUEST',
    SET_LAB_LIMITATION_SUCCESS: 'SET_LAB_LIMITATION_SUCCESS',
    SET_LAB_LIMITATION_ERROR: 'SET_LAB_LIMITATION_ERROR',

    TOGGLE_TIME_FRAME_MODAL: 'TOGGLE_TIME_FRAME_MODAL',
    TOGGLE_TIME_FRAME: 'TOGGLE_TIME_FRAME',
    SELECT_TIME_FRAME_DAY: 'SELECT_TIME_FRAME_DAY',
    CHANGE_TIME_FRAME_DATE: 'CHANGE_TIME_FRAME_DATE',
    SET_TIME_FRAME_DATE: 'SET_TIME_FRAME_DATE',
    SET_TIME_FRAME_TIME: 'SET_TIME_FRAME_TIME',
    UPDATE_TIME_FRAME_REQUEST: 'UPDATE_TIME_FRAME_REQUEST',
    UPDATE_TIME_FRAME_SUCCESS: 'UPDATE_TIME_FRAME_SUCCESS',
    UPDATE_TIME_FRAME_ERROR: 'UPDATE_TIME_FRAME_ERROR',

    ADD_NEW_LAB_CLOSE_STATUS_MODAL: 'ADD_NEW_LAB_CLOSE_STATUS_MODAL',
    CLOSE_SUCCESS_ADD_GROUP_LABS_MODAL: 'CLOSE_SUCCESS_ADD_GROUP_LABS_MODAL',
    REMOVE_USER_FROM_GROUP_NOTIF_MODAL_SHOW: 'REMOVE_USER_FROM_GROUP_NOTIF_MODAL_SHOW',
    REMOVE_USER_FROM_GROUP_NOTIF_MODAL_CLOSE: 'REMOVE_USER_FROM_GROUP_NOTIF_MODAL_CLOSE',

    REMOVE_USER_FROM_GROUP_REQUEST: 'REMOVE_USER_FROM_GROUP_REQUEST',
    REMOVE_USER_FROM_GROUP_SUCCESS: 'REMOVE_USER_FROM_GROUP_SUCCESS',
    REMOVE_USER_FROM_GROUP_ERROR: 'REMOVE_USER_FROM_GROUP_ERROR',
    UPDATE_USERS_IN_GROUP_REQUEST: 'UPDATE_USERS_IN_GROUP_REQUEST',
    UPDATE_USERS_IN_GROUP_SUCCESS: 'UPDATE_USERS_IN_GROUP_SUCCESS',
    UPDATE_USERS_IN_GROUP_ERROR: 'UPDATE_USERS_IN_GROUP_ERROR',
    GET_USERS_BELONGS_SUCCESS_WITHOUT_REOPEN_MODAL: 'GET_USERS_BELONGS_SUCCESS_WITHOUT_REOPEN_MODAL',
    SHOW_USERS_MODAL_CLOSE: 'SHOW_USERS_MODAL_CLOSE',
    TOGGLE_SHOW_USERS_MODAL: 'TOGGLE_SHOW_USERS_MODAL',

    TOGGLE_EDIT_USER_MODAL: 'TOGGLE_EDIT_USER_MODAL',
    EDIT_USER_MODAL_REQUEST: 'EDIT_USER_MODAL_REQUEST',
    EDIT_USER_MODAL_SUCCESS: 'EDIT_USER_MODAL_SUCCESS',
    EDIT_USER_MODAL_ERROR: 'EDIT_USER_MODAL_ERROR',
    EDIT_USER_MODAL_CHANGE_FORM: 'EDIT_USER_MODAL_CHANGE_FORM',
    EDIT_USER_MODAL_EMPTY_FIELDS: 'EDIT_USER_MODAL_EMPTY_FIELDS',
    EDIT_USER_MODAL_SHOW_ERROR_MESSAGE: 'EDIT_USER_MODAL_SHOW_ERROR_MESSAGE',
    EDIT_USER_MODAL_HIDE_ERROR_MESSAGE: 'EDIT_USER_MODAL_HIDE_ERROR_MESSAGE',

    DELETE_USERS_MULTIPLE_REQUEST: 'DELETE_USERS_MULTIPLE_REQUEST',
    DELETE_USERS_MULTIPLE_SUCCESS: 'DELETE_USERS_MULTIPLE_SUCCESS',
    DELETE_USERS_MULTIPLE_ERROR: 'DELETE_USERS_MULTIPLE_ERROR',

    OPEN_DELETE_LAB_MODAL: 'OPEN_DELETE_LAB_MODAL',
    OPEN_DELETE_SEMINAR_LAB_MODAL: 'OPEN_DELETE_SEMINAR_LAB_MODAL',
    DELETE_LAB_FROM_SYSTEM_REQUEST: 'DELETE_LAB_FROM_SYSTEM_REQUEST',
    DELETE_LAB_FROM_SYSTEM_SUCCESS: 'DELETE_LAB_FROM_SYSTEM_SUCCESS',
    DELETE_LAB_FROM_SYSTEM_ERROR: 'DELETE_LAB_FROM_SYSTEM_ERROR',

    DELETE_LABS_STATUS_REQUEST: 'DELETE_LABS_STATUS_REQUEST',
    DELETE_LABS_STATUS_SUCCESS: 'DELETE_LABS_STATUS_SUCCESS',
    DELETE_LABS_STATUS_ERROR: 'DELETE_LABS_STATUS_ERROR',
    DELETE_LABS_FROM_SYSTEM_REQUEST: 'DELETE_LABS_FROM_SYSTEM_REQUEST',
    DELETE_LABS_FROM_SYSTEM_SUCCESS: 'DELETE_LABS_FROM_SYSTEM_SUCCESS',
    DELETE_LABS_FROM_SYSTEM_ERROR: 'DELETE_LABS_FROM_SYSTEM_ERROR',

    TOGGLE_ADD_TEMPLATE_MODAL: 'TOGGLE_ADD_TEMPLATE_MODAL',
    UPLOAD_LAB_TEMPLATE_FILE: 'UPLOAD_LAB_TEMPLATE_FILE',
    CHANGE_LAB_TEMPLATE_NAME: 'CHANGE_LAB_TEMPLATE_NAME',
    ADD_LAB_TEMPLATE_REQUEST: 'ADD_LAB_TEMPLATE_REQUEST',
    ADD_LAB_TEMPLATE_SUCCESS: 'ADD_LAB_TEMPLATE_SUCCESS',
    ADD_LAB_TEMPLATE_ERROR: 'ADD_LAB_TEMPLATE_ERROR',

    DELETE_LAB_TEMPLATE_REQUEST: 'DELETE_LAB_TEMPLATE_REQUEST',
    DELETE_LAB_TEMPLATE_SUCCESS: 'DELETE_LAB_TEMPLATE_SUCCESS',
    DELETE_LAB_TEMPLATE_ERROR: 'DELETE_LAB_TEMPLATE_ERROR',
    DELETE_LAB_TEMPLATE_MODAL_SHOW: 'DELETE_LAB_TEMPLATE_MODAL_SHOW',
    DELETE_BLUEPRINT_MODAL_SHOW: 'DELETE_BLUEPRINT_MODAL_SHOW',

    ADD_LAB_TEMEPLATE_SHOW_ERROR_MESSAGE: 'ADD_LAB_TEMEPLATE_SHOW_ERROR_MESSAGE',
    ADD_LAB_TEMEPLATE_HIDE_ERROR_MESSAGE: 'ADD_LAB_TEMEPLATE_HIDE_ERROR_MESSAGE',

    TOGGLE_UPDATE_LAB_TEMPLATE_MODAL: 'TOGGLE_UPDATE_LAB_TEMPLATE_MODAL',
    UPDATE_LAB_TEMPLATE_MODAL_CHANGE_FORM: 'UPDATE_LAB_TEMPLATE_MODAL_CHANGE_FORM',
    UPDATE_LAB_TEMPLATE_REQUEST: 'UPDATE_LAB_TEMPLATE_REQUEST',
    UPDATE_LAB_TEMPLATE_SUCCESS: 'UPDATE_LAB_TEMPLATE_SUCCESS',
    UPDATE_LAB_TEMPLATE_ERROR: 'UPDATE_LAB_TEMPLATE_ERROR',
    UPDATE_LAB_TEMPLATE_NO_CHANGES: 'UPDATE_LAB_TEMPLATE_NO_CHANGES',
    UPDATE_LAB_TEMPLATE_SHOW_ERROR_MESSAGE: 'UPDATE_LAB_TEMPLATE_SHOW_ERROR_MESSAGE',
    UPDATE_LAB_TEMPLATE_HIDE_ERROR_MESSAGE: 'UPDATE_LAB_TEMPLATE_HIDE_ERROR_MESSAGE',

    TOGGLE_TEACHER_PROFILE_EDIT_EMAIL_MODAL: 'TOGGLE_TEACHER_PROFILE_EDIT_EMAIL_MODAL',
    TEACHER_PROFILE_EDIT_EMAIL_MODAL_CHANGE_FORM: 'TEACHER_PROFILE_EDIT_EMAIL_MODAL_CHANGE_FORM',
    TEACHER_PROFILE_EDIT_EMAIL_REQUEST: 'TEACHER_PROFILE_EDIT_EMAIL_REQUEST',
    TEACHER_PROFILE_EDIT_EMAIL_SUCCESS: 'TEACHER_PROFILE_EDIT_EMAIL_SUCCESS',
    TEACHER_PROFILE_EDIT_EMAIL_ERROR: 'TEACHER_PROFILE_EDIT_EMAIL_ERROR',
    TEACHER_PROFILE_EDIT_EMAIL_NO_CHANGES: 'TEACHER_PROFILE_EDIT_EMAIL_NO_CHANGES',
    TEACHER_PROFILE_GET_EMAIL: 'TEACHER_PROFILE_GET_EMAIL',
    TEACHER_PROFILE_SHOW_EMAIL_NOTIFICATION_MODAL: 'TEACHER_PROFILE_SHOW_EMAIL_NOTIFICATION_MODAL',
    TEACHER_PROFILE_EDIT_EMAIL_CLOSE_STATUS_MODAL: 'TEACHER_PROFILE_EDIT_EMAIL_CLOSE_STATUS_MODAL',

    TEACHER_PROFILE_RESET_PASSWORD_SHOW_NOTIFICATION_MODAL: 'TEACHER_PROFILE_RESET_PASSWORD_SHOW_NOTIFICATION_MODAL',
    TEACHER_PROFILE_RESET_PASSWORD: 'TEACHER_PROFILE_RESET_PASSWORD',
    TEACHER_PROFILE_RESET_PASSWORD_REQUEST: 'TEACHER_PROFILE_RESET_PASSWORD_REQUEST',
    TEACHER_PROFILE_RESET_PASSWORD_SUCCESS: 'TEACHER_PROFILE_RESET_PASSWORD_SUCCESS',
    TEACHER_PROFILE_RESET_PASSWORD_ERROR: 'TEACHER_PROFILE_RESET_PASSWORD_ERROR',

    SHOW_ERROR_WRONG_FILE: 'SHOW_ERROR_WRONG_FILE',

    TOGGLE_ADD_ROLE_MODAL: 'TOGGLE_ADD_ROLE_MODAL',
    ADD_ROLE_CHANGE_FORM: 'ADD_ROLE_CHANGE_FORM',

    TOGGLE_ASSIGN_GROUP_MODAL: 'TOGGLE_ASSIGN_GROUP_MODAL',
    ASSIGN_GROUP_CHANGE_FORM: 'ASSIGN_GROUP_CHANGE_FORM',
    ASSIGN_GROUP_SET_GROUP_NAME: 'ASSIGN_GROUP_SET_GROUP_NAME',
    ASSIGN_GROUP_REQUEST: 'ASSIGN_GROUP_REQUEST',
    ASSIGN_GROUP_SUCCESS: 'ASSIGN_GROUP_SUCCESS',
    ASSIGN_GROUP_ERROR: 'ASSIGN_GROUP_ERROR',
    GET_ASSIGNED_GROUPS_REQUEST: 'GET_ASSIGNED_GROUPS_REQUEST',
    GET_ASSIGNED_GROUPS_SUCCESS: 'GET_ASSIGNED_GROUPS_SUCCESS',
    GET_ASSIGNED_GROUPS_ERROR: 'GET_ASSIGNED_GROUPS_ERROR',

    TOGGLE_ASSIGN_LAB_TYPE_MODAL: 'TOGGLE_ASSIGN_LAB_TYPE_MODAL',
    ASSIGN_LAB_TYPE_CHANGE_FORM: 'ASSIGN_LAB_TYPE_CHANGE_FORM',
    ASSIGN_LAB_TYPE_SET_LAB_NAME: 'ASSIGN_LAB_TYPE_SET_LAB_NAME',
    ASSIGN_LAB_TYPE_REMOVE_LAB_NAME: 'ASSIGN_LAB_TYPE_REMOVE_LAB_NAME',
    ASSIGN_LAB_TYPE_REQUEST: 'ASSIGN_LAB_TYPE_REQUEST',
    ASSIGN_LAB_TYPE_SUCCESS: 'ASSIGN_LAB_TYPE_SUCCESS',
    ASSIGN_LAB_TYPE_ERROR: 'ASSIGN_LAB_TYPE_ERROR',
    GET_ASSIGNED_LAB_TYPES_REQUEST: 'GET_ASSIGNED_LAB_TYPES_REQUEST',
    GET_ASSIGNED_LAB_TYPES_SUCCESS: 'GET_ASSIGNED_LAB_TYPES_SUCCESS',
    GET_ASSIGNED_LAB_TYPES_ERROR: 'GET_ASSIGNED_LAB_TYPES_ERROR',

    SHOW_USER_MODAL_SET_USER_NAME: 'SHOW_USER_MODAL_SET_USER_NAME',
    SHOW_USER_MODAL_SET_FILTER_GROUP_ID: 'SHOW_USER_MODAL_SET_FILTER_GROUP_ID',
    CHECK_FILTER_USERS: 'CHECK_FILTER_USERS',
    CHECK_GROUP_USERS: 'CHECK_GROUP_USERS',
    ASSIGN_USERS_MOVE: 'ASSIGN_USERS_MOVE',
    UNASSIGN_USERS_MOVE: 'UNASSIGN_USERS_MOVE',
    UNASSIGN_USER_BY_ID: 'UNASSIGN_USER_BY_ID',
    SHOW_USERS_MODAL_SORT_UP_FILTER_USER_NAME: 'SHOW_USERS_MODAL_SORT_UP_FILTER_USER_NAME',
    SHOW_USERS_MODAL_SORT_DOWN_FILTER_USER_NAME: 'SHOW_USERS_MODAL_SORT_DOWN_FILTER_USER_NAME',
    SHOW_USERS_MODAL_SORT_UP_FILTER_USER_EMAIL: 'SHOW_USERS_MODAL_SORT_UP_FILTER_USER_EMAIL',
    SHOW_USERS_MODAL_SORT_DOWN_FILTER_USER_EMAIL: 'SHOW_USERS_MODAL_SORT_DOWN_FILTER_USER_EMAIL',
    SHOW_USERS_MODAL_SORT_UP_GROUP_USER_NAME: 'SHOW_USERS_MODAL_SORT_UP_GROUP_USER_NAME',
    SHOW_USERS_MODAL_SORT_DOWN_GROUP_USER_NAME: 'SHOW_USERS_MODAL_SORT_DOWN_GROUP_USER_NAME',
    SHOW_USERS_MODAL_SORT_UP_GROUP_USER_EMAIL: 'SHOW_USERS_MODAL_SORT_UP_GROUP_USER_EMAIL',
    SHOW_USERS_MODAL_SORT_DOWN_GROUP_USER_EMAIL: 'SHOW_USERS_MODAL_SORT_DOWN_GROUP_USER_EMAIL',
    HANDLE_SELECT_ALL_CLICK: 'HANDLE_SELECT_ALL_CLICK',
    HANDLE_SELECT_ALL_IN_GROUP_CLICK: 'HANDLE_SELECT_ALL_IN_GROUP_CLICK',

    SHOW_COUNTDOWN_MODAL: 'SHOW_COUNTDOWN_MODAL',
    HIDE_COUNTDOWN_MODAL: 'HIDE_COUNTDOWN_MODAL',
    UPDATE_LIST_OF_COUNTDOWN_LABS: 'UPDATE_LIST_OF_COUNTDOWN_LABS',
    RESET_TIMER_SHOW_NOTIFICATION_MODAL: 'RESET_TIMER_SHOW_NOTIFICATION_MODAL',
    RESET_TIMER_SHOW_NOTIFICATION_MODAL_FOR_USER: 'RESET_TIMER_SHOW_NOTIFICATION_MODAL_FOR_USER',
    RESET_TIMER_REQUEST: 'RESET_TIMER_REQUEST',
    RESET_TIMER_SUCCESS: 'RESET_TIMER_SUCCESS',
    RESET_TIMER_ERROR: 'RESET_TIMER_ERROR',

    TOGGLE_MAINTENANCE_NOTIFICATION_MODAL: 'TOGGLE_MAINTENANCE_NOTIFICATION_MODAL',

    OPEN_TEMPLATE_VERSION_MODAL: 'OPEN_TEMPLATE_VERSION_MODAL',
    CLOSE_TEMPLATE_VERSION_MODAL: 'CLOSE_TEMPLATE_VERSION_MODAL',
    CHANGE_TEMPLATE_VERSION_REQUEST: 'CHANGE_TEMPLATE_VERSION_REQUEST',
    CHANGE_TEMPLATE_VERSION_SUCCESS: 'CHANGE_TEMPLATE_VERSION_SUCCESS',
    CHANGE_TEMPLATE_VERSION_ERROR: 'CHANGE_TEMPLATE_VERSION_ERROR',

    OPEN_TEMPLATE_SHARE_VERSION_MODAL: 'OPEN_TEMPLATE_SHARE_VERSION_MODAL',
    CLOSE_TEMPLATE_SHARE_VERSION_MODAL: 'CLOSE_TEMPLATE_SHARE_VERSION_MODAL',
    CHANGE_TEMPLATE_SHARE_VERSION_REQUEST: 'CHANGE_TEMPLATE_SHARE_VERSION_REQUEST',
    CHANGE_TEMPLATE_SHARE_VERSION_SUCCESS: 'CHANGE_TEMPLATE_SHARE_VERSION_SUCCESS',
    CHANGE_TEMPLATE_SHARE_VERSION_ERROR: 'CHANGE_TEMPLATE_SHARE_VERSION_ERROR',

    OPEN_ASSIGNED_ADMINS_MODAL: 'OPEN_ASSIGNED_ADMINS_MODAL',
    CLOSE_ASSIGNED_ADMINS_MODAL: 'CLOSE_ASSIGNED_ADMINS_MODAL'
};
