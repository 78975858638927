import { modalConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    successValidation: [],
    info: {
        groupName: [],
        groupId: [],
    },
    acceptedFile: '',
    warningMessagesStuff: {
        errorMessage: '',
    },
    requestIsActive: false,
    dropUsersFileModalIsOpen: false,
};

export function dropUsersFileModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.TOGGLE_ADD_USERS_BULK_MODAL:
            let result = initialState;
            if (!state.dropUsersFileModalIsOpen) result = { ...state, dropUsersFileModalIsOpen: true };
            return result;

        case modalConstants.GET_BULK_FILE_STATUS_REQUEST:
            return {
                ...state,
                warningMessagesStuff: {
                    errorMessage: '',
                },
                requestIsActive: true,
            };
        case modalConstants.GET_BULK_FILE_STATUS_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                successValidation: action.payload.successValidation,
                acceptedFile: action.payload.acceptedFile,
            };
        case modalConstants.GET_BULK_FILE_STATUS_ERROR:
            return {
                ...state,
                requestIsActive: false,
                warningMessagesStuff: {
                    errorMessage: action.payload.text,
                },
            };
        case modalConstants.ADD_BULK_FILE_REQUEST:
            return {
                ...state,
                warningMessagesStuff: {
                    errorMessage: '',
                },
                requestIsActive: true,
            };
        case modalConstants.ADD_BULK_FILE_SUCCESS:
            return initialState;
        case modalConstants.ADD_BULK_FILE_ERROR:
            return {
                ...state,
                requestIsActive: false,
                warningMessagesStuff: {
                    errorMessage: action.payload.text,
                },
            };
        case modalConstants.ADD_BULK_FILE_CHANGE_GROUP_NAME:
            // let newName = state.info.groupName;
            let newId = state.info.groupId.filter(item => item !== 5);
            // newName.push(action.payload.name[0]);
            newId.push(action.payload.id[0]);
            return {
                ...state,
                info: {
                    ...state.info,
                    // groupName: newName,
                    groupId: newId,
                },
            };
        case modalConstants.ADD_BULK_FILE_CHANGE_FORM:
            return {
                ...state,
                info: {
                    ...state.info,
                    groupName: action.payload.name,
                    groupId: action.payload.id,
                },
            };
        case modalConstants.ADD_BULK_FILE_SET_GROUP_NAME:
            // let groupName = state.info.groupName;
            let groupName = state.info.groupName.filter(item => item !== "Freelance");
            groupName.push(action.payload.groupName[0]);
            return {
                ...state,
                info: {
                    ...state.info,
                    groupName: groupName,
                },
            };
        default:
            return state;
    }
}
