export const stylesUtil = {
    getCustomStyles,
};

function getCustomStyles() {
    setTimeout(() => {
        const element0 = document.querySelectorAll('[role="menu"]')[0];
        const element = element0 ? element0.parentElement.parentElement : null;
        element && element.setAttribute('style', 'position: absolute; bottom: 100% !important');
    }, 0);
}
