import { dataListsManagementConstants, modalConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    users: [],
    needToSearch: false,
    checkedID: [],
    isRequestActive: false,
    deleteUserModal: {
        isOpen: false,
        text: '',
        method: '',
        userID: '',
    },
    resetPasswordNotificationModal: {
        isOpen: false,
        text: '',
        method: '',
        userID: '',
    },
    deleteUsersMultipleNotificationModal: {
        isOpen: false,
        modalType: '',
        dataList: '',
    },
    size: 20,
    sizeList: [10, 20, 30],
    page: 0,
    total: 3,
    totalElements: 0,
    search: '',
    maintenanceFilter: null,
};

export function userManagement(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case dataListsManagementConstants.GET_ALL_USERS_REQUEST:
            return {
                ...state,
                getUsersRequestActive: true,
            };
        case dataListsManagementConstants.GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload.users.content,
                filterUserRole: undefined,
                totalElements: action.payload.users.totalElements,
                getUsersRequestActive: false,
                needToSearch: false,
            };
        case dataListsManagementConstants.GET_ALL_USERS_ERROR:
            return {
                ...state,
                getUsersRequestActive: false,
            };
        case modalConstants.DELETE_USER_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                deleteUserModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    userID: action.payload.userID,
                },
            };
        case modalConstants.DELETE_USERS_SUCCESS:
        case modalConstants.DELETE_USERS_ERROR:
            return {
                ...state,
                isRequestActive: false,
                page: 0,
            };
        case modalConstants.DELETE_USERS_REQUEST:
        case modalConstants.DELETE_USER_REQUEST:
            return {
                ...state,
                isRequestActive: true,
            };
        // case modalConstants.DELETE_USER_SUCCESS:
        case modalConstants.CLOSE_NOTIFICATION_MODAL:
            return {
                ...state,
                isRequestActive: false,
                deleteUserModal: {
                    ...initialState.deleteUserModal,
                },
                resetPasswordNotificationModal: {
                    ...initialState.resetPasswordNotificationModal,
                },
                deleteUsersMultipleNotificationModal: {
                    ...initialState.deleteUsersMultipleNotificationModal,
                },
                usersToDelete: [],
                page: 0,
            };
        case modalConstants.DELETE_USER_ERROR:
            return {
                ...state,
                deleteUserModal: {
                    ...initialState.deleteUserModal,
                },
            };
        case modalConstants.RESET_PASSWORD_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                resetPasswordNotificationModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    userID: action.payload.userID,
                },
            };
        case dataListsManagementConstants.RESET_USER_PASSWORD_REQUEST:
            return {
                ...state,
                isRequestActive: true,
            };
        case dataListsManagementConstants.RESET_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordNotificationModal: {
                    ...initialState.resetPasswordNotificationModal,
                },
                isRequestActive: false,
            };
        case dataListsManagementConstants.DELETE_USERS_SUCCESS:
        case dataListsManagementConstants.DELETE_USERS_ERROR:
        case dataListsManagementConstants.RESET_USER_PASSWORD_ERROR:
            return {
                ...state,
                isRequestActive: false,
            };
        case dataListsManagementConstants.HANDLE_CLICK: {
            const { checkedID } = state;
            const { id } = action.payload;
            const selectedIndex = checkedID.indexOf(id);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(checkedID, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(checkedID.slice(1));
            } else if (selectedIndex === checkedID.length - 1) {
                newSelected = newSelected.concat(checkedID.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(checkedID.slice(0, selectedIndex), checkedID.slice(selectedIndex + 1));
            }
            return {
                ...state,
                checkedID: newSelected,
            };
        }
        case dataListsManagementConstants.HANDLE_SELECT_ALL_CLICK: {
            const { checked } = action.payload;
            const { users } = state;
            if (!checked) {
                return {
                    ...state,
                    checkedID: users.map(n => n.id),
                };
            } else {
                return {
                    ...state,
                    checkedID: [],
                };
            }
        }
        case dataListsManagementConstants.CLEAN_CHECKBOXES:
            return {
                ...state,
                checkedID: [],
            };
        case dataListsManagementConstants.PERFORM_USERS_SEARCH:
            return {
                ...state,
                needToSearch: true,
                page: 0,
            };
        case dataListsManagementConstants.DISABLE_USERS_SEARCH:
            return {
                ...state,
                needToSearch: false,
            };
        case dataListsManagementConstants.CHANGE_USERS_PAGE:
            return {
                ...state,
                page: action.payload.value,
            };
        case dataListsManagementConstants.CHANGE_USERS_SIZE:
            return {
                ...state,
                size: action.payload.value,
                page: 0,
            };
        case dataListsManagementConstants.CLEAR_USERS_PAGE_CHECKED_ID:
            return {
                ...state,
                checkedID: [],
            };
        case dataListsManagementConstants.SET_MAINTENANCE_FILTER:
            return {
                ...state,
                maintenanceFilter: action.payload.maintenance,
            };
        case dataListsManagementConstants.CHANGE_USERS_SEARCH:
            return {
                ...state,
                search: action.payload.value,
            };
        case modalConstants.DELETE_USER_SUCCESS:
            return {
                ...state,
                isRequestActive: false,
                deleteUserModal: {
                    ...initialState.deleteUserModal,
                },
                resetPasswordNotificationModal: {
                    ...initialState.resetPasswordNotificationModal,
                },
                deleteUsersMultipleNotificationModal: {
                    ...initialState.deleteUsersMultipleNotificationModal,
                },
                usersToDelete: [],
                page: 0,
                needToSearch: true,
            };
        default:
            return state;
    }
}
