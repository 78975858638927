import { urlConstant } from '../Constants';
import {groupErrorRequests, labErrorRequests} from '../Handlers';
import Maintenance from '../Modules/maintenance.module';
import {AxiosRequest} from "./AxiosApi";

const isRepair = Maintenance.getMaintenance();

export const addLabTemplateService = {
    getJsonById,
    addBlueprint,
};

function getJsonById(templateId) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/templates/${templateId}`,
            data: {},
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('get json by id', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function addBlueprint(blueprint) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/templates/validation/${blueprint.templateId}/${Boolean(blueprint.verificationNeeded)}`,
            data: {
                name: blueprint.blueprintName,
                json: JSON.stringify(blueprint.json),
                path: blueprint.path,
                fileName: blueprint.fileName,
                // blueprintId: blueprint.blueprintId || null,
                templateId: blueprint.templateId || null,
                comment: blueprint.comment,
                parentVersionId: blueprint.currentVersion || null,
                editable: blueprint.editable,
                verificationNeeded: blueprint.verificationNeeded,
                description: blueprint.description
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('add blueprint', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}
