import { modalConstants, seminarLabsConstants } from '../../Constants';
import { arraysUtil } from '../../Utils';
import _ from "lodash";

const initialState = {
    labs: [],
    seminarID: '',
    checkedID: [],
    requestIsActive: false,
    needToSearch: false,
    search: '',
    allLabsStatus: {
        started: false,
        stopped: false,
        pending: false,
        inProgress: false,
    },
    size: 20,
    sizeList: [10, 20, 30],
    page: 0,
    total: 3,
    totalElements: 0,
    totalPages: 0,
    recreateSeminarLabNotificationModal: {
        method: '',
        isOpen: false,
        text: '',
        labTypeID: '',
        goBack: false
    },
    deleteSeminarLabModal: {
        method: '',
        isOpen: false,
        text: '',
        labId: '',
    },
};

export function seminarLabs(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case seminarLabsConstants.REQUEST:
            return {
                ...state,
                requestIsActive: true,
                needToSearch: false
            };
        case seminarLabsConstants.GET_ALL_LABS_SUCCESS: {
            return {
                ...state,
                requestIsActive: false,
                labs: action.payload.labs.content,
                seminarID: action.payload.seminarID,
                allLabsStatus: arraysUtil.labsStatusSetter(action.payload.labs.content),
                totalElements: action.payload.labs.totalElements,
                needToSearch: false,
                size: action.payload.labs.size,
            };
        }
        case seminarLabsConstants.GET_ALL_LABS_ERROR:
            return {
                ...state,
                requestIsActive: false,
                needToSearch: false
            };
        case seminarLabsConstants.CHANGE_SEMINAR:
            return {
                ...state,
                page: 0,
                seminarID: action.payload.seminarID,
            };
        case seminarLabsConstants.HANDLE_SELECT_ALL_CLICK_LAB: {
            const { checked } = action.payload;
            const { labs } = state;
            if (!checked) {
                return {
                    ...state,
                    checkedID: labs.map(n => n.id),
                };
            } else {
                return {
                    ...state,
                    checkedID: [],
                };
            }
        }
        case seminarLabsConstants.HANDLE_CLICK_LAB:
            const { checkedID } = state;
            const { id } = action.payload;
            const selectedIndex = checkedID.indexOf(id);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(checkedID, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(checkedID.slice(1));
            } else if (selectedIndex === checkedID.length - 1) {
                newSelected = newSelected.concat(checkedID.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(checkedID.slice(0, selectedIndex), checkedID.slice(selectedIndex + 1));
            }
            return {
                ...state,
                checkedID: newSelected,
            };
        case seminarLabsConstants.CHANGE_LABS_PAGE:
            return {
                ...state,
                page: action.payload.value,
            };
        case seminarLabsConstants.CHANGE_LABS_SIZE:
            return {
                ...state,
                size: action.payload.value,
                checkedID: []
            };

        case seminarLabsConstants.CLEAR_CHECKBOXES:
            return {
                ...state,
                checkedID: []
            }

        /* ====== DELETE LAB ====== */
        case seminarLabsConstants.DELETE_LABS_FROM_SYSTEM_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case seminarLabsConstants.DELETE_LABS_FROM_SYSTEM_SUCCESS:
            return {
                ...state,
                checkedID: [],
                requestIsActive: false,
                deleteSeminarLabModal: {
                    method: '',
                    isOpen: false,
                    text: '',
                    labId: '',
                },
            };

        /* ====== NOTIFICATION MODALS ====== */
        case seminarLabsConstants.RECREATE_SEMINAR_LAB_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                recreateSeminarLabNotificationModal: {
                    method: action.payload.method,
                    isOpen: action.payload.isOpen,
                    text: action.payload.text,
                    labTypeID: action.payload.labID,
                    goBack: action.payload.goBack
                },
            };
        case modalConstants.OPEN_DELETE_SEMINAR_LAB_MODAL:
            return {
                ...state,
                deleteSeminarLabModal: {
                    method: action.payload.method,
                    isOpen: true,
                    text: action.payload.text,
                    labId: action.payload.labID,
                },
            };
        case modalConstants.CLOSE_NOTIFICATION_MODAL:
            return {
                ...state,
                recreateSeminarLabNotificationModal: {
                    method: '',
                    isOpen: false,
                    text: '',
                    labTypeID: '',
                    goBack: false
                },
                deleteSeminarLabModal: {
                    method: '',
                    isOpen: false,
                    text: '',
                    labId: '',
                },
            };

        case seminarLabsConstants.CHANGE_SEARCH_SEMINAR_LABS:
            return {
                ...state,
                search: action.payload.value
            }

        case seminarLabsConstants.PERFORM_SEARCH_SEMINAR_LABS:
            return {
                ...state,
                needToSearch: true
            }
        default:
            return state;
    }
}
