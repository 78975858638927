import { modalConstants } from '../../Constants';
import { reducersUtil } from '../../Utils';
import _ from "lodash";

const initialState = {
    info: {
        username: '',
        email: '',
        limitationMinutes: '',
        groupName: [],
    },
    warningMessagesStuff: {
        errorMessage: '',
        emailErrorMessage: '',
        wrongEmail: '',
        checkInput: '',
    },
    requestIsActive: false,
    addUserModalIsOpen: false,
};

export function addUserModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.TOGGLE_ADD_USER_MODAL: {
            if (state.addUserModalIsOpen) {
                return initialState;
            } else {
                return {
                    ...state,
                    addUserModalIsOpen: true,
                };
            }
        }
        case modalConstants.ADD_NEW_USER_REQUEST:
            return {
                ...state,
                warningMessagesStuff: {},
                requestIsActive: true,
            };
        case modalConstants.ADD_NEW_USER_SUCCESS:
            return initialState;
        case modalConstants.ADD_NEW_USER_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case modalConstants.ADD_NEW_USER_CHANGE_FORM: {
            const { name, value } = action.payload.event.target;
            if (name === 'email' || name === 'username') {
                return {
                    ...state,
                    info: {
                        ...state.info,
                        [name]: value.replace(/(^\s*)|(\s*)$/g, ''),
                    },
                };
            } else if(name === 'limitationMinutes') {
                return {
                    ...state,
                    info: {
                        ...state.info,
                        [name]: value.replace(/[^0-9]+/gi, ''),
                    },
                };
            } else {
                return {
                    ...state,
                    info: {
                        ...state.info,
                        [name]: value,
                    },
                };
            }
        }
        case modalConstants.ADD_NEW_USER_SHOW_ERROR_MESSAGE: {
            const { value, name } = action.payload.event.target;
            let { wrongEmail: getWrongEmail } = state.warningMessagesStuff;
            return {
                ...state,
                warningMessagesStuff: {
                    ...state.warningMessagesStuff,
                    checkInput: true,
                    ...reducersUtil.addUserErrorMessage(value, name, getWrongEmail),
                },
            };
        }
        case modalConstants.ADD_NEW_USER_HIDE_ERROR_MESSAGE: {
            const { name } = action.payload.event.target;
            let errorMessage;
            let emailErrorMessage;

            if (name === 'email') {
                emailErrorMessage = '';

                return {
                    ...state,
                    warningMessagesStuff: {
                        ...state.warningMessagesStuff,
                        emailErrorMessage,
                    },
                };
            } else if (name === 'username') {
                errorMessage = '';
                return {
                    ...state,
                    warningMessagesStuff: {
                        ...state.warningMessagesStuff,
                        errorMessage,
                    },
                };
            } else return state;
        }
        case modalConstants.ADD_NEW_USER_SET_GROUP_NAME: {
            let newGroupName = state.info.groupName.filter(item => item !== 5);
            newGroupName.push(action.payload.groupName[0]);
            // newGroupName = action.payload.groupName[0];
            return {
                ...state,
                info: {
                    ...state.info,
                    groupName: newGroupName,
                },
            };
        }
        default:
            return state;
    }
}
