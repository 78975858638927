import { labsManagementService, singleLabManagementService, userService } from '../Services';
import { dataListsManagementConstants, errorRequest, modalConstants, notificationModalActions } from '../Constants';
import {notifierActions} from "./notifier.actions";

const {enqueueNotification} = notifierActions;

export const labsManagementActions = {
    getAllLabs,
    loadAllLabs,
    getLabsInGroup,
    loadLabsInGroup,
    handleClick,
    onSelectAllClick,
    deleteLabsNotificationModal,
    deleteLabsGroupAdminNotificationModal,
    deleteLabFromSystem,
    deleteLabNotificationModal,
    deleteLabGroupAdminNotificationModal,
    getUserLabs,
    loadUserLabs,
    changePage,
    changeSize,
    recreateLabByAdmin,
    recreateLabByUser,
    recreateLabByGroupAdmin,
    recreateSeminarLabByGroupAdmin,
    recreateLabShowNotificationModal,
    recreateSeminarLabShowNotificationModal,
    recreateLabShowNotificationModalFromDetails,
    recreateLabGroupAdminShowNotificationModal,
    recreateLabGroupAdminShowNotificationModalFromDetails,
    performSearch,
    resetTimerModalShow,
    resetTimer,
    getUserLabsInGroup,
    setSearchString,
    resetTimerModalShowForUser,
    resetUserTimer,
    resetLabsReducer,
    getCurrentLabsInGroupGA,
    loadCurrentLabsInGroupGA,
    getMyLabsInGroupGA,
    loadMyLabsInGroupGA,
    deleteLabFromSystemGroupAdmin,
    resetTimerModalShowGroupAdmin,
    resetTimerGroupAdmin,
    uncheckAll,
    setLabLimitation,
    createLabByAdmin,
    createLabByGroupAdmin,
    startLabByAdmin,
    startLabByGroupAdmin,
    startLabByUser,
    createLabTemplateInstance,
    recreateSeminarLabShowNotificationModalFromDetails,
    setStatusFilter,
    saveCurrentPage,
    setColumnFilter,
    clearColumnFilters
};

function getUserLabsInGroup(userName, groupID, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labsManagementService
            .getUserLabsInGroup(userName, groupID, page, size)
            .then(response => {
                dispatch(success(response.data.content, response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_LABS_IN_GROUP_REQUEST,
        };
    }
    function success(labs, data) {
        return {
            type: dataListsManagementConstants.GET_LABS_IN_GROUP_SUCCESS,
            payload: {
                labs,
                data,
            },
        };
    }
    function failure(error) {
        return {
            type: dataListsManagementConstants.GET_LABS_IN_GROUP_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function getAllLabs() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labsManagementService
            .getAllLabs()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_ALL_LABS_REQUEST,
        };
    }
    function success(labs) {
        return {
            type: dataListsManagementConstants.GET_ALL_LABS_SUCCESS,
            payload: {
                labs,
            },
        };
    }
    function failure(error) {
        return {
            type: dataListsManagementConstants.GET_ALL_LABS_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function getLabsInGroup(userName, groupID, page, size, statusFilter, sendPending = true) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        if (sendPending) {
            dispatch(request());
        }
        labsManagementService
            .getLabsInGroup(userName, groupID, page, size, statusFilter)
            .then(response => {
                dispatch(success(response.data.content, response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_LABS_IN_GROUP_REQUEST,
        };
    }
    function success(labs, data) {
        return {
            type: dataListsManagementConstants.GET_LABS_IN_GROUP_SUCCESS,
            payload: {
                labs,
                data,
            },
        };
    }
    function failure(error) {
        return {
            type: dataListsManagementConstants.GET_LABS_IN_GROUP_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function loadLabsInGroup(userName, groupID, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labsManagementService
            .getLabsInGroup(userName, groupID, page, size)
            .then(response => {
                dispatch(success(response.data.content, response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.LOAD_LABS_IN_GROUP_REQUEST,
        };
    }
    function success(labs, data) {
        return {
            type: dataListsManagementConstants.LOAD_LABS_IN_GROUP_SUCCESS,
            payload: {
                labs,
                data,
            },
        };
    }
    function failure(error) {
        return {
            type: dataListsManagementConstants.LOAD_LABS_IN_GROUP_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function loadAllLabs() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labsManagementService
            .getAllLabs()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.LOAD_ALL_LABS_REQUEST,
        };
    }
    function success(labs) {
        return {
            type: dataListsManagementConstants.LOAD_ALL_LABS_SUCCESS,
            payload: {
                labs,
            },
        };
    }
    function failure(error) {
        return {
            type: dataListsManagementConstants.LOAD_ALL_LABS_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function handleClick(id) {
    return {
        type: dataListsManagementConstants.HANDLE_CLICK_LAB,
        payload: {
            id,
        },
    };
}

function onSelectAllClick(checked) {
    return {
        type: dataListsManagementConstants.HANDLE_SELECT_ALL_CLICK_LAB,
        payload: {
            checked,
        },
    };
}

function deleteLabsNotificationModal(checkedID) {
    return {
        type: modalConstants.OPEN_DELETE_LAB_MODAL,
        payload: {
            labID: checkedID,
            text: 'Are you sure you want to delete checked labs?',
            method: notificationModalActions.DELETE_LABS,
        },
    };
}

function deleteLabsGroupAdminNotificationModal(checkedID) {
    return {
        type: modalConstants.OPEN_DELETE_LAB_MODAL,
        payload: {
            labID: checkedID,
            text: 'Are you sure you want to delete checked labs?',
            method: notificationModalActions.DELETE_LAB_BY_GROUP_ADMIN,
        },
    };
}

function deleteLabFromSystem(checkedID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        singleLabManagementService
            .deleteByAdmin(checkedID, true)
            .then(() => {
                dispatch(success());
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.DELETE_LABS_FROM_SYSTEM_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.DELETE_LABS_FROM_SYSTEM_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.DELETE_LABS_FROM_SYSTEM_ERROR,
        };
    }
}

function deleteLabNotificationModal(labID) {
    return {
        type: modalConstants.OPEN_DELETE_LAB_MODAL,
        payload: {
            labID,
            text: 'Are you sure you want to delete lab?',
            method: notificationModalActions.DELETE_LAB,
        },
    };
}

function deleteLabGroupAdminNotificationModal(labID) {
    return {
        type: modalConstants.OPEN_DELETE_LAB_MODAL,
        payload: {
            labID,
            text: 'Are you sure you want to delete lab?',
            method: notificationModalActions.DELETE_LAB_BY_GROUP_ADMIN,
        },
    };
}

function getUserLabs() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labsManagementService
            .getUserLabs()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_ALL_LABS_REQUEST,
        };
    }
    function success(labs) {
        return {
            type: dataListsManagementConstants.GET_ALL_LABS_SUCCESS,
            payload: {
                labs,
            },
        };
    }
    function failure(error) {
        return {
            type: dataListsManagementConstants.GET_ALL_LABS_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function loadUserLabs() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labsManagementService
            .getUserLabs()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.LOAD_ALL_LABS_REQUEST,
        };
    }
    function success(labs) {
        return {
            type: dataListsManagementConstants.LOAD_ALL_LABS_SUCCESS,
            payload: {
                labs,
            },
        };
    }
    function failure(error) {
        return {
            type: dataListsManagementConstants.LOAD_ALL_LABS_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function changePage(value) {
    return {
        type: dataListsManagementConstants.CHANGE_LABS_PAGE,
        payload: {
            value,
        },
    };
}

function changeSize(value) {
    return {
        type: dataListsManagementConstants.CHANGE_LABS_SIZE,
        payload: {
            value,
        },
    };
}

function recreateLabByAdmin(labID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        singleLabManagementService.recreateByAdmin(labID).catch(error => {
            dispatch(enqueueNotification(String(error), {variant: 'error'}));
        });
    }
}

function recreateLabByUser(labID) {
    return dispatch => {
        main(dispatch);
    };

    function main(dispatch) {
        singleLabManagementService.recreateByUser(labID).catch(error => {
            if (error === errorRequest.UNAUTHORIZED) {
                userService.refreshToken().then(() => main(dispatch));
            } else {
                dispatch(enqueueNotification(String(error), {variant: 'error'}));
            }
        });
    }
}

function recreateLabByGroupAdmin(labID) {
    return dispatch => {
        main(dispatch);
    };

    function main(dispatch) {
        singleLabManagementService.recreateLabByGroupAdmin(labID).catch(error => {
            if (error === errorRequest.UNAUTHORIZED) {
                userService.refreshToken().then(() => main(dispatch));
            } else {
                dispatch(failure());
                dispatch(enqueueNotification(String(error), {variant: 'error'}));
            }
        });
    }

    function failure() {
        return {
            type: dataListsManagementConstants.RECREATE_LAB_BY_GROUP_ADMIN_ERROR,
        };
    }
}

function recreateSeminarLabByGroupAdmin(labID) {
    return dispatch => {
        main(dispatch);
    };
    function main() {
        singleLabManagementService.recreateSeminarLabByGroupAdmin(labID);
    }
}

function recreateLabShowNotificationModal(labID) {
    return {
        type: dataListsManagementConstants.RECREATE_LAB_SHOW_NOTIFICATION_MODAL,
        payload: {
            method: notificationModalActions.RECREATE_LAB,
            isOpen: true,
            text: 'Are you sure you want to recreate lab?',
            labID,
        },
    };
}

function recreateSeminarLabShowNotificationModal(labID) {
    return {
        type: dataListsManagementConstants.RECREATE_LAB_GROUP_ADMIN_SHOW_NOTIFICATION_MODAL,
        payload: {
            method: notificationModalActions.RECREATE_SEMINAR_LAB_GROUP_ADMIN,
            isOpen: true,
            text: 'Are you sure you want to recreate lab?',
            labID,
        },
    };
}

function recreateSeminarLabShowNotificationModalFromDetails(labID) {
    return {
        type: dataListsManagementConstants.RECREATE_LAB_GROUP_ADMIN_SHOW_NOTIFICATION_MODAL,
        payload: {
            method: notificationModalActions.RECREATE_SEMINAR_LAB_GROUP_ADMIN_FROM_DETAILS,
            isOpen: true,
            text: 'Are you sure you want to recreate lab?',
            labID,
        },
    };
}

function recreateLabShowNotificationModalFromDetails(labID) {
    return {
        type: dataListsManagementConstants.RECREATE_LAB_SHOW_NOTIFICATION_MODAL,
        payload: {
            method: notificationModalActions.RECREATE_LAB_FROM_DETAILS,
            isOpen: true,
            text: 'Are you sure you want to recreate lab?',
            labID,
        },
    };
}

function recreateLabGroupAdminShowNotificationModal(labID) {
    // console.log('recreateLabGroupAdminShowNotificationModal');
    return {
        type: dataListsManagementConstants.RECREATE_LAB_GROUP_ADMIN_SHOW_NOTIFICATION_MODAL,
        payload: {
            method: notificationModalActions.RECREATE_LAB_GROUP_ADMIN,
            isOpen: true,
            text: 'Are you sure you want to recreate lab?',
            labID,
        },
    };
}

function recreateLabGroupAdminShowNotificationModalFromDetails(labID) {
    return {
        type: dataListsManagementConstants.RECREATE_LAB_GROUP_ADMIN_SHOW_NOTIFICATION_MODAL,
        payload: {
            method: notificationModalActions.RECREATE_LAB_GROUP_ADMIN_FROM_DETAILS,
            isOpen: true,
            text: 'Are you sure you want to recreate lab?',
            labID,
        },
    };
}

function performSearch() {
    return {
        type: dataListsManagementConstants.PERFORM_LABS_SEARCH,
    };
}

function resetTimerModalShow(labId, groupID, userName, labType) {
    return {
        type: modalConstants.RESET_TIMER_SHOW_NOTIFICATION_MODAL,
        payload: {
            labId,
            text: 'Are you sure you want to reset timer?',
            method: notificationModalActions.RESET_TIMER,
            role: 'admin',
            groupID,
            resetTimerUser: userName,
            labType,
        },
    };
}

function resetTimerModalShowGroupAdmin(labId, groupID, userName, labType) {
    return {
        type: modalConstants.RESET_TIMER_SHOW_NOTIFICATION_MODAL,
        payload: {
            labId,
            text: 'Are you sure you want to reset timer?',
            method: notificationModalActions.RESET_TIMER,
            role: 'groupAdmin',
            groupID,
            resetTimerUser: userName,
            labType,
        },
    };
}

function resetTimerModalShowForUser(labId) {
    return {
        type: modalConstants.RESET_TIMER_SHOW_NOTIFICATION_MODAL_FOR_USER,
        payload: {
            labId,
            text: 'Are you sure you want to reset timer?',
            method: notificationModalActions.RESET_TIMER,
            role: 'user',
        },
    };
}

function resetUserTimer(labID) {
    return dispatch => {
        main(dispatch);
    };

    function main(dispatch) {
        singleLabManagementService
            .resetUserTimer(labID)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Timer was successfully reset', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function success() {
        return {
            type: modalConstants.RESET_TIMER_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.RESET_TIMER_ERROR,
        };
    }
}

function resetTimer(labID, groupName, userName, groupID, page, size, labType) {
    return dispatch => {
        main(dispatch);
    };

    function main(dispatch) {
        singleLabManagementService
            .resetTimer(labID, labType)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Timer was successfully reset', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function success() {
        return {
            type: modalConstants.RESET_TIMER_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.RESET_TIMER_ERROR,
        };
    }

    /* ====== SEMINAR STUFF ====== */

    // function mainSeminar(dispatch, state) {
    //     singleLabManagementService
    //         .resetTimer(labID)
    //         .then(() => {
    //             dispatch(success());
    //
    //             const {seminarID} = state.seminarLabs;
    //
    //             seminarService
    //                 .getLabsOfSeminar(seminarID)
    //                 .then(response => {
    //                     dispatch(getAllLabsSuccessSeminar(response.data, seminarID));
    //                 })
    //                 .catch(() => {
    //                     dispatch(getAllLabsFailureSeminar());
    //                 })
    //         })
    //         .catch(error => {
    //             if (error === errorRequest.UNAUTHORIZED) {
    //                 userService
    //                     .refreshToken()
    //                     .then(() => main(dispatch));
    //             } else {
    //                 dispatch(failure());
    //             }
    //         });
    // }
    // function getAllLabsSuccessSeminar(labs, seminarID) {
    //     return {
    //         type: seminarLabsConstants.GET_ALL_LABS_SUCCESS,
    //         payload: {
    //             labs,
    //             seminarID
    //         }
    //     }
    // }
    // function getAllLabsFailureSeminar() {
    //     return {
    //         type: seminarLabsConstants.GET_ALL_LABS_ERROR,
    //     }
    // }
}
function resetTimerGroupAdmin(labID, groupName, userName, groupID, page, size, labType) {
    return dispatch => {
        main(dispatch);
    };

    function main(dispatch) {
        singleLabManagementService
            .resetTimerGroupAdmin(labID, labType)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Timer was successfully reset', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function success() {
        return {
            type: modalConstants.RESET_TIMER_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.RESET_TIMER_ERROR,
        };
    }
}

function setSearchString(search) {
    return {
        type: dataListsManagementConstants.SET_USER_NAME,
        payload: {
            search,
        },
    };
}

function resetLabsReducer() {
    return {
        type: dataListsManagementConstants.RESET_LABS_REDUCER,
    };
}

function getCurrentLabsInGroupGA(groupID, page, size, username, statusFilter, sendPending = true) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        if (sendPending) {
            dispatch(request());
        }
        labsManagementService
            .getCurrentLabsInGroupGA(groupID, page, size, username, statusFilter)
            .then(response => {
                dispatch(success(response.data.content, response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_LABS_IN_GROUP_REQUEST,
        };
    }
    function success(labs, data) {
        return {
            type: dataListsManagementConstants.GET_LABS_IN_GROUP_SUCCESS,
            payload: {
                labs,
                data,
            },
        };
    }
    function failure(error) {
        return {
            type: dataListsManagementConstants.GET_LABS_IN_GROUP_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function loadCurrentLabsInGroupGA(groupID, page, size, username) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labsManagementService
            .getCurrentLabsInGroupGA(groupID, page, size, username)
            .then(response => {
                dispatch(success(response.data.content, response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.LOAD_LABS_IN_GROUP_REQUEST,
        };
    }
    function success(labs, data) {
        return {
            type: dataListsManagementConstants.LOAD_LABS_IN_GROUP_SUCCESS,
            payload: {
                labs,
                data,
            },
        };
    }
    function failure(error) {
        return {
            type: dataListsManagementConstants.LOAD_LABS_IN_GROUP_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function getMyLabsInGroupGA(groupName, page, size, groupID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labsManagementService
            .getMyLabsInGroupGA(groupName, page, size, groupID)
            .then(response => {
                dispatch(success(response.data.content, response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                    dispatch(failure(error));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_LABS_IN_GROUP_REQUEST,
        };
    }
    function success(labs, data) {
        return {
            type: dataListsManagementConstants.GET_LABS_IN_GROUP_SUCCESS,
            payload: {
                labs,
                data,
            },
        };
    }
    function failure(error) {
        return {
            type: dataListsManagementConstants.GET_LABS_IN_GROUP_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function loadMyLabsInGroupGA(groupName, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labsManagementService
            .getMyLabsInGroupGA(groupName, page, size)
            .then(response => {
                dispatch(success(response.data.content, response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.LOAD_LABS_IN_GROUP_REQUEST,
        };
    }
    function success(labs, data) {
        return {
            type: dataListsManagementConstants.LOAD_LABS_IN_GROUP_SUCCESS,
            payload: {
                labs,
                data,
            },
        };
    }
    function failure(error) {
        return {
            type: dataListsManagementConstants.LOAD_LABS_IN_GROUP_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function deleteLabFromSystemGroupAdmin(checkedID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        singleLabManagementService
            .deleteByGroupAdmin(checkedID, true)
            .then(() => {
                dispatch(success());
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.DELETE_LABS_FROM_SYSTEM_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.DELETE_LABS_FROM_SYSTEM_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.DELETE_LABS_FROM_SYSTEM_ERROR,
        };
    }
}

function uncheckAll() {
    return {
        type: dataListsManagementConstants.CLEAN_LABS_PAGE_CHECKBOXES,
    };
}

function setLabLimitation(userId, groupId, labId, limitationMinutes) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        singleLabManagementService
            .setLabLimitation(userId, groupId, labId, Number(limitationMinutes))
            .then(() => {
                dispatch(success());
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());

                    if (error.response && error.response.data && error.response.data.cause) {
                        dispatch(enqueueNotification(error.response.data.cause, {variant: 'error'}));
                    }
                    else {
                        dispatch(enqueueNotification('Service or network error', {variant: 'error'}));
                    }
                }
            });
    }
    function request() {
        return {
            type: modalConstants.SET_LAB_LIMITATION_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.SET_LAB_LIMITATION_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.SET_LAB_LIMITATION_ERROR,
        };
    }
}

function createLabByAdmin(labId) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        singleLabManagementService
            .createByAdmin(labId)
            .then(() => {
                dispatch(success());
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());

                    if (error.response && error.response.data && error.response.data.cause) {
                        dispatch(enqueueNotification(error.response.data.cause, {variant: 'error'}));
                    }
                    else {
                        dispatch(enqueueNotification('Service or network error', {variant: 'error'}));
                    }
                }
            });
    }

    function request() {
        return {
            type: dataListsManagementConstants.CREATE_LAB_BY_ADMIN_REQUEST,
        };
    }

    function success() {
        return {
            type: dataListsManagementConstants.CREATE_LAB_BY_ADMIN_SUCCESS,
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.CREATE_LAB_BY_ADMIN_ERROR,
        };
    }
}

function createLabByGroupAdmin(labId) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        singleLabManagementService
            .createByGroupAdmin(labId)
            .then(() => {
                dispatch(success());
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());

                    if (error.response && error.response.data && error.response.data.cause) {
                        dispatch(enqueueNotification(error.response.data.cause, {variant: 'error'}));
                    }
                    else {
                        dispatch(enqueueNotification('Service or network error', {variant: 'error'}));
                    }
                }
            });
    }

    function request() {
        return {
            type: dataListsManagementConstants.CREATE_LAB_BY_GROUP_ADMIN_REQUEST,
        };
    }

    function success() {
        return {
            type: dataListsManagementConstants.CREATE_LAB_BY_GROUP_ADMIN_SUCCESS,
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.CREATE_LAB_BY_GROUP_ADMIN_ERROR,
        };
    }
}

function startLabByAdmin(labId) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        singleLabManagementService
            .startByAdmin(labId)
            .then(() => {
                dispatch(success());
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());

                    if (error.response && error.response.data && error.response.data.cause) {
                        dispatch(enqueueNotification(error.response.data.cause, {variant: 'error'}));
                    }
                    else {
                        dispatch(enqueueNotification('Service or network error', {variant: 'error'}));
                    }
                }
            });
    }

    function request() {
        return {
            type: dataListsManagementConstants.START_LAB_BY_ADMIN_REQUEST,
        };
    }

    function success() {
        return {
            type: dataListsManagementConstants.START_LAB_BY_ADMIN_SUCCESS,
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.START_LAB_BY_ADMIN_ERROR,
        };
    }
}

function startLabByGroupAdmin(labId) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        singleLabManagementService
            .startByGroupAdmin(labId)
            .then(() => {
                dispatch(success());
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());

                    if (error.response && error.response.data && error.response.data.cause) {
                        dispatch(enqueueNotification(error.response.data.cause, {variant: 'error'}));
                    }
                    else {
                        dispatch(enqueueNotification('Service or network error', {variant: 'error'}));
                    }
                }
            });
    }

    function request() {
        return {
            type: dataListsManagementConstants.START_LAB_BY_ADMIN_REQUEST,
        };
    }

    function success() {
        return {
            type: dataListsManagementConstants.START_LAB_BY_ADMIN_SUCCESS,
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.START_LAB_BY_ADMIN_ERROR,
        };
    }
}

function startLabByUser(labId) {
    console.log('Start by user action');
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        singleLabManagementService
            .startByUser(labId)
            .then(() => {
                dispatch(success());
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());

                    if (error.response && error.response.data && error.response.data.cause) {
                        dispatch(enqueueNotification(error.response.data.cause, {variant: 'error'}));
                    }
                    else {
                        dispatch(enqueueNotification('Service or network error', {variant: 'error'}));
                    }
                }
            });
    }

    function request() {
        return {
            type: dataListsManagementConstants.START_LAB_BY_USER_REQUEST,
        };
    }

    function success() {
        return {
            type: dataListsManagementConstants.START_LAB_BY_USER_SUCCESS,
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.START_LAB_BY_USER_ERROR,
        };
    }
}

function createLabTemplateInstance(templateId, versionId) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        singleLabManagementService
            .createLabTemplateInstance(templateId, versionId)
            .then(() => {
                dispatch(success());
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());

                    if (error.response && error.response.data && error.response.data.cause) {
                        dispatch(enqueueNotification(error.response.data.cause, {variant: 'error'}));
                    }
                    else {
                        dispatch(enqueueNotification('Service or network error', {variant: 'error'}));
                    }
                }
            });
    }

    function request() {
        return {
            type: dataListsManagementConstants.START_LAB_BY_USER_REQUEST,
        };
    }

    function success() {
        return {
            type: dataListsManagementConstants.START_LAB_BY_USER_SUCCESS,
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.START_LAB_BY_USER_ERROR,
        };
    }
}

function setStatusFilter(filter) {
    return {
        type: dataListsManagementConstants.SET_STATUS_FILTER,
        payload: filter
    }
}

function saveCurrentPage(value) {
    return {
        type: dataListsManagementConstants.SAVE_CURRENT_PAGE,
        payload: {
            value
        }
    }
}

function setColumnFilter(id, value) {
    return {
        type: dataListsManagementConstants.SET_COLUMN_FILTER,
        payload: {
            id,
            value
        }
    }
}

function clearColumnFilters() {
    return {
        type: dataListsManagementConstants.CLEAR_COLUMN_FILTERS,
    }
}