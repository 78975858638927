import {
    errorRequest,
    modalConstants,
    notificationModalActions,
    dataListsManagementConstants,
    REFRESH_TOKEN
} from '../Constants';
import Auth from '../Modules/auth.module';
import { userManagementService, userProfileService, userService } from '../Services';
import { statusModalActions } from './statusModal.actions';
import {notifierActions} from "./notifier.actions";

const {enqueueNotification} = notifierActions;
const { openStatusModal } = statusModalActions;

export const userProfileActions = {
    toggleTeacherProfileEditEmailModal,
    getUserEmail,
    userProfileChangeForm,
    editUserEmail,
    profileChangeEmailShowNotificationModal,
    resetPasswordShowNotificationModal,
    resetPassword,
    getUserInfo,
    logoutUser,
    toggleMaintenance,
    getMaintenanceStatus,
    setMaintenance,
    setRefreshTokenInterval,
    clearRefreshTokenInterval
};

function getUserEmail() {
    return {
        type: modalConstants.TEACHER_PROFILE_GET_EMAIL,
        payload: {
            email: Auth.getUser(),
        },
    };
}

function toggleTeacherProfileEditEmailModal() {
    return {
        type: modalConstants.TOGGLE_TEACHER_PROFILE_EDIT_EMAIL_MODAL,
    };
}

function userProfileChangeForm(event) {
    return {
        type: modalConstants.TEACHER_PROFILE_EDIT_EMAIL_MODAL_CHANGE_FORM,
        payload: {
            event,
        },
    };
}

function editUserEmail(data) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .editUserEmail(data.email)
            .then(() => {
                dispatch(success());
                dispatch(enqueueNotification('Email edit successfully', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.TEACHER_PROFILE_EDIT_EMAIL_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.TEACHER_PROFILE_EDIT_EMAIL_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.TEACHER_PROFILE_EDIT_EMAIL_ERROR,
        };
    }
}

function profileChangeEmailShowNotificationModal() {
    return {
        type: modalConstants.TEACHER_PROFILE_SHOW_EMAIL_NOTIFICATION_MODAL,
        payload: {
            text: 'Are you sure you want to change your email?',
            method: notificationModalActions.PROFILE_CHANGE_EMAIL,
        },
    };
}

function resetPasswordShowNotificationModal() {
    return {
        type: modalConstants.TEACHER_PROFILE_RESET_PASSWORD_SHOW_NOTIFICATION_MODAL,
        payload: {
            text: 'Are you sure you want to reset password?',
            method: notificationModalActions.RESET_PASSWORD,
        },
    };
}

function resetPassword() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userProfileService
            .changePassword()
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Password reset link was sent to email', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: modalConstants.TEACHER_PROFILE_RESET_PASSWORD_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.TEACHER_PROFILE_RESET_PASSWORD_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.TEACHER_PROFILE_RESET_PASSWORD_ERROR,
        };
    }
}

function getUserInfo() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userService
            .getUserInfo()
            .then(response => {
                dispatch(success(response));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_USER_INFO_REQUEST,
        };
    }
    function success(response) {
        return {
            type: dataListsManagementConstants.GET_USER_INFO_SUCCESS,
            payload: {
                data: response.data,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_USER_INFO_ERROR,
        };
    }
}

function logoutUser(reason, email) {
    console.log({ reason, email }, '=============logout ==========');

    return dispatch => {
        dispatch(
            openStatusModal({
                status: false,
                needLogout: true,
                text:
                    reason === 'CHANGE_USER_ROLE'
                        ? 'Your role changed. You will be signed out.'
                        : reason === 'CHANGEUSEREMAIL'
                        ? `Your email address has been changed by System Admin. Reset password link has been sent to ${email}.
                    To sign into the system you should use new email and new password.`
                        : reason === 'CHANGE_MAINTENANCE'
                        ? 'Maintenance mode switched on. You will be signed out.'
                        : reason === 'CHANGE_MAINTENANCE_FOR_USER'
                        ? 'Maintenance mode switched on. You will be signed out.'
                        : reason === 'SIGNOUT'
                        ? 'You will be signed out.'
                        : reason === 'SeminarSignOut'
                        ? 'Your seminar updated. You will be signed out.'
                        : reason === 'SEMINARENDSIGNOUT'
                        ? 'Your seminar ended. You will be signed out.'
                        : reason === 'SEMINARSEND'
                        ? 'Your seminar ended. You will be signed out.'
                        : reason === 'DELETEUSER'
                        ? 'Your account was deleted by Administrator.'
                        : reason === 'SEMINARSIGNOUTANDDELETEUSER'
                        ? 'You have been removed from the seminar'
                        : 'Maintenance mode switched on. You will be signed out.',
            }),
        );
    };
}

function setMaintenance(maintenanceNeedToBe) {
    return {
        type: dataListsManagementConstants.TOGGLE_MAINTENANCE_MAINTENANCE,
        payload: {
            maintenanceNeedToBe,
        },
    };
}

function toggleMaintenance(maintenanceNeedToBe) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userProfileService
            .maintenanceSwitch(maintenanceNeedToBe)
            .then(() => {
                dispatch(success());
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.TOGGLE_MAINTENANCE_REQUEST,
        };
    }
    function success() {
        return {
            type: dataListsManagementConstants.TOGGLE_MAINTENANCE_SUCCESS,
            payload: {
                maintenanceNeedToBe,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.TOGGLE_MAINTENANCE_ERROR,
        };
    }
}

function getMaintenanceStatus() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userProfileService
            .getMaintenanceStatus()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(() => {
                dispatch(failure());
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_MAINTENANCE_STATUS_REQUEST,
        };
    }
    function success(value) {
        return {
            type: dataListsManagementConstants.GET_MAINTENANCE_STATUS_SUCCESS,
            payload: {
                value,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_MAINTENANCE_STATUS_ERROR,
        };
    }
}

let refreshTokenIntervalId = null;
function setRefreshTokenInterval(interval = 5 * 60000) {
    return dispatch => {
        main(dispatch);
    };

    function main(dispatch) {
        clearInterval(refreshTokenIntervalId);

        refreshTokenIntervalId = setInterval(() => {
            if (Auth.getRefreshToken()) {
                userService.refreshToken().then(() => {
                    dispatch({
                        type: REFRESH_TOKEN
                    })
                })
            }
        }, interval);
    }
}

function clearRefreshTokenInterval() {
    clearInterval(refreshTokenIntervalId);
}