class VersionManagement {
    static setCurrentVersion = value => {
        localStorage.setItem('current_version', value);
    };

    static getCurrentVersion = () => {
        return localStorage.getItem('current_version');
    };

    static isVersionExist = () => {
        return localStorage.getItem('current_version') !== null;
    };
}

export default VersionManagement;
