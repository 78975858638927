import { combineReducers } from 'redux';
import { dataListsManagementConstants, webSocketConstants } from '../../Constants';
import _ from 'lodash';

const content = (state = [], { type, payload }) => {
    switch (type) {
        case dataListsManagementConstants.GET_ALL_BLUEPRINTS_LIST_SUCCESS:
            return payload.content;
        case webSocketConstants.SET_DATA: {
            switch (payload.type) {
                case webSocketConstants.BLUEPRINT_STATE:
                    return state.map((bp) => (bp.blueprintId === payload.id ? {...bp, state: payload.state} : bp));

                case webSocketConstants.BLUEPRINT_LIFECYCLE:
                    if (payload.lifecycle === 'DELETED') {
                        let findIndex = state.findIndex((bp) => bp.blueprintId === payload.id);

                        if (findIndex >= 0) {
                            let newState = _.cloneDeep(state);
                            newState.splice(findIndex, 1);
                            return newState;
                        }
                        else {
                            return state;
                        }
                    }

                    return state;

                default:
                    return state;
            }
        }

        default:
            return state;
    }
};

const pagination = (state = { page: 0, size: 20, totalElements: 0, first: true, last: true }, { type, payload }) => {
    switch (type) {
        case dataListsManagementConstants.GET_ALL_BLUEPRINTS_LIST_SUCCESS:
            return {
                page: payload.number,
                size: payload.size,
                totalElements: payload.totalElements,
                last: payload.last,
                first: payload.first,
            };
        case dataListsManagementConstants.CHANGE_BLUEPRINT_PAGE:
            return { ...state, page: payload };
        case dataListsManagementConstants.CHANGE_BLUEPRINT_PAGE_SIZE:
            return { ...state, size: payload };
        default:
            return state;
    }
};

const isRequestActive = (state = false, { type, payload }) => {
    switch (type) {
        case dataListsManagementConstants.GET_ALL_BLUEPRINTS_LIST_REQUEST:
            return true;
        case dataListsManagementConstants.GET_ALL_BLUEPRINTS_LIST_SUCCESS:
        case dataListsManagementConstants.GET_ALL_BLUEPRINTS_ERROR:
            return false;
        default:
            return state;
    }
};

export default combineReducers({ content, pagination, isRequestActive });
