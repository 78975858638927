import { modalConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    info: {
        userID: '',
        instanceTypeID: '',
        instanceTypeName: '',
        groupID: '',
    },
    requestIsActive: false,
    addLabModalIsOpen: false,
    isLabTypeSelected: false,
    successAddGroupLabsModal: {
        isOpen: false,
        successAdded: [],
    },
};

export function addLabModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.ADD_LAB_TOGGLE_MODAL: {
            if (state.addLabModalIsOpen) {
                return initialState;
            } else {
                if (action.payload) {
                    return {
                        ...state,
                        addLabModalIsOpen: true,
                        info: {
                            ...state.info,
                            userID: action.payload.userID,
                        },
                    };
                } else {
                    return {
                        ...state,
                        addLabModalIsOpen: true,
                    };
                }
            }
        }
        case modalConstants.ADD_LAB_CHANGE_FORM:
            return {
                ...state,
                info: action.payload.info,
                isLabTypeSelected: true,
            };
        case modalConstants.ADD_LAB_FOR_USER_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case modalConstants.ADD_LAB_FOR_USER_ERROR:
        case modalConstants.ADD_LAB_FOR_USER_SUCCESS:
            return initialState;
        case modalConstants.ADD_LAB_FOR_GROUP_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case modalConstants.ADD_LAB_FOR_GROUP_SUCCESS: {
            if (action.payload.users.length)
                return {
                    ...initialState,
                    successAddGroupLabsModal: {
                        ...state.successAddGroupLabsModal,
                        isOpen: true,
                        successAdded: action.payload.users,
                    },
                };
            else return initialState;
        }
        case modalConstants.ADD_LAB_FOR_GROUP_ERROR:
            return initialState;
        case modalConstants.CLOSE_SUCCESS_ADD_GROUP_LABS_MODAL:
            return {
                ...state,
                successAddGroupLabsModal: {
                    isOpen: false,
                    successAdded: [],
                },
            };
        default:
            return state;
    }
}
