import {modalConstants} from "../../Constants";
import _ from 'lodash';

const initialState = {
    open: false,
    template: null
};

export function assignedAdminsModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.OPEN_ASSIGNED_ADMINS_MODAL:
            return {
                ...state,
                open: true,
                template: action.payload
            }

        case modalConstants.CLOSE_ASSIGNED_ADMINS_MODAL:
            return {
                ...state,
                open: false,
                template: null
            }

        default:
            return state;
    }
}