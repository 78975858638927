class Maintenance {
    static setMaintenance = data => {
        localStorage.setItem('maintenance', data);
    };

    static getMaintenance = () => {
        return localStorage.getItem('maintenance') === 'true';
    };
}

export default Maintenance;
