import { transformUtil } from './transform.util';

export const seminarUtil = {
    getGroupAdminIDs,
    getGroupAdminNames,
    getSubscriptionName,
    getSeminarByID,
    convertCreateData,
    convertEditData,
    convertGetData,
    isUserTemporary,
    isSeminarExits,
};

function getGroupAdminIDs(seminars, seminarID) {
    const { adminIds } = seminars.find(item => seminarID === item.id);
    return adminIds;
}

function getSelectedGroupAdmins(groupAdmins) {
    let result = [];
    groupAdmins.forEach(item => {
        result.push(item.id);
    });
    return result;
}

function getGroupAdminNames(groupAdmins, groupAdminIDs) {
    const groupAdminNames = [];
    groupAdminIDs.forEach(item => {
        const obj = groupAdmins.find(user => user.id === item);
        if (obj) {
            groupAdminNames.push(obj.username);
        }
    });
    if (!groupAdminNames.length) {
        groupAdminNames.push('');
    }
    return groupAdminNames;
}

function getSubscriptionName(subscriptions, subscriptionID) {
    const { subscriptionName } = subscriptions.find(item => subscriptionID === item.subscriptionId);
    return subscriptionName;
}

function getSeminarByID(seminars, seminarID) {
    return seminars.find(item => item.id === seminarID);
}

function convertCreateData(data) {
    const modifiedData = modifyData(data);
    return {
        name: modifiedData.name,
        password: modifiedData.password,
        startTime: modifiedData.startDate,
        endTime: modifiedData.endDate,
        subscriptionId: modifiedData.subscriptionID,
        subscriptionName: modifiedData.subscriptionName,
        labTypeIds: modifiedData.labTemplateIDs,
        allowedUsersCount: modifiedData.maxUsers,
        timeOutMin: modifiedData.labTimeOut,
        adminIds: modifiedData.groupAdminIDs,
        fixedNetId: modifiedData.fixedNetId,
    };
}

function convertEditData(data) {
    const modifiedData = modifyData(data);
    return {
        name: modifiedData.name,
        password: modifiedData.password,
        startTime: modifiedData.startDate,
        endTime: modifiedData.endDate,
        allowedUsersCount: modifiedData.maxUsers,
        timeOutSeconds: modifiedData.labTimeOut,
        adminIds: modifiedData.groupAdminIDs,
        seminarId: modifiedData.id,
        fixedNetId: modifiedData.fixedNetId,
    };
}

function convertGetData(seminar) {
    let startDate = new Date(seminar.startTime);
    startDate.setHours(0);
    startDate.setMinutes(0);
    const startTime = new Date(seminar.startTime);

    let endDate = new Date(seminar.endTime);
    startDate.setHours(0);
    startDate.setMinutes(0);
    const endTime = new Date(seminar.endTime);

    const labTemplateNames = seminar.labsTemplate.map(item => item.name);

    return {
        name: seminar.name,
        password: seminar.password,
        startDate,
        endDate,
        startTime,
        endTime,
        status: seminar.enable,
        selectedGroupAdmins: seminar.groupAdmins,
        groupAdminIDs: getSelectedGroupAdmins(seminar.groupAdmins),
        // groupAdminIDs: seminar.adminIds,
        subscriptionName: seminar.subscriptionName,
        labTemplateIDs: seminar.labTypeIds,
        labTemplateNames: labTemplateNames.length ? labTemplateNames : [''],
        maxUsers: seminar.allowedUsersCount,
        // labTimeOut: seminar.timeOutMin/60
        // labTimeOut: transformUtil.getInterval(seminar.timeOutMin),
        labTimeOut: transformUtil.getHours(seminar.timeOutMin),
        fixedNetId: seminar.fixedNetId,
    };
}

function isUserTemporary(users, userID) {
    const user = users.find(item => item.id === userID);
    return user.temporary;
}

function isSeminarExits(seminars, seminarID) {
    let result = false;
    for (let i = 0; i < seminars.length; i++) {
        if (seminars[i].id === seminarID) {
            result = true;
            break;
        }
    }
    return result;
}

function modifyData(data) {
    /* --- combine, modify date and time --- */
    data.startDate.setHours(data.startTime.getHours());
    data.startDate.setMinutes(data.startTime.getMinutes());
    data.startDate.setSeconds(0);
    data.endDate.setHours(data.endTime.getHours());
    data.endDate.setMinutes(data.endTime.getMinutes());
    data.endDate.setSeconds(0);

    data.startDate = data.startDate.getTime();
    data.endDate = data.endDate.getTime();

    // data.labTimeOut = transformUtil.parseInterval(data.labTimeOut) * 60;
    data.labTimeOut = transformUtil.getSecondsFromHours(data.labTimeOut);
    /* --- */
    return data;
}
