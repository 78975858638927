import {dataListsManagementConstants, labDetailsConstants, modalConstants, statusList} from '../../Constants';
import { arraysUtil } from '../../Utils';
import _ from "lodash";

const initialState = {
    labID: '',
    checkedID: [],
    notificationModal: {
        isOpen: false,
        method: '',
        text: '',
    },
    deleteAllUsersNotificationListModal: {
        isOpen: false,
        dataList: '',
        type: 'DELETE_LABS_MULTIPLE',
    },
    requestIsActive: false,
    allLabsStatus: {
        started: false,
        stopped: false,
        pending: false,
        inProgress: false,
    },
    getLabsRequestActive: false,
    needToGetLabs: false,
    labs: [],
    search: '',
    currentGroupName: '',
    size: 20,
    sizeList: [10, 20, 30],
    page: 0,
    total: 3,
    totalElements: 0,
    recreateLabNotificationModal: {
        method: '',
        isOpen: false,
        text: '',
        labTypeID: '',
    },
    recreateLabGroupAdminNotificationModal: {
        method: '',
        isOpen: false,
        text: '',
        labTypeID: '',
    },
    hardRecreateLabNotificationModal: {
        method: '',
        isOpen: false,
        text: '',
        labTypeID: '',
    },
    resetTimerModal: {
        isOpen: false,
        text: '',
        method: '',
        labId: '',
        role: '',
        groupID: '',
        userName: '',
        labType: '',
    },
    showPreloader: true,
    needToGetBack: false,
    statusFilter: null,
    filters: {
        'group.name': 'All groups',
        'status': 'All statuses'
    },
    filterLists: {
        'group.name': [],
        status: statusList.map(s => ({
            id: s,
            value: s
        }))
    }
};

export function labsManagement(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.OPEN_DELETE_LAB_MODAL:
            return {
                ...state,
                labID: action.payload.labID,
                notificationModal: {
                    ...state.notificationModal,
                    method: action.payload.method,
                    text: action.payload.text,
                    isOpen: true,
                },
            };
        case modalConstants.CLOSE_NOTIFICATION_MODAL:
            return {
                ...state,
                labID: '',
                notificationModal: {
                    ...state.notificationModal,
                    method: '',
                    text: '',
                    isOpen: false,
                },
                deleteAllUsersNotificationListModal: {
                    ...state.deleteAllUsersNotificationListModal,
                    isOpen: false,
                    dataList: '',
                },
                hardRecreateLabNotificationModal: {
                    method: '',
                    isOpen: false,
                    text: '',
                    labTypeID: '',
                },
                recreateLabGroupAdminNotificationModal: {
                    method: '',
                    isOpen: false,
                    text: '',
                    labTypeID: '',
                },
                recreateLabNotificationModal: {
                    method: '',
                    isOpen: false,
                    text: '',
                    labTypeID: '',
                },
                resetTimerModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    labId: '',
                    role: '',
                    groupID: '',
                    userName: '',
                },
            };
        case modalConstants.RESET_TIMER_REQUEST:
        case modalConstants.DELETE_LAB_FROM_SYSTEM_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        // case modalConstants.DELETE_LAB_FROM_SYSTEM_REQUEST:
        //     return {
        //         ...state,
        //         requestIsActive: true,
        //         notificationModal: {
        //             ...state.notificationModal,
        //             isOpen: false,
        //             method: '',
        //             text: ''
        //         }
        //     };
        case modalConstants.DELETE_LAB_FROM_SYSTEM_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                labID: '',
                notificationModal: {
                    ...state.notificationModal,
                    isOpen: false,
                    method: '',
                    text: '',
                },
            };
        case modalConstants.DELETE_LAB_FROM_SYSTEM_ERROR:
            return {
                ...state,
                requestIsActive: false,
                labID: '',
                notificationModal: {
                    ...state.notificationModal,
                    isOpen: false,
                    method: '',
                    text: '',
                },
            };
        case modalConstants.DELETE_LABS_STATUS_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        // case modalConstants.DELETE_LABS_STATUS_SUCCESS:
        //     return {
        //         ...state,
        //         requestIsActive: false,
        //         deleteAllUsersNotificationListModal: {
        //             ...state.deleteAllUsersNotificationListModal,
        //             dataList: action.payload.successLabsDelete,
        //             isOpen: true
        //         }
        //     };
        case modalConstants.DELETE_LABS_STATUS_ERROR:
            return {
                ...state,
                requestIsActive: false,
                labID: '',
                deleteAllUsersNotificationListModal: {
                    ...state.deleteAllUsersNotificationListModal,
                    isOpen: false,
                    dataList: '',
                },
            };
        case modalConstants.DELETE_LABS_FROM_SYSTEM_REQUEST:
            return {
                ...state,
                requestIsActive: true,
                page: 0,
            };
        case modalConstants.DELETE_LABS_FROM_SYSTEM_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                needToGetLabs: true,
                notificationModal: {
                    isOpen: false,
                    method: '',
                    text: '',
                },
            };
        case modalConstants.DELETE_LABS_FROM_SYSTEM_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case dataListsManagementConstants.GET_ALL_LABS_REQUEST:
        case dataListsManagementConstants.GET_LABS_IN_GROUP_REQUEST:
        case dataListsManagementConstants.GET_MY_LABS_REQUEST:
            return {
                ...state,
                getLabsRequestActive: true,
                requestIsActive: true
            };
        case dataListsManagementConstants.LOAD_LABS_IN_GROUP_REQUEST:
            return {
                ...state,
                getLabsRequestActive: true,
                requestIsActive: true
            };
        case dataListsManagementConstants.GET_LABS_IN_GROUP_SUCCESS: {
            const { labs, data } = action.payload;
            const totalEl = data ? data.totalElements : 0;
            return {
                ...state,
                labs: labs,
                allLabsStatus: arraysUtil.labsStatusSetter(action.payload.labs),
                needToGetLabs: false,
                getLabsRequestActive: false,
                requestIsActive: false,
                showPreloader: true,
                totalElements: totalEl,
            };
        }
        // case dataListsManagementConstants.LOAD_ALL_LABS_SUCCESS:
        case dataListsManagementConstants.LOAD_LABS_IN_GROUP_SUCCESS:
        case dataListsManagementConstants.LOAD_MY_LABS_SUCCESS:
        case dataListsManagementConstants.GET_ALL_LABS_SUCCESS:
        case dataListsManagementConstants.GET_MY_LABS_SUCCESS:
            const { labs, data } = action.payload;
            const totalEl = data ? data.totalElements : 0;
            return {
                ...state,
                labs: labs,
                allLabsStatus: arraysUtil.labsStatusSetter(action.payload.labs),
                needToGetLabs: false,
                getLabsRequestActive: false,
                showPreloader: true,
                totalElements: totalEl,
                requestIsActive: false
            };
        case dataListsManagementConstants.GET_ALL_LABS_ERROR:
        case dataListsManagementConstants.GET_LABS_IN_GROUP_ERROR:
        case dataListsManagementConstants.LOAD_ALL_LABS_ERROR: {
            if (action.payload.text === 'Group not exist') {
                return {
                    ...state,
                    needToGetLabs: false,
                    getLabsRequestActive: false,
                    needToGetBack: true,
                };
            } else return state;
        }
        case dataListsManagementConstants.PERFORM_LABS_SEARCH:
            return {
                ...state,
                page: 0,
            };
        case dataListsManagementConstants.RESET_LABS_REDUCER:
            return {
                ...state,
                labs: [],
                allLabsStatus: {
                    ...initialState.allLabsStatus,
                },
                needToGetBack: false,
            };
        case dataListsManagementConstants.SET_USER_NAME:
            return {
                ...state,
                search: action.payload.search,
                page: 0,
                needToGetLabs: true,
            };
        case dataListsManagementConstants.SET_CURRENT_GROUP:
            return {
                ...state,
                needToGetLabs: true,
            };
        case dataListsManagementConstants.RECREATE_LAB_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                recreateLabNotificationModal: {
                    method: action.payload.method,
                    isOpen: action.payload.isOpen,
                    text: action.payload.text,
                    labTypeID: action.payload.labID,
                },
            };
        case dataListsManagementConstants.RECREATE_LAB_GROUP_ADMIN_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                recreateLabGroupAdminNotificationModal: {
                    method: action.payload.method,
                    isOpen: action.payload.isOpen,
                    text: action.payload.text,
                    labTypeID: action.payload.labID,
                },
            };
        case dataListsManagementConstants.HARD_RECREATE_LAB_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                hardRecreateLabNotificationModal: {
                    method: action.payload.method,
                    isOpen: action.payload.isOpen,
                    text: action.payload.text,
                    labTypeID: action.payload.labID,
                },
            };
        case dataListsManagementConstants.RECREATE_LAB_BY_USER_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                recreateLabNotificationModal: {
                    method: action.payload.method,
                    isOpen: action.payload.isOpen,
                    text: action.payload.text,
                    labTypeID: action.payload.labID,
                },
            };
        case modalConstants.RESET_TIMER_SHOW_NOTIFICATION_MODAL_FOR_USER:
            return {
                ...state,
                resetTimerModal: {
                    isOpen: true,
                    labId: action.payload.labId,
                    text: action.payload.text,
                    method: action.payload.method,
                    role: action.payload.role,
                },
                showPreloader: false,
            };

        case modalConstants.RESET_TIMER_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                resetTimerModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    labId: action.payload.labId,
                    role: action.payload.role,
                    groupID: action.payload.groupID,
                    userName: action.payload.resetTimerUser,
                    labType: action.payload.labType,
                },
                showPreloader: false,
            };
        case modalConstants.RESET_TIMER_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                resetTimerModal: initialState.resetTimerModal,
                needToGetLabs: true,
            };
        case modalConstants.RESET_TIMER_ERROR:
            return {
                ...state,
                requestIsActive: false,
                resetTimerModal: {
                    ...initialState.resetTimerModal,
                },
            };
        case dataListsManagementConstants.CLEAN_LABS_REDUCER:
            return initialState;
        case labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_SUCCESS:
        case labDetailsConstants.TURN_OFF_NEED_TO_GET_LABS:
            return {
                ...state,
                needToGetLabs: false,
            };
        case labDetailsConstants.SHOW_LOADER:
            return {
                ...state,
                showPreloader: true,
            };
        case dataListsManagementConstants.HANDLE_CLICK_LAB: {
            const { checkedID } = state;
            const { id } = action.payload;
            const selectedIndex = checkedID.indexOf(id);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(checkedID, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(checkedID.slice(1));
            } else if (selectedIndex === checkedID.length - 1) {
                newSelected = newSelected.concat(checkedID.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(checkedID.slice(0, selectedIndex), checkedID.slice(selectedIndex + 1));
            }
            return {
                ...state,
                checkedID: newSelected,
            };
        }
        case dataListsManagementConstants.HANDLE_SELECT_ALL_CLICK_LAB: {
            const { checked } = action.payload;
            const { labs } = state;
            if (!checked) {
                return {
                    ...state,
                    checkedID: labs.map(n => n.id),
                };
            } else {
                return {
                    ...state,
                    checkedID: [],
                };
            }
        }
        case dataListsManagementConstants.CHANGE_LABS_PAGE:
            return {
                ...state,
                page: action.payload.value,
                checkedID: [],
            };
        case dataListsManagementConstants.CHANGE_LABS_SIZE:
            return {
                ...state,
                size: action.payload.value,
                page: 0,
                checkedID: [],
            };
        case dataListsManagementConstants.CLEAN_LABS_PAGE_CHECKBOXES:
            return {
                ...state,
                checkedID: [],
            };
        case modalConstants.SET_LAB_LIMITATION_SUCCESS:
            return {
                ...state,
                needToGetLabs: true,
            };

        case dataListsManagementConstants.SET_STATUS_FILTER:
            return {
                ...state,
                statusFilter: action.payload,
                page: 0
            }

        case dataListsManagementConstants.SET_COLUMN_FILTER:
            let filters = _.cloneDeep(state.filters);
            filters[action.payload.id] = action.payload.value;
            return {
                ...state,
                filters: filters
            }

        case dataListsManagementConstants.GET_ALL_GROUPS_DROPDOWN_SUCCESS:
            let list = _.cloneDeep(state.filterLists);
            list['group.name'] = action.payload.groups.map(g => ({
                id: g.id,
                value: (g.name === 'dev-Freelance' || g.name === 'Freelance' || g.name === 'local-Freelance') ? '(no group)' : g.name
            }));
            return {
                ...state,
                filterLists: list
            }

        case dataListsManagementConstants.CLEAR_COLUMN_FILTERS:
            return {
                ...state,
                filters: {}
            }

        default:
            return state;
    }
}
