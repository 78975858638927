import { labStatuses } from '../Constants';

export const arraysUtil = {
    getAssignUsersResultArrays,
    getFilterArray,
    convertIp,
    labsStatusSetter,
    getSubscriptionNameByID,
    isUserExistByID,
    isInString,
    areOnlyHiddenLabs,
};

function getAssignUsersResultArrays(allGroupUsers, usersCurrentGroup, initialGroupUsers) {
    let addUserArray = [],
        removeUserArray = [],
        removedUsers = [];
    initialGroupUsers.forEach(item => {
        if (!usersCurrentGroup.find(u => u.id === item.id)) {
            removeUserArray.push(item.id);
            removedUsers.push(item);
        }
    });
    usersCurrentGroup.forEach(item => {
        addUserArray.push(item.id);
    });
    return {
        addUserArray,
        removeUserArray,
        removedUsers
    };
}

function getFilterArray(filterUsers, usersOfGroup) {
    let resultArray = [],
        exist;
    filterUsers.forEach(filterUser => {
        exist = false;
        usersOfGroup.forEach(groupUser => {
            if (filterUser.id === groupUser.id) {
                exist = true;
            }
        });
        if (!exist) {
            resultArray.push(filterUser);
        }
    });
    return resultArray;
}

function convertIp(item) {
    let array = item.split(';');
    if (array[array.length - 1] === '') array.pop();
    return array;
}

function labsStatusSetter(array) {
    let allLabsStatus = {};
    let pendingCounter = 0;
    for (let i = 0; i < array.length; i++) {
        if (
            array[i].status === 'DELETING' ||
            array[i].status === 'STOPPING' ||
            array[i].status === 'STARTING' ||
            array[i].status === 'CREATING'
        )
            pendingCounter++;
        else if (array[i].status === labStatuses.PENDING) allLabsStatus.pending = true;
        else if (array[i].status === labStatuses.STARTED) allLabsStatus.started = true;
        else if (array[i].status === labStatuses.STOPPED) allLabsStatus.stopped = true;
    }
    allLabsStatus.inProgress = pendingCounter === array.length && array.length !== 0;
    return allLabsStatus;
}

function getSubscriptionNameByID(subscriptionID, subscriptions) {
    let subscriptionName = '';
    for (let i = 0; i < subscriptions.length; i++) {
        if (subscriptions[i].subscriptionId === subscriptionID) {
            subscriptionName = subscriptions[i].subscriptionName;
            break;
        }
    }
    return subscriptionName;
}

function isUserExistByID(userID, usersIDs) {
    let exist = false;
    for (let i = 0; i < usersIDs.length; i++) {
        if (usersIDs[i] === userID) {
            exist = true;
            break;
        }
    }
    return exist;
}

function isInString(value, stringToArray) {
    let array = stringToArray.split(', ');
    return array.indexOf(value) > -1;
}

function areOnlyHiddenLabs(labs) {
    for (let i = 0; i < labs.length; i++) {
        if (labs[i].visible) {
            return false;
        }
    }
    return true;
}
