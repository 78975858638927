
const mq = window.matchMedia("(min-width: 1919px)");
let tableColumnHeaderStyles = {
  color: "white",
  position: "relative",
  fontSize: "12px",
  padding: "0 10px 0 10px",
};
let tableColumnStyles = {
  color: "white",
  position: "relative",
  fontSize: "12px",
  borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
  padding: "0 10px 0 10px",
};
const tableHeight = "calc(100vh - 245px)";
const tableHistoryHeight = "calc(100vh - 275px)";

export const darkBaseThemeConst = {
  palette: {
    mode: 'dark',
    primary: {
      main: "#FFFFFF",
    },
    // secondary: {
    //   // main: orange[500],
    // },
  },
  components: {
    MuiTextField: {
         defaultProps: {
             variant: 'standard',
         },
     },
    MuiSelect: {
         defaultProps: {
             variant: 'standard',
         },
         styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            paddingLeft: '6px',
          },
        },
         
     },
  }
  
}

export const defaultMuiTheme = {
  palette: {
    
  },
}

export const generalStyles = {
  mq,
  tableStyles: {
    tableColumnHeaderStyles,
    tableColumnStyles,
    table: {
      backgroundColor: "rgba(0, 0, 0, 0.50)",
    },
    tableHeader: {
      backgroundColor: "rgba(0, 0, 0, 0.50)",
    },
    height: tableHeight,
    tableHistoryHeight: tableHistoryHeight,
  },
  buttonStyles: {
    style: {
      fontSize: "12px",
      borderRadius: "10px",
    },
    backgroundColor: "rgba(51, 7, 7, 0.66)",
  },
  buttonForgotPasswordStyles: {
    style: {
      fontSize: "12px",
      borderRadius: "10px",
      marginTop: "10px",
    },
    backgroundColor: "rgba(51, 7, 7, 0.66)",
  },
  scrollBarStyles: {
    backgroundColor: "red",
    width: "4px",
    opacity: "0.6",
  },
};

export const dateTimePickerStyles = {
  datePicker: {
    color: "#ff6b66",
    textColor: "#fff",
    selectColor: "#ff6b66",
    headerColor: "#b30000",
  },
  timePicker: {
    textColor: "#fff",
    headerColor: "#b30000",
    accentColor: "#ff6b66",
    selectTextColor: "#fff",
  },
  flatButton: {
    primaryTextColor: "#fff",
  },
};

export const datePickerStyle = {
  palette: {
    mode: "dark",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          color: "#fff",
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        toolbar: {
          backgroundColor: "rgb(179, 0, 0)",
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        switchHeader: {
          // backgroundColor: '#ff0000',
          // color: "white",
        },
      },
    },
    MuiPickerDTTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor: "rgb(179, 0, 0)",
        },
      },
    },
    MuiPickersBasePicker: {
      styleOverrides: {
        pickerView: {
          // backgroundColor: 'rgb(48, 48, 48)'
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        day: {
          // color: '#ffffff',
        },
        daySelected: {
          backgroundColor: "rgb(255, 107, 102)",
        },
        dayDisabled: {
          // color: '#ff0000',
        },
        current: {
          color: "rgb(255, 107, 102)",
        },
      },
    },
    MuiPickersModal: {
      styleOverrides: {
        dialogAction: {
          // color: '#ff0000',
        },
      },
    },
    MuiPickersClock: {
      styleOverrides: {
        pin: {
          backgroundColor: "rgb(255, 107, 102)",
        },
      },
    },
    MuiPickersClockPointer: {
      styleOverrides: {
        pointer: {
          backgroundColor: "rgb(255, 107, 102)",
        },
        thumb: {
          border: "14px solid rgb(255, 107, 102)",
        },
        noPoint: {
          backgroundColor: "#fafafa",
        },
      },
    },
  },
};
