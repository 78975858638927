import { modalConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    info: {
        email: '',
        oldEmail: '',
    },
    isModalOpen: false,
    requestIsActive: false,
    changeProfileEmailNotificationModal: {
        isOpen: false,
        text: '',
        method: '',
    },
};

export function teacherProfileEditEmailModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.TOGGLE_TEACHER_PROFILE_EDIT_EMAIL_MODAL: {
            if (state.isModalOpen) {
                return {
                    ...state,
                    isModalOpen: false,
                    info: {
                        ...state.info,
                        email: state.info.oldEmail,
                    },
                };
            } else {
                return {
                    ...state,
                    isModalOpen: true,
                };
            }
        }
        case modalConstants.TEACHER_PROFILE_EDIT_EMAIL_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case modalConstants.TEACHER_PROFILE_EDIT_EMAIL_SUCCESS:
            return {
                ...state,
                isEditUserModalOpen: false,
                changeProfileEmailNotificationModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                },
                requestIsActive: false,
                info: {
                    oldEmail: state.info.email,
                },
            };
        case modalConstants.TEACHER_PROFILE_EDIT_EMAIL_ERROR:
            return {
                ...state,
                changeProfileEmailNotificationModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                },
                requestIsActive: false,
            };
        case modalConstants.TEACHER_PROFILE_EDIT_EMAIL_MODAL_CHANGE_FORM: {
            const { value, name } = action.payload.event.target;
            return {
                ...state,
                info: {
                    ...state.info,
                    [name]: value,
                },
            };
        }
        case modalConstants.TEACHER_PROFILE_GET_EMAIL:
            return {
                ...state,
                info: {
                    email: action.payload.email,
                    oldEmail: action.payload.email,
                },
            };
        case modalConstants.TEACHER_PROFILE_SHOW_EMAIL_NOTIFICATION_MODAL:
            return {
                ...state,
                isModalOpen: false,
                changeProfileEmailNotificationModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                },
            };
        case modalConstants.CLOSE_NOTIFICATION_MODAL:
            return {
                ...state,
                changeProfileEmailNotificationModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                },
            };
        default:
            return state;
    }
}
