import { urlConstant } from '../Constants';
import { labErrorRequests, seminarErrorRequests, userErrorRequests } from '../Handlers';
import { seminarUtil } from '../Utils';
import Maintenance from '../Modules/maintenance.module';
import {AxiosRequest} from "./AxiosApi";
import _ from 'lodash';

const isRepair = Maintenance.getMaintenance();

export const seminarService = {
    getAll,
    getAllUsersPage,
    getEditSeminarData,
    toggleAddSeminarModal,
    create,
    getLabsOfFirstSeminar,
    getLabsOfSeminar,
    remove,
    edit,
    changeStatus,
    getStudentAll,
    getSeminarUsersByID,
    getGroupAdminSeminarUsersByID,
    adminGetLabInfoByID,
    adminGetSeminarLabInfoByID,
    userGetLabInfoByID,
    getAllSeminarGA,
    getAllLabsGA,
    groupAdminGetLabInfoByID,
    changeStatusGA,
    getEditSeminarDataGA,
    editSeminarGroupAdmin,
    deleteUser,
    deleteUserGroupAdmin,
    resetPasswordSeminarUser,
    resetPasswordSeminarUserGroupAdmin,
    startSeminar,
    startSeminarGA
};

function getAll(page, size, search) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/seminar/?page=${page}&size=${size}`,
            data: {
                query: search,
                status: null,
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('get all seminars', error);
                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getAllUsersPage() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/seminar/select`,
            data: {
                query: '',
                status: null,
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('get all seminars', error);
                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getEditSeminarData(seminarID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/seminar/edit/${seminarID}`,
            data: {},
        })
            .then(response => {
                // console.log('get edit seminar successful', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('get edit seminars', error);
                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function toggleAddSeminarModal() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/seminar/new`,
        })
            .then(response => {
                // console.log('toggleAddSeminarModal successful', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('toggleAddSeminarModal', error);
                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function create(data, labTemplateIDs, adminIds) {

    return new Promise((resolve, reject) => {
        let updatedData = seminarUtil.convertCreateData(data);
        updatedData.labTypeIds = labTemplateIDs;
        updatedData.adminIds = adminIds;

        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/seminar/new`,
            data: updatedData,
        })
            .then(response => {
                // console.log('New seminar created successfully');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('create seminar', error);
                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getLabsOfFirstSeminar(seminarId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/seminar`,
            data: {
                seminarId,
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('get all seminars', error);
                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getLabsOfSeminar(seminarIds, page, size, search) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/seminar/labs/?page=${page}&size=${size}&sort=createdTime,desc`,
            data: {
                query: search,
                seminarIds: seminarIds !== 'all' ? [seminarIds] : null,
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('get labs of seminar', error);
                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function remove(seminarId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/admin/seminar/${seminarId}`,
        })
            .then(response => {
                // isRepair && console.log('Seminar removed successfully');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('remove seminar', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function edit(data) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/seminar/edit`,
            data: seminarUtil.convertEditData(data),
        })
            .then(response => {
                // isRepair && console.log('Seminar edited successfully');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('edit seminar', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function changeStatus(seminarID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/seminar/stop/${seminarID}`,
        })
            .then(response => {
                // isRepair && console.log('Seminar ended successfully');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('change status', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getStudentAll(seminarId, page, size) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/seminar/user/?page=${page}&size=${size}`,
            data: {
                seminarId: +seminarId,
            },
        })
            .then(response => {
                // isRepair && console.log('getStudentAll successful', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('get all student labs', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function startSeminar(seminarID) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/seminar/start/${seminarID}`,
        })
            .then(response => {
                // isRepair && console.log('Seminar ended successfully');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('start seminar', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function startSeminarGA(seminarID) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/groupAdmin/seminar/start/${seminarID}`,
        })
            .then(response => {
                // isRepair && console.log('Seminar ended successfully');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('start seminar', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getSeminarUsersByID(seminarID, page, size, search) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/users/seminar/?page=${page}&size=${size}`,
            data: {
                query: search,
                role: null,
                seminarIds: seminarID,
            },
        })
            .then(response => {
                // isRepair && console.log('getSeminarUsersByID successful', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('get seminar users by id', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupAdminSeminarUsersByID(seminarID, page, size, search) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/seminar/users/?page=${page}&size=${size}`,
            data: {
                query: search,
                role: null,
                seminarIds: seminarID,
            },
        })
            .then(response => {
                // isRepair && console.log('getGroupAdminSeminarUsersByID successful', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('get seminar users by id', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function adminGetLabInfoByID(labID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/labs/seminar/${labID}/instances`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get seminar lab info', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function adminGetSeminarLabInfoByID(labID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/labs/seminar/${labID}/instances/`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get seminar lab info', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function userGetLabInfoByID(labID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/seminar/user/${labID}/instances`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get seminar lab info', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getAllSeminarGA(page, size, search) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/seminar?page=${page}&size=${size}`,
            data: {
                query: search,
                status: null,
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('get all seminars', error);
                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getAllLabsGA(seminarIds, page, size, search) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/seminar/labs/?page=${page}&size=${size}`,
            data: {
                seminarIds: seminarIds !== 'all' ? [seminarIds] : null,
                query: search
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('get all seminars', error);
                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function groupAdminGetLabInfoByID(labID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/groupAdmin/seminar/${labID}/seminar/instances`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get seminar lab info', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function changeStatusGA(seminarID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/groupAdmin/seminar/stop/${seminarID}`,
        })
            .then(response => {
                // isRepair && console.log('Seminar ended successfully');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('change status', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getEditSeminarDataGA(seminarID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/groupAdmin/seminar/edit/${seminarID}`,
        })
            .then(response => {
                // console.log('get edit seminar successful', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('get edit seminars', error);
                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function editSeminarGroupAdmin(data) {

    return new Promise((resolve, reject) => {
        const convertedData = _.cloneDeep(seminarUtil.convertEditData(data));
        convertedData.adminIds = null;
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/groupAdmin/seminar/edit`,
            data: convertedData,
        })
            .then(response => {
                // isRepair && console.log('Seminar edited successfully');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('edit seminar', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function deleteUser(usersId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/admin/users/seminar`,
            data: usersId
        })
            .then(response => {
                // isRepair && console.log('User deleted successfully');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('delete user', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function deleteUserGroupAdmin(usersId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/groupAdmin/seminar/user`,
            data: {
                usersId,
            },
        })
            .then(response => {
                // isRepair && console.log('User deleted successfully by Group Admin');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = seminarErrorRequests('delete user group admin', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function resetPasswordSeminarUser(userID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/users/seminar/password`,
            data: {
                usersId: userID,
            },
        })
            .then(response => {
                // isRepair && console.log('Password reset status', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('password reset status', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function resetPasswordSeminarUserGroupAdmin(userID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/seminar/user/password`,
            data: {
                usersId: userID,
            },
        })
            .then(response => {
                // isRepair && console.log('Password reset status Group Admin', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('password reset status group admin', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}
