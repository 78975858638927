import { dataListsManagementConstants } from '../../Constants';

const initialState = {
    groupName: '',
    groupID: null,
    groupType: '',
    isGroupEmpty: true,
};

export function currentGroup(state = initialState, action) {
    switch (action.type) {
        case dataListsManagementConstants.SET_CURRENT_GROUP:
            if (action.payload.groupName === 'allGroups') {
                return {
                    ...state,
                    groupName: action.payload.groupName,
                    groupID: [],
                    groupType: action.payload.groupType,
                    isGroupEmpty: action.payload.isGroupEmpty,
                };
            } else {
                return {
                    ...state,
                    groupName: action.payload.groupName,
                    groupID: [action.payload.groupID],
                    groupType: action.payload.groupType,
                    isGroupEmpty: action.payload.isGroupEmpty,
                };
            }
        case dataListsManagementConstants.UPDATE_CURRENT_GROUP:
            return {
                ...state,
                isGroupEmpty: action.payload.isGroupEmpty,
            };
        case dataListsManagementConstants.CLEAN_GROUPS_REDUCERS:
            return initialState;
        default:
            return state;
    }
}
