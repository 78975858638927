import Auth from '../Modules/auth.module';
import { urlConstant } from '../Constants';
import { transformUtil } from '../Utils';
import { historyErrorRequests } from '../Handlers';
import Maintenance from '../Modules/maintenance.module';
import {AxiosRequest} from "./AxiosApi";

const isRepair = Maintenance.getMaintenance();

export const historyManagementService = {
    getAllHistory,
    getActionList,
    getUserHistory,
    getLabsHistory,
    getGroupsHistory,
    getTemplatesHistory,
    getUserActionList,
    getLabsActionList,
    getGroupsActionList,
    getTemplatesActionList,
    getSeminarsActionList,
    getSeminarsHistory,
};

function getSeminarsActionList() {
    const userRole = Auth.getUserRole();
    let role = '';
    if (userRole === 'ROLE_ADMIN') {
        role = 'admin';
    } else if (userRole === 'ROLE_GROUP_ADMIN') {
        role = 'groupAdmin';
    } else if (userRole === 'ROLE_USER') {
        role = 'user';
    }

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/${role}/history/seminars`,
        })
            .then(response => {
                // isRepair && console.log('Get seminars action list', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get seminars action list', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getSeminarsHistory(data, page, size) {
    const userRole = Auth.getUserRole();
    let role = '';
    if (userRole === 'ROLE_ADMIN') {
        role = 'admin';
    } else if (userRole === 'ROLE_GROUP_ADMIN') {
        role = 'groupAdmin';
    } else if (userRole === 'ROLE_USER') {
        role = 'user';
    }

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/${role}/history/seminars?page=${page}&size=${size}`,
            data: data,
        })
            .then(response => {
                // isRepair && console.log('Get seminar history', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get seminar history', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getAllHistory(search, field) {
    const { body, query } = transformUtil.getAllHistoryTransformedBody(search, field);


    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/history`,
            params: query,
            data: body,
        })
            .then(response => {
                // isRepair && console.log('Get all history', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get all history', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getActionList() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/history/actionType`,
        })
            .then(response => {
                // isRepair && console.log('Get action list', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get action list', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getUserHistory(data, page, size, sortField, sortDirection) {
    // const {body, query} = transformUtil.getAllHistoryTransformedBody(search, field);

    const userRole = Auth.getUserRole();
    let role = '';
    if (userRole === 'ROLE_ADMIN') {
        role = 'admin';
    } else if (userRole === 'ROLE_GROUP_ADMIN') {
        role = 'groupAdmin';
    } else if (userRole === 'ROLE_USER') {
        role = 'user';
    }
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/${role}/history/users?page=${page}&size=${size}&sort=createdTime,${sortDirection}`,
            data: data,
        })
            .then(response => {
                // isRepair && console.log('Get user history', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get user history', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getLabsHistory(data, page, size, sortField, sortDirection) {

    const userRole = Auth.getUserRole();
    let role = '';
    if (userRole === 'ROLE_ADMIN') {
        role = 'admin';
    } else if (userRole === 'ROLE_GROUP_ADMIN') {
        role = 'groupAdmin';
    } else if (userRole === 'ROLE_USER') {
        role = 'user';
    }
    // console.log('Labs data', data);
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/${role}/history/labs?page=${page}&size=${size}&sort=createdTime,${sortDirection}`,
            data: data,
        })
            .then(response => {
                // isRepair && console.log('Get labs history', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get labs history', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupsHistory(data, page, size, sortField, sortDirection) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/history/groups?page=${page}&size=${size}&sort=createdTime,${sortDirection}`,
            data: data,
        })
            .then(response => {
                // isRepair && console.log('Get group history', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get group history', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getTemplatesHistory(data, page, size, sortField, sortDirection) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/history/templates?page=${page}&size=${size}&sort=createdTime,${sortDirection}`,
            data: data,
        })
            .then(response => {
                // isRepair && console.log('Get template history', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get template history', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getUserActionList() {
    const userRole = Auth.getUserRole();
    let role = '';
    if (userRole === 'ROLE_ADMIN') {
        role = 'admin';
    } else if (userRole === 'ROLE_GROUP_ADMIN') {
        role = 'groupAdmin';
    } else if (userRole === 'ROLE_USER') {
        role = 'user';
    }

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/${role}/history/users`,
        })
            .then(response => {
                // isRepair && console.log('Get user action list', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get user action list', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getLabsActionList() {
    const userRole = Auth.getUserRole();
    let role = '';
    if (userRole === 'ROLE_ADMIN') {
        role = 'admin';
    } else if (userRole === 'ROLE_GROUP_ADMIN') {
        role = 'groupAdmin';
    } else if (userRole === 'ROLE_USER') {
        role = 'user';
    }

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/${role}/history/labs`,
        })
            .then(response => {
                // isRepair && console.log('Get labs action list', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get labs action list', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupsActionList() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/history/groups`,
        })
            .then(response => {
                // isRepair && console.log('Get groups action list', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get groups action list', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getTemplatesActionList() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/history/lab_templates`,
        })
            .then(response => {
                // isRepair && console.log('Get templates action list', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get templates action list', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}
