export const signInSeminarConstants = {
    CLOSE_SEMINAR_SIGN_IN: 'CLOSE_SEMINAR_SIGN_IN',
    SHOW_PASSWORD: 'SHOW_PASSWORD',

    /* ====== FIRST STEP ====== */

    CHANGE_FORM_DATA_FIRST_STEP: 'CHANGE_FORM_DATA_FIRST_STEP',
    VERIFY_SEMINAR_REQUEST: 'VERIFY_SEMINAR_REQUEST',
    VERIFY_SEMINAR_SUCCESS: 'VERIFY_SEMINAR_SUCCESS',
    VERIFY_SEMINAR_ERROR: 'VERIFY_SEMINAR_ERROR',
    SHOW_ERROR_FIRST_STEP: 'SHOW_ERROR_FIRST_STEP',
    HIDE_ERROR_FIRST_STEP: 'HIDE_ERROR_FIRST_STEP',

    /* ======= SECOND STEP ====== */

    CHANGE_FORM_DATA_SECOND_STEP: 'CHANGE_FORM_DATA_SECOND_STEP',
    VERIFY_USER_REQUEST: 'VERIFY_USER_REQUEST',
    VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
    VERIFY_USER_ERROR: 'VERIFY_USER_ERROR',
    SHOW_ERROR_SECOND_STEP: 'SHOW_ERROR_SECOND_STEP',
    HIDE_ERROR_SECOND_STEP: 'HIDE_ERROR_SECOND_STEP',
    VERIFY_CAPTCHA_SECOND_STEP: 'VERIFY_CAPTCHA_SECOND_STEP',
    SHOW_CAPTURE_ERROR_SECOND_STEP: 'SHOW_CAPTURE_ERROR_SECOND_STEP',
    LOGIN_USER_TO_SEMINAR_SUCCESS: 'LOGIN_USER_TO_SEMINAR_SUCCESS',
    LOGIN_USER_TO_SEMINAR_ERROR: 'LOGIN_USER_TO_SEMINAR_ERROR',
};
