class Seminars {
    static setSeminarId = data => {
        localStorage.setItem('seminar_id', data);
    };

    static getSeminarId = () => {
        return localStorage.getItem('seminar_id');
    };
}

export default Seminars;
