import { errorRequest } from '../Constants';
import Auth from '../Modules/auth.module';

export const labErrorRequests = (requestType, error) => {
    if (error.response) {
        const errorRes = error.response;

        switch (requestType) {
            case 'reset timer':
            case 'create all labs':
            case 'start all labs':
            case 'stop all labs':
            case 'delete all labs':
            case 'start all users labs':
            case 'stop all users labs':
            case 'create lab by admin':
            case 'start lab by admin':
            case 'stop lab by admin':
            case 'delete lab by admin':
            case 'start lab by user':
            case 'stop lab by user': {
                switch (errorRes.status) {
                    case 400: {
                        return errorRes.data;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    case 403: {
                        if (errorRes.data.error === 'access_denied') return 'A haven`t access for this action';
                        return errorRes.data.error;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }

            case 'add blueprint':
            case 'add lab for user': {
                switch (errorRes.status) {
                    case 406: {
                        return 'User already have selected lab template';
                    }
                    case 400: {
                        return error.response.data.cause;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'add lab for group':
            case 'get action list':
            case 'get all history': {
                switch (errorRes.status) {
                    case 400: {
                        return errorRes.data.cause;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'get all labs of group': {
                switch (errorRes.status) {
                    case 400: {
                        return `Error to ${requestType}`;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    case 403: {
                        return 'Group not exist';
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'add lab template': {
                switch (errorRes.status) {
                    case 400: {
                        return error.response.data.cause;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'delete lab template': {
                switch (errorRes.status) {
                    case 406: {
                        return "Can't delete lab template. It is used in lab instances";
                    }
                    case 400: {
                        return error.response.data.cause;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'delete lab from system': {
                switch (errorRes.status) {
                    case 406: {
                        return error.response.data.cause.charAt(0).toUpperCase() + error.response.data.cause.slice(1);
                    }
                    case 400: {
                        return error.response.data.cause;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'recreate lab by admin': {
                switch (errorRes.status) {
                    case 400: {
                        return errorRes.data.cause;
                    }

                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'set blueprint name': {
                switch (errorRes.status) {
                    case 400: {
                        return errorRes.data.cause;
                    }

                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }

                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'get lab info for GA': {
                switch (errorRes.status) {
                    case 400: {
                        return errorRes.data.cause;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    case 404: {
                        return 'Lab entity not found by id';
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'get current labs for GA':
                switch (errorRes.status) {
                    case 403:
                    case 400: {
                        return errorRes.data.cause;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            default: {
                switch (errorRes.status) {
                    case 400: {
                        return errorRes.data.cause;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
        }
    } else {
        Auth.deauthenticateUser();
        return errorRequest.DEFAULT;
    }
};
