import {dataListsManagementConstants, modalConstants, REFRESH_TOKEN} from '../../Constants';
import Maintenance from '../../Modules/maintenance.module';
import _ from "lodash";

const initialState = {
    teacherProfileResetPasswordModal: {
        isOpen: false,
        text: '',
        method: '',
    },
    requestIsActive: false,
    userInfo: {
        id: '',
        name: '',
        email: '',
        lastLogin: '',
        groupList: [],
        groupAdminList: [],
        labTypeAdminList: [],
    },
    maintenanceIsActive: null,
    maintenanceNotificationModal: {
        method: '',
        isOpen: false,
        text: '',
    },
    refreshToken: false
};

export function userProfile(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.TEACHER_PROFILE_RESET_PASSWORD_SHOW_NOTIFICATION_MODAL: {
            if (state.teacherProfileResetPasswordModal.isOpen) {
                return initialState;
            } else {
                return {
                    ...state,
                    teacherProfileResetPasswordModal: {
                        isOpen: true,
                        text: action.payload.text,
                        method: action.payload.method,
                    },
                };
            }
        }
        case modalConstants.TEACHER_PROFILE_RESET_PASSWORD_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case modalConstants.TEACHER_PROFILE_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                teacherProfileResetPasswordModal: initialState.teacherProfileResetPasswordModal,
                requestIsActive: false,
            };
        case modalConstants.CLOSE_NOTIFICATION_MODAL:
            return {
                ...state,
                requestIsActive: false,
                teacherProfileResetPasswordModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                },
                maintenanceNotificationModal: {
                    method: '',
                    isOpen: false,
                    text: '',
                },
            };
        case dataListsManagementConstants.GET_USER_INFO_SUCCESS: {
            const data = action.payload.data;
            const { id, username, email, lastLoginTime, groupList, groupAdminList, labTypeAdminList } = data;
            // const object = action.payload.data.groupList;
            // const namesArray = object.map(item => item.groupName);
            return {
                ...state,
                userInfo: {
                    id: id,
                    name: username,
                    email: email,
                    lastLogin: lastLoginTime,
                    groupList: groupList,
                    groupAdminList,
                    labTypeAdminList,
                },
            };
        }
        case dataListsManagementConstants.TOGGLE_MAINTENANCE_SUCCESS: {
            Maintenance.setMaintenance(action.payload.maintenanceNeedToBe);
            return {
                ...state,
                maintenanceIsActive: action.payload.maintenanceNeedToBe,
                maintenanceNotificationModal: {
                    method: '',
                    isOpen: false,
                    text: '',
                },
            };
        }
        case dataListsManagementConstants.TOGGLE_MAINTENANCE_ERROR:
            return {
                ...state,
                maintenanceNotificationModal: {
                    method: '',
                    isOpen: false,
                    text: '',
                },
            };
        case dataListsManagementConstants.GET_MAINTENANCE_STATUS_SUCCESS: {
            Maintenance.setMaintenance(action.payload.value);
            return {
                ...state,
                maintenanceIsActive: action.payload.value,
            };
        }
        case modalConstants.TOGGLE_MAINTENANCE_NOTIFICATION_MODAL:
            if (!state.maintenanceNotificationModal.isOpen) {
                return {
                    ...state,
                    maintenanceNotificationModal: {
                        method: action.payload.method,
                        isOpen: true,
                        text: Maintenance.getMaintenance()
                            ? 'Are you sure to disable maintenance mode?'
                            : 'Are you sure to enable maintenance mode?',
                    },
                };
            } else return initialState;

        case REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: true
            }
        default:
            return state;
    }
}
