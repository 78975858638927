import { useEffect } from 'react';
// import {withSnackbar} from "notistack";
import {connect} from 'react-redux';
import {notifierActions} from "../../../../Actions";
import { useSnackbar } from 'notistack'

// class Notifier extends React.Component {
//     componentDidUpdate(prevProps, prevState, snapshot) {
//         const {notification: note} = this.props;
//         if (note.enqueue) {

//             this.props.enqueueSnackbar(
//                 note.message,
//                 note.options
//             );

//             this.props.clearEnqueueNotification();
//         }
//     }

//     render() {
//         return null;
//     };
// }

const Notifier = ({ notification: note, clearEnqueueNotification }) => {
    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
        if (note.enqueue) {
            enqueueSnackbar(note.message, note.options);
            clearEnqueueNotification();
        }
    }, [note, enqueueSnackbar, clearEnqueueNotification]);

    return null;
};


const connectedNotifier = connect(
    state => ({
        notification: state.notification
    }),
    {
        clearEnqueueNotification: notifierActions.clearEnqueueNotification
    }
)(Notifier);

// export default withSnackbar(connectedNotifier);
export default connectedNotifier;