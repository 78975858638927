import Auth from '../Modules/auth.module';
import { urlConstant } from '../Constants';
import { userErrorRequests } from '../Handlers';
import Maintenance from '../Modules/maintenance.module';
import {AxiosRequest} from "./AxiosApi";

const isRepair = Maintenance.getMaintenance();

export const userProfileService = {
    changeEmail,
    changePassword,
    maintenanceSwitch,
    getMaintenanceStatus,
};

function changeEmail(email) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/user/student/email/`,
            data: {
                email,
            },
        })
            .then(response => {
                isRepair && console.log('Profile edit email status', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('edit user error', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function changePassword() {

    const userRole = Auth.getUserRole();
    let role = '';
    if (userRole === 'ROLE_ADMIN') {
        role = 'admin';
    } else if (userRole === 'ROLE_GROUP_ADMIN') {
        role = 'groupAdmin';
    } else if (userRole === 'ROLE_USER') {
        role = 'user';
    }
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/${role}/password`,
        })
            .then(response => {
                isRepair && console.log('Password reset status', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('password reset status', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function maintenanceSwitch(underMaintenance) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/users/maintenance/update`,
            data: { underMaintenance },
        })
            .then(response => {
                isRepair && console.log('maintenanceSwitch successful');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('maintenance switch', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getMaintenanceStatus() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/maintenance`,
            additionalConfigOptions: {
                headers: null
            }
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('maintenance status', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}
