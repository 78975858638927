import { dataListsManagementConstants } from '../../Constants';
import _ from 'lodash';

const initialState = {
    date: {
        from: null,
        to: null,
    },
    dateToSend: {
        from: null,
        to: null,
    },
    sortField: 'date',
    sortDirection: 'desc',
    size: 20,
    sizeList: [10, 20, 30],
    page: 0,
    total: 0,
    requestIsActive: false,
    action: ['default'],
    actionList: [],
    search: '',
    history: [],
    detailsModal: {
        isModalOpen: false,
        item: {},
    },
    needToSearch: false,
    currentTab: 'users',
    includeTestData: false
};

export function historyManagement(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case dataListsManagementConstants.GET_USER_HISTORY_REQUEST:
        case dataListsManagementConstants.GET_LABS_HISTORY_REQUEST:
        case dataListsManagementConstants.GET_GROUPS_HISTORY_REQUEST:
        case dataListsManagementConstants.GET_SEMINARS_HISTORY_REQUEST:
        case dataListsManagementConstants.GET_TEMPLATE_HISTORY_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case dataListsManagementConstants.GET_USER_HISTORY_SUCCESS:
        case dataListsManagementConstants.GET_LABS_HISTORY_SUCCESS:
        case dataListsManagementConstants.GET_GROUPS_HISTORY_SUCCESS:
        case dataListsManagementConstants.GET_SEMINARS_HISTORY_SUCCESS:
        case dataListsManagementConstants.GET_TEMPLATE_HISTORY_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                history: action.payload.history.content,
                total: action.payload.history.totalElements,
                // actionList: action.payload.history.content[0].operationTypes,
            };
        case dataListsManagementConstants.GET_USER_HISTORY_ERROR:
        case dataListsManagementConstants.GET_LABS_HISTORY_ERROR:
        case dataListsManagementConstants.GET_GROUPS_HISTORY_ERROR:
        case dataListsManagementConstants.GET_SEMINARS_HISTORY_ERROR:
        case dataListsManagementConstants.GET_TEMPLATE_HISTORY_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case dataListsManagementConstants.SET_DATE_ACTION: {
            let date = state.date;
            date[action.payload.action] = action.payload.value;
            return {
                ...state,
                date: date,
                page: 0,
            };
        }
        case dataListsManagementConstants.CHANGE_SEARCH:
            return {
                ...state,
                search: action.payload.value,
            };
        case dataListsManagementConstants.CHANGE_ACTION:
            return {
                ...state,
                action: action.payload.value,
            };
        case dataListsManagementConstants.CHANGE_SIZE:
            return {
                ...state,
                size: action.payload.value,
            };
        case dataListsManagementConstants.CHANGE_PAGE:
            return {
                ...state,
                page: action.payload.value,
            };
        case dataListsManagementConstants.CHANGE_SORTING:
            return {
                ...state,
                sortField: action.payload.field,
                sortDirection: action.payload.direction,
                list: action.payload.list,
            };
        case dataListsManagementConstants.GET_USER_ACTIONS:
        case dataListsManagementConstants.GET_LABS_ACTIONS:
        case dataListsManagementConstants.GET_GROUPS_ACTIONS:
        case dataListsManagementConstants.GET_SEMINARS_ACTIONS:
        case dataListsManagementConstants.GET_TEMPLATES_ACTIONS: {
            let resultArray = [];

            /* --- Particular changes --- should be in utils */

            action.payload.list.forEach(item => {
                if (item === 'VM_Deallocated') {
                    resultArray.push('VM_Stopped');
                } else if (item === 'Not_Importent' || item === 'Lab_Change_Ip' || item === 'VM_Delete' || item === 'Delete_VM') {
                    //
                } else if (item === 'SignIn') {
                    resultArray.push('Sign In');
                } else if (item === 'SignOut') {
                    resultArray.push('Sign Out');
                } else if (item === 'Lab_Creating') {
                    resultArray.push('Lab_Create');
                } else if (item === 'VM_Starting') {
                    resultArray.push('VM_Start');
                } else if (item === 'VM_Stopping') {
                    resultArray.push('VM_Stop');
                } else if (item === 'Failed_To_Create') {
                    resultArray.push('Lab_Failed');
                } else {
                    resultArray.push(item);
                }
            });

            /* --- */

            /* general changes */

            // resultArray = resultArray.map(item => {
            //     let splitArray = item.split('_');
            //     let resultItem = '';
            //     splitArray.forEach((item, index) => {
            //         if (index === splitArray.length -1) {
            //             resultItem += item;
            //         } else {
            //             resultItem += item + ' ';
            //         }
            //     });
            //     return resultItem;
            // });

            /* --- */

            return {
                ...state,
                actionList: resultArray,
            };
        }
        case dataListsManagementConstants.TOGGLE_HISTORY_DETAILS_MODAL: {
            if (state.detailsModal.isModalOpen) {
                return {
                    ...state,
                    detailsModal: {
                        isModalOpen: false,
                        item: {},
                    },
                };
            } else {
                return {
                    ...state,
                    detailsModal: {
                        isModalOpen: true,
                        item: action.payload.item,
                    },
                };
            }
        }
        case dataListsManagementConstants.CLEAR_PAGE_COUNTER:
            return {
                ...state,
                page: 0,
            };
        case dataListsManagementConstants.PERFORM_HISTORY_SEARCH:
            return {
                ...state,
                needToSearch: true,
            };
        case dataListsManagementConstants.DISABLE_HISTORY_SEARCH:
            return {
                ...state,
                needToSearch: false,
            };
        case dataListsManagementConstants.CLEAN_USER_HISTORY_REDUCER:
            return initialState;
        case dataListsManagementConstants.SET_CURRENT_HISTORY_SIZE:
            return {
                ...state,
                size: action.payload.value,
            };
        case dataListsManagementConstants.CHANGE_TAB:
            return {
                ...state,
                page: 0,
                currentTab: action.payload.value,
                action: ['default'],
            };

        case dataListsManagementConstants.TOGGLE_INCLUDE_TEST_DATA:
            return {
                ...state,
                includeTestData: !state.includeTestData,
                needToSearch: true
            }
        default:
            return state;
    }
}
