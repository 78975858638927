import { modalConstants } from '../../Constants';
import { groupsUtil } from '../../Utils';

const initialState = {
    timeFrameModalOpen: false,
    checkedDays: [],
    from: null,
    to: null,
    startTime: '',
    endTime: '',
    requestIsActive: false,
    labLimitation: '',
    userId: '',
    groupId: '',
    labId: '',
    isActive: true,
};

export function timeFrameModal(state = initialState, action) {
    switch (action.type) {
        case modalConstants.TOGGLE_TIME_FRAME_MODAL: {
            const { period } = action.payload;
            if (state.timeFrameModalOpen) {
                return {
                    timeFrameModalOpen: false,
                    checkedDays: [],
                    from: null,
                    to: null,
                    startTime: '',
                    endTime: '',
                    requestIsActive: false,
                    labLimitation: '',
                    userId: '',
                    groupId: '',
                    labId: '',
                };
            } else if (period) {
                return {
                    ...state,
                    timeFrameModalOpen: true,
                    groupId: action.payload.groupId,
                    checkedDays: action.payload.period.daysOfWeek,
                    // date: {
                    //     from: moment.unix(period.dateFrom).toDate(),
                    //     to: moment.unix(period.dateTo).toDate(),
                    // },
                    from: groupsUtil.convertReceivedDates(period.dateFrom, period.dateTo).startTime,
                    to: groupsUtil.convertReceivedDates(period.dateFrom, period.dateTo).endTime,
                    startTime: groupsUtil.convertReceivedDates(period.dateFrom, period.dateTo).startTime,
                    // startTime: moment(period.timeStart, 'HH:mm:ss').toDate(),
                    endTime: groupsUtil.convertReceivedDates(period.dateFrom, period.dateTo).endTime,
                    // endTime: moment(period.timeStop, 'HH:mm:ss').toDate(),
                    isActive: true,
                };
            } else {
                return {
                    ...state,
                    timeFrameModalOpen: true,
                    groupId: action.payload.groupId,
                    from: groupsUtil.setDefaultDates().dateFrom,
                    to: groupsUtil.setDefaultDates().dateTo,
                    startTime: groupsUtil.setDefaultDates().startTime,
                    endTime: groupsUtil.setDefaultDates().endTime,
                    isActive: false,
                };
            }
        }
        case modalConstants.SELECT_TIME_FRAME_DAY: {
            const { checkedDays } = state;
            const { day } = action.payload;
            const selectedIndex = checkedDays.indexOf(day);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(checkedDays, day);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(checkedDays.slice(1));
            } else if (selectedIndex === checkedDays.length - 1) {
                newSelected = newSelected.concat(checkedDays.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(checkedDays.slice(0, selectedIndex), checkedDays.slice(selectedIndex + 1));
            }
            return {
                ...state,
                checkedDays: newSelected,
            };
        }
        case modalConstants.CHANGE_TIME_FRAME_DATE: {
            const { type, value } = action.payload;
            return {
                ...state,
                [type]: value,
            };
        }
        case modalConstants.SET_TIME_FRAME_DATE: {
            let date = state.date;
            date[action.payload.action] = action.payload.value;
            return {
                ...state,
                date: date,
            };
        }
        case modalConstants.SET_TIME_FRAME_TIME: {
            const { type, value } = action.payload;
            return {
                ...state,
                [type]: value,
            };
        }
        case modalConstants.UPDATE_TIME_FRAME_SUCCESS: {
            return {
                timeFrameModalOpen: false,
                checkedDays: [],
                from: null,
                to: null,
                startTime: '',
                endTime: '',
                requestIsActive: false,
                labLimitation: '',
                userId: '',
                groupId: '',
                labId: '',
            };
        }
        case modalConstants.TOGGLE_TIME_FRAME: {
            if (state.isActive) {
                return {
                    ...state,
                    isActive: false,
                };
            } else {
                return {
                    ...state,
                    isActive: true,
                };
            }
        }
        case modalConstants.GET_ALL_GROUPS_REQUEST:
        case modalConstants.UPDATE_TIME_FRAME_REQUEST: {
            return { ...state, requestIsActive: true };
        }

        case modalConstants.UPDATE_TIME_FRAME_ERROR: {
            return { ...state, requestIsActive: false };
        }
        default:
            return state;
    }
}
