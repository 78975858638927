import { statusModalConstants } from '../../Constants';

const initialState = {
    isOpen: false,
    status: undefined,
    text: '',
    needLogout: false,
    forgotPassword: false,
};

export function statusModal(state = initialState, action) {
    switch (action.type) {
        case statusModalConstants.OPEN:
            return {
                isOpen: true,
                status: action.payload.status ? 'Great :)' : 'Oops :(',
                text: action.payload.text,
                needLogout: action.payload.needLogout,
                forgotPassword: action.payload.forgotPassword,
            };
        case statusModalConstants.CLOSE:
            return initialState;
        default:
            return state;
    }
}
