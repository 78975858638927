import React, { Component } from 'react';
import { connect } from 'react-redux';
import SockJsClient from 'react-stomp';
import { urlConstant } from '../../../../Constants';
import {notifierActions, webSocketActions} from '../../../../Actions';
import Auth from "../../../../Modules/auth.module";

class WebSocket extends Component {
    render() {
        const { setData, enqueueNotification } = this.props;

        return (
            <SockJsClient
                url={`${urlConstant}/ws`}
                topics={['/admin', `/user/${Auth.getUserId()}/specific-user`]}
                onMessage={setData}
                onConnect={() => {
                    console.log('Socket connection established.');
                    if (Auth.isUserAuthenticated()) {
                        enqueueNotification('Socket connection established.', {variant: 'success'});
                    }
                }}
                onDisconnect={() => {
                    console.log('Socket connection closed.');
                    if (Auth.isUserAuthenticated()) {
                        enqueueNotification('Socket connection closed.', {variant: 'warning'});
                    }
                }}
                debug={process.env.NODE_ENV === `development`}
            />
        );
    }
}

const mapDispatchToProps = {
    setData: webSocketActions.setData,
    enqueueNotification: notifierActions.enqueueNotification,
};

export default connect(null, mapDispatchToProps)(WebSocket);
