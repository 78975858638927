import {confirmModalConstants} from "../Constants/Actions/confirmModal.constants";

export const confirmModalActions = {
    openConfirmModal,
    closeConfirmModal
};

function openConfirmModal(options) {
    return {
        type: confirmModalConstants.OPEN_CONFIRM_MODAL,
        payload: options
    }
}

function closeConfirmModal() {
    return {
        type: confirmModalConstants.CLOSE_CONFIRM_MODAL
    }
}