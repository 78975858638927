import { modalConstants } from '../../Constants';

const initialState = {
    limitationModalOpen: false,
    requestIsActive: false,
    labLimitation: '',
    userId: '',
    groupId: '',
    labId: '',
};

export function labLimitationModal(state = initialState, action) {
    switch (action.type) {
        case modalConstants.TOGGLE_LAB_LIMITATION_MODAL: {
            if (state.limitationModalOpen) {
                return initialState;
            } else {
                return {
                    ...state,
                    limitationModalOpen: true,
                    labId: action.payload.labId,
                    userId: action.payload.userId,
                    groupId: action.payload.groupId,
                    labLimitation: action.payload.limitation,
                };
            }
        }
        case modalConstants.CHANGE_LAB_LIMITATION: {
            return {
                ...state,
                labLimitation: action.payload.limitation.replace(/[^0-9]+/gi, ''),
            };
        }
        case modalConstants.SET_LAB_LIMITATION_REQUEST: {
            return {
                ...state,
                requestIsActive: true,
            };
        }
        case modalConstants.SET_LAB_LIMITATION_SUCCESS: {
            return initialState;
        }
        case modalConstants.SET_LAB_LIMITATION_ERROR: {
            return initialState;
        }

        default:
            return state;
    }
}
