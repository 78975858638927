import _ from 'underscore';
import React from 'react';
import moment from 'moment/moment';
import 'moment-duration-format';

export const transformUtil = {
    convertDate,
    convertHistoryDate,
    getAllHistoryTransformedBody,
    getPowerState,
    countPowerState,
    toUpperCase,
    checkActiveVM,
    parseInterval,
    getInterval,
    getHours,
    getMinutes,
    getDaysFromMinutes,
    getMinutesFromDays,
    getSecondsFromHours,
    replaceWhiteSpace,
};

function compactObject(obj) {
    const o = _.clone(obj);
    _.each(o, function(v, k) {
        if (_.isObject(v)) {
            if (_.isEmpty(v)) {
                delete o[k];
            }
        } else {
            if (!v) {
                delete o[k];
            }
        }
    });
    return o;
}

function convertDate(milliseconds) {
    let date = new Date(+milliseconds);
    const options = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Asia/Jerusalem',
        hour12: false,
        formatMatcher: 'basic',
    };
    return date.toLocaleString('es-ES', options).replace(/[/]/g, '.');
}

function convertHistoryDate(milliseconds) {
    let date = new Date(milliseconds);
    const options = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'Asia/Jerusalem',
        hour12: false,
        formatMatcher: 'basic',
    };
    return date.toLocaleString('es-ES', options).replace(/[/]/g, '.');
}

function getAllHistoryTransformedBody(search, field) {
    let { operationTypes } = search.body;

    for (let i = 0; i < operationTypes.length; i++) {
        if (operationTypes[i] === 'Sign In') {
            operationTypes[i] = 'SignIn';
        } else if (operationTypes[i] === 'Sign Out') {
            operationTypes[i] = 'SignOut';
        } else if (operationTypes[i] === 'Lab Create') {
            operationTypes[i] = 'Lab_Creating';
        }
        if (operationTypes[i] === 'VM Stopped') {
            operationTypes[i] = 'VM_Deallocated';
        } else if (operationTypes[i] === 'VM Start') {
            operationTypes[i] = 'VM_Starting';
        } else if (operationTypes[i] === 'VM Stop') {
            operationTypes[i] = 'VM_Stopping';
        } else if (operationTypes[i] === 'Lab Failed') {
            operationTypes[i] = 'Failed_To_Create';
        } else {
            if (operationTypes[i] && operationTypes[i].includes(' ')) {
                let arrayResult = operationTypes[i].split(' ');
                operationTypes[i] = [];
                arrayResult.forEach((item, index) => {
                    if (index === arrayResult.length - 1) {
                        operationTypes[i] += item;
                    } else {
                        operationTypes[i] += item + '_';
                    }
                });
            }
        }
    }

    search.body.actionTypes = operationTypes.length !== '' ? operationTypes : undefined;
    search.body.actionType = undefined;

    let body = _.clone(search.body);
    body.date = compactObject(search.body.date);
    body = compactObject(body);

    const sortField = field === 'actionType' ? 'action' : field === 'date' ? 'date' : '';
    if (sortField !== '') {
        body = {
            ...body,
            sortField,
        };
    }

    if (body && body.date && body.date.dateTo) {
        body.date.dateTo += 86400000;
    }

    return {
        body,
        query: search.query,
    };
}

function checkActiveVM(labInfo) {
    if (labInfo && labInfo.length) {
        let stopped = false;
        if (Object.keys(labInfo).length !== 0) {
            labInfo.forEach(item => {
                if (item.powerState !== 'RUNNING') {
                    stopped = true;
                }
            });
        }
        return stopped;
    }
}

function getPowerState(labInfo) {
    if (labInfo && labInfo.length) {
        let powerState = 'static';
        if (Object.keys(labInfo).length !== 0) {
            labInfo.forEach(item => {
                if (item.powerState === 'Starting' || item.powerState === 'Stopping' || item.powerState === 'Restarting') {
                    powerState = 'in progress';
                }
            });
        }
        return powerState;
    }
}

function countPowerState(labInfo) {
    if (labInfo && labInfo.length) {
        let obj = {
            starting: [],
            stopping: [],
            restarting: [],
        };
        if (Object.keys(labInfo).length !== 0) {
            labInfo.forEach(item => {
                switch (item.powerState) {
                    case 'Starting':
                        return obj.starting.push(1);
                    case 'Stopping':
                        return obj.stopping.push(1);
                    case 'Restarting':
                        return obj.restarting.push(1);
                    default:
                        break;
                }
            });
        }
        return (
            <div>
                {obj.starting.length > 0 ? <div>Starting: {obj.starting.length}</div> : ''}
                {obj.stopping.length > 0 ? <div>Stopping: {obj.stopping.length}</div> : ''}
                {obj.restarting.length > 0 ? <div>Restarting: {obj.restarting.length}</div> : ''}
            </div>
        );
    }
}

function toUpperCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function parseInterval(interval) {
    let u = {};
    u['minute'] = 1;
    u['hour'] = 60 * u['minute'];
    u['day'] = 24 * u['hour'];
    u['week'] = 7 * u['day'];
    u['month'] = 30 * u['day'];
    u['quarter'] = 3 * u['month'];
    u['year'] = 365 * u['day'];
    let strInterval = interval.toString();
    let numbers = strInterval.match(/[0-9]+/g);
    // let numbers = interval.match(/[0-9]+/g);
    let words = strInterval.split(/[0-9]+/g);
    // let words = interval.split(/[0-9]+/g);
    let result = 0;
    for (let i = 1; i < words.length; i++) {
        for (let unit in u) {
            if (words[i].indexOf(unit) !== -1) {
                let num = parseInt(numbers[i - 1], 10) * u[unit];
                if (!isNaN(num)) {
                    result += num;
                }
            }
        }
    }
    if (result === 0) {
        result = parseInt(interval, 10);
    }
    return isNaN(result) ? 0 : result > 525600 ? 525600 : result;
}

function getInterval(minutes) {
    let duration = moment.duration(minutes, 'minutes');
    // let formatted = duration.format("*hh[h]:mm[m]:ss[s]");
    let formatted = '';
    if (duration.months() > 0) {
        // formatted = `${duration.months()}m:${duration.weeks()}w:${duration.days()}d`
        formatted = duration.format('*w [week] d[day] h [hour]');
    } else if (duration.weeks() > 0) {
        formatted = duration.format('w [week] d[day] h [hour]');
    } else if (duration.weeks() === 0 && duration.days() > 0) {
        formatted = duration.format('d [day] h [hour] m [minute]');
    } else {
        formatted = duration.format('*h [hour] m [minute] s [second]');
    }

    return formatted;
}

function getHours(mins) {
    let h = mins / 60;
    return +h;
}

function getMinutes(hours) {
    let minutes = hours * 60;
    return +minutes;
}

function getDaysFromMinutes(minutes) {
    let days = minutes / 1440;
    return days;
}

function getMinutesFromDays(days) {
    let minutes = days * 1440;
    return minutes;
}

function getSecondsFromHours(hours) {
    let seconds = hours * 3600;
    return seconds;
}

function replaceWhiteSpace(string) {
    const newString = string.replace(/\s/g, '_');
    return newString;
}
