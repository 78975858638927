import { dataListsManagementConstants, errorRequest, modalConstants, notificationModalActions } from '../Constants';
import { labsManagementService, usersAssignmentService, singleLabManagementService, userService } from '../Services';
import {notifierActions} from "./notifier.actions";

const {enqueueNotification} = notifierActions;

export const userAssigningActions = {
    changeForm,
    addNewLabForGroup,
    addNewLabForUser,
    closeSuccessAddGroupLabsModal,
    removeUserFromGroupNotifModalShow,
    getUsersAllGroups,
    getUsersOfGroup,
    deleteUserFromGroup,
    getAllUsersBelongs,
    updateUsersInGroup,
    setUsername,
    setFilterGroupId,
    checkFilterUsers,
    checkGroupUsers,
    getFilterUsers,
    assignUsers,
    unassignUsers,
    sortUpFilterUsersName,
    sortDownFilterUsersName,
    sortUpFilterUsersEmail,
    sortDownFilterUsersEmail,
    sortUpGroupUsersName,
    sortDownGroupUsersName,
    sortUpGroupUsersEmail,
    sortDownGroupUsersEmail,
    onSelectAllClick,
    onSelectAllClickInGroup,
    getUsersOfGroupGroupAdmin,
    addNewLabForUserGroupAdmin,
    addNewLabForGroupAdmin,
    // getTableData,
    changePage,
    changeSize,
    resetPage,
    unassignUserById
};

function changeForm(info) {
    return {
        type: modalConstants.ADD_LAB_CHANGE_FORM,
        payload: {
            info,
        },
    };
}

function addNewLabForUser(userID, groupID, instanceTypeID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        singleLabManagementService
            .addForUser(userID, groupID, instanceTypeID)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Lab added successfully', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.ADD_LAB_FOR_USER_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.ADD_LAB_FOR_USER_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.ADD_LAB_FOR_USER_ERROR,
        };
    }
}

function addNewLabForGroup(groupID, instanceTypeID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labsManagementService
            .addForGroup(groupID, instanceTypeID)
            .then(response => {
                dispatch(success(response.data));

                dispatch(enqueueNotification('Labs added successfully', {variant: 'success'}));
                // if (!response.data.length) {
                //     console.log('Response data', response);
                //     dispatch(openStatusModal({
                //         text: 'All users from this group already have this type of lab',
                //         status: false,
                //     }));
                // }
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.ADD_LAB_FOR_GROUP_REQUEST,
        };
    }
    function success(users) {
        return {
            type: modalConstants.ADD_LAB_FOR_GROUP_SUCCESS,
            payload: {
                users,
            },
        };
    }
    function failure() {
        return {
            type: modalConstants.ADD_LAB_FOR_GROUP_ERROR,
        };
    }
}

function addNewLabForGroupAdmin(groupID, instanceTypeID) {
    // console.log('addNewLabForGroupAdmin action');
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labsManagementService
            .addForGroupAdmin(groupID, instanceTypeID)
            .then(response => {
                // console.log('addForGroupAdmin action', response.data);
                dispatch(success(response.data));

                dispatch(enqueueNotification('Labs added successfully', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    console.log('addForGroupAdmin error', error);
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.ADD_LAB_FOR_GROUP_REQUEST,
        };
    }
    function success(users) {
        return {
            type: modalConstants.ADD_LAB_FOR_GROUP_SUCCESS,
            payload: {
                users,
            },
        };
    }
    function failure() {
        return {
            type: modalConstants.ADD_LAB_FOR_GROUP_ERROR,
        };
    }
}

function closeSuccessAddGroupLabsModal() {
    return {
        type: modalConstants.CLOSE_SUCCESS_ADD_GROUP_LABS_MODAL,
    };
}

function removeUserFromGroupNotifModalShow(groupID, userId) {
    return {
        type: modalConstants.REMOVE_USER_FROM_GROUP_NOTIF_MODAL_SHOW,
        payload: {
            groupID,
            userId,
            text: 'Are you sure you want to delete user from group?',
            method: notificationModalActions.DELETE_USER_FROM_GROUP,
        },
    };
}

function getUsersAllGroups() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        usersAssignmentService
            .getFromAllGroups()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_USERS_ALL_GROUPS_REQUEST,
        };
    }
    function success(groupUsers) {
        return {
            type: dataListsManagementConstants.GET_USERS_ALL_GROUPS_SUCCESS,
            payload: {
                groupUsers,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_USERS_ALL_GROUPS_ERROR,
        };
    }
}

function getUsersOfGroup(groupID, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        usersAssignmentService
            .getAll(groupID, page, size)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_USERS_BY_GROUP_REQUEST,
        };
    }
    function success(groupUsers) {
        return {
            type: dataListsManagementConstants.GET_USERS_BY_GROUP_SUCCESS,
            payload: {
                groupUsers,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_USERS_BY_GROUP_ERROR,
        };
    }
}

function deleteUserFromGroup(groupID, userID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        usersAssignmentService
            .deleteUserFromGroup(groupID, userID)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('User successfully removed from group', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.REMOVE_USER_FROM_GROUP_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.REMOVE_USER_FROM_GROUP_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.REMOVE_USER_FROM_GROUP_ERROR,
        };
    }
}

function getAllUsersBelongs(groupName) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        usersAssignmentService
            .getAllUsersBelongs(groupName)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_USERS_BELONGS_REQUEST,
        };
    }
    function success(usersBelongs) {
        return {
            type: dataListsManagementConstants.GET_USERS_BELONGS_SUCCESS,
            payload: {
                usersBelongs,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_USERS_BELONGS_ERROR,
        };
    }
}

function updateUsersInGroup(addUserArray, removeUserArray, groupID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        usersAssignmentService
            .updateUsersInGroup(addUserArray, removeUserArray, groupID)
            .then(() => {
                dispatch(success());
                dispatch(requestGetAllUsersByGroup());
                usersAssignmentService
                    .getAll(groupID)
                    .then(response => {
                        dispatch(successGetAllUsersByGroup(response.data.content));

                        if (!response.data.length) {
                            dispatch({
                                type: dataListsManagementConstants.UPDATE_CURRENT_GROUP,
                                payload: {
                                    isGroupEmpty: true,
                                },
                            });
                        } else {
                            dispatch({
                                type: dataListsManagementConstants.UPDATE_CURRENT_GROUP,
                                payload: {
                                    isGroupEmpty: false,
                                },
                            });
                        }
                    })
                    .catch(() => {
                        dispatch(failureGetAllUsersByGroup());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.UPDATE_USERS_IN_GROUP_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.UPDATE_USERS_IN_GROUP_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.UPDATE_USERS_IN_GROUP_ERROR,
        };
    }
    function requestGetAllUsersByGroup() {
        return {
            type: dataListsManagementConstants.GET_USERS_BELONGS_REQUEST,
        };
    }
    function successGetAllUsersByGroup(users) {
        return {
            type: modalConstants.GET_USERS_BELONGS_SUCCESS_WITHOUT_REOPEN_MODAL,
            payload: {
                users,
            },
        };
    }
    function failureGetAllUsersByGroup() {
        return {
            type: dataListsManagementConstants.GET_USERS_BELONGS_ERROR,
        };
    }
}

function setUsername(userName) {
    return {
        type: modalConstants.SHOW_USER_MODAL_SET_USER_NAME,
        payload: {
            userName,
        },
    };
}

function setFilterGroupId(groupId) {
    return {
        type: modalConstants.SHOW_USER_MODAL_SET_FILTER_GROUP_ID,
        payload: {
            groupId,
        },
    };
}

function checkFilterUsers(indexes, item) {
    return {
        type: modalConstants.CHECK_FILTER_USERS,
        payload: {
            item,
        },
    };
}

function checkGroupUsers(indexes, item) {
    return {
        type: modalConstants.CHECK_GROUP_USERS,
        payload: {
            id: item,
        },
    };
}

function getFilterUsers(groupId, filterGroupId, userName) {
    // console.log('getFilterUsers Action', filterGroupId, groupId, userName);
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        usersAssignmentService
            .getUsersByGroupAndUsername(groupId, filterGroupId, userName)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_All_USERS_FILTER_REQUEST,
        };
    }
    function success(users) {
        return {
            type: dataListsManagementConstants.GET_All_USERS_FILTER_SUCCESS,
            payload: {
                users,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_All_USERS_FILTER_ERROR,
        };
    }
}

// function getTableData(groupID) {
//     return dispatch => {
//         main(dispatch);
//     };
//     function main(dispatch) {
//         dispatch(request());
//         usersAssignmentService
//             .getUsersByGroupAndUsername(groupID)
//             .then(response => {
//                 dispatch(success(response.data));
//             })
//             .catch(error => {
//                 if (error === errorRequest.UNAUTHORIZED) {
//                     userService
//                         .refreshToken()
//                         .then(() => main(dispatch));
//                 } else {
//                     dispatch(failure());
//                 }
//             });
//     }
//     function request() {
//         return {
//             type: dataListsManagementConstants.GET_TABLE_DATA_REQUEST
//         }
//     }
//     function success(data) {
//         return {
//             type: dataListsManagementConstants.GET_TABLE_DATA_SUCCESS,
//             payload: {
//                 data
//             }
//         }
//     }
//     function failure() {
//         return {
//             type: dataListsManagementConstants.GET_TABLE_DATA_ERROR,
//         }
//     }
// }

function assignUsers() {
    return {
        type: modalConstants.ASSIGN_USERS_MOVE,
    };
}

function unassignUsers() {
    return {
        type: modalConstants.UNASSIGN_USERS_MOVE,
    };
}

function unassignUserById(id) {
    return {
        type: modalConstants.UNASSIGN_USER_BY_ID,
        payload: id
    }
}

// --- Sort stuff ---

function sortUpFilterUsersName() {
    return {
        type: modalConstants.SHOW_USERS_MODAL_SORT_UP_FILTER_USER_NAME,
    };
}

function sortDownFilterUsersName() {
    return {
        type: modalConstants.SHOW_USERS_MODAL_SORT_DOWN_FILTER_USER_NAME,
    };
}

function sortUpFilterUsersEmail() {
    return {
        type: modalConstants.SHOW_USERS_MODAL_SORT_UP_FILTER_USER_EMAIL,
    };
}

function sortDownFilterUsersEmail() {
    return {
        type: modalConstants.SHOW_USERS_MODAL_SORT_DOWN_FILTER_USER_EMAIL,
    };
}

function sortUpGroupUsersName() {
    return {
        type: modalConstants.SHOW_USERS_MODAL_SORT_UP_GROUP_USER_NAME,
    };
}

function sortDownGroupUsersName() {
    return {
        type: modalConstants.SHOW_USERS_MODAL_SORT_DOWN_GROUP_USER_NAME,
    };
}

function sortUpGroupUsersEmail() {
    return {
        type: modalConstants.SHOW_USERS_MODAL_SORT_UP_GROUP_USER_EMAIL,
    };
}

function sortDownGroupUsersEmail() {
    return {
        type: modalConstants.SHOW_USERS_MODAL_SORT_DOWN_GROUP_USER_EMAIL,
    };
}
//----

function onSelectAllClick(checked) {
    return {
        type: modalConstants.HANDLE_SELECT_ALL_CLICK,
        payload: {
            checked,
        },
    };
}

function onSelectAllClickInGroup(checked) {
    return {
        type: modalConstants.HANDLE_SELECT_ALL_IN_GROUP_CLICK,
        payload: {
            checked,
        },
    };
}

function getUsersOfGroupGroupAdmin(groupID, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        usersAssignmentService
            .getAllUsersGroupAdmin(groupID, page, size)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    // userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_USERS_BY_GROUP_REQUEST,
        };
    }
    function success(groupUsers) {
        return {
            type: dataListsManagementConstants.GET_USERS_BY_GROUP_SUCCESS,
            payload: {
                groupUsers,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_USERS_BY_GROUP_ERROR,
        };
    }
}

function addNewLabForUserGroupAdmin(userID, groupID, instanceTypeID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        singleLabManagementService
            .addForUserGroupAdmin(userID, groupID, instanceTypeID)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Lab added successfully', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.ADD_LAB_FOR_USER_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.ADD_LAB_FOR_USER_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.ADD_LAB_FOR_USER_ERROR,
        };
    }
}

function changePage(value) {
    return {
        type: dataListsManagementConstants.CHANGE_USER_ASSIGN_PAGE,
        payload: {
            value,
        },
    };
}

function changeSize(value) {
    return {
        type: dataListsManagementConstants.CHANGE_USER_ASSIGN_SIZE,
        payload: {
            value,
        },
    };
}

function resetPage() {
    return {
        type: dataListsManagementConstants.USER_ASSIGN_RESET_PAGE,
        payload: {},
    };
}
