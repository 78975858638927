import { modalConstants, seminarUsersConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    users: [],
    seminarID: '',
    requestIsActive: false,
    needToSearch: false,
    checkedIDs: [],
    deleteSeminarUserModal: {
        isOpen: false,
        text: '',
        method: '',
        userID: '',
    },
    resetPasswordModal: {
        isOpen: false,
        text: '',
        method: '',
        userID: '',
    },
    deleteUsersNotificationModal: {
        isOpen: false,
        type: '',
        dataList: [],
    },
    resetPasswordsModal: {
        isOpen: false,
        text: '',
        method: '',
    },
    size: 20,
    sizeList: [10, 20, 30],
    page: 0,
    totalElements: 0,
    search: '',
};

export function seminarUsers(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case seminarUsersConstants.REQUEST:
            return {
                ...state,
                requestIsActive: true,
                needToSearch: false,
            };
        case seminarUsersConstants.GET_ALL_SEMINARS_USERS_SUCCESS: {
            return {
                ...state,
                requestIsActive: false,
                users: action.payload.users,
                seminarID: action.payload.seminarID,
                size: action.payload.data.size,
                totalElements: action.payload.data.totalElements,
                needToSearch: false,
            };
        }
        case seminarUsersConstants.GET_ALL_SEMINARS_USERS_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case seminarUsersConstants.HANDLE_CLICK: {
            const { checkedIDs } = state;
            const { id } = action.payload;
            const selectedIndex = checkedIDs.indexOf(id);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(checkedIDs, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(checkedIDs.slice(1));
            } else if (selectedIndex === checkedIDs.length - 1) {
                newSelected = newSelected.concat(checkedIDs.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(checkedIDs.slice(0, selectedIndex), checkedIDs.slice(selectedIndex + 1));
            }
            return {
                ...state,
                checkedIDs: newSelected,
            };
        }
        case seminarUsersConstants.HANDLE_SELECT_ALL_CLICK: {
            const { checked } = action.payload;
            const { users } = state;
            if (!checked) {
                return {
                    ...state,
                    checkedIDs: users.map(n => n.id),
                };
            } else {
                return {
                    ...state,
                    checkedIDs: [],
                };
            }
        }

        /* ====== DELETE_USER ====== */
        case seminarUsersConstants.DELETE_USER_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                deleteSeminarUserModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    userID: action.payload.userID,
                },
            };
        case seminarUsersConstants.DELETE_SEMINAR_USER_SUCCESS:
            return {
                ...initialState,
                requestIsActive: false,
                deleteSeminarUserModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    userID: '',
                },
            };
        case seminarUsersConstants.DELETE_SEMINAR_USER_ERROR:
            return {
                ...state,
                requestIsActive: false,
                deleteSeminarUserModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    userID: '',
                },
            };

        /* ====== RESET_PASSWORD ====== */
        case seminarUsersConstants.RESET_PASSWORD_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                resetPasswordModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    userID: action.payload.userID,
                },
            };
        case seminarUsersConstants.RESET_PASSWORD_SEMINAR_USER_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                resetPasswordModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    userID: '',
                },
            };
        case seminarUsersConstants.RESET_PASSWORD_SEMINAR_USER_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };

        /* ====== DELETE_USERS ====== */
        case seminarUsersConstants.CLEAR_CHECKBOXES:
            return {
                ...state,
                checkedIDs: [],
            };
        case seminarUsersConstants.DELETE_USERS_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                deleteUsersNotificationModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    userID: action.payload.userID,
                },
            };
        case seminarUsersConstants.DELETE_USERS_STATUS_SUCCESS:
            return {
                ...state,
                deleteUsersNotificationModal: {
                    isOpen: true,
                    type: 'SEMINAR_DELETE_USERS_MULTIPLE',
                    dataList: action.payload,
                },
            };
        case seminarUsersConstants.DELETE_USERS_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                deleteUsersNotificationModal: {
                    isOpen: false,
                    type: '',
                    dataList: [],
                },
            };
        case seminarUsersConstants.DELETE_USERS_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };

        /* ====== RESET_PASSWORDS ====== */
        case seminarUsersConstants.RESET_PASSWORDS_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                resetPasswordsModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    userID: action.payload.userID,
                },
            };
        case seminarUsersConstants.RESET_PASSWORDS_SEMINAR_USERS_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                resetPasswordsModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                },
            };
        case seminarUsersConstants.RESET_PASSWORDS_SEMINAR_USERS_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };

        /* ====== CLOSE_MODAL ====== */
        case modalConstants.CLOSE_NOTIFICATION_MODAL:
            return {
                ...state,
                deleteSeminarUserModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    userID: '',
                },
                resetPasswordModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    userID: '',
                },
                deleteUsersNotificationModal: {
                    isOpen: false,
                    type: '',
                    dataList: [],
                },
                resetPasswordsModal: {
                    title: '',
                    text: '',
                    isOpen: false,
                },
            };
        case seminarUsersConstants.CHANGE_PAGE_SEMINAR_USERS:
            return {
                ...state,
                page: action.payload.value,
            };
        case seminarUsersConstants.CHANGE_SIZE_SEMINAR_USERS:
            return {
                ...state,
                size: action.payload.value,
            };
        case seminarUsersConstants.CHANGE_SEARCH_SEMINAR_USERS:
            return {
                ...state,
                search: action.payload.value,
            };
        case seminarUsersConstants.PERFORM_SEARCH_SEMINAR_USERS:
            return {
                ...state,
                needToSearch: true,
            };
        default:
            return state;
    }
}
