export const seminarHistoryConstants = {
    GET_SEMINARS_HISTORY_REQUEST: 'GET_SEMINARS_HISTORY_REQUEST',
    GET_SEMINARS_HISTORY_SUCCESS: 'GET_SEMINARS_HISTORY_SUCCESS',
    GET_SEMINARS_HISTORY_ERROR: 'GET_SEMINARS_HISTORY_ERROR',

    GET_SEMINARS_HISTORY_ACTIONS_REQUEST: 'GET_SEMINARS_HISTORY_ACTIONS_REQUEST',
    GET_SEMINARS_HISTORY_ACTIONS_SUCCESS: 'GET_SEMINARS_HISTORY_ACTIONS_SUCCESS',
    GET_SEMINARS_HISTORY_ACTIONS_ERROR: 'GET_SEMINARS_HISTORY_ACTIONS_ERROR',

    GET_SEMINAR_USER_HISTORY_REQUEST: 'GET_SEMINAR_USER_HISTORY_REQUEST',
    GET_SEMINAR_USER_HISTORY_SUCCESS: 'GET_SEMINAR_USER_HISTORY_SUCCESS',
    GET_SEMINAR_USER_HISTORY_ERROR: 'GET_SEMINAR_USER_HISTORY_ERROR',

    GET_SEMINAR_USER_HISTORY_ACTIONS_SUCCESS: 'GET_SEMINAR_USER_HISTORY_ACTIONS_SUCCESS',

    GET_SEMINAR_LAB_HISTORY_REQUEST: 'GET_SEMINAR_LAB_HISTORY_REQUEST',
    GET_SEMINAR_LAB_HISTORY_SUCCESS: 'GET_SEMINAR_LAB_HISTORY_SUCCESS',
    GET_SEMINAR_LAB_HISTORY_ERROR: 'GET_SEMINAR_LAB_HISTORY_ERROR',

    GET_SEMINAR_LAB_HISTORY_ACTIONS_SUCCESS: 'GET_SEMINAR_LAB_HISTORY_ACTIONS_SUCCESS',

    CHANGE_SEMINAR_HISTORY_TAB: 'CHANGE_SEMINAR_HISTORY_TAB',
    CHANGE_SEMINAR_HISTORY_PAGE: 'CHANGE_SEMINAR_HISTORY_PAGE',
    CHANGE_SEMINAR_HISTORY_SIZE: 'CHANGE_SEMINAR_HISTORY_SIZE',
    CHANGE_SEMINAR_SORTING: 'CHANGE_SEMINAR_SORTING',

    CHANGE_ACTION: 'CHANGE_ACTION',
    CHANGE_SEARCH: 'CHANGE_SEARCH',
    PERFORM_HISTORY_SEARCH: 'PERFORM_HISTORY_SEARCH',
    DISABLE_SEMINAR_HISTORY_SEARCH: 'DISABLE_SEMINAR_HISTORY_SEARCH',
    SET_DATE_ACTION: 'SET_DATE_ACTION',
    TOGGLE_INCLUDE_TEST_DATA: 'TOGGLE_INCLUDE_TEST_DATA'
};
