import { signInSeminarConstants } from '../../Constants';
import { reducersUtil } from '../../Utils';
import _ from "lodash";

const initialState = {
    requestIsActive: false,
    step: 1,
    firstStep: {
        seminarName: '',
        seminarPassword: '',
        errorName: '',
        errorPassword: '',
    },
    seminarID: '',
    secondStep: {
        userName: '',
        userPassword: '',
        userEmail: '',
        captchaVerified: false,
        errorName: '',
        errorPassword: '',
        errorEmail: '',
        errorCaptcha: '',
    },
    inputType: 'password',
};

export function signInSeminar(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case signInSeminarConstants.CLOSE_SEMINAR_SIGN_IN: {
            return initialState;
        }

        case signInSeminarConstants.SHOW_PASSWORD: {
            return {
                ...state,
                inputType: state.inputType === 'password' ? 'text' : 'password',
            };
        }

        /* ====== FIRST STEP ====== */

        case signInSeminarConstants.CHANGE_FORM_DATA_FIRST_STEP: {
            const { name, value } = action.payload.target;
            return {
                ...state,
                firstStep: {
                    ...state.firstStep,
                    [name]: value,
                },
            };
        }
        case signInSeminarConstants.SHOW_ERROR_FIRST_STEP: {
            const result = reducersUtil.signInSeminarErrorFirstStep(action.payload);
            for (let field in result) {
                if (result.hasOwnProperty(field) && result[field]) {
                    return {
                        ...state,
                        firstStep: {
                            ...state.firstStep,
                            [field]: result[field],
                        },
                    };
                }
            }
            break;
        }
        case signInSeminarConstants.HIDE_ERROR_FIRST_STEP:
            return {
                ...state,
                firstStep: {
                    ...state.firstStep,
                    errorName: '',
                    errorPassword: '',
                },
            };
        case signInSeminarConstants.VERIFY_SEMINAR_REQUEST:
        case signInSeminarConstants.VERIFY_USER_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case signInSeminarConstants.VERIFY_SEMINAR_SUCCESS:
            return {
                ...initialState,
                step: ++state.step,
                seminarID: action.payload.seminarID,
            };
        case signInSeminarConstants.VERIFY_SEMINAR_ERROR:
            return {
                ...state,
                requestIsActive: false,
                firstStep: {
                    ...state.firstStep,
                    errorPassword: action.payload.error,
                },
            };

        /* ======= SECOND STEP ====== */

        case signInSeminarConstants.CHANGE_FORM_DATA_SECOND_STEP: {
            const { name, value } = action.payload.target;
            return {
                ...state,
                secondStep: {
                    ...state.secondStep,
                    [name]: value,
                },
            };
        }
        case signInSeminarConstants.SHOW_ERROR_SECOND_STEP: {
            const { payload } = action;
            const { captchaVerified } = state.secondStep;
            const { errorName, errorPassword, errorEmail, errorCaptcha } = reducersUtil.signInSeminarErrorSecondStep(
                payload,
                captchaVerified,
            );
            return {
                ...state,
                secondStep: {
                    ...state.secondStep,
                    errorName: errorName ? errorName : state.secondStep.errorName,
                    errorPassword: errorPassword ? errorPassword : state.secondStep.errorPassword,
                    errorEmail: errorEmail ? errorEmail : state.secondStep.errorEmail,
                    errorCaptcha: errorCaptcha ? errorCaptcha : state.secondStep.errorCaptcha,
                },
            };
        }
        case signInSeminarConstants.HIDE_ERROR_SECOND_STEP:
            return {
                ...state,
                secondStep: {
                    ...state.secondStep,
                    errorName: '',
                    errorPassword: '',
                    errorEmail: '',
                    errorCaptcha: '',
                },
            };
        /* case VERIFY_USER_REQUEST is same as VERIFY_SEMINAR_REQUEST */
        case signInSeminarConstants.LOGIN_USER_TO_SEMINAR_SUCCESS:
            return {
                ...initialState,
                step: 3,
            };
        case signInSeminarConstants.LOGIN_USER_TO_SEMINAR_ERROR:
            return {
                ...state,
                requestIsActive: false,
                secondStep: {
                    ...state.secondStep,
                    errorEmail: action.payload.error,
                },
            };
        case signInSeminarConstants.VERIFY_CAPTCHA_SECOND_STEP: {
            return {
                ...state,
                secondStep: {
                    ...state.secondStep,
                    captchaVerified: true,
                    errorCaptcha: '',
                },
            };
        }
        case signInSeminarConstants.SHOW_CAPTURE_ERROR_SECOND_STEP:
            return {
                ...state,
                secondStep: {
                    ...state.secondStep,
                    errorCaptcha: 'Please, verify recaptcha',
                },
            };
        default:
            return state;
    }
}
