import { errorRequest, seminarHistoryConstants } from '../../Constants';
import { seminarHistoryService, userService } from '../../Services';
import {notifierActions} from "../notifier.actions";

const {enqueueNotification} = notifierActions;

export const seminarHistoryActions = {
    getSeminarsHistory,
    getGroupAdminSeminarsHistory,
    getSeminarsActionList,
    getGroupAdminSeminarsActionList,
    getSeminarUserHistory,
    getGroupAdminSeminarUserHistory,
    getSeminarUserActionList,
    getGroupAdminSeminarUserActionList,
    getSeminarLabHistory,
    getGroupAdminSeminarLabHistory,
    getSeminarLabActionList,
    getGroupAdminSeminarLabActionList,
    changeSeminarHistoryTab,
    changeAction,
    changeSearch,
    performSearch,
    disableSearch,
    setDateAction,
    toggleIncludeTestData
};

function getSeminarsHistory(info, page, size, sortField, sortDirection) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarHistoryService
            .getSeminarsHistory(info.body, page, size, sortField, sortDirection)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarHistoryConstants.GET_SEMINARS_HISTORY_REQUEST,
        };
    }
    function success(history) {
        return {
            type: seminarHistoryConstants.GET_SEMINARS_HISTORY_SUCCESS,
            payload: {
                history,
            },
        };
    }
    function failure() {
        return {
            type: seminarHistoryConstants.GET_SEMINARS_HISTORY_ERROR,
        };
    }
}

function getGroupAdminSeminarsHistory(info, page, size, sortField, sortDirection) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarHistoryService
            .getGroupAdminSeminarsHistory(info.body, page, size, sortField, sortDirection)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarHistoryConstants.GET_SEMINARS_HISTORY_REQUEST,
        };
    }
    function success(history) {
        return {
            type: seminarHistoryConstants.GET_SEMINARS_HISTORY_SUCCESS,
            payload: {
                history,
            },
        };
    }
    function failure() {
        return {
            type: seminarHistoryConstants.GET_SEMINARS_HISTORY_ERROR,
        };
    }
}

function getSeminarsActionList() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        seminarHistoryService
            .getSeminarsActionList()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                }
            });
    }
    function success(list) {
        return {
            type: seminarHistoryConstants.GET_SEMINARS_HISTORY_ACTIONS_SUCCESS,
            payload: {
                list,
            },
        };
    }
}

function getGroupAdminSeminarsActionList() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        seminarHistoryService
            .getGroupAdminSeminarsActionList()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                }
            });
    }
    function success(list) {
        return {
            type: seminarHistoryConstants.GET_SEMINARS_HISTORY_ACTIONS_SUCCESS,
            payload: {
                list,
            },
        };
    }
}

function getSeminarUserHistory(info, page, size, sortField, sortDirection) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarHistoryService
            .getUsersHistory(info, page, size, sortField, sortDirection)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_USER_HISTORY_REQUEST,
        };
    }
    function success(history) {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_USER_HISTORY_SUCCESS,
            payload: {
                history,
            },
        };
    }
    function failure() {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_USER_HISTORY_ERROR,
        };
    }
}

function getGroupAdminSeminarUserHistory(info, page, size, sortField, sortDirection) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarHistoryService
            .getGroupAdminUsersHistory(info, page, size, sortField, sortDirection)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_USER_HISTORY_REQUEST,
        };
    }
    function success(history) {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_USER_HISTORY_SUCCESS,
            payload: {
                history,
            },
        };
    }
    function failure() {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_USER_HISTORY_ERROR,
        };
    }
}

function getSeminarUserActionList() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        seminarHistoryService
            .getUsersActionList()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                }
            });
    }
    function success(list) {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_USER_HISTORY_ACTIONS_SUCCESS,
            payload: {
                list,
            },
        };
    }
}

function getGroupAdminSeminarUserActionList() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        seminarHistoryService
            .getGroupAdminUsersActionList()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                }
            });
    }
    function success(list) {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_USER_HISTORY_ACTIONS_SUCCESS,
            payload: {
                list,
            },
        };
    }
}

function getSeminarLabHistory(info, page, size, sortField, sortDirection) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarHistoryService
            .getLabsHistory(info, page, size, sortField, sortDirection)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_LAB_HISTORY_REQUEST,
        };
    }
    function success(history) {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_LAB_HISTORY_SUCCESS,
            payload: {
                history,
            },
        };
    }
    function failure() {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_LAB_HISTORY_ERROR,
        };
    }
}

function getGroupAdminSeminarLabHistory(info, page, size, sortField, sortDirection) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarHistoryService
            .getGroupAdminLabsHistory(info, page, size, sortField, sortDirection)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_LAB_HISTORY_REQUEST,
        };
    }
    function success(history) {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_LAB_HISTORY_SUCCESS,
            payload: {
                history,
            },
        };
    }
    function failure() {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_LAB_HISTORY_ERROR,
        };
    }
}

function getSeminarLabActionList() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        seminarHistoryService
            .getLabsActionList()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                }
            });
    }
    function success(list) {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_LAB_HISTORY_ACTIONS_SUCCESS,
            payload: {
                list,
            },
        };
    }
}

function getGroupAdminSeminarLabActionList() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        seminarHistoryService
            .getGroupAdminLabsActionList()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                }
            });
    }
    function success(list) {
        return {
            type: seminarHistoryConstants.GET_SEMINAR_LAB_HISTORY_ACTIONS_SUCCESS,
            payload: {
                list,
            },
        };
    }
}

function changeSeminarHistoryTab(value) {
    return {
        type: seminarHistoryConstants.CHANGE_SEMINAR_HISTORY_TAB,
        payload: {
            value,
        },
    };
}

function changeAction(value) {
    return {
        type: seminarHistoryConstants.CHANGE_ACTION,
        payload: {
            value,
        },
    };
}

function changeSearch(value) {
    return {
        type: seminarHistoryConstants.CHANGE_SEARCH,
        payload: {
            value,
        },
    };
}

function performSearch() {
    return {
        type: seminarHistoryConstants.PERFORM_HISTORY_SEARCH,
    };
}

function disableSearch() {
    return {
        type: seminarHistoryConstants.DISABLE_SEMINAR_HISTORY_SEARCH,
    };
}

function setDateAction(action, value, date) {
    return {
        type: seminarHistoryConstants.SET_DATE_ACTION,
        payload: {
            action,
            value,
            date,
        },
    };
}

function toggleIncludeTestData() {
    return {
        type: seminarHistoryConstants.TOGGLE_INCLUDE_TEST_DATA
    }
}