class IPManagement {
    static setCurrentIP = value => {
        localStorage.setItem('current_ip', value);
    };

    static getCurrentIP = () => {
        return localStorage.getItem('current_ip');
    };
}

export default IPManagement;
