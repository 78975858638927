import { modalConstants } from '../../Constants';

const initialState = {
    isOpen: false,
    isRequestActive: false,
    versionId: '',
    versionNumber: '',
    templateId: null,
    versions: [],
};

export function templateShareVersionModal(state = initialState, action) {
    switch (action.type) {
        case modalConstants.OPEN_TEMPLATE_SHARE_VERSION_MODAL:
            // const currentVersion = action.payload.versions.find(v => v.current);
            return {
                ...state,
                isOpen: true,
                templateId: action.payload.id,
                // versionId: currentVersion ? currentVersion.id : null,
                // versionNumber: currentVersion ? currentVersion.versionNumber : null,
                versions: action.payload.versions,
                name: action.payload.name,
                // comment: currentVersion ? currentVersion.comment : '',
                shared: action.payload.shared
            };

        case modalConstants.CLOSE_TEMPLATE_SHARE_VERSION_MODAL:
            return {
                ...state,
                isOpen: false,
            };

        default:
            return state;
    }
}