import {addLabTemplateConstants, errorRequest, templateEditorConstants} from '../Constants';
import {addLabTemplateService, instancesTypesManagementService, userService} from '../Services';
import {history} from "../Utils";
import {notifierActions} from "./notifier.actions";

const {enqueueNotification} = notifierActions;

export const TemplateEditorActions = {
    editJson,
    editComment,
    changeInstanceName,
    saveInstanceAsDraft,
    clearJsonData,
    changeInstance,
    changeInstanceVersion,
    discardBlueprint,
    uploadNewInstance,
    changeInstanceNameJsonEditedState,
    editDescription,
    saveInstanceAsDraftThenValidate,
    clearInstanceVersionSelection
};

function clearInstanceVersionSelection() {
    return {
        type: templateEditorConstants.CLEAR_INSTANCE_SELECTION
    }
}

function editJson(json) {
    return {
        type: templateEditorConstants.EDIT_INSTANCE_JSON_FILE,
        payload: json,
    };
}

function editComment(comment) {
    return { type: templateEditorConstants.EDIT_INSTANCE_COMMENT, payload: comment };
}

function editDescription(description) {
    return {
        type: templateEditorConstants.EDIT_INSTANCE_DESCRIPTION,
        payload: description
    }
}

function changeInstanceNameJsonEditedState(isJsonEdited) {
    return { type: templateEditorConstants.CHANGE_INSTANCE_NAME_JSON_EDITED, payload: { isJsonEdited } };
}

function changeInstanceName(name) {
    return { type: templateEditorConstants.CHANGE_INSTANCE_NAME, payload: { name } };
}

function clearJsonData() {
    return { type: templateEditorConstants.CLEAR_INSTANCE_JSON_FILE };
}

function changeInstance(file) {
    return { type: templateEditorConstants.CHANGE_INSTANCE, payload: file };
}

function changeInstanceVersion(versionId) {
    return { type: templateEditorConstants.CHANGE_INSTANCE_VERSION, payload: versionId };
}

function uploadNewInstance(fileInfo) {
    return { type: templateEditorConstants.UPLOAD_NEW_INSTANCE, payload: fileInfo };
}

function saveInstanceAsDraft(draft) {
    return dispatch => {
        // Init request
        main(dispatch);

        // Request
        function main(dispatch) {
            instancesTypesManagementService
                .saveAsDraft(draft)
                .then(res => {
                    // Success
                    dispatch({ type: templateEditorConstants.SAVE_INSTANCE_AS_DRAFT_SUCCESS, payload: res.data });
                })
                .catch(error => {
                    if (error === errorRequest.UNAUTHORIZED) {
                        // Retry
                        // userService.refreshToken().then(() => main(dispatch));
                    } else {
                        // Request failed
                        // TODO: что делать во время ошибки?
                        console.log(error);
                        dispatch({ type: templateEditorConstants.SAVE_INSTANCE_AS_DRAFT_ERROR });
                    }
                });
        }
    };
}

function saveInstanceAsDraftThenValidate(draft) {
    return dispatch => {
        // Init request
        main(dispatch);

        // Request
        function main(dispatch) {
            instancesTypesManagementService
                .saveAsDraft(draft)
                .then(res => {
                    // Success
                    dispatch({ type: templateEditorConstants.SAVE_INSTANCE_AS_DRAFT_SUCCESS, payload: res.data });

                    //
                    addLabTemplateService
                        .addBlueprint({templateId: res.data.id, verificationNeeded: draft.verificationNeeded})
                        .then(response => {
                            // TODO: дописать логику
                            dispatch({ type: addLabTemplateConstants.VERIFY_BLUEPRINT_SUCCESS });
                            history.push('lab-templates-management');
                        })
                        .catch(error => {
                            if (error === errorRequest.UNAUTHORIZED) {
                                // Retry
                                userService.refreshToken().then(() => main(dispatch));
                            } else {
                                // Request failed
                                dispatch({
                                    type: addLabTemplateConstants.VERIFY_BLUEPRINT_ERROR,
                                    payload: {
                                        text: error,
                                    },
                                });
                                dispatch(enqueueNotification(String(error), {variant: 'error'}));
                            }
                        });
                })
                .catch(error => {
                    if (error === errorRequest.UNAUTHORIZED) {
                        // Retry
                        userService.refreshToken().then(() => main(dispatch));
                    } else {
                        // Request failed
                        // TODO: что делать во время ошибки?
                        console.log(error);
                        dispatch({ type: templateEditorConstants.SAVE_INSTANCE_AS_DRAFT_ERROR });
                    }
                });
        }
    };
}

function discardBlueprint(blueprintId) {
    return dispatch => {
        instancesTypesManagementService
            .deleteBlueprint(blueprintId)
            .then(res => dispatch({ type: templateEditorConstants.DISCARD_BLUEPRINT }))
            .catch(err => console.error(err)); // TODO: что должно происходить если ошибка удаления блюпринта
    };
}
