export const notificationModalActions = {
    DELETE_USER: 'DELETE_USER',
    SIGN_OUT: 'SIGN_OUT',
    DELETE_GROUP: 'DELETE_GROUP',
    UPDATE_GROUP: 'UPDATE_GROUP',
    DELETE_USER_FROM_GROUP: 'DELETE_USER_FROM_GROUP',
    RESET_PASSWORD: 'RESET_PASSWORD',
    DELETE_LAB: 'DELETE_LAB',
    DELETE_LAB_BY_GROUP_ADMIN: 'DELETE_LAB_BY_GROUP_ADMIN',
    DELETE_LABS: 'DELETE_LABS',
    DELETE_LAB_TEMPLATE: 'DELETE_LAB_TEMPLATE',
    DELETE_BLUEPRINT: 'DELETE_BLUEPRINT',
    PROFILE_CHANGE_EMAIL: 'PROFILE_CHANGE_EMAIL',
    HARD_RECREATE_LAB: 'HARD_RECREATE_LAB',
    RECREATE_LAB: 'RECREATE_LAB',
    RECREATE_SEMINAR_LAB_GROUP_ADMIN: 'RECREATE_SEMINAR_LAB_GROUP_ADMIN',
    RECREATE_SEMINAR_LAB_GROUP_ADMIN_FROM_DETAILS: 'RECREATE_SEMINAR_LAB_GROUP_ADMIN_FROM_DETAILS',
    RECREATE_LAB_FROM_DETAILS: 'RECREATE_LAB_FROM_DETAILS',
    RECREATE_LAB_GROUP_ADMIN: 'RECREATE_LAB_GROUP_ADMIN',
    RECREATE_LAB_GROUP_ADMIN_FROM_DETAILS: 'RECREATE_LAB_GROUP_ADMIN_FROM_DETAILS',
    RECREATE_LAB_BY_USER: 'RECREATE_LAB_BY_USER',
    RESET_TIMER: 'RESET_TIMER',
    TOGGLE_MAINTENANCE: 'TOGGLE_MAINTENANCE',
    DELETE_SEMINAR: 'DELETE_SEMINAR',
    END_SEMINAR: 'END_SEMINAR',
    END_SEMINAR_GA: 'END_SEMINAR_GA',
    DELETE_SEMINAR_USER: 'DELETE_SEMINAR_USER',
    RESET_PASSWORD_SEMINAR_USER: 'RESET_PASSWORD_SEMINAR_USER',
    RESET_PASSWORD_SEMINAR_USER_GROUP_ADMIN: 'RESET_PASSWORD_SEMINAR_USER_GROUP_ADMIN',
    RESET_PASSWORDS_SEMINAR_USERS: 'RESET_PASSWORDS_SEMINAR_USERS',
    RESET_PASSWORDS_SEMINAR_USERS_GROUP_ADMIN: 'RESET_PASSWORDS_SEMINAR_USERS_GROUP_ADMIN',

    DELETE_SEMINAR_LAB: 'DELETE_SEMINAR_LAB',
    DELETE_SEMINAR_LAB_GROUP_ADMIN: 'DELETE_SEMINAR_LAB_GROUP_ADMIN',
    DELETE_SEMINAR_PAGE_USER: 'DELETE_SEMINAR_PAGE_USER',
    DELETE_SEMINAR_PAGE_USER_GROUP_ADMIN: 'DELETE_SEMINAR_PAGE_USER_GROUP_ADMIN',
    DELETE_CHECKED_USERS_SEMINAR_PAGE_USER: 'DELETE_CHECKED_USERS_SEMINAR_PAGE_USER',
    DELETE_CHECKED_USERS_SEMINAR_PAGE_USER_GROUP_ADMIN: 'DELETE_CHECKED_USERS_SEMINAR_PAGE_USER_GROUP_ADMIN',

    UPLOAD_NEW_JSON: 'UPLOAD_NEW_JSON',
    SELECT_NEW_JSON: 'SELECT_NEW_JSON',
};
