import { modalConstants, seminarConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    seminars: [],
    requestIsActive: false,
    needToGetSeminars: false,
    deleteSeminarModal: {
        isOpen: false,
        text: '',
        method: '',
        seminarID: '',
    },
    endSeminarModal: {
        isOpen: false,
        text: '',
        method: '',
        seminarID: '',
    },
    size: 20,
    sizeList: [10, 20, 30],
    page: 0,
    total: 3,
    totalElements: 0,
    search: '',
};

export function seminar(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case seminarConstants.REQUEST:
            return {
                ...state,
                requestIsActive: true,
                needToGetSeminars: false,
            };
        case seminarConstants.GET_ALL_SEMINARS_SUCCESS:
            return {
                ...state,
                seminars: action.payload.seminars,
                requestIsActive: false,
                totalElements: action.payload.totalElements,
                needToGetSeminars: false,
            };
        case seminarConstants.GET_ALL_SEMINARS_ERROR:
            return {
                ...state,
                requestIsActive: false,
                needToGetSeminars: false,
            };
        case seminarConstants.DELETE_SEMINAR_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                deleteSeminarModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    seminarID: action.payload.seminarID,
                },
            };
        case seminarConstants.DELETE_SEMINAR_SUCCESS:
            return {
                ...state,
                seminars: [],
                requestIsActive: false,
                deleteSeminarModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    seminarID: '',
                },
                endSeminarModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    seminarID: '',
                },
                page: 0,
                search: '',
            };
        case seminarConstants.DELETE_SEMINAR_ERROR:
            return {
                ...state,
                requestIsActive: false,
                deleteSeminarModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    seminarID: '',
                },
            };
        case seminarConstants.EMD_SEMINAR_SHOW_NOTIFICATION_MODAL:
        case seminarConstants.END_SEMINAR_GROUP_ADMIN_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                endSeminarModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    seminarID: action.payload.seminarID,
                },
            };
        case modalConstants.CLOSE_NOTIFICATION_MODAL:
            return {
                ...state,
                deleteSeminarModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    seminarID: '',
                },
                endSeminarModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    seminarID: '',
                },
            };
        case seminarConstants.CHANGE_STATUS_SEMINAR_SUCCESS:
            return {
                ...state,
                endSeminarModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    seminarID: '',
                },
            };
        case seminarConstants.CHANGE_SEMINAR_PAGE:
            return {
                ...state,
                page: action.payload.value,
            };
        case seminarConstants.CHANGE_SEMINAR_SIZE:
            return {
                ...state,
                size: action.payload.value,
                page: 0,
            };
        case seminarConstants.CHANGE_SEMINAR_SEARCH:
            return {
                ...state,
                search: action.payload.value,
            };
        case seminarConstants.PERFORM_SEMINAR_SEARCH:
            return {
                ...state,
                needToGetSeminars: true,
            };
        default:
            return state;
    }
}
