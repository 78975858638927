import { statusModalConstants } from '../Constants';

export const statusModalActions = {
    closeStatusModal,
    openStatusModal,
};

function closeStatusModal() {
    return {
        type: statusModalConstants.CLOSE,
    };
}

function openStatusModal(data) {
    return {
        type: statusModalConstants.OPEN,
        payload: data,
    };
}
