export const templateEditorConstants = {
    CHANGE_INSTANCE: 'CHANGE_INSTANCE',
    CHANGE_INSTANCE_VERSION: 'CHANGE_INSTANCE_VERSION',
    CHANGE_INSTANCE_NAME: 'CHANGE_INSTANCE_NAME',
    CHANGE_INSTANCE_NAME_JSON_EDITED: 'CHANGE_INSTANCE_NAME_JSON_EDITED',
    UPLOAD_NEW_INSTANCE: 'UPLOAD_NEW_INSTANCE',
    EDIT_INSTANCE_JSON_FILE: 'EDIT_INSTANCE_JSON_FILE',
    EDIT_INSTANCE_COMMENT: 'EDIT_INSTANCE_COMMENT',
    CLEAR_INSTANCE_JSON_FILE: 'CLEAR_INSTANCE_JSON_FILE',
    DISCARD_BLUEPRINT: 'DISCARD_BLUEPRINT',
    EDIT_INSTANCE_DESCRIPTION: 'EDIT_INSTANCE_DESCRIPTION',

    SAVE_INSTANCE_AS_DRAFT_SUCCESS: 'SAVE_INSTANCE_AS_DRAFT_SUCCESS',
    SAVE_INSTANCE_AS_DRAFT_ERROR: 'SAVE_INSTANCE_AS_DRAFT_ERROR',
    CLEAR_INSTANCE_SELECTION: 'CLEAR_INSTANCE_SELECTION'
};
