export const reducersUtil = {
    generateGroupManagementErrorMessage,
    generateLabTemplateManagementErrorMessage,
    addUserErrorMessage,
    editUserErrorMessage,
    signInSeminarErrorFirstStep,
    signInSeminarErrorSecondStep,
    addEditSeminarError,
    addSeminarDropDownsError,
    isDateOverflow,
    findActiveThumbnail,
    resetInstanceReducerUtil,
    findActiveReport,
    findActiveSubReport,
};

function passwordPatternMatch(str) {
    return /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])\S{10,}$/.test(str);
}

function generateGroupManagementErrorMessage(value, name) {
    let errorMessage;
    if (value === '') {
        errorMessage = 'Enter group name';
    } else if ((value.length < 3 || value.length > 16) && name === 'groupName') {
        errorMessage = 'Group name length must be within 3 to 16 characters';
    } else if (name === 'groupName') {
        errorMessage = 'Invalid group name format. Use letter first. English alphabet letters, numbers 0-9 allowed only';
    }
    return errorMessage;
}

function generateLabTemplateManagementErrorMessage(value) {
    console.log('Utils value', value);
    let errorMessage;
    if (value.length < 5 || value.length > 30) {
        errorMessage = 'Lab template name length must be within 5 to 30 characters length';
    } else {
        errorMessage = 'Invalid character detected in lab template name. Letters, numbers 0-9, hyphen and underscore are allowed only';
    }
    return errorMessage;
}

function addUserErrorMessage(value, name, getWrongEmail) {
    let errorMessage;
    let emailErrorMessage;
    let wrongEmail;
    let isLat = str => {
        return /^\w+$/i.test(str);
    };

    let isLetterFirst = letter => {
        return /[A-Za-z]/.test(letter);
    };

    if (value === '' && name === 'email') {
        wrongEmail = 1;
        emailErrorMessage = 'Email field is empty. Please fill it.';
    } else if (value !== '' && name === 'email') {
        wrongEmail = 2;
        emailErrorMessage = 'Invalid email. Letters, numbers (0-9), periods (.) and @ symbol are allowed.';
    } else if (value === '' && name === 'username' && getWrongEmail === 1) {
        emailErrorMessage = 'Email field is empty. Please fill it.';
        errorMessage = 'Username field is empty. Please fill it.';
    } else if (value === '' && name === 'username' && getWrongEmail === 2) {
        emailErrorMessage = 'Invalid email. Letters, numbers (0-9), periods (.) and @ symbol are allowed.';
        errorMessage = 'Username field is empty. Please fill it.';
    } else if (value === '' && name === 'username') {
        errorMessage = 'Username field is empty. Please fill it.';
    } else if (name === 'username' && getWrongEmail === 2 && (value.length < 6 || value.length > 25)) {
        emailErrorMessage = 'Invalid email. Letters, numbers (0-9), periods (.) and @ symbol are allowed.';
        errorMessage = 'User name length must be within 6 to 25 characters';
    } else if (name === 'username' && getWrongEmail === 2) {
        emailErrorMessage = 'Invalid email. Letters, numbers (0-9), periods (.) and @ symbol are allowed.';
        errorMessage = 'Invalid user name. Letters, numbers (0-9), periods (.) are allowed. First uppercase letter is expected.';
    } else if (
        (value.length < 6 || value.length > 25) &&
        name === 'username' &&
        (getWrongEmail === '' || getWrongEmail === undefined)
    ) {
        errorMessage = 'User name length must be within 6 to 25 characters';
    } else if (!isLat(value) && name === 'username' && (getWrongEmail === '' || getWrongEmail === undefined)) {
        errorMessage =
            'Unsupported characters detected in user name. Please use English alphabet letters, numbers 0-9 and dot character only';
    } else if (!isLat(value) && name === 'username' && getWrongEmail === 1) {
        errorMessage =
            'Unsupported characters detected in user name. Please use English alphabet letters, numbers 0-9 and dot character only';
        emailErrorMessage = 'Email field is empty. Please fill it.';
    } else if (!isLetterFirst(value[0]) && name === 'username' && getWrongEmail === '') {
        errorMessage = 'Letter must be the first character';
    } else if (!isLetterFirst(value[0]) && name === 'username' && getWrongEmail === 1) {
        errorMessage = 'Letter must be the first character';
        emailErrorMessage = 'Email field is empty. Please fill it.';
    } else if (value !== '' && name === 'username' && getWrongEmail === 1 && (value.length < 6 || value.length > 25)) {
        emailErrorMessage = 'Email field is empty. Please fill it.';
        errorMessage = 'User name length must be within 6 to 25 characters';
    } else if (value !== '' && name === 'username' && getWrongEmail === 1) {
        emailErrorMessage = 'Email field is empty. Please fill it.';
        errorMessage = 'Invalid user name. Latin letters, numbers (0-9), periods (.) are allowed. First uppercase letter is expected.';
    } else if (value !== '' && name === 'username' && getWrongEmail !== 1) {
        errorMessage = 'Invalid user name. Latin letters, numbers (0-9), periods (.) are allowed. First uppercase letter is expected.';
    }

    return {
        errorMessage,
        emailErrorMessage,
        wrongEmail,
    };
}

function editUserErrorMessage(value, name, getWrongEmail) {
    let errorMessage;
    let emailErrorMessage;
    let wrongEmail;
    // let isLat = str => {
    //     return /^\w+$/i.test(str);
    // };
    let isLetterFirst = letter => {
        return /[A-Za-z]/.test(letter);
    };
    if (value === '') {
        errorMessage = 'Fill all fields';
    } else if (name === 'userName' && getWrongEmail > 0) {
        emailErrorMessage = 'Invalid email. Letters, numbers (0-9), periods (.) and @ symbol are allowed.';
        errorMessage = 'Invalid user name. Letters, numbers (0-9), periods (.) are allowed. First uppercase letter is expected.';
    } else if ((value.length < 6 || value.length > 25) && name === 'userName') {
        errorMessage = 'User name length must be within 6 to 25 characters';
    } else if (!isLetterFirst(value[0]) && name === 'userName' && getWrongEmail === '') {
        errorMessage = 'Letter must be the first character';
    }
    else if (name === 'email') {
        emailErrorMessage = 'Invalid email. Letters, numbers (0-9), periods (.) and @ symbol are allowed.';
        wrongEmail = 1;
    } else if (name === 'userName') {
        errorMessage = 'Invalid user name. Letters, numbers (0-9), periods (.) are allowed. First uppercase letter is expected.';
    }
    return {
        errorMessage,
        emailErrorMessage,
        wrongEmail,
    };
}

function signInSeminarErrorFirstStep({ target }) {
    const { name, value } = target;
    let errorName = '',
        errorPassword = '';
    if (name === 'seminarName' && !value) {
        errorName = 'Seminar Name field is empty. Please fill it';
    } else if (name === 'Seminar' && value) {
        errorName = 'Entered Seminar Name is not valid';
    } else if (name === 'seminarPassword' && !value) {
        errorPassword = 'Seminar Password field is empty. Please fill it';
    } else if (name === 'seminarPassword' && value) {
        errorPassword = 'Entered Seminar Password is not valid';
    }
    return {
        errorName,
        errorPassword,
    };
}

function signInSeminarErrorSecondStep({ target }, captchaVerified) {
    const { name, value } = target;
    let errorName = '',
        errorPassword = '',
        errorEmail = '',
        errorCaptcha = '';
    if (name === 'userName' && !value) {
        errorName = 'User Name field is empty. Please fill it';
    } else if ((value.length < 6 || value.length > 25) && name === 'userName') {
        errorName = 'User name length must be within 6 to 25 characters';
    } else if (name === 'userName' && value) {
        errorName = 'Entered User Name is not valid';
    } else if (name === 'userPassword' && !passwordPatternMatch(value)) {
        errorPassword = 'User Password must contain at least one uppercase letter, one lowercase letter and one number';
    } else if (name === 'userPassword' && !value) {
        errorPassword = 'User Password field is empty. Please fill it';
    } else if (name === 'userPassword' && value) {
        errorPassword = 'Entered User Password is not valid';
    } else if (name === 'userEmail' && value) {
        errorEmail = 'Entered User Email is not valid';
    }
    if (!captchaVerified) {
        errorCaptcha = 'Please, verify recaptcha';
    }
    return {
        errorName,
        errorPassword,
        errorEmail,
        errorCaptcha,
    };
}

function addEditSeminarError({ value, name }, { subscriptionID, startDate, endDate, startTime, endTime, labTemplateIDs }) {
    let errorName, errorPassword, errorMaxUsers, errorLabTimeout;

    /* ====== text fields check ====== */
    if (name === 'name' && !value) {
        errorName = 'Seminar Name field is empty. Please fill it';
    } else if ((value.length < 5 || value.length > 30) && name === 'name') {
        errorName = 'Seminar Name length must be within 5 to 30 characters length';
    } else if (name === 'name') {
        errorName = 'Invalid character detected in Seminar Name. Letters, numbers 0-9, hyphen and underscore are allowed only';
    } else if (name === 'password' && !value) {
        errorPassword = 'Seminar Password field is empty. Please fill it';
    } else if (name === 'password' && value) {
        errorPassword = 'Entered Seminar Password is not valid. It must contain minimum 4 letters, at least one character (small or big shouldn\'t matter) and at least one digit.';
    } else if (name === 'maxUsers' && !value) {
        errorMaxUsers = 'Maximum Allowed Users field is empty. Please fill it';
    } else if (name === 'maxUsers' && value) {
        errorMaxUsers = 'Entered Maximum Allowed Users is not valid (1-100)';
    } else if (name === 'labTimeOut' && !value) {
        errorLabTimeout = 'Lab Shutdown Timeout field is empty. Please fill it';
    } else if (name === 'labTimeOut' && value) {
        errorLabTimeout = 'Entered Lab Shutdown Timeout is not valid';
    }

    /* ====== dropDowns check ====== */
    const { errorSubscription, errorLabType, errorTime } = addSeminarDropDownsError({
        subscriptionID,
        labTemplateIDs,
        startDate,
        endDate,
        startTime,
        endTime,
    });

    /*startDate = moment(startDate, dateFormat).toDate();
    endDate = moment(endDate, dateFormat).toDate();
    endTime = moment(endTime, "HH:mm").toDate();
    startTime = moment(startTime, "HH:mm").toDate();*/

    return {
        errorName,
        errorPassword,
        errorSubscription,
        errorMaxUsers,
        errorLabTimeout,
        errorTime,
        errorLabType,
    };
}

function addSeminarDropDownsError({ subscriptionID, startDate, endDate, startTime, endTime }) {
    let errorSubscription;
    let errorLabType;

    if (!subscriptionID) {
        errorSubscription = 'Please, choose Subscription';
    }
    /*
    if (!labTemplateIDs.length) {
        errorLabType = 'Please, choose Lab Template';
    }
    if (!groupAdminIDs.length) {
        errorGroupAdmin = 'Please, choose Group Admin'
    }*/

    const { errorTime } = isDateOverflow({ startDate, endDate, startTime, endTime });

    return {
        errorSubscription,
        errorLabType,
        errorTime,
    };
}

function isDateOverflow({ startDate, endDate, startTime, endTime }) {
    let errorTime;
    if (startDate.getFullYear && startDate.getMonth && startDate.getDate &&
        startDate.getFullYear() === endDate.getFullYear() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getDate() === endDate.getDate()
    ) {
        if (startTime >= endTime) {
            errorTime = 'End Time must be grazer then Start Time';
        }
    }
    return { errorTime };
}

function findActiveThumbnail(labInfo, activeVM) {
    const { instances } = labInfo;
    let machine = '';
    if (!instances) return machine;
    instances.forEach(item => {
        for (let i in item) {
            if (item[i] === activeVM) {
                machine = item.screenshot.url;
            }
        }
    });

    return machine;
}

function resetInstanceReducerUtil(labInfo, newInstance) {
    const resultLabInfo = { ...labInfo };
    resultLabInfo.instances = resultLabInfo.instances.map(instance => {
        if (instance.id === newInstance.id) return newInstance;
        return instance;
    });

    return resultLabInfo;
}

function findActiveReport(reports, id, data) {
    let result = reports;

    result.forEach(item => {
        for (let i in item) {
            if (item[i] === id) {
                item.subReports = data;
            }
        }
    });

    return result;
}

function findActiveSubReport(reports, id, subId, data) {
    let result = reports;

    result.forEach(item => {
        for (let i in item) {
            if (item[i] === id) {
                item.subReports &&
                    item.subReports.length &&
                    item.subReports.forEach(subreport => {
                        for (let s in subreport) {
                            if (subreport[s] === subId) {
                                subreport.subSubReports = data;
                            }
                        }
                    });
            }
        }
    });

    return result;
}
