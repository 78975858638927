import { webSocketConstants } from '../Constants';

export const webSocketActions = {
    setData,
    cleanReducer,
};

function setData(data) {
    return {
        type: webSocketConstants.SET_DATA,
        payload: data,
    };
}

function cleanReducer() {
    return {
        type: webSocketConstants.CLEAN_REDUCER,
    };
}
