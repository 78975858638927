import { addSeminarModalConstants } from '../../Constants';
import { groupsUtil, instancesTypesUtil, reducersUtil, seminarUtil } from '../../Utils';
import moment from 'moment';
import _ from "lodash";

const initialState = {
    name: '',
    password: '',
    startDate: new Date(),
    endDate: moment(new Date())
        .add(2, 'days')
        .toDate(),
    startTime: new Date(new Date().setHours(0, 0, 0, 0)),
    endTime: new Date(new Date().setHours(0, 0, 0, 0)),
    status: false,
    fixedNetId: false,
    groupAdmins: [],
    groupAdminNames: [''],
    groupAdminIDs: [],
    subscriptions: [],
    subscriptionID: '',
    subscriptionName: '',
    labTemplateIDs: [],
    labTemplateNames: [''],
    labTemplateDtoList: [],
    subscriptionDtoList: [],
    allGroupAdmin: [],
    maxUsers: 30,
    labTimeOut: 3,
    requestIsActive: false,
    modalIsOpen: false,
    errors: {
        errorName: '',
        errorPassword: '',
        errorSubscription: '',
        errorMaxUsers: '',
        errorLabTimeout: '',
        errorTime: '',
        errorLabType: '',
    },
};

export function addSeminarModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case addSeminarModalConstants.TOGGLE_MODAL:
            return {
                ...initialState,
                modalIsOpen: !state.modalIsOpen,
            };
        case addSeminarModalConstants.GET_MODAL_DATA_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case addSeminarModalConstants.GET_MODAL_DATA_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                labTemplateDtoList: action.payload.data.labTemplateDtoList,
                subscriptionDtoList: action.payload.data.subscriptionDtoList,
                groupAdmins: action.payload.data.allGroupAdmin,
            };
        /* ====== GET SUBSCRIPTIONS ====== */
        case addSeminarModalConstants.GET_ALL_SUBSCRIPTIONS_ERROR:
        case addSeminarModalConstants.GET_ALL_GROUP_ADMINS_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case addSeminarModalConstants.REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case addSeminarModalConstants.GET_ALL_SUBSCRIPTIONS_SUCCESS: {
            return {
                ...state,
                requestIsActive: false,
                subscriptions: action.payload.subscriptions,
            };
        }
        case addSeminarModalConstants.GET_ALL_GROUP_ADMINS_SUCCESS: {
            return {
                ...state,
                requestIsActive: state.subscriptions.length ? false : state.requestIsActive,
                groupAdmins: action.payload.groupAdmins,
            };
        }

        /* ====== CHANGE FORM DATA ======= */
        case addSeminarModalConstants.CHANGE_SUBSCRIPTION: {
            const { value } = action.payload;
            const { subscriptionDtoList } = state;
            const subscriptionName = seminarUtil.getSubscriptionName(subscriptionDtoList, value);
            return {
                ...state,
                subscriptionID: action.payload.value,
                subscriptionName,
            };
        }
        case addSeminarModalConstants.CHANGE_LAB_TEMPLATE: {
            const { value } = action.payload;
            const { labTemplateDtoList } = state;
            // const {instancesTypesManagement: {instancesTypes}} = store.getState();
            const instanceTypeID = instancesTypesUtil.getInstancesTypeID(labTemplateDtoList, value);
            const instancesTypesNames = instancesTypesUtil.filterInstancesTypesNames(value);

            return {
                ...state,
                labTemplateIDs: instanceTypeID,
                labTemplateNames: instancesTypesNames,
            };
        }
        case addSeminarModalConstants.CHANGE_GROUP_ADMIN: {
            const { value } = action.payload;
            const groupAdminIDs = groupsUtil.getGroupAdminID(state.groupAdmins, value);
            const groupAdminNames = groupsUtil.filterGroupAdminNames(value);

            return {
                ...state,
                groupAdminIDs,
                groupAdminNames,
            };
        }
        case addSeminarModalConstants.CHANGE_FORM_DATA: {
            const { value, name } = action.payload.target;
            let parsedValue = value;
            if (name === 'labTimeOut' || name === 'maxUsers') {
                parsedValue = value.replace(/[^0-9]+/gi, '');
            }
            return {
                ...state,
                [name]: parsedValue,
            };
        }
        case addSeminarModalConstants.CHANGE_DATE: {
            const { type, value } = action.payload;
            return {
                ...state,
                [type]: value,
            };
        }
        case addSeminarModalConstants.CHANGE_FIXED_NET_ID: {
            return {
                ...state,
                fixedNetId: !state.fixedNetId,
            };
        }

        /* ====== ERROR MESSAGES ====== */
        case addSeminarModalConstants.SHOW_ERROR: {
            const { target } = action.payload;
            const {
                errorName,
                errorPassword,
                errorSubscription,
                errorMaxUsers,
                errorLabTimeout,
                errorTime,
                errorLabType,
            } = reducersUtil.addEditSeminarError(target, state);
            return {
                ...state,
                errors: {
                    ...state.errors,
                    errorName: errorName ? errorName : state.errors.errorName,
                    errorPassword: errorPassword ? errorPassword : state.errors.errorPassword,
                    errorSubscription: errorSubscription ? errorSubscription : state.errors.errorSubscription,
                    errorMaxUsers: errorMaxUsers ? errorMaxUsers : state.errors.errorMaxUsers,
                    errorLabTimeout: errorLabTimeout ? errorLabTimeout : state.errors.errorLabTimeout,
                    errorTime: errorTime ? errorTime : state.errors.errorTime,
                    errorLabType: errorLabType ? errorLabType : state.errors.errorLabType,
                },
            };
        }
        case addSeminarModalConstants.SHOW_DROP_DOWNS_ERROR: {
            const { errorSubscription, errorLabType, errorTime } = reducersUtil.addSeminarDropDownsError(state);
            return {
                ...state,
                errors: {
                    ...state.errors,
                    errorSubscription: errorSubscription ? errorSubscription : state.errors.errorSubscription,
                    errorLabType: errorLabType ? errorLabType : state.errors.errorLabType,
                    errorTime: errorTime ? errorTime : state.errors.errorTime,
                },
            };
        }
        case addSeminarModalConstants.HIDE_ERROR:
            return {
                ...state,
                errors: {
                    ...initialState.errors,
                },
            };
        case addSeminarModalConstants.ADD_SEMINAR_SUCCESS:
            return initialState;
        case addSeminarModalConstants.ADD_SEMINAR_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        default:
            return state;
    }
}
