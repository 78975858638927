import Auth from '../Modules/auth.module';
import { userErrorRequests } from '../Handlers';
import { urlConstant } from '../Constants';
import { versionControlService } from '../Services';
import jwt_decode from 'jwt-decode';
import Maintenance from '../Modules/maintenance.module';
import {AxiosRequest} from "./AxiosApi";

const isRepair = Maintenance.getMaintenance();

export const userService = {
    login,
    logout,
    getUserInfo,
    sendToken,
    setPassword,
    refreshToken,
    forgotPassword,
    verifySeminar,
    verifyUser,
};

function login(email, password, browser) {
    return new Promise((resolve, reject) => {
        versionControlService.getComputerIP().then(responseIP => {
            let bodyFormData = new FormData();
            bodyFormData.set('username', email);
            bodyFormData.set('password', password);
            bodyFormData.set('ip', responseIP);
            bodyFormData.set('browser', browser);
            AxiosRequest({
                method: 'post',
                url: `${urlConstant}/login?grant_type=password`,
                headers: {
                    Authorization: 'Basic d2ViX2NsaWVudDpjWWJFclBBU1NfMjNAIw==',
                    'Content-Type': 'multipart/form-data',
                },
                data: bodyFormData,
            })
                .then(response => {
                    const { access_token, refresh_token, id } = response.data;
                    const role = jwt_decode(response.data.access_token);
                    // console.log('Role', role);
                    Auth.authenticateUser(access_token, role, refresh_token, id);
                    if (role.authorities[0] === 'ROLE_USER') {
                        getUserInfo().then(({ data }) => {
                            const { temporary, userName, email } = data;
                            const name = temporary ? userName : email;
                            Auth.extendUserInfo(name);
                            if (temporary) Auth.setTempUSer();
                            resolve(response);
                        });
                    } else if (role.authorities[0] === 'SEMINAR_USER') {
                        getUserInfo().then(({ data }) => {

                            const { userName } = data;
                            Auth.extendUserInfo(userName);
                            Auth.setTempUSer();
                            resolve(response);
                        });
                    } else {
                        Auth.extendUserInfo(email);
                        resolve(response);
                    }
                })
                .catch(error => {
                    const errorMessage = userErrorRequests('sign in', error);
                    isRepair && console.error(errorMessage, error);
                    reject(errorMessage);
                });
        });
    });
}

function logout() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/sign-out`,
        })
            .then(response => {
                isRepair && console.log('logout success');
                Auth.deauthenticateUser();
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('sign out', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getUserInfo() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/user/profile`,
        })
            .then(response => {
                isRepair && console.log('Get user info request success', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('get user', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function sendToken(userId, token) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/password/validate`,
            data: { userId, token },
            additionalConfigOptions: {
                headers: null
            }
        })
            .then(response => {
                isRepair && console.log('sendToken', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('send token', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function setPassword(userId, token, password) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/password/new`,
            data: { userId, token, password },
            additionalConfigOptions: {
                headers: null
            }
        })
            .then(response => {
                isRepair && console.log('setPassword');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('create password', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function refreshToken() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/login?grant_type=refresh_token&refresh_token=${Auth.getRefreshToken()}`,
            headers: {
                Authorization: 'Basic d2ViX2NsaWVudDpjWWJFclBBU1NfMjNAIw==',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then(response => {
                isRepair && console.log('refreshToken success', response);
                Auth.setNewTokens(response.data.access_token, response.data.refresh_token);
                // update data in local storage
                const role = jwt_decode(response.data.access_token);
                Auth.authenticateUser(response.data.access_token, role, response.data.refresh_token, Auth.getUserId());
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('refresh token', error);
                isRepair && console.error('Error to refresh token', errorMessage, error);
                reject(errorMessage);
            });
    });
}

function forgotPassword(email) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/password/forgot`,
            data: {
                email: email,
            },
            additionalConfigOptions: {
                headers: null
            }
        })
            .then(response => {
                isRepair && console.log('forgotPassword');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('forgot password', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function verifySeminar({ seminarName, seminarPassword }) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/seminar`,
            data: {
                seminarName: seminarName,
                password: seminarPassword,
            },
            additionalConfigOptions: {
                headers: null
            }
        })
            .then(response => {
                isRepair && console.log('Verify seminar successfully');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = userErrorRequests('verify seminar', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function verifyUser({ userName, userPassword, userEmail }, seminarID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/seminar`,
            data: {
                username: userName,
                password: userPassword,
                email: userEmail,
                seminarId: seminarID,
            },
            additionalConfigOptions: {
                headers: null
            }
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                let errorMessage;
                if (userEmail) {
                    errorMessage = userErrorRequests('verify user with email', error);
                } else {
                    errorMessage = userErrorRequests('verify user without email', error);
                }
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}
