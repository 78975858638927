export const seminarUsersConstants = {
    REQUEST: 'SEMINAR_USERS_REQUEST',
    CHECK_USERS: 'SEMINAR_USERS_CHECK_USERS',
    HANDLE_SELECT_ALL_CLICK: 'SEMINAR_USERS_HANDLE_SELECT_ALL_CLICK',
    HANDLE_CLICK: 'SEMINAR_USERS_HANDLE_CLICK',
    DELETE_USER_SHOW_NOTIFICATION_MODAL: 'SEMINAR_USERS_DELETE_USER_SHOW_NOTIFICATION_MODAL',
    DELETE_USERS_SHOW_NOTIFICATION_MODAL: 'SEMINAR_USERS_DELETE_USER_SHOW_NOTIFICATION_MODAL',

    DELETE_SEMINAR_USER_SUCCESS: 'SEMINAR_USERS_DELETE_SEMINAR_USER_SUCCESS',
    DELETE_SEMINAR_USER_ERROR: 'SEMINAR_USERS_DELETE_SEMINAR_USER_ERROR',
    GET_ALL_SEMINARS_USERS_SUCCESS: 'SEMINAR_USERS_GET_ALL_SEMINARS_USERS_SUCCESS',
    GET_ALL_SEMINARS_USERS_ERROR: 'SEMINAR_USERS_GET_ALL_SEMINARS_USERS_ERROR',
    RESET_PASSWORD_SHOW_NOTIFICATION_MODAL: 'SEMINAR_USERS_RESET_PASSWORD_SHOW_NOTIFICATION_MODAL',

    RESET_PASSWORD_SEMINAR_USER_REQUEST: 'RESET_PASSWORD_SEMINAR_USER_REQUEST',
    RESET_PASSWORD_SEMINAR_USER_SUCCESS: 'RESET_PASSWORD_SEMINAR_USER_SUCCESS',
    RESET_PASSWORD_SEMINAR_USER_ERROR: 'RESET_PASSWORD_SEMINAR_USER_ERROR',

    CLEAR_CHECKBOXES: 'SEMINAR_USERS_CLEAR_CHECKBOXES',

    DELETE_USERS_STATUS_SUCCESS: 'SEMINAR_USERS_DELETE_USERS_STATUS_SUCCESS',
    DELETE_USERS_STATUS_ERROR: 'SEMINAR_USERS_DELETE_USERS_STATUS_ERROR',

    DELETE_USERS_SUCCESS: 'SEMINAR_USERS_DELETE_USERS_SUCCESS',
    DELETE_USERS_ERROR: 'SEMINAR_USERS_DELETE_USERS_ERROR',

    RESET_PASSWORDS_SHOW_NOTIFICATION_MODAL: 'SEMINAR_USERS_RESET_PASSWORDS_SHOW_NOTIFICATION_MODAL',

    RESET_PASSWORDS_SEMINAR_USERS_SUCCESS: 'RESET_PASSWORDS_SEMINAR_USERS_SUCCESS',
    RESET_PASSWORDS_SEMINAR_USERS_ERROR: 'RESET_PASSWORDS_SEMINAR_USERS_ERROR',

    GET_ALL_SEMINARS_USERS_PAGE_REQUEST: 'GET_ALL_SEMINARS_USERS_PAGE_REQUEST',
    GET_ALL_SEMINARS_USERS_PAGE_SUCCESS: 'GET_ALL_SEMINARS_USERS_PAGE_SUCCESS',
    GET_USERS_SEMINARS_USERS_PAGE_SUCCESS: 'GET_USERS_SEMINARS_USERS_PAGE_SUCCESS',
    GET_USERS_SEMINARS_USERS_PAGE_ERROR: 'GET_USERS_SEMINARS_USERS_PAGE_ERROR',
    CHANGE_SEMINAR_USERS_PAGE: 'CHANGE_SEMINAR_USERS_PAGE',
    DELETE_USER_MODAL_SEMINAR_PAGE: 'DELETE_USER_MODAL_SEMINAR_PAGE',
    DELETE_USER_SEMINAR_USER_PAGE_REQUEST: 'DELETE_USER_SEMINAR_USER_PAGE_REQUEST',
    DELETE_USER_SEMINAR_USER_PAGE_SUCCESS: 'DELETE_USER_SEMINAR_USER_PAGE_SUCCESS',
    DELETE_USER_SEMINAR_USER_PAGE_ERROR: 'DELETE_USER_SEMINAR_USER_PAGE_ERROR',
    HANDLE_CLICK_USERS_PAGE: 'HANDLE_CLICK_USERS_PAGE',
    HANDLE_CLICK_ALL_USERS_PAGE: 'HANDLE_CLICK_ALL_USERS_PAGE',
    CLEAR_USER_PAGE_CHECKBOXES: 'CLEAR_USER_PAGE_CHECKBOXES',
    RESET_PASSWORD_USER_PAGE_SHOW_NOTIFICATION_MODAL: 'RESET_PASSWORD_USER_PAGE_SHOW_NOTIFICATION_MODAL',
    RESET_PASSWORDS_USER_PAGE_SHOW_NOTIFICATION_MODAL: 'RESET_PASSWORDS_USER_PAGE_SHOW_NOTIFICATION_MODAL',
    DELETE_CHECKED_USERS_MODAL_SEMINAR_PAGE: 'DELETE_CHECKED_USERS_MODAL_SEMINAR_PAGE',
    DELETE_CHECKED_USERS_SEMINAR_PAGE_USER: 'DELETE_CHECKED_USERS_SEMINAR_PAGE_USER',

    CHANGE_PAGE_SEMINAR_USERS_TAB: 'CHANGE_PAGE_SEMINAR_USERS_TAB',
    CHANGE_SIZE_SEMINAR_USERS_TAB: 'CHANGE_SIZE_SEMINAR_USERS_TAB',
    CHANGE_SEARCH_SEMINAR_USERS_TAB: 'CHANGE_SEARCH_SEMINAR_USERS_TAB',
    PERFORM_SEARCH_SEMINAR_USERS_TAB: 'PERFORM_SEARCH_SEMINAR_USERS_TAB',

    CHANGE_PAGE_SEMINAR_USERS: 'CHANGE_PAGE_SEMINAR_USERS',
    CHANGE_SIZE_SEMINAR_USERS: 'CHANGE_SIZE_SEMINAR_USERS',
    CHANGE_SEARCH_SEMINAR_USERS: 'CHANGE_SEARCH_SEMINAR_USERS',
    PERFORM_SEARCH_SEMINAR_USERS: 'PERFORM_SEARCH_SEMINAR_USERS',
};
