import { modalConstants } from '../../Constants';

const initialState = {
    isOpen: false,
    isRequestActive: false,
    versionId: '',
    versionNumber: '',
    templateId: null,
    versions: [],
};

export function templateVersionModal(state = initialState, action) {
    switch (action.type) {
        case modalConstants.CHANGE_TEMPLATE_VERSION_REQUEST:
            return { ...state, isRequestActive: true };
        case modalConstants.CHANGE_TEMPLATE_VERSION_SUCCESS:
            return {
                ...state,
                versionId: action.payload.versionId,
                versionNumber: action.payload.versionNumber,
                isRequestActive: false,
            };
        // TODO: add error case
        case modalConstants.CHANGE_TEMPLATE_VERSION_ERROR:
            return state;
        case modalConstants.OPEN_TEMPLATE_VERSION_MODAL: {
            // const currentVersion = action.payload.versions.find(v => v.current);
            return {
                ...state,
                isOpen: true,
                templateId: action.payload.id,
                // versionId: currentVersion ? currentVersion.id : null,
                // versionNumber: currentVersion ? currentVersion.versionNumber : null,
                // versions: action.payload.versions.filter(v => v.validationState === 'VALIDATED'),
                shared: action.payload.shared
            };
        }
        case modalConstants.CLOSE_TEMPLATE_VERSION_MODAL:
            return {
                ...state,
                isOpen: false,
            };
        default:
            return state;
    }
}
