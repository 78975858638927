import { modalConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    signOutModal: {
        isOpen: false,
        text: '',
        method: '',
    },
};

export function signOutModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.SHOW_SIGN_OUT_NOTIFICATION_MODAL:
            return {
                ...state,
                signOutModal: {
                    ...state.signOutModal,
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                },
            };
        case modalConstants.CLOSE_NOTIFICATION_MODAL:
            return initialState;
        default:
            return state;
    }
}
