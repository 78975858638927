import { errorRequest, notificationModalActions, seminarUsersConstants } from '../../Constants';
import { seminarService, userManagementService, userService } from '../../Services';
import {notifierActions} from "../notifier.actions";

const {enqueueNotification} = notifierActions;

export const seminarUsersActions = {
    getSeminarUsersByID,
    getGroupAdminSeminarUsersByID,
    handleSelectAllClick,
    handleClick,
    openDeleteUserModal,
    openDeleteUsersModal,
    deleteSeminarUser,
    openResetPasswordModal,
    resetPasswordSeminarUser,
    clearCheckboxes,
    deleteUsersStatus,
    deleteSeminarUsersMultiple,
    resetPasswordsOpenModal,
    resetPasswordsSeminarUsers,
    changePage,
    changeSize,
    changeSearch,
    performSearch,
};

function getSeminarUsersByID(seminarID, page, size, search) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .getSeminarUsersByID(seminarID, page, size, search)
            .then(response => {
                dispatch(success(response.data, seminarID));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: seminarUsersConstants.REQUEST,
        };
    }
    function success(data, seminarID) {
        return {
            type: seminarUsersConstants.GET_ALL_SEMINARS_USERS_SUCCESS,
            payload: {
                users: data.content,
                seminarID,
                data,
            },
        };
    }
    function failure() {
        return {
            type: seminarUsersConstants.GET_ALL_SEMINARS_USERS_ERROR,
        };
    }
}

function getGroupAdminSeminarUsersByID(seminarID, page, size, search) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .getGroupAdminSeminarUsersByID(seminarID, page, size, search)
            .then(response => {
                dispatch(success(response.data, seminarID));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: seminarUsersConstants.REQUEST,
        };
    }
    function success(data, seminarID) {
        return {
            type: seminarUsersConstants.GET_ALL_SEMINARS_USERS_SUCCESS,
            payload: {
                users: data.content,
                seminarID,
                data,
            },
        };
    }
    function failure() {
        return {
            type: seminarUsersConstants.GET_ALL_SEMINARS_USERS_ERROR,
        };
    }
}

function handleSelectAllClick(checked) {
    return {
        type: seminarUsersConstants.HANDLE_SELECT_ALL_CLICK,
        payload: {
            checked,
        },
    };
}

function handleClick(id) {
    return {
        type: seminarUsersConstants.HANDLE_CLICK,
        payload: {
            id,
        },
    };
}

function openDeleteUserModal(userID) {
    return {
        type: seminarUsersConstants.DELETE_USER_SHOW_NOTIFICATION_MODAL,
        payload: {
            userID,
            text: 'Are you sure, you want to delete user?',
            method: notificationModalActions.DELETE_SEMINAR_USER,
        },
    };
}

function openDeleteUsersModal(userID) {
    return {
        type: seminarUsersConstants.DELETE_USERS_SHOW_NOTIFICATION_MODAL,
        payload: {
            userID,
            text: 'Are you sure, you want to delete selected users?',
            method: notificationModalActions.DELETE_SEMINAR_USER,
        },
    };
}

function deleteSeminarUser(seminarID, userID, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .deleteUser(userID)
            .then(() => {
                dispatch(successDelete());

                dispatch(enqueueNotification('User deleted successfully', {variant: 'success'}));

                seminarService
                    .getSeminarUsersByID(seminarID, page, size)
                    .then(response => {
                        dispatch(successGetAll(response.data, seminarID));
                    })
                    .catch(() => {
                        dispatch(failureGetAll());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureDelete(error));
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarUsersConstants.REQUEST,
        };
    }
    function successDelete() {
        return {
            type: seminarUsersConstants.DELETE_SEMINAR_USER_SUCCESS,
        };
    }
    function failureDelete() {
        return {
            type: seminarUsersConstants.DELETE_SEMINAR_USER_ERROR,
        };
    }
    function successGetAll(data, seminarID) {
        return {
            type: seminarUsersConstants.GET_ALL_SEMINARS_USERS_SUCCESS,
            payload: {
                users: data.content,
                seminarID,
                data,
            },
        };
    }
    function failureGetAll() {
        return {
            type: seminarUsersConstants.GET_ALL_SEMINARS_USERS_ERROR,
        };
    }
}

function openResetPasswordModal(userID, name) {
    return {
        type: seminarUsersConstants.RESET_PASSWORD_SHOW_NOTIFICATION_MODAL,
        payload: {
            userID,
            text: `Are you sure, you want to reset password for this user ${name}?`,
            method: notificationModalActions.RESET_PASSWORD_SEMINAR_USER,
        },
    };
}

function resetPasswordSeminarUser(userID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .resetPasswordSeminarUser(userID)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('User password will be stored on next sign into the seminar.', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarUsersConstants.RESET_PASSWORD_SEMINAR_USER_REQUEST,
        };
    }
    function success() {
        return {
            type: seminarUsersConstants.RESET_PASSWORD_SEMINAR_USER_SUCCESS,
        };
    }
    function failure() {
        return {
            type: seminarUsersConstants.RESET_PASSWORD_SEMINAR_USER_ERROR,
        };
    }
}

function clearCheckboxes() {
    return {
        type: seminarUsersConstants.CLEAR_CHECKBOXES,
    };
}

function deleteUsersStatus() {
    return (dispatch, getState) => {
        main(dispatch, getState());
    };
    function main(dispatch, state) {
        const { checkedIDs } = state.seminarUsers;

        userManagementService
            .deleteUsersMultipleStatus(checkedIDs)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                }
            });
    }

    function success(users) {
        return {
            type: seminarUsersConstants.DELETE_USERS_STATUS_SUCCESS,
            payload: {
                users,
            },
        };
    }
    function failure() {
        return {
            type: seminarUsersConstants.DELETE_USERS_STATUS_ERROR,
        };
    }
}

function deleteSeminarUsersMultiple(users, page, size) {
    return (dispatch, getState) => {
        main(dispatch, getState());
    };

    function main(dispatch, state) {
        const indexes = getIndexes(users);
        const { seminarID } = state.seminarUsers;

        dispatch(request());
        seminarService
            .deleteUsers(indexes, seminarID)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Users have been deleted successfully', {variant: 'success'}));

                seminarService
                    .getSeminarUsersByID(seminarID, page, size)
                    .then(response => {
                        dispatch(successGetAll(response.data));
                    })
                    .catch(() => {
                        dispatch(failureGetAll());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }

    function request() {
        return {
            type: seminarUsersConstants.REQUEST,
        };
    }
    function success() {
        return {
            type: seminarUsersConstants.DELETE_USERS_SUCCESS,
        };
    }
    function failure() {
        return {
            type: seminarUsersConstants.DELETE_USERS_ERROR,
        };
    }
    function getIndexes(elements) {
        return elements.users.succedUserDelete.map(item => item.id);
    }
    function successGetAll(users) {
        return {
            type: seminarUsersConstants.GET_ALL_SEMINARS_USERS_SUCCESS,
            payload: {
                users,
            },
        };
    }
    function failureGetAll() {
        return {
            type: seminarUsersConstants.GET_ALL_SEMINARS_USERS_ERROR,
        };
    }
}

function resetPasswordsOpenModal() {
    return {
        type: seminarUsersConstants.RESET_PASSWORDS_SHOW_NOTIFICATION_MODAL,
        payload: {
            text: 'Are you sure, you want to reset passwords for these users?',
            method: notificationModalActions.RESET_PASSWORDS_SEMINAR_USERS,
        },
    };
}

function resetPasswordsSeminarUsers() {
    return (dispatch, getState) => {
        main(dispatch, getState());
    };
    function main(dispatch, state) {
        const { checkedIDs, seminarID } = state.seminarUsers;

        seminarService
            .resetUsersPasswords(seminarID, checkedIDs)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Passwords have been reseted successfully', {variant: 'success'}));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function success() {
        return {
            type: seminarUsersConstants.RESET_PASSWORDS_SEMINAR_USERS_SUCCESS,
        };
    }
    function failure() {
        return {
            type: seminarUsersConstants.RESET_PASSWORDS_SEMINAR_USERS_ERROR,
        };
    }
}

function changePage(value) {
    return {
        type: seminarUsersConstants.CHANGE_PAGE_SEMINAR_USERS,
        payload: {
            value,
        },
    };
}

function changeSize(value) {
    return {
        type: seminarUsersConstants.CHANGE_SIZE_SEMINAR_USERS,
        payload: {
            value,
        },
    };
}

function changeSearch(value) {
    return {
        type: seminarUsersConstants.CHANGE_SEARCH_SEMINAR_USERS,
        payload: {
            value,
        },
    };
}

function performSearch() {
    return {
        type: seminarUsersConstants.PERFORM_SEARCH_SEMINAR_USERS,
        payload: {},
    };
}
