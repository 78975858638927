import versionManagement from '../Modules/versionManagement.module';
import { userService, versionControlService } from '../Services';
import Auth from '../Modules/auth.module';
import { errorRequest } from '../Constants';

const checkAppVersionHandler = response => {
    if (response.data.uiVersion) {
        const { uiVersion } = response.data;

        if (versionManagement.isVersionExist()) {
            if (uiVersion !== parseInt(versionManagement.getCurrentVersion(), 10)) {
                console.log('Versions do not match ' + uiVersion + ' and ' + versionManagement.getCurrentVersion());

                versionManagement.setCurrentVersion(uiVersion);
                userService.logout().then(() => {
                    window.location.reload(true);
                });
            }
        } else {
            console.log('Set current version', uiVersion);
            versionManagement.setCurrentVersion(uiVersion);
        }
    } else {
        console.error('Error to get version from response');
    }
};

export const versionErrorRequests = (requestType, error) => {
    if (error.response) {
        const errorRes = error.response;

        switch (requestType) {
            case 'get computer ip': {
                return 'IP undefined';
            }
            case 'get version': {
                switch (errorRes.status) {
                    case 400: {
                        return `Error to ${requestType}`;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            default: {
                switch (errorRes.status) {
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        Auth.deauthenticateUser();
                        return errorRequest.DEFAULT;
                    }
                }
            }
        }
    } else {
        Auth.deauthenticateUser();
        return errorRequest.DEFAULT;
    }
};

export const checkVersionWrapper = () => {
    versionControlService.getVersion().then(response => {
        checkAppVersionHandler(response);
    });
};

export const formatingValidatedCsvData = (userData, groupIdList) => {
    let result = [];
    if (!userData && !userData.length) return result;

    userData.forEach(user => {
        const { email, username } = user;
        result.push({
            username,
            email,
            groupIdList,
        });
    });

    return result;
};
