import { urlConstant } from '../Constants';
import { historyErrorRequests } from '../Handlers';
import Maintenance from '../Modules/maintenance.module';
import {AxiosRequest} from "./AxiosApi";

const isRepair = Maintenance.getMaintenance();

export const seminarHistoryService = {
    getSeminarsHistory,
    getGroupAdminSeminarsHistory,
    getSeminarsActionList,
    getGroupAdminSeminarsActionList,
    getUsersHistory,
    getGroupAdminUsersHistory,
    getUsersActionList,
    getGroupAdminUsersActionList,
    getLabsHistory,
    getGroupAdminLabsHistory,
    getLabsActionList,
    getGroupAdminLabsActionList,
};

function getSeminarsHistory(data, page, size, sortField, sortDirection) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/history/seminars?page=${page}&size=${size}&sort=createdTime,${sortDirection}`,
            data: {
                query: data.query,
                dateFrom: data.dateFrom,
                dateTo: data.dateTo,
                operationTypes: data.operationTypes,
                includeTestData: data.includeTestData
            },
        })
            .then(response => {
                // isRepair && console.log('Get seminars history', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get seminars history', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupAdminSeminarsHistory(data, page, size, sortField, sortDirection) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/history/seminars?page=${page}&size=${size}&sort=createdTime,${sortDirection}`,
            data: {
                query: data.query,
                dateFrom: data.dateFrom,
                dateTo: data.dateTo,
                operationTypes: data.operationTypes,
                includeTestData: data.includeTestData
            },
        })
            .then(response => {
                // isRepair && console.log('Get seminars history', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get seminars history', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getSeminarsActionList() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/history/seminars`,
        })
            .then(response => {
                // isRepair && console.log('Get seminars action list', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get seminars action list', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupAdminSeminarsActionList() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/groupAdmin/history/seminars`,
        })
            .then(response => {
                // isRepair && console.log('Get seminars action list', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get seminars action list', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getUsersHistory(info, page, size, sortField, sortDirection) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/history/seminar/user?page=${page}&size=${size}&sort=createdTime,${sortDirection}`,
            data: {
                userId: null,
                seminarIds: null,
                query: info.body.query,
                dateFrom: info.body.dateFrom,
                dateTo: info.body.dateTo,
                operationTypes: info.body.operationTypes,
                includeTestData: info.body.includeTestData
            },
        })
            .then(response => {
                // isRepair && console.log('Get seminar user history', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get seminar user history', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupAdminUsersHistory(info, page, size, sortField, sortDirection) {
    // console.log('Info', info);
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/history/seminar/user?page=${page}&size=${size}&sort=createdTime,${sortDirection}`,
            data: {
                userId: null,
                seminarIds: null,
                query: info.body.query,
                dateFrom: info.body.dateFrom,
                dateTo: info.body.dateTo,
                operationTypes: info.body.operationTypes,
                includeTestData: info.body.includeTestData
            },
        })
            .then(response => {
                // isRepair && console.log('Get seminar user history', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get seminar user history', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getUsersActionList() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/history/seminar_user`,
        })
            .then(response => {
                // isRepair && console.log('Get seminar user action list', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get seminar user action list', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupAdminUsersActionList() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/groupAdmin/history/seminar_user`,
        })
            .then(response => {
                // isRepair && console.log('Get seminar user action list', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get seminar user action list', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getLabsHistory(info, page, size, sortField, sortDirection) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/history/seminars/lab?page=${page}&size=${size}&sort=createdTime,${sortDirection}`,
            data: {
                userId: null,
                seminarIds: null,
                query: info.body.query,
                dateFrom: info.body.dateFrom,
                dateTo: info.body.dateTo,
                operationTypes: info.body.operationTypes,
                includeTestData: info.body.includeTestData
            },
        })
            .then(response => {
                // isRepair && console.log('Get seminar labs history', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get seminar labs history', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupAdminLabsHistory(info, page, size, sortField, sortDirection) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/history/seminars/lab?page=${page}&size=${size}&sort=createdTime,${sortDirection}`,
            data: {
                userId: null,
                seminarIds: null,
                query: info.body.query,
                dateFrom: info.body.dateFrom,
                dateTo: info.body.dateTo,
                operationTypes: info.body.operationTypes,
                includeTestData: info.body.includeTestData
            },
        })
            .then(response => {
                // isRepair && console.log('Get seminar labs history', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get seminar labs history', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getLabsActionList() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/history/seminar_lab`,
        })
            .then(response => {
                // isRepair && console.log('Get seminar labs action list', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get seminar labs action list', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupAdminLabsActionList() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/groupAdmin/history/seminar_lab`,
        })
            .then(response => {
                // isRepair && console.log('Get seminar labs action list', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = historyErrorRequests('get seminar labs action list', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}
