import { addLabTemplateConstants, dataListsManagementConstants, modalConstants, templateEditorConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    needToSearch: false,
    getInstancesTypesRequestActive: false,
    instancesTypes: [],
    instancesTypesDropdown: [],
    deleteLabTemplateModal: {
        isOpen: false,
        text: '',
        method: '',
        labTemplateID: '',
    },
    deleteBlueprintModal: {
        isOpen: false,
        text: '',
        method: '',
        labTemplateID: '',
    },
    size: 20,
    sizeList: [10, 20, 30],
    page: 0,
    total: 3,
    totalElements: 0,
    search: '',
    currentTab: 'templates',
    json: '',
    editTemplateName: '',
    jsonInfo: {
        acceptedFile: {
            path: '',
        },
        fileName: '',
    },
    blueprintId: '',
};

export function instancesTypesManagement(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_REQUEST:
            return {
                ...state,
                getInstancesTypesRequestActive: true,
            };
        case dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_SUCCESS:
            return {
                ...state,
                instancesTypes: action.payload.content,
                getInstancesTypesRequestActive: false,
                totalElements: action.payload.totalElements,
                size: action.payload.size,
                needToSearch: false,
            };
        // TODO: Add some loading state
        case dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_DROPDOWN_REQUEST:
            return state;
        case dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_DROPDOWN_SUCCESS:
            return {
                ...state,
                instancesTypesDropdown: action.payload,
            };
        // TODO: Add some errors state
        case dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_DROPDOWN_ERROR:
            return state;
        case dataListsManagementConstants.TOGGLE_ADD_TEMPLATE_MODAL:
            return {
                ...state,
                deleteLabTemplateModal: {
                    isOpen: true,
                    text: '',
                    method: '',
                    labTemplateID: '',
                },
            };
        case modalConstants.DELETE_LAB_TEMPLATE_MODAL_SHOW:
            return {
                ...state,
                deleteLabTemplateModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    labTemplateID: action.payload.labTemplateID,
                },
            };
        case modalConstants.DELETE_BLUEPRINT_MODAL_SHOW:
            return {
                ...state,
                deleteBlueprintModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    labTemplateID: action.payload.labTemplateID,
                },
            };
        case dataListsManagementConstants.DELETE_BLUEPRINT_REQUEST:
            return {
                ...state,
                getInstancesTypesRequestActive: true,
            };
        case dataListsManagementConstants.DELETE_BLUEPRINT_SUCCESS:
            return {
                ...state,
                getInstancesTypesRequestActive: false,
                deleteBlueprintModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    labTemplateID: '',
                },
            };
        case dataListsManagementConstants.DELETE_BLUEPRINT_ERROR:
            return {
                ...state,
                getInstancesTypesRequestActive: false,
                deleteBlueprintModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    labTemplateID: '',
                },
            };
        case modalConstants.DELETE_LAB_TEMPLATE_REQUEST:
            return {
                ...state,
                getInstancesTypesRequestActive: true,
            };
        case modalConstants.DELETE_LAB_TEMPLATE_SUCCESS:
            return {
                ...state,
                getInstancesTypesRequestActive: false,
                deleteLabTemplateModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    labTemplateID: '',
                },
            };
        case modalConstants.DELETE_LAB_TEMPLATE_ERROR:
            return {
                ...state,
                getInstancesTypesRequestActive: false,
                deleteLabTemplateModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    labTemplateID: '',
                },
            };
        case modalConstants.CLOSE_NOTIFICATION_MODAL:
            return {
                ...state,
                deleteLabTemplateModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    labTemplateID: '',
                },
                deleteBlueprintModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    labTemplateID: '',
                },
            };
        case dataListsManagementConstants.GET_ALL_BLUEPRINTS_LIST_REQUEST:
            return {
                ...state,
                getInstancesTypesRequestActive: true,
            };
        case dataListsManagementConstants.GET_ALL_BLUEPRINTS_LIST_SUCCESS:
            return {
                ...state,
                getInstancesTypesRequestActive: false,
                instancesTypes: action.payload.content,
                totalElements: action.payload.totalElements,
                size: action.payload.size,
            };
        case dataListsManagementConstants.GET_ALL_BLUEPRINTS_LIST_ERROR:
            return {
                ...state,
                getInstancesTypesRequestActive: false,
            };
        case dataListsManagementConstants.GET_EDITABLE_TEMPLATE_REQUEST:
        case dataListsManagementConstants.GET_EDITABLE_BLUEPRINT_REQUEST:
            return {
                ...state,
                getInstancesTypesRequestActive: true,
            };
        case dataListsManagementConstants.GET_EDITABLE_TEMPLATE_SUCCESS:
        case dataListsManagementConstants.GET_EDITABLE_BLUEPRINT_SUCCESS:
            return {
                ...state,
                getInstancesTypesRequestActive: false,
                json: action.payload.json,
                editTemplateName: action.payload.editTemplateName,
                jsonInfo: action.payload.jsonInfo,
                blueprintId: action.payload.blueprintId,
            };
        case dataListsManagementConstants.GET_EDITABLE_TEMPLATE_ERROR:
        case dataListsManagementConstants.GET_EDITABLE_BLUEPRINT_ERROR:
            return {
                ...state,
                getInstancesTypesRequestActive: false,
            };
        case templateEditorConstants.CHANGE_TEMPLATE_NAME:
            return {
                ...state,
                editTemplateName: action.payload.name,
            };
        case templateEditorConstants.CHANGE_JSON_FILE:
            return {
                ...state,
                json: action.payload.json,
            };
        case addLabTemplateConstants.VERIFY_BLUEPRINT_SUCCESS:
            return {
                ...state,
                json: '',
                editTemplateName: '',
            };
        case templateEditorConstants.SAVE_AS_DRAFT_SUCCESS:
            return {
                ...state,
                blueprintId: action.payload.blueprintId,
                templateId: action.payload.id
            };
        default:
            return state;
    }
}
