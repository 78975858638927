import {
    seminarLabsConstants,
    errorRequest,
    seminarConstants,
    labDetailsConstants,
    notificationModalActions,
    modalConstants,
} from '../../Constants';
import { seminarService, singleLabManagementService, userService } from '../../Services';
import { seminarUtil } from '../../Utils';
import Seminars from '../../Modules/seminar.module';
import {notifierActions} from "../notifier.actions";

const {enqueueNotification} = notifierActions;

export const seminarLabsActions = {
    getLabsOfFirstSeminar,
    changeSeminar,
    getLabsBySeminarID,
    loadAllLabs,
    setCurrentSeminar,
    onSelectAllClick,
    handleClick,
    changePage,
    changeSize,
    getLabDetailsByID,
    getSeminarLabDetailsByID,
    getSeminarUserLabDetailsByID,
    recreateLabShowNotificationModal,
    recreateSeminarLabByAdmin,
    recreateSeminarLabByGroupAdmin,
    getLabsOfFirstSeminarGA,
    getLabsBySeminarIDGroupAdmin,
    loadAllLabsGA,
    getGroupAdminLabDetailsByID,
    deleteLabNotificationModal,
    deleteLabNotificationModalGA,
    deleteLabsNotificationModal,
    deleteLabsNotificationModalGA,
    deleteSeminarLabFromSystem,
    deleteSeminarLabFromSystemGA,
    changeSearch,
    performSearch,
    clearCheckboxes,
    saveCurrentSeminarPage
};

function getLabsOfFirstSeminar(page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .getAll()
            .then(response => {
                dispatch(successGetSeminars(response.data.content));

                let seminarID;
                if (
                    Seminars.getSeminarId() !== null &&
                    seminarUtil.isSeminarExits(response.data.content, Number(Seminars.getSeminarId()))
                ) {
                    seminarID = Number(Seminars.getSeminarId());
                } else {
                    // seminarID = response.data.content.length ? response.data.content[0].id : null;
                    seminarID = 'all';
                    Seminars.setSeminarId(seminarID);
                }

                if (seminarID !== null) {
                    seminarService
                        .getLabsOfSeminar(seminarID, page, size)
                        .then(response => {
                            dispatch(successGetLabs(response.data, seminarID));
                        })
                        .catch(error => {
                            dispatch(failureGetAll(error));
                        });
                } else {
                    console.log('Failure');
                    dispatch(failureGetAll('no seminars'));
                }
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureGetSeminars());
                }
            });
    }
    function request() {
        return {
            type: seminarLabsConstants.REQUEST,
        };
    }
    function successGetSeminars(seminars) {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_SUCCESS,
            payload: {
                seminars,
            },
        };
    }
    function failureGetSeminars() {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_ERROR,
        };
    }
    function successGetLabs(labs, seminarID) {
        return {
            type: seminarLabsConstants.GET_ALL_LABS_SUCCESS,
            payload: {
                labs,
                seminarID,
            },
        };
    }
    function failureGetAll(error) {
        return {
            type: seminarLabsConstants.GET_ALL_LABS_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function changeSeminar(event, index, value) {
    return {
        type: seminarLabsConstants.CHANGE_SEMINAR,
        payload: {
            seminarID: value,
        },
    };
}

function getLabsBySeminarID(seminarID, page, size, search) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .getLabsOfSeminar(seminarID, page, size, search)
            .then(response => {
                dispatch(successGetLabs(response.data, seminarID));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureGetAll());
                }
            });
    }
    function request() {
        return {
            type: seminarLabsConstants.REQUEST,
        };
    }
    function successGetLabs(labs, seminarID) {
        return {
            type: seminarLabsConstants.GET_ALL_LABS_SUCCESS,
            payload: {
                labs,
                seminarID,
            },
        };
    }
    function failureGetAll() {
        return {
            type: seminarLabsConstants.GET_ALL_LABS_ERROR,
        };
    }
}

function loadAllLabs(page, size) {
    return (dispatch, getState) => {
        main(dispatch, getState());
    };
    function main(dispatch, state) {
        const { seminarID } = state.seminarLabs;

        seminarService
            .getLabsOfSeminar(seminarID, page, size)
            .then(response => {
                dispatch(successGetLabs(response.data, seminarID));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureGetAll());
                }
            });
    }
    function successGetLabs(labs, seminarID) {
        return {
            type: seminarLabsConstants.GET_ALL_LABS_SUCCESS,
            payload: {
                labs,
                seminarID,
            },
        };
    }
    function failureGetAll() {
        return {
            type: seminarLabsConstants.GET_ALL_LABS_ERROR,
        };
    }
}

function setCurrentSeminar(seminarID) {
    return {
        type: seminarLabsConstants.CHANGE_SEMINAR,
        payload: {
            seminarID,
        },
    };
}

function onSelectAllClick(checked) {
    return {
        type: seminarLabsConstants.HANDLE_SELECT_ALL_CLICK_LAB,
        payload: {
            checked,
        },
    };
}

function handleClick(id) {
    return {
        type: seminarLabsConstants.HANDLE_CLICK_LAB,
        payload: {
            id,
        },
    };
}

function changePage(value) {
    // console.log('offset', value);
    return {
        type: seminarLabsConstants.CHANGE_LABS_PAGE,
        payload: {
            value,
        },
    };
}

function changeSize(value) {
    return {
        type: seminarLabsConstants.CHANGE_LABS_SIZE,
        payload: {
            value,
        },
    };
}

function clearCheckboxes() {
    return {
        type: seminarLabsConstants.CLEAR_CHECKBOXES
    }
}

function getLabDetailsByID(labID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .adminGetLabInfoByID(labID)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_REQUEST,
        };
    }
    function success(labInfo) {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_SUCCESS,
            payload: {
                labInfo,
            },
        };
    }
    function failure() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_ERROR,
        };
    }
}

function getSeminarLabDetailsByID(labID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .adminGetSeminarLabInfoByID(labID)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_REQUEST,
        };
    }
    function success(labInfo) {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_SUCCESS,
            payload: {
                labInfo,
            },
        };
    }
    function failure() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_ERROR,
        };
    }
}

function getSeminarUserLabDetailsByID(labID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .userGetLabInfoByID(labID)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_REQUEST,
        };
    }
    function success(labInfo) {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_SUCCESS,
            payload: {
                labInfo,
            },
        };
    }
    function failure() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_ERROR,
        };
    }
}

function recreateLabShowNotificationModal(labID, goBack = false) {
    return {
        type: seminarLabsConstants.RECREATE_SEMINAR_LAB_SHOW_NOTIFICATION_MODAL,
        payload: {
            method: notificationModalActions.RECREATE_LAB,
            isOpen: true,
            text: 'Are you sure you want to recreate lab?',
            labID,
            goBack
        },
    };
}

function recreateSeminarLabByAdmin(labID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        singleLabManagementService.recreateByAdminSeminarLab(labID).catch(error => {
            dispatch(enqueueNotification(String(error), {variant: 'error'}));
        });
    }
}

function recreateSeminarLabByGroupAdmin(labID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        singleLabManagementService.recreateSeminarLabByGroupAdmin(labID).catch(error => {
            dispatch(enqueueNotification(String(error), {variant: 'error'}));
        });
    }
}

function getLabsOfFirstSeminarGA(page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .getAllSeminarGA(0, 20, '')
            .then(response => {
                dispatch(successGetSeminars(response.data.content));

                let seminarID;
                if (
                    Seminars.getSeminarId() !== null &&
                    seminarUtil.isSeminarExits(response.data.content, Number(Seminars.getSeminarId()))
                ) {
                    seminarID = Number(Seminars.getSeminarId());
                } else {
                    // seminarID = response.data.content.length ? response.data.content[0].id : null;
                    seminarID = 'all';
                    Seminars.setSeminarId(seminarID);
                }

                if (seminarID !== null) {
                    seminarService
                        .getAllLabsGA(seminarID, page, size)
                        .then(response => {
                            dispatch(successGetLabs(response.data, seminarID));
                        })
                        .catch(error => {
                            dispatch(failureGetAll(error));
                        });
                } else {
                    console.log('Failure');
                    dispatch(failureGetAll('no seminars'));
                }
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureGetSeminars());
                }
            });
    }
    function request() {
        return {
            type: seminarLabsConstants.REQUEST,
        };
    }
    function successGetSeminars(seminars) {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_SUCCESS,
            payload: {
                seminars,
            },
        };
    }
    function failureGetSeminars() {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_ERROR,
        };
    }
    function successGetLabs(labs, seminarID) {
        return {
            type: seminarLabsConstants.GET_ALL_LABS_SUCCESS,
            payload: {
                labs,
                seminarID,
            },
        };
    }
    function failureGetAll(error) {
        return {
            type: seminarLabsConstants.GET_ALL_LABS_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function getLabsBySeminarIDGroupAdmin(seminarID, page, size, search) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .getAllLabsGA(seminarID, page, size, search)
            .then(response => {
                dispatch(successGetLabs(response.data, seminarID));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureGetAll());
                }
            });
    }
    function request() {
        return {
            type: seminarLabsConstants.REQUEST,
        };
    }
    function successGetLabs(labs, seminarID) {
        return {
            type: seminarLabsConstants.GET_ALL_LABS_SUCCESS,
            payload: {
                labs,
                seminarID,
            },
        };
    }
    function failureGetAll() {
        return {
            type: seminarLabsConstants.GET_ALL_LABS_ERROR,
        };
    }
}

function loadAllLabsGA(page, size) {
    return (dispatch, getState) => {
        main(dispatch, getState());
    };
    function main(dispatch, state) {
        const { seminarID } = state.seminarLabs;

        seminarService
            .getAllLabsGA(seminarID, page, size)
            .then(response => {
                dispatch(successGetLabs(response.data, seminarID));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureGetAll());
                }
            });
    }
    function successGetLabs(labs, seminarID) {
        return {
            type: seminarLabsConstants.GET_ALL_LABS_SUCCESS,
            payload: {
                labs,
                seminarID,
            },
        };
    }
    function failureGetAll() {
        return {
            type: seminarLabsConstants.GET_ALL_LABS_ERROR,
        };
    }
}

function getGroupAdminLabDetailsByID(labID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .groupAdminGetLabInfoByID(labID)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_REQUEST,
        };
    }
    function success(labInfo) {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_SUCCESS,
            payload: {
                labInfo,
            },
        };
    }
    function failure() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_ERROR,
        };
    }
}

function deleteLabNotificationModal(labID) {
    return {
        type: modalConstants.OPEN_DELETE_SEMINAR_LAB_MODAL,
        payload: {
            labID,
            text: 'Are you sure you want to delete lab?',
            method: notificationModalActions.DELETE_SEMINAR_LAB,
        },
    };
}

function deleteLabNotificationModalGA(labID) {
    return {
        type: modalConstants.OPEN_DELETE_SEMINAR_LAB_MODAL,
        payload: {
            labID,
            text: 'Are you sure you want to delete lab?',
            method: notificationModalActions.DELETE_SEMINAR_LAB_GROUP_ADMIN,
        },
    };
}

function deleteLabsNotificationModal(labID) {
    return {
        type: modalConstants.OPEN_DELETE_SEMINAR_LAB_MODAL,
        payload: {
            labID,
            text: 'Are you sure you want to delete labs?',
            method: notificationModalActions.DELETE_SEMINAR_LAB,
        },
    };
}

function deleteLabsNotificationModalGA(labID) {
    return {
        type: modalConstants.OPEN_DELETE_SEMINAR_LAB_MODAL,
        payload: {
            labID,
            text: 'Are you sure you want to delete labs?',
            method: notificationModalActions.DELETE_SEMINAR_LAB_GROUP_ADMIN,
        },
    };
}

function deleteSeminarLabFromSystem(checkedID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        singleLabManagementService
            .deleteByAdminSeminarLab(checkedID, true)
            .then(() => {
                dispatch(success());
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarLabsConstants.DELETE_LABS_FROM_SYSTEM_REQUEST,
        };
    }
    function success() {
        return {
            type: seminarLabsConstants.DELETE_LABS_FROM_SYSTEM_SUCCESS,
        };
    }
    function failure() {
        return {
            type: seminarLabsConstants.DELETE_LABS_FROM_SYSTEM_ERROR,
        };
    }
}

function deleteSeminarLabFromSystemGA(checkedID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        singleLabManagementService
            .deleteByGroupAdminSeminarLab(checkedID, true)
            .then(() => {
                dispatch(success());
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: seminarLabsConstants.DELETE_LABS_FROM_SYSTEM_REQUEST,
        };
    }
    function success() {
        return {
            type: seminarLabsConstants.DELETE_LABS_FROM_SYSTEM_SUCCESS,
        };
    }
    function failure() {
        return {
            type: seminarLabsConstants.DELETE_LABS_FROM_SYSTEM_ERROR,
        };
    }
}

function changeSearch(value) {
    return {
        type: seminarLabsConstants.CHANGE_SEARCH_SEMINAR_LABS,
        payload: {
            value
        }
    }
}

function performSearch() {
    return {
        type: seminarLabsConstants.PERFORM_SEARCH_SEMINAR_LABS,
        payload: {}
    }
}

function saveCurrentSeminarPage(value) {
    return {
        type: seminarLabsConstants.SAVE_CURRENT_SEMINAR_PAGE,
        payload: {
            value
        }
    }
}