import {
    dataListsManagementConstants,
    errorRequest,
    addSeminarModalConstants,
    editSeminarModalConstants,
    seminarConstants,
} from '../../Constants';
import { instancesTypesManagementService, userManagementService, userService, seminarService } from '../../Services';
import {notifierActions} from "../notifier.actions";

const {enqueueNotification} = notifierActions;

export const editSeminarModalActions = {
    openEditSeminarModal,
    closeEditSeminarModal,
    changeLabTemplate,
    changeForm,
    showError,
    hideError,
    changeDate,
    editSeminar,
    changeGroupAdmin,
    changeFixedNetId,
    showDateOverflowError,
    openEditSeminarModalGA,
    editSeminarGroupAdmin,
};

function openEditSeminarModal(seminarID) {
    return dispatch => {
        open(dispatch, seminarID);
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        instancesTypesManagementService
            .getAllInstancesTypes()
            .then(response => {
                dispatch(successLabTemplates(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureLabTemplates());
                }
            });
        seminarService
            .getEditSeminarData(seminarID)
            .then(response => {
                dispatch(successSeminars(response.data));
            })
            .then(() => {
                userManagementService
                    .getGroupAdmins()
                    .then(response => {
                        dispatch(successGroupAdmins(response.data));
                    })
                    .catch(error => {
                        if (error === errorRequest.UNAUTHORIZED) {
                            userService.refreshToken().then(() => main(dispatch));
                        } else {
                            dispatch(failureGroupAdmins());
                        }
                    });
            });
    }
    function request() {
        return {
            type: addSeminarModalConstants.REQUEST,
        };
    }
    function successLabTemplates(instancesTypes) {
        return {
            type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_SUCCESS,
            payload: {
                instancesTypes,
            },
        };
    }
    function failureLabTemplates() {
        return {
            type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_ERROR,
        };
    }
    function successGroupAdmins(groupAdmins) {
        return {
            type: editSeminarModalConstants.GET_ALL_GROUP_ADMINS_SUCCESS,
            payload: {
                groupAdmins,
            },
        };
    }
    function failureGroupAdmins() {
        return {
            type: editSeminarModalConstants.GET_ALL_GROUP_ADMINS_ERROR,
        };
    }
    function open(dispatch, seminarID) {
        dispatch({
            type: editSeminarModalConstants.OPEN_MODAL,
            payload: {
                seminarID,
            },
        });
    }
    function successSeminars(seminars) {
        return {
            type: editSeminarModalConstants.GET_SEMINARS_IN_MODAL_SUCCESS,
            payload: {
                seminars,
            },
        };
    }
}

function closeEditSeminarModal() {
    return {
        type: editSeminarModalConstants.CLOSE_MODAL,
    };
}

function changeLabTemplate(value) {
    return {
        type: editSeminarModalConstants.CHANGE_LAB_TEMPLATE,
        payload: {
            value,
        },
    };
}

function changeForm(target) {
    return {
        type: editSeminarModalConstants.CHANGE_FORM_DATA,
        payload: {
            target,
        },
    };
}

function showError(target) {
    return {
        type: editSeminarModalConstants.SHOW_ERROR,
        payload: {
            target,
        },
    };
}

function hideError() {
    return {
        type: editSeminarModalConstants.HIDE_ERROR,
    };
}

function changeDate(type, value) {
    return {
        type: editSeminarModalConstants.CHANGE_DATE,
        payload: {
            type,
            value,
        },
    };
}

function changeGroupAdmin(value) {
    return {
        type: editSeminarModalConstants.CHANGE_GROUP_ADMIN,
        payload: {
            value,
        },
    };
}

function changeFixedNetId() {
    return {
        type: editSeminarModalConstants.CHANGE_FIXED_NET_ID,
        payload: {},
    };
}

function editSeminar(page, size) {
    return (dispatch, getState) => {
        const { editSeminarModal } = getState();
        main(dispatch, editSeminarModal);
    };
    function main(dispatch, editSeminarModal) {
        dispatch(request());
        seminarService
            .edit(editSeminarModal)
            .then(() => {
                dispatch(successEditSeminar());

                dispatch(enqueueNotification('Seminar edited successfully', {variant: 'success'}));

                seminarService
                    .getAll(page, size)
                    .then(response => {
                        dispatch(successGetSeminars(response.data.content, response.data.totalElements));
                    })
                    .catch(() => {
                        dispatch(failureGetSeminars());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureEditSeminar());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: editSeminarModalConstants.REQUEST,
        };
    }
    function successEditSeminar() {
        return {
            type: editSeminarModalConstants.EDIT_SEMINAR_SUCCESS,
        };
    }
    function failureEditSeminar() {
        return {
            type: editSeminarModalConstants.EDIT_SEMINAR_ERROR,
        };
    }
    function successGetSeminars(seminars, totalElements) {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_SUCCESS,
            payload: {
                seminars,
                totalElements,
            },
        };
    }
    function failureGetSeminars() {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_ERROR,
        };
    }
}

function showDateOverflowError() {
    return {
        type: editSeminarModalConstants.SHOW_DATE_OVERFLOW_ERROR,
    };
}

function openEditSeminarModalGA(seminarID) {
    return dispatch => {
        open(dispatch, seminarID);
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        // instancesTypesManagementService
        //     .getGroupAdminInstancesTypes()
        //     .then(response => {
        //         dispatch(successLabTemplates(response.data));
        //     })
        //     .catch(error => {
        //         if (error === errorRequest.UNAUTHORIZED) {
        //             userService
        //                 .refreshToken()
        //                 .then(() => main(dispatch));
        //         } else {
        //             dispatch(failureLabTemplates());
        //         }
        //     });
        seminarService
            .getEditSeminarDataGA(seminarID)
            .then(response => {
                dispatch(successSeminars(response.data));
            })
            .then(() => {
                userManagementService
                    .getGroupAdminsGA()
                    .then(response => {
                        dispatch(successGroupAdmins(response.data));
                    })
                    .catch(error => {
                        if (error === errorRequest.UNAUTHORIZED) {
                            userService.refreshToken().then(() => main(dispatch));
                        } else {
                            dispatch(failureGroupAdmins());
                        }
                    });
            });
    }
    function request() {
        return {
            type: addSeminarModalConstants.REQUEST,
        };
    }
    // function successLabTemplates(instancesTypes) {
    //     return {
    //         type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_SUCCESS,
    //         payload: {
    //             instancesTypes
    //         }
    //     }
    // }
    // function failureLabTemplates() {
    //     return {
    //         type: dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_ERROR,
    //     }
    // }
    function successGroupAdmins(groupAdmins) {
        // console.log(groupAdmins);
        return {
            type: editSeminarModalConstants.GET_ALL_GROUP_ADMINS_SUCCESS,
            payload: {
                groupAdmins,
            },
        };
    }
    function failureGroupAdmins() {
        return {
            type: editSeminarModalConstants.GET_ALL_GROUP_ADMINS_ERROR,
        };
    }
    function open(dispatch, seminarID) {
        dispatch({
            type: editSeminarModalConstants.OPEN_MODAL,
            payload: {
                seminarID,
            },
        });
    }
    function successSeminars(seminars) {
        return {
            type: editSeminarModalConstants.GET_SEMINARS_IN_MODAL_SUCCESS,
            payload: {
                seminars,
            },
        };
    }
}

function editSeminarGroupAdmin(page, size) {
    return (dispatch, getState) => {
        const { editSeminarModal } = getState();
        main(dispatch, editSeminarModal);
    };
    function main(dispatch, editSeminarModal) {
        dispatch(request());
        seminarService
            .editSeminarGroupAdmin(editSeminarModal)
            .then(() => {
                dispatch(successEditSeminar());

                dispatch(enqueueNotification('Seminar edited successfully', {variant: 'success'}));

                seminarService
                    .getAllSeminarGA(page, size)
                    .then(response => {
                        dispatch(successGetSeminars(response.data.content, response.data.totalElements));
                    })
                    .catch(() => {
                        dispatch(failureGetSeminars());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureEditSeminar());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: editSeminarModalConstants.REQUEST,
        };
    }
    function successEditSeminar() {
        return {
            type: editSeminarModalConstants.EDIT_SEMINAR_SUCCESS,
        };
    }
    function failureEditSeminar() {
        return {
            type: editSeminarModalConstants.EDIT_SEMINAR_ERROR,
        };
    }
    function successGetSeminars(seminars, totalElements) {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_SUCCESS,
            payload: {
                seminars,
                totalElements,
            },
        };
    }
    function failureGetSeminars() {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_ERROR,
        };
    }
}
