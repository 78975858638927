import { errorRequest } from '../Constants';
import Auth from '../Modules/auth.module';

export const groupErrorRequests = (requestType, error) => {
    if (error.response) {
        const errorRes = error.response;

        switch (requestType) {
            case 'delete group': {
                switch (errorRes.status) {
                    case 400: {
                        return `Error to delete group, ${error.response.data.cause}.`;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }

            case 'check group':
            case 'set template version':
            case 'create group':
            case 'get all groups': {
                switch (errorRes.status) {
                    case 400: {
                        return error.response.data.cause || `Error to ${requestType}`;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'update group fixed net id': {
                switch (errorRes.status) {
                    case 400: {
                        return `Error to ${requestType}`;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'update group': {
                switch (errorRes.status) {
                    case 400: {
                        return error.response.data.cause;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            default: {
                switch (errorRes.status) {
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
        }
    } else {
        Auth.deauthenticateUser();
        return errorRequest.DEFAULT;
    }
};
