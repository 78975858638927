import { errorRequest, urlConstant } from '../Constants';
import { labErrorRequests } from '../Handlers';
import { userService } from '../Services';
import Maintenance from '../Modules/maintenance.module';
import {AxiosRequest} from "./AxiosApi";

const isRepair = Maintenance.getMaintenance();

export const singleLabManagementService = {
    createByAdmin,
    startByAdmin,
    stopByAdmin,
    deleteByAdmin,
    recreateByAdmin,
    addForUser,
    startByUser,
    stopByUser,
    recreateByUser,
    resetTimer,
    resetUserTimer,
    createByGroupAdmin,
    startByGroupAdmin,
    stopByGroupAdmin,
    deleteByGroupAdmin,
    recreateLabByGroupAdmin,
    recreateSeminarLabByGroupAdmin,
    addForUserGroupAdmin,
    resetTimerGroupAdmin,
    createByAdminSeminarLab,
    startByAdminSeminarLab,
    stopByAdminSeminarLab,
    deleteByAdminSeminarLab,
    recreateByAdminSeminarLab,
    stopByUserSeminarLab,
    startByUserSeminarLab,
    recreateByUserSeminarLab,
    createByGroupAdminSeminarLab,
    startByGroupAdminSeminarLab,
    stopByGroupAdminSeminarLab,
    deleteByGroupAdminSeminarLab,
    setLabLimitation,
    createLabTemplateInstance
};

function createByAdmin(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/labs/create`,
            data: {
                labIds: labId,
                deleteFromSystem: false,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to create single lab');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('create lab by admin', error);

                if (errorMessage === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken(() => createByAdmin(labId));
                }

                console.error(errorMessage, error);
                reject(error);
            });
    });
}

function startByAdmin(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/labs/start`,
            data: {
                labIds: labId,
                deleteFromSystem: false,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to start single lab');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('create lab by admin', error);

                if (errorMessage === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken(() => startByAdmin(labId));
                }

                console.error(errorMessage, error);
                reject(error);
            });
    });
}

function stopByAdmin(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/labs/stop`,
            data: {
                labIds: labId,
                deleteFromSystem: false,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to stop single lab');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('create lab by admin', error);

                if (errorMessage === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken(() => stopByAdmin(labId));
                }

                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function deleteByAdmin(labId, deleteFromSystem) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/admin/labs/delete`,
            data: {
                labIds: labId,
                deleteFromSystem,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to delete single lab');
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('create lab by admin', error);
                //
                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}

function recreateByAdmin(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/labs/recreate`,
            data: {
                labIds: labId,
                deleteFromSystem: false,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to recreate single lab');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('recreate lab by admin', error);

                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }

                console.error('recreateByAdmin service error:', errorMessage);
                reject(errorMessage);
            });
    });
}

function addForUser(userID, groupID, instanceTypeID) {
    const body = {
        userId: userID,
        groupId: groupID,
        labTypeId: instanceTypeID,
    };


    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/users/addLab`,
            data: body,
        })
            .then(response => {
                isRepair && console.log('Add new lab for user successful', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('add lab for user', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function startByUser(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/user/start`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to start single lab by user');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('start lab by user', error);

                if (errorMessage === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken(() => startByUser(labId));
                }

                console.error(errorMessage, error);
                reject(error);
            });
    });
}

function stopByUser(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/user/stop`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to stop single lab by user');
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('create lab by admin', error);
                //
                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}

function recreateByUser(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/user/recreate`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to recreate single lab by user');
                resolve(response);
                // history.goBack();
            })
            .catch(error => {
                const errorMessage = labErrorRequests('recreate lab by admin', error);

                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }

                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function resetTimer(labID, labType = 'labs') {

    return new Promise((resolve, reject) => {
        let url = `${urlConstant}/admin/labs/refresh/${labID}`;
        if (labType === 'seminar') url = `${urlConstant}/admin/labs/${labType}/refresh/${labID}`;

        AxiosRequest({
            method: 'put',
            url: url,
        })
            .then(response => {
                isRepair && console.log('Reset timer successful', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('reset timer', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}
function resetTimerGroupAdmin(labID, labType = 'labs') {

    return new Promise((resolve, reject) => {
        let url = `${urlConstant}/groupAdmin/refresh/${labID}`;
        if (labType === 'seminar') url = `${urlConstant}/groupAdmin/seminar/refresh/${labID}`;
        AxiosRequest({
            method: 'put',
            url: url,
        })
            .then(response => {
                isRepair && console.log('Reset timer group admin successful', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('reset timer', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function resetUserTimer(labID) {

    return new Promise((resolve, reject) => {
        // const url = `${urlConstant}${Auth.getUserRole() === 'ROLE_USER' ? '/user' : '/admin'}/instance/${labID}/prolong-time`;
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/user/refresh/${labID}`,
        })
            .then(response => {
                isRepair && console.log('Reset timer successful', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('reset timer', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function createByGroupAdmin(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/labs/create`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to create single lab');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('create lab by admin', error);

                if (errorMessage === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken(() => createByGroupAdmin(labId));
                }

                console.error(errorMessage, error);
                reject(error);
            });
    });
}

function startByGroupAdmin(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/labs/start`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to start single lab');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('create lab by admin', error);

                if (errorMessage === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken(() => startByGroupAdmin(labId));
                }

                console.error(errorMessage, error);
                reject(error);
            });
    });
}

function stopByGroupAdmin(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/labs/stop`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to stop single lab');
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('create lab by admin', error);
                //
                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}

function deleteByGroupAdmin(labId, deleteFromSystem) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/groupAdmin/labs/delete`,
            data: {
                labIds: labId,
                deleteFromSystem,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to delete single lab');
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('create lab by admin', error);
                //
                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}

function recreateLabByGroupAdmin(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/groupAdmin/labs/recreate`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to recreate single lab by GA');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('recreate lab by admin', error);

                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }

                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function recreateSeminarLabByGroupAdmin(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/groupAdmin/seminar/labs/recreate`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to recreate seminar lab by GA');
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('create lab by admin', error);
                //
                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}

function addForUserGroupAdmin(userID, groupID, instanceTypeID) {
    const body = {
        userId: userID,
        groupId: groupID,
        labTypeId: instanceTypeID,
    };


    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/groupAdmin/add`,
            data: body,
        })
            .then(response => {
                isRepair && console.log('Add new lab for user successful', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('add lab for user', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function createByAdminSeminarLab(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/labs/seminar/create`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to create single lab');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('create lab by admin', error);

                if (errorMessage === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken(() => createByAdmin(labId));
                }

                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function startByAdminSeminarLab(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/labs/seminar/start`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to start single lab');
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('create lab by admin', error);
                //
                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}

function stopByAdminSeminarLab(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/labs/seminar/stop`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to stop single lab');
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('create lab by admin', error);
                //
                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}

function deleteByAdminSeminarLab(labId, deleteFromSystem) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/admin/labs/seminar/delete`,
            data: {
                labIds: labId,
                deleteFromSystem,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to delete single lab');
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('create lab by admin', error);
                //
                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}

function recreateByAdminSeminarLab(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/labs/seminar/recreate`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to delete single lab');
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('create lab by admin', error);
                //
                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}

function stopByUserSeminarLab(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/seminar/user/stop`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to stop single lab');
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('create lab by admin', error);
                //
                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}

function startByUserSeminarLab(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/seminar/user/start`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to start single lab');
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('create lab by admin', error);
                //
                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}

function recreateByUserSeminarLab(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/seminar/user/recreate`,

            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to recreate single lab');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('recreate lab by admin', error);

                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }

                console.error('recreateByAdmin service error:', errorMessage);
                reject(errorMessage);
            });
    });
}

function createByGroupAdminSeminarLab(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/seminar/labs/create`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to create single lab');
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('create lab by admin', error);

                if (errorMessage === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken(() => createByAdmin(labId));
                }

                console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function startByGroupAdminSeminarLab(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/seminar/labs/start`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to start single lab');
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('create lab by admin', error);
                //
                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}

function stopByGroupAdminSeminarLab(labId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/seminar/labs/stop`,
            data: {
                labIds: labId,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to stop single lab');
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('create lab by admin', error);
                //
                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}

function deleteByGroupAdminSeminarLab(labId, deleteFromSystem) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/groupAdmin/seminar/labs/delete`,
            data: {
                labIds: labId,
                deleteFromSystem,
            },
        })
            .then(response => {
                isRepair && console.log('Sent request to delete single lab');
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('create lab by admin', error);
                //
                // if (errorMessage === errorRequest.UNAUTHORIZED) {
                //     userService.refreshToken(() => createByAdmin(userId, instanceId));
                // }
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}

function setLabLimitation(userId, groupId, labId, limitationMinutes) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/limitation`,
            data: {
                userId,
                groupId,
                labId,
                limitationMinutes,
                level: 'FIRST',
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = labErrorRequests('set lab limitation', error);
                reject(error);
            });
    });
}

function createLabTemplateInstance(templateId, versionId) {


    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/templates/${templateId}/labs/${versionId}`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('create lab by admin', error);

                console.error(errorMessage, error);
                reject(error);
            });
    })
}