import { editSeminarModalConstants } from '../../Constants';
import { groupsUtil, instancesTypesUtil, reducersUtil, store, seminarUtil } from '../../Utils';
import _ from "lodash";

const initialState = {
    id: '',
    name: '',
    password: '',
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    status: false,
    fixedNetId: false,
    selectedGroupAdmins: [],
    groupAdmins: [],
    groupAdminNames: [''],
    groupAdminIDs: [],
    subscriptionName: '',
    labTemplateIDs: [],
    labTemplateNames: [''],
    maxUsers: '',
    labTimeOut: '',
    requestIsActive: false,
    modalIsOpen: false,
    errors: {
        errorName: '',
        errorPassword: '',
        errorMaxUsers: '',
        errorLabTimeout: '',
        errorTime: '',
    },
};

export function editSeminarModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case editSeminarModalConstants.OPEN_MODAL:
            const { seminarID } = action.payload;
            // const {seminar: {seminars}} = store.getState();
            // const seminar = seminarUtil.getSeminarByID(seminars, seminarID);
            // const convertedSeminar = seminarUtil.convertGetData(seminar);

            return {
                ...state,
                // ...convertedSeminar,
                id: seminarID,
                modalIsOpen: true,
            };
        case editSeminarModalConstants.CLOSE_MODAL:
            return {
                ...initialState,
                modalIsOpen: false,
            };

        case editSeminarModalConstants.GET_SEMINARS_IN_MODAL_SUCCESS:
            const seminars = action.payload.seminars;
            // const seminar = seminarUtil.getSeminarByID(seminars, state.id);
            const convertedSeminar = seminarUtil.convertGetData(seminars);

            return {
                ...state,
                ...convertedSeminar,
            };

        /* ====== GET GROUP ADMINS ====== */
        case editSeminarModalConstants.GET_ALL_GROUP_ADMINS_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case editSeminarModalConstants.REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case editSeminarModalConstants.GET_ALL_GROUP_ADMINS_SUCCESS: {
            const { groupAdminIDs } = state;
            const { groupAdmins } = action.payload;
            const { selectedGroupAdmins } = state;
            const groupAdminNames = seminarUtil.getGroupAdminNames(selectedGroupAdmins, groupAdminIDs);
            // console.log('groupAdminIDs', groupAdminIDs);
            // console.log('groupAdmins', groupAdmins);
            // console.log('selectedGroupAdmins', selectedGroupAdmins);
            return {
                ...state,
                requestIsActive: false,
                groupAdmins,
                groupAdminNames,
            };
        }

        /* ====== CHANGE FORM DATA ======= */
        case editSeminarModalConstants.CHANGE_LAB_TEMPLATE: {
            const { value } = action.payload;
            const {
                instancesTypesManagement: { instancesTypes },
            } = store.getState();
            const instanceTypeID = instancesTypesUtil.getInstancesTypeID(instancesTypes, value);
            const instancesTypesNames = instancesTypesUtil.filterInstancesTypesNames(value);

            return {
                ...state,
                labTemplateIDs: instanceTypeID,
                labTemplateNames: instancesTypesNames,
            };
        }
        case editSeminarModalConstants.CHANGE_GROUP_ADMIN: {
            const { value } = action.payload;
            const groupAdminIDs = groupsUtil.getGroupAdminID(state.groupAdmins, value);
            const groupAdminNames = groupsUtil.filterGroupAdminNames(value);

            return {
                ...state,
                groupAdminIDs,
                groupAdminNames,
            };
        }
        case editSeminarModalConstants.CHANGE_FORM_DATA: {
            const { value, name } = action.payload.target;
            let parsedValue = value;
            if (name === 'labTimeOut' || name === 'maxUsers') {
                parsedValue = value.replace(/[^0-9]+/gi, '');
            }
            return {
                ...state,
                password: '',
                [name]: parsedValue,
            };
        }
        case editSeminarModalConstants.CHANGE_DATE: {
            const { type, value } = action.payload;
            return {
                ...state,
                [type]: value,
            };
        }
        case editSeminarModalConstants.CHANGE_FIXED_NET_ID: {
            return {
                ...state,
                fixedNetId: !state.fixedNetId,
            };
        }

        /* ====== ERROR MESSAGES ====== */
        case editSeminarModalConstants.SHOW_ERROR: {
            const { target } = action.payload;
            console.log('Error target', target);
            const { errorName, errorPassword, errorMaxUsers, errorLabTimeout, errorTime } = reducersUtil.addEditSeminarError(
                target,
                state,
            );
            return {
                ...state,
                errors: {
                    ...state.errors,
                    errorName: errorName ? errorName : state.errors.errorName,
                    errorPassword: errorPassword ? errorPassword : state.errors.errorPassword,
                    errorMaxUsers: errorMaxUsers ? errorMaxUsers : state.errors.errorMaxUsers,
                    errorLabTimeout: errorLabTimeout ? errorLabTimeout : state.errors.errorLabTimeout,
                    errorTime: errorTime ? errorTime : state.errors.errorTime,
                },
            };
        }
        case editSeminarModalConstants.HIDE_ERROR:
            return {
                ...state,
                errors: {
                    ...initialState.errors,
                },
            };
        case editSeminarModalConstants.EDIT_SEMINAR_SUCCESS:
            return initialState;
        case editSeminarModalConstants.EDIT_SEMINAR_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case editSeminarModalConstants.SHOW_DATE_OVERFLOW_ERROR: {
            const { errorTime } = reducersUtil.isDateOverflow(state);
            return {
                ...state,
                errors: {
                    ...state.errors,
                    errorTime: errorTime ? errorTime : state.errors.errorTime,
                },
            };
        }
        default:
            return state;
    }
}
