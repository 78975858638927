import axios from 'axios';
import _ from 'lodash';
import Auth from "../Modules/auth.module";

// Main request function
export function AxiosRequest({method = 'get', url = '/', params = {}, data = null, headers = null, additionalConfigOptions = {}}) {
    let requestConfig = {
        headers: {
            Authorization: `Bearer ${Auth.getToken()}`
        }
    };
    requestConfig.method = _.toLower(method);
    requestConfig.url = url;
    requestConfig.params = _.isPlainObject(params) ? params : {};
    requestConfig.data = data;

    _.assign(requestConfig.headers, _.isPlainObject(headers) ? headers : null);
    _.assign(requestConfig, additionalConfigOptions);

    return new Promise((resolve, reject) => {
        axios.request(requestConfig)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                if (typeof axios.isCancel === 'function' && axios.isCancel(error)) {
                    console.log(error.message);
                }
                else {
                    mainErrorHandler(error);
                    reject(error);
                }
            });
    })
}

function mainErrorHandler(error) {
    console.log(error);
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('Error.response.headers: ', error.response.headers);
        console.log('Error.response.data: ', error.response.data);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log('The request was made but no response was received', error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
}