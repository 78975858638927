// const urlConstant = 'http://192.168.1.61:8080/api';
const urlConstant = process.env.REACT_APP_BASE_URL;
const maxJsonFileUploadSize = process.env.REACT_APP_MAX_JSON_FILE_UPLOAD_SIZE;
const sitekey = process.env.REACT_APP_RECAPTCHA_SITEKEY;

const dateFormat = 'DD.MM.YY';
const declinedMessageDateFormat = 'YYYY-MM-DD HH:mm:ss';
const failChangeBlueprintName = 'Please select different template name';
const generalDatetimeFormat = 'DD.MM.YY HH:mm';
const recreateLabMessage = 'Are you sure you want to recreate lab? The draft version for this template may have been changed.';

const errorRequest = {
    UNAUTHORIZED: 'Token expired',
    DEFAULT: 'Service or network error',
};

const labStatuses = {
    PENDING: 'READY_TO_CREATE',
    STOPPED: 'STOPPED',
    STARTED: 'RUNNING',
    STOPPING_FAILED: 'STOPPING_FAILED',
    DELETING_FAILED: 'DELETING_FAILED',
    STARTING_FAILED: 'STARTING_FAILED',
    CREATING_FAILED: 'CREATING_FAILED',
    DELETING: 'DELETING',
    STOPPING: 'STOPPING'
};

const statusList = [
    labStatuses.CREATING_FAILED,
    labStatuses.STARTING_FAILED,
    labStatuses.DELETING_FAILED,
    labStatuses.STOPPING_FAILED,
    labStatuses.PENDING,
    labStatuses.STARTED,
    labStatuses.STOPPED,
    labStatuses.DELETING,
    labStatuses.STOPPING
];

const seminarStates = {
    STOPPED: 'Stopped',
    PENDING: 'Pending',
    RUNNING: 'Running',
    FINISHED: 'Finished',
    STOPPING: 'Stopping',
    STARTING: 'Starting',
    CREATING: 'Creating',
};

const RESET_APP = 'RESET_APP';
const REFRESH_TOKEN = 'REFRESH_TOKEN';

const templateStates = {
    'DRAFT': 'DRAFT',
    'VERIFYING': 'VALIDATING',
    'DECLINED': 'INVALID',
    'COMMITTED': 'COMMITTED',
    'VALIDATED': 'COMMITTED'
};

const groupTypes = {
    VALIDATION: 'VALIDATION',
    REGULAR: 'REGULAR'
}

const columnFilterTypes = {
    TEXT: 'TEXT',
    LIST: 'LIST'
};

const timerTypes = {
    DAYS: "DAYS",
    HOURS: "HOURS"
}

export {
    REFRESH_TOKEN,
    statusList,
    groupTypes,
    templateStates,
    errorRequest,
    urlConstant,
    dateFormat,
    labStatuses,
    seminarStates,
    declinedMessageDateFormat,
    failChangeBlueprintName,
    generalDatetimeFormat,
    maxJsonFileUploadSize,
    sitekey,
    RESET_APP,
    recreateLabMessage,
    columnFilterTypes,
    timerTypes
};