import { combineReducers } from 'redux';
import { dataListsManagementConstants } from '../../Constants';

const content = (state = [], { type, payload }) => {
    switch (type) {
        case dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_SUCCESS:
            return payload.content;

        // case webSocketConstants.SET_DATA:
        //     switch (payload.type) {
        //         case webSocketConstants.BLUEPRINT_STATE:
        //             return state.map((bp) => (bp.id === payload.id ? {...bp, state: payload.state} : bp));
        //
        //         default:
        //             return state;
        //     }

        default:
            return state;
    }
};

const pagination = (state = { page: 0, size: 20, totalElements: 0, first: true, last: true }, { type, payload }) => {
    switch (type) {
        case dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_SUCCESS:
            return {
                ...state,
                totalElements: payload.totalElements,
                last: payload.last,
                first: payload.first,
            };
        case dataListsManagementConstants.CHANGE_LAB_TEMPLATE_PAGE:
            return { ...state, page: payload };
        case dataListsManagementConstants.CHANGE_LAB_TEMPLATE_PAGE_SIZE:
            return { ...state, size: payload };
        default:
            return state;
    }
};

const isRequestActive = (state = false, { type, payload }) => {
    switch (type) {
        case dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_REQUEST:
            return true;
        case dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_SUCCESS:
        case dataListsManagementConstants.GET_ALL_INSTANCES_TYPES_ERROR:
            return false;
        default:
            return state;
    }
};

const search = (state = '', {type, payload}) => {
    switch (type) {
        case dataListsManagementConstants.CHANGE_LAB_TEMPLATE_SEARCH:
            return payload;

        default:
            return state;
    }
};

const needToSearch = (state = false, {type}) => {
    switch (type) {
        case dataListsManagementConstants.PERFORM_INSTANCES_TYPES_SEARCH:
            return true;

        case dataListsManagementConstants.DISABLE_INSTANCES_TYPES_SEARCH:
            return false;

        default:
            return state;
    }
}

const shared = (state = null, action) => {
    switch (action.type) {
        case dataListsManagementConstants.SET_SHARED_FILTER:
            return action.payload;

        default:
            return state;
    }
}

export default combineReducers({ content, pagination, isRequestActive, search, needToSearch, shared});
