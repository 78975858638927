import {notifierConstants} from "../Constants/Actions/notifier.constants";

export const notifierActions = {
    enqueueNotification,
    clearEnqueueNotification
};

function enqueueNotification(message, options) {
    if (message) {
        return {
            type: notifierConstants.ENQUEUE_NOTIFICATION,
            payload: {
                message,
                options: options || {},
                enqueue: true
            }
        }
    }
}

function clearEnqueueNotification() {
    return {
        type: notifierConstants.CLEAR_ENQUEUE_NOTIFICATION,
        payload: {
            enqueue: false
        }
    }
}