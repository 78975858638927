import {dataListsManagementConstants, labDetailsConstants, seminarLabsConstants} from '../../Constants';
import { reducersUtil } from '../../Utils';
import _ from "lodash";

const initialState = {
    labInfo: {},
    requestIsActive: false,
    needToGoBack: false,
    hideButtons: {
        isHide: false,
        instanceName: '',
        vm_id: [],
    },
    disabledRestartButton: '',
    thumbnail: false,
    thumbnail_url: '',
    active_vm: '',
    newUrl: [],
    currentPage: 0,
    currentSeminarPage: 0
    // urlArray: [],
};

export function labDetails(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case labDetailsConstants.INSTANCE_RESET_SUCCESS:
            return {
                ...state,
                labInfo: reducersUtil.resetInstanceReducerUtil(state.labInfo, action.payload.instance),
            };

        case labDetailsConstants.SAVE_LAB_DETAILS_INFO:
            return {
                ...state,
                labInfo: action.payload.labInfo,
                needToGoBack: false,
            };
        case labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_REQUEST:
            return {
                ...state,
                requestIsActive: true,
                needToGoBack: false,
            };
        case labDetailsConstants.RESTART_INSTANCE_BY_ADMIN_REQUEST:
            return {
                ...state,
                disabledRestartButton: action.payload.instanceId,
            };
        case labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_SUCCESS:
            return {
                ...state,
                labInfo: JSON.parse(JSON.stringify(action.payload.labInfo)),
                // labInfo: action.payload.labInfo,
                thumbnail: state.thumbnail,
                thumbnail_url: reducersUtil.findActiveThumbnail(action.payload.labInfo, state.active_vm),
                active_vm: state.active_vm,
                hideButtons: {
                    isHide: false,
                    instanceName: '',
                    // vm_id: [...state.hideButtons.vm_id]
                    vm_id: [],
                },
                disabledRestartButton: '',

                // urlArray: action.payload.labInfo.instanceEntityList.map(item => {
                //     return JSON.parse(JSON.stringify(item.pathFullSizeImage))
                // })
            };
        case labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_ERROR:
            return {
                ...state,
                labInfo: {},
                requestIsActive: false,
                needToGoBack: true,
            };
        case labDetailsConstants.HIDE_BUTTONS:
            return {
                ...state,
                hideButtons: {
                    isHide: true,
                    instanceName: action.payload.instanceName,
                    vm_id: [...state.hideButtons.vm_id, action.payload.vm_id],
                },
                thumbnail: false,
                thumbnail_url: '',
                active_vm: '',
            };
        case labDetailsConstants.SHOW_THUMBNAIL:
            if (state.thumbnail === false) {
                return {
                    ...state,
                    thumbnail: true,
                    thumbnail_url: action.payload.url,
                    active_vm: action.payload.id,
                };
            } else if (state.active_vm === action.payload.id) {
                return {
                    ...state,
                    thumbnail: false,
                    thumbnail_url: '',
                    active_vm: '',
                };
            } else {
                return {
                    ...state,
                    thumbnail_url: action.payload.url,
                    active_vm: action.payload.id,
                };
            }
        // return {
        //     ...state,
        //     thumbnail: !state.thumbnail,
        //     thumbnail_url: action.payload.url,
        //     active_vm: action.payload.id,
        // };
        case labDetailsConstants.HIDE_THUMBNAIL:
            return {
                ...state,
                thumbnail: false,
                thumbnail_url: '',
                active_vm: '',
            };
        case labDetailsConstants.SET_HIDE_BUTTONS:
            return {
                ...state,
                hideButtons: {
                    ...state.hideButtons,
                    vm_id: action.payload.vm_id,
                },
            };
        case labDetailsConstants.SET_IMAGE_URL_SUCCESS:
            return {
                ...state,
                newUrl: [...state.newUrl, action.payload.newUrl],
            };

        case labDetailsConstants.CLEAN_INSTANCE:
            return _.cloneDeep(initialState);

        case dataListsManagementConstants.SAVE_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload.value
            }

        case seminarLabsConstants.SAVE_CURRENT_SEMINAR_PAGE:
            return {
                ...state,
                currentSeminarPage: action.payload.value
            }

        default:
            return state;
    }
}
