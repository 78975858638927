import { dataListsManagementConstants, modalConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    groups: [],
    groupsDropdown: [],
    myGroups: [],
    requestIsActive: false,
    needToSearch: false,
    deleteUsersStatusModal: {
        dataList: [],
        isOpen: false,
        groupID: '',
        usersId: [],
    },
    deleteGroupModal: {
        isOpen: false,
        text: '',
        method: '',
        groupName: '',
    },
    updateGroupNotificationModal: {
        isOpen: false,
        text: '',
        method: '',
        info: '',
    },
    size: 20,
    sizeList: [10, 20, 30],
    page: 0,
    total: 3,
    totalElements: 0,
    search: '',
};

export function groupsManagement(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case dataListsManagementConstants.GET_ALL_GROUPS_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case dataListsManagementConstants.GET_ALL_GROUPS_SUCCESS:
            return {
                ...state,
                groups: action.payload.groups.content,
                requestIsActive: false,
                needToSearch: false,
                size: action.payload.groups.size,
                totalElements: action.payload.groups.totalElements,
            };
        case dataListsManagementConstants.GET_ALL_GROUPS_ERROR:
            return {
                ...state,
                requestIsActive: false,
                needToSearch: false,
            };
        case dataListsManagementConstants.GET_ALL_GROUPS_DROPDOWN_SUCCESS:
            return {
                ...state,
                groupsDropdown: action.payload.groups,
            };
        case modalConstants.DELETE_GROUP_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                deleteGroupModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    groupName: action.payload.groupName,
                },
            };
        case modalConstants.UPDATE_GROUP_SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                updateGroupNotificationModal: {
                    isOpen: true,
                    text: action.payload.text,
                    method: action.payload.method,
                    info: action.payload.info,
                },
            };
        case modalConstants.CLOSE_NOTIFICATION_MODAL:
            return {
                ...state,
                deleteGroupModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    userID: '',
                },
                deleteUsersStatusModal: {
                    dataList: [],
                    isOpen: false,
                    groupID: '',
                    usersId: [],
                },
                updateGroupNotificationModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    groupName: '',
                },
            };
        case modalConstants.DELETE_GROUP_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };

        case modalConstants.DELETE_GROUP_SUCCESS:
        case modalConstants.DELETE_GROUP_ERROR:
        case modalConstants.UPDATE_GROUP_SUCCESS:
        case modalConstants.UPDATE_GROUP_ERROR:
            return {
                ...state,
                requestIsActive: false,
                needToSearch: false,
                deleteGroupModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    userID: '',
                },
                updateGroupNotificationModal: {
                    isOpen: false,
                    text: '',
                    method: '',
                    groupName: '',
                },
            };
        case modalConstants.DELETE_USERS_STATUS_SUCCESS:
            return {
                ...state,
                deleteUsersStatusModal: {
                    dataList: action.payload.successUserDelete,
                    isOpen: true,
                    groupID: action.payload.groupID,
                    usersId: action.payload.successUserDelete.map(item => item.id),
                },
            };
        case modalConstants.DELETE_USERS_SUCCESS:
            return {
                ...state,
                deleteUsersStatusModal: {
                    dataList: [],
                    isOpen: false,
                    groupID: '',
                },
            };
        case dataListsManagementConstants.GET_USER_INFO_SUCCESS:
            return {
                ...state,
                myGroups: action.payload.data.groupList,
            };
        case dataListsManagementConstants.CLEAN_GROUPS_REDUCERS:
            return initialState;
        case dataListsManagementConstants.CHANGE_GROUPS_PAGE:
            return {
                ...state,
                page: action.payload.value,
            };
        case dataListsManagementConstants.CHANGE_GROUPS_SIZE:
            return {
                ...state,
                size: action.payload.value,
                page: 0,
            };
        case dataListsManagementConstants.CHANGE_GROUPS_SEARCH:
            return {
                ...state,
                search: action.payload.value,
            };
        case dataListsManagementConstants.PERFORM_GROUPS_SEARCH:
            return {
                ...state,
                needToSearch: true,
            };
        default:
            return state;
    }
}
