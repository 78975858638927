import { modalConstants } from '../../Constants';
import { reducersUtil, transformUtil } from '../../Utils';
import _ from "lodash";

const initialState = {
    info: {
        id: '',
        groupName: '',
        oldGroupName: '',
        fixedNetID: false,
        oldFixedNetID: false,
        defaultTimeToStopLab: '',
        defaultTimeToDeleteLab: '',
        timeToStopLab: '',
        timeToDeleteLab: '',
        updating: true,
        limitation: '',
        oldLimitation: '',
    },
    warningMessagesStuff: {
        errorMessage: '',
    },
    requestIsActive: false,
    updateGroupModalIsOpen: false,
};

export function updateGroupModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.TOGGLE_UPDATE_GROUP_MODAL: {
            if (state.updateGroupModalIsOpen) {
                return initialState;
            } else {
                return {
                    ...state,
                    updateGroupModalIsOpen: true,
                    info: {
                        id: action.payload.id,
                        groupName: action.payload.groupName,
                        oldGroupName: action.payload.groupName,
                        fixedNetID: action.payload.fixedNetID,
                        oldFixedNetID: action.payload.fixedNetID,
                        // defaultTimeToStopLab: transformUtil.getInterval(action.payload.timeToStopLab),
                        defaultTimeToStopLab: transformUtil.getHours(action.payload.timeToStopLab),
                        // defaultTimeToDeleteLab: transformUtil.getInterval(action.payload.timeToDeleteLab),
                        defaultTimeToDeleteLab: transformUtil.getDaysFromMinutes(action.payload.timeToDeleteLab),
                        // timeToStopLab: transformUtil.getInterval(action.payload.timeToStopLab),
                        timeToStopLab: transformUtil.getHours(action.payload.timeToStopLab),
                        // timeToDeleteLab: transformUtil.getInterval(action.payload.timeToDeleteLab),
                        timeToDeleteLab: transformUtil.getDaysFromMinutes(action.payload.timeToDeleteLab),
                        updating: true,
                        limitation: transformUtil.getHours(action.payload.limitation),
                        oldLimitation: transformUtil.getHours(action.payload.limitation),
                        type: action.payload.type
                    },
                };
            }
        }
        case modalConstants.CHECK_GROUP_SUCCESS:
            return {
                ...initialState,
                info: {
                    ...state.info,
                    updating: action.payload.info.updating,
                },
                requestIsActive: false,
            };
        case modalConstants.CHECK_GROUP_REQUEST:
        case modalConstants.UPDATE_GROUP_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case modalConstants.UPDATE_GROUP_SUCCESS:
            return {
                ...initialState,
            };
        case modalConstants.CHECK_GROUP_ERROR:
        case modalConstants.UPDATE_GROUP_ERROR:
            return {
                ...state,
                warningMessagesStuff: {
                    errorMessage: '',
                },
                requestIsActive: false,
            };
        case modalConstants.UPDATE_GROUP_CHANGE_FORM:
            const { name, value } = action.payload.event.target;
            let parsedValue = value;
            if (name === 'limitation' || name === 'timeToStopLab' || name === 'timeToDeleteLab') {
                parsedValue = value.replace(/[^0-9.]+/gi, '');
            }
            return {
                ...state,
                info: {
                    ...state.info,
                    [name]: parsedValue,
                },
            };
        case modalConstants.UPDATE_GROUP_MODAL_EMPTY_FIELDS:
            return {
                ...state,
                warningMessagesStuff: {
                    errorMessage: action.payload.errorMessage,
                },
            };
        case modalConstants.UPDATE_GROUP_SHOW_ERROR_MESSAGE: {
            const { value, name } = action.payload.event.target;
            return {
                ...state,
                warningMessagesStuff: {
                    ...state.warningMessagesStuff,
                    errorMessage: reducersUtil.generateGroupManagementErrorMessage(value, name),
                },
            };
        }
        case modalConstants.UPDATE_GROUP_HIDE_ERROR_MESSAGE:
            return {
                ...state,
                warningMessagesStuff: {
                    ...state.warningMessagesStuff,
                    errorMessage: '',
                },
            };
        case modalConstants.UPDATE_GROUP_MODAL_CHANGE_FIXED_ID:
            return {
                ...state,
                info: {
                    ...state.info,
                    fixedNetID: !state.info.fixedNetID,
                },
            };
        default:
            return state;
    }
}
