export const instancesTypesUtil = {
    getInstanceTypeID,
    getInstanceTypePath,
    getInstancesTypeID,
    getInstancesTypeName,
    filterInstancesTypesNames,
    checkInstanceName
};

function getInstanceTypeID(instancesTypes, instanceTypeName) {
    let instanceTypeID;
    for (let i = 0; i < instancesTypes.length; i++) {
        if (instancesTypes[i].name === instanceTypeName) {
            instanceTypeID = instancesTypes[i].id;
            break;
        }
    }
    return instanceTypeID;
}

function getInstanceTypePath(instancesTypes, instanceTypeName) {
    let instanceTypePath;
    for (let i = 0; i < instancesTypes.length; i++) {
        if (instancesTypes[i].name === instanceTypeName) {
            instanceTypePath = instancesTypes[i].path;
            break;
        }
    }
    return instanceTypePath;
}

function getInstancesTypeID(instancesTypes, instanceTypeName) {
    let instancesTypeID = [];
    for (let i = 0; i < instancesTypes.length; i++) {
        for (let j = 0; j < instanceTypeName.length; j++) {
            if (instancesTypes[i].name === instanceTypeName[j]) {
                instancesTypeID.push(instancesTypes[i].id);
                break;
            }
        }
    }
    return instancesTypeID;
}

function getInstancesTypeName(instancesTypes, instanceTypeName) {
    let fileName;
    for (let i = 0; i < instancesTypes.length; i++) {
        if (instancesTypes[i].name === instanceTypeName) {
            fileName = instancesTypes[i].fileName;
            break;
        }
    }
    return fileName;
}

function filterInstancesTypesNames(array) {
    let result = [];
    array.forEach(item => {
        if (!(array.length > 1 && item === '')) {
            result.push(item);
        }
    });
    if (!array.length && !result.length) {
        result.push('');
    }
    return result;
}

function checkInstanceName(name) {
    let error = false;
    let message = '';

    if (name.length === 0) {
        error = true;
        message = 'This field is required!';
    }

    if (name.length < 5 || name.length > 120) {
        error = true;
        message = 'Lab template name length must be within 5 to 120 characters length';
    }

    if (/[^A-Za-z0-9_\-().\s]/.test(name)) {
        error = true;
        message = 'Invalid character detected in lab template name. Letters, numbers 0-9, spaces, hyphen, underscore, parenthesis and dots are allowed only';
    }

    if (!(/^[a-zA-Z0-9\d].*[a-zA-Z0-9\d]$/.test(name))) {
        error = true;
        message = 'Name must begin and end with a letter or number';
    }

    return {
        error,
        message
    }
}