import { webSocketConstants } from '../../Constants';

const initialState = {
    type: '',
    id: null,
    exceptionMessage: '',
};

export function webSocket(state = initialState, action) {
    switch (action.type) {
        case webSocketConstants.SET_DATA:
            return {
                ...action.payload,
            };
        case webSocketConstants.NOT_VALID:
            return { ...state, ...action.payload };
        case webSocketConstants.CLEAN_REDUCER:
            return initialState;
        default:
            return state;
    }
}
