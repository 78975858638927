import { labDetailsService, userService } from '../Services';
import { errorRequest, labDetailsConstants } from '../Constants';
import {notifierActions} from "./notifier.actions";

const {enqueueNotification} = notifierActions;

export const labDetailsActions = {
    getLabDetailsByID,
    getLabDetails,
    showThumbnail,
    hideThumbnail,
    getUserLabDetailsByID,
    restartInstanceByAdmin,
    restartSeminarInstanceByAdmin,
    restartInstanceByUser,
    restartSeminarInstanceByUser,
    getGroupAdminLabDetailsByID,
    restartInstanceByGroupAdmin,
    restartSeminarInstanceByGroupAdmin,
    getLabTemplateInstanceById,
    cleanLabInstance
};

function restartInstanceByUser(labId, instanceId) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labDetailsService
            .restartInstanceByUser(labId, instanceId)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_REQUEST,
        };
    }
    function success(instance) {
        return {
            type: labDetailsConstants.INSTANCE_RESET_SUCCESS,
            payload: {
                instance,
            },
        };
    }
    function failure() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_ERROR,
        };
    }
}

function restartSeminarInstanceByUser(labId, instanceId) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labDetailsService
            .restartSeminarInstanceByUser(labId, instanceId)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_REQUEST,
        };
    }
    function success(instance) {
        return {
            type: labDetailsConstants.INSTANCE_RESET_SUCCESS,
            payload: {
                instance,
            },
        };
    }
    function failure() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_ERROR,
        };
    }
}

function getLabDetails(labInfo) {
    return {
        type: labDetailsConstants.SAVE_LAB_DETAILS_INFO,
        payload: {
            labInfo,
        },
    };
}

function getLabDetailsByID(labID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labDetailsService
            .adminGetLabInfoByID(labID)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_REQUEST,
        };
    }
    function success(labInfo) {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_SUCCESS,
            payload: {
                labInfo,
            },
        };
    }
    function failure() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_ERROR,
        };
    }
}

function showThumbnail(url, id) {
    return {
        type: labDetailsConstants.SHOW_THUMBNAIL,
        payload: {
            url,
            id,
        },
    };
}

function hideThumbnail() {
    return {
        type: labDetailsConstants.HIDE_THUMBNAIL,
        payload: {},
    };
}

function getUserLabDetailsByID(labID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labDetailsService
            .userGetLabInfoByID(labID)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_REQUEST,
        };
    }
    function success(labInfo) {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_SUCCESS,
            payload: {
                labInfo,
            },
        };
    }
    function failure() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_ERROR,
        };
    }
}

function restartInstanceByAdmin(labId, instanceId) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request(instanceId));
        labDetailsService
            .restartInstanceByAdmin(labId, instanceId)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request(instanceId) {
        return {
            type: labDetailsConstants.RESTART_INSTANCE_BY_ADMIN_REQUEST,
            payload: {
                instanceId,
            },
        };
    }
    function success(labInfo) {
        return {
            type: labDetailsConstants.RESTART_INSTANCE_BY_ADMIN_SUCCESS,
            payload: {
                labInfo,
            },
        };
    }
    function failure() {
        return {
            type: labDetailsConstants.RESTART_INSTANCE_BY_ADMIN_ERROR,
        };
    }
}

function restartSeminarInstanceByAdmin(labId, instanceId) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labDetailsService
            .restartSeminartInstanceByAdmin(labId, instanceId)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: labDetailsConstants.RESTART_INSTANCE_BY_ADMIN_REQUEST,
            payload: {
                instanceId,
            },
        };
    }
    function success(labInfo) {
        return {
            type: labDetailsConstants.RESTART_INSTANCE_BY_ADMIN_SUCCESS,
            payload: {
                labInfo,
            },
        };
    }
    function failure() {
        return {
            type: labDetailsConstants.RESTART_INSTANCE_BY_ADMIN_ERROR,
        };
    }
}

function restartInstanceByGroupAdmin(labId, instanceId) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labDetailsService
            .restartInstanceByGroupAdmin(labId, instanceId)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: labDetailsConstants.RESTART_INSTANCE_BY_ADMIN_REQUEST,
            payload: {
                instanceId,
            },
        };
    }
    function success(labInfo) {
        return {
            type: labDetailsConstants.RESTART_INSTANCE_BY_ADMIN_SUCCESS,
            payload: {
                labInfo,
            },
        };
    }
    function failure() {
        return {
            type: labDetailsConstants.RESTART_INSTANCE_BY_ADMIN_ERROR,
        };
    }
}

function restartSeminarInstanceByGroupAdmin(labId, instanceId) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labDetailsService
            .restartSeminarInstanceByGroupAdmin(labId, instanceId)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: labDetailsConstants.RESTART_INSTANCE_BY_ADMIN_REQUEST,
            payload: {
                instanceId,
            },
        };
    }
    function success(labInfo) {
        return {
            type: labDetailsConstants.RESTART_INSTANCE_BY_ADMIN_SUCCESS,
            payload: {
                labInfo,
            },
        };
    }
    function failure() {
        return {
            type: labDetailsConstants.RESTART_INSTANCE_BY_ADMIN_ERROR,
        };
    }
}

function getGroupAdminLabDetailsByID(labID, labType) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        labDetailsService
            .getGroupAdminLabDetailsByID(labID, labType)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_REQUEST,
        };
    }
    function success(labInfo) {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_SUCCESS,
            payload: {
                labInfo,
            },
        };
    }
    function failure() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_ERROR,
        };
    }
}

function getLabTemplateInstanceById(id, activeInstanceId) {
    return dispatch => {
        main(dispatch);
    };

    function main(dispatch) {
        dispatch(request());
        labDetailsService
            .getLabTemplateInstanceById(id, activeInstanceId)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_REQUEST,
        };
    }
    function success(labInfo) {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_SUCCESS,
            payload: {
                labInfo,
            },
        };
    }
    function failure() {
        return {
            type: labDetailsConstants.GET_LAB_DETAILS_INFO_BY_ID_ERROR,
        };
    }
}

function cleanLabInstance() {
    return {
        type: labDetailsConstants.CLEAN_INSTANCE
    }
}