export const dataListsManagementConstants = {
    SET_CURRENT_GROUP: 'SET_CURRENT_GROUP',
    UPDATE_CURRENT_GROUP: 'UPDATE_CURRENT_GROUP',

    GET_ALL_GROUPS_REQUEST: 'GET_ALL_GROUPS_REQUEST',
    GET_ALL_GROUPS_SUCCESS: 'GET_ALL_GROUPS_SUCCESS',
    GET_ALL_GROUPS_ERROR: 'GET_ALL_GROUPS_ERROR',
    GET_ALL_GROUPS_DROPDOWN_REQUEST: 'GET_ALL_GROUPS_DROPDOWN_REQUEST',
    GET_ALL_GROUPS_DROPDOWN_SUCCESS: 'GET_ALL_GROUPS_DROPDOWN_SUCCESS',
    GET_ALL_GROUPS_DROPDOWN_ERROR: 'GET_ALL_GROUPS_DROPDOWN_ERROR',
    CHANGE_GROUPS_PAGE: 'CHANGE_GROUPS_PAGE',
    CHANGE_GROUPS_SIZE: 'CHANGE_GROUPS_SIZE',
    CHANGE_GROUPS_SEARCH: 'CHANGE_GROUPS_SEARCH',
    PERFORM_GROUPS_SEARCH: 'PERFORM_GROUPS_SEARCH',

    GET_ALL_INSTANCES_TYPES_REQUEST: 'GET_ALL_INSTANCES_TYPES_REQUEST',
    GET_ALL_INSTANCES_TYPES_SUCCESS: 'GET_ALL_INSTANCES_TYPES_SUCCESS',
    GET_ALL_INSTANCES_TYPES_ERROR: 'GET_ALL_INSTANCES_TYPES_ERROR',
    GET_ALL_INSTANCES_TYPES_DROPDOWN_REQUEST: 'GET_ALL_INSTANCES_TYPES_DROPDOWN_REQUEST',
    GET_ALL_INSTANCES_TYPES_DROPDOWN_SUCCESS: 'GET_ALL_INSTANCES_TYPES_DROPDOWN_SUCCESS',
    GET_ALL_INSTANCES_TYPES_DROPDOWN_ERROR: 'GET_ALL_INSTANCES_TYPES_DROPDOWN_ERROR',
    CHANGE_LAB_TEMPLATE_PAGE: 'CHANGE_LAB_TEMPLATE_PAGE',
    CHANGE_LAB_TEMPLATE_PAGE_SIZE: 'CHANGE_LAB_TEMPLATE_PAGE_SIZE',
    CHANGE_LAB_TEMPLATE_SEARCH: 'CHANGE_LAB_TEMPLATE_SEARCH',
    CHANGE_BLUEPRINT_PAGE: 'CHANGE_BLUEPRINT_PAGE',
    CHANGE_BLUEPRINT_PAGE_SIZE: 'CHANGE_BLUEPRINT_PAGE_SIZE',
    PERFORM_INSTANCES_TYPES_SEARCH: 'PERFORM_INSTANCES_TYPES_SEARCH',
    DISABLE_INSTANCES_TYPES_SEARCH: 'DISABLE_INSTANCES_TYPES_SEARCH',

    GET_ALL_BLUEPRINTS_REQUEST: 'GET_ALL_BLUEPRINTS_REQUEST',
    GET_ALL_BLUEPRINTS_SUCCESS: 'GET_ALL_BLUEPRINTS_SUCCESS',
    GET_ALL_BLUEPRINTS_ERROR: 'GET_ALL_BLUEPRINTS_ERROR',
    GET_ALL_BLUEPRINTS_LIST_REQUEST: 'GET_ALL_BLUEPRINTS_LIST_REQUEST',
    GET_ALL_BLUEPRINTS_LIST_SUCCESS: 'GET_ALL_BLUEPRINTS_LIST_SUCCESS',
    GET_ALL_BLUEPRINTS_LIST_ERROR: 'GET_ALL_BLUEPRINTS_LIST_ERROR',

    GET_EDITABLE_TEMPLATE_REQUEST: 'GET_EDITABLE_TEMPLATE_REQUEST',
    GET_EDITABLE_TEMPLATE_SUCCESS: 'GET_EDITABLE_TEMPLATE_SUCCESS',
    GET_EDITABLE_TEMPLATE_ERROR: 'GET_EDITABLE_TEMPLATE_ERROR',
    GET_EDITABLE_BLUEPRINT_REQUEST: 'GET_EDITABLE_BLUEPRINT_REQUEST',
    GET_EDITABLE_BLUEPRINT_SUCCESS: 'GET_EDITABLE_BLUEPRINT_SUCCESS',
    GET_EDITABLE_BLUEPRINT_ERROR: 'GET_EDITABLE_BLUEPRINT_ERROR',

    DELETE_BLUEPRINT_REQUEST: 'DELETE_BLUEPRINT_REQUEST',
    DELETE_BLUEPRINT_SUCCESS: 'DELETE_BLUEPRINT_SUCCESS',
    DELETE_BLUEPRINT_ERROR: 'DELETE_BLUEPRINT_ERROR',

    GET_ALL_USERS_REQUEST: 'GET_ALL_USERS_REQUEST',
    GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
    GET_ALL_USERS_ERROR: 'GET_ALL_USERS_ERROR',
    UPDATE_ALL_USERS: 'UPDATE_ALL_USERS',
    CHECK_USERS: 'CHECK_ALL_USERS',

    GET_USERS_ALL_GROUPS_REQUEST: 'GET_USERS_ALL_GROUPS_REQUEST',
    GET_USERS_ALL_GROUPS_SUCCESS: 'GET_USERS_ALL_GROUPS_SUCCESS',
    GET_USERS_ALL_GROUPS_ERROR: 'GET_USERS_ALL_GROUPS_ERROR',

    GET_USERS_BY_GROUP_REQUEST: 'GET_USERS_BY_GROUP_REQUEST',
    GET_USERS_BY_GROUP_SUCCESS: 'GET_USERS_BY_GROUP_SUCCESS',
    GET_USERS_BY_GROUP_ERROR: 'GET_USERS_BY_GROUP_ERROR',

    TOGGLE_SHOW_USER_MODAL_REQUEST: 'TOGGLE_SHOW_USER_MODAL_REQUEST',
    TOGGLE_SHOW_USER_MODAL_SUCCESS: 'TOGGLE_SHOW_USER_MODAL_SUCCESS',
    TOGGLE_SHOW_USER_MODAL_ERROR: 'TOGGLE_SHOW_USER_MODAL_ERROR',

    GET_USERS_BELONGS_REQUEST: 'GET_USERS_BELONGS_REQUEST',
    GET_USERS_BELONGS_SUCCESS: 'GET_USERS_BELONGS_SUCCESS',
    GET_USERS_BELONGS_ERROR: 'GET_USERS_BELONGS_ERROR',

    GET_All_USERS_FILTER_REQUEST: 'GET_All_USERS_FILTER_REQUEST',
    GET_All_USERS_FILTER_SUCCESS: 'GET_All_USERS_FILTER_SUCCESS',
    GET_All_USERS_FILTER_ERROR: 'GET_All_USERS_FILTER_ERROR',

    RESET_USER_PASSWORD_REQUEST: 'RESET_USER_PASSWORD_REQUEST',
    RESET_USER_PASSWORD_SUCCESS: 'RESET_USER_PASSWORD_SUCCESS',
    RESET_USER_PASSWORD_ERROR: 'RESET_USER_PASSWORD_ERROR',

    DELETE_USERS_MULTIPLE_REQUEST: 'DELETE_USERS_MULTIPLE_STATUS_REQUEST',
    DELETE_USERS_MULTIPLE_SUCCESS: 'DELETE_USERS_MULTIPLE_STATUS_SUCCESS',
    DELETE_USERS_MULTIPLE_ERROR: 'DELETE_USERS_MULTIPLE_STATUS_ERROR',

    GET_INDEXES: 'GET_INDEXES',

    LOAD_ALL_LABS_REQUEST: 'LOAD_ALL_LABS_REQUEST',
    LOAD_ALL_LABS_SUCCESS: 'LOAD_ALL_LABS_SUCCESS',
    LOAD_ALL_LABS_ERROR: 'LOAD_ALL_LABS_ERROR',

    CHANGE_LABS_PAGE: 'CHANGE_LABS_PAGE',
    CHANGE_LABS_SIZE: 'CHANGE_LABS_SIZE',
    PERFORM_LABS_SEARCH: 'PERFORM_LABS_SEARCH',

    GET_LABS_IN_GROUP_REQUEST: 'GET_LABS_IN_GROUP_REQUEST',
    GET_LABS_IN_GROUP_SUCCESS: 'GET_LABS_IN_GROUP_SUCCESS',
    GET_LABS_IN_GROUP_ERROR: 'GET_LABS_IN_GROUP_ERROR',
    LOAD_LABS_IN_GROUP_REQUEST: 'LOAD_LABS_IN_GROUP_REQUEST',
    LOAD_LABS_IN_GROUP_SUCCESS: 'LOAD_LABS_IN_GROUP_SUCCESS',
    LOAD_LABS_IN_GROUP_ERROR: 'LOAD_LABS_IN_GROUP_ERROR',

    LOAD_MY_LABS_REQUEST: 'LOAD_MY_LABS_REQUEST',
    LOAD_MY_LABS_SUCCESS: 'LOAD_MY_LABS_SUCCESS',
    LOAD_MY_LABS_ERROR: 'LOAD_MY_LABS_ERROR',

    GET_ALL_LABS_REQUEST: 'GET_ALL_LABS_REQUEST',
    GET_ALL_LABS_SUCCESS: 'GET_ALL_LABS_SUCCESS',
    GET_ALL_LABS_ERROR: 'GET_ALL_LABS_ERROR',
    SET_USER_NAME: 'SET_USER_NAME',
    SET_GROUP_NAME: 'SET_GROUP_NAME',

    GET_MY_LABS_REQUEST: 'GET_MY_LABS_REQUEST',
    GET_MY_LABS_SUCCESS: 'GET_MY_LABS_SUCCESS',
    GET_MY_LABS_ERROR: 'GET_MY_LABS_ERROR',

    GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_ERROR: 'GET_USER_INFO_ERROR',

    SET_USER_ROLE_REQUEST: 'SET_USER_ROLE_REQUEST',
    SET_USER_ROLE_SUCCESS: 'SET_USER_ROLE_SUCCESS',
    SET_USER_ROLE_ERROR: 'SET_USER_ROLE_ERROR',

    CREATE_LAB_BY_ADMIN_REQUEST: 'CREATE_LAB_BY_ADMIN_REQUEST',
    CREATE_LAB_BY_ADMIN_SUCCESS: 'CREATE_LAB_BY_ADMIN_SUCCESS',
    CREATE_LAB_BY_ADMIN_ERROR: 'CREATE_LAB_BY_ADMIN_ERROR',

    CREATE_LAB_BY_GROUP_ADMIN_REQUEST: 'CREATE_LAB_BY_GROUP_ADMIN_REQUEST',
    CREATE_LAB_BY_GROUP_ADMIN_SUCCESS: 'CREATE_LAB_BY_GROUP_ADMIN_SUCCESS',
    CREATE_LAB_BY_GROUP_ADMIN_ERROR: 'CREATE_LAB_BY_GROUP_ADMIN_ERROR',

    RECREATE_LAB_BY_GROUP_ADMIN_REQUEST: 'RECREATE_LAB_BY_GROUP_ADMIN_REQUEST',
    RECREATE_LAB_BY_GROUP_ADMIN_SUCCESS: 'RECREATE_LAB_BY_GROUP_ADMIN_SUCCESS',
    RECREATE_LAB_BY_GROUP_ADMIN_ERROR: 'RECREATE_LAB_BY_GROUP_ADMIN_ERROR',

    START_LAB_BY_ADMIN_REQUEST: 'START_LAB_BY_ADMIN_REQUEST',
    START_LAB_BY_ADMIN_SUCCESS: 'START_LAB_BY_ADMIN_SUCCESS',
    START_LAB_BY_ADMIN_ERROR: 'START_LAB_BY_ADMIN_ERROR',

    START_LAB_BY_USER_REQUEST: 'START_LAB_BY_USER_REQUEST',
    START_LAB_BY_USER_SUCCESS: 'START_LAB_BY_USER_SUCCESS',
    START_LAB_BY_USER_ERROR: 'START_LAB_BY_USER_ERROR',

    RECREATE_LAB: 'RECREATE_LAB',
    RECREATE_LAB_SHOW_NOTIFICATION_MODAL: 'RECREATE_LAB_SHOW_NOTIFICATION_MODAL',
    RECREATE_LAB_GROUP_ADMIN_SHOW_NOTIFICATION_MODAL: 'RECREATE_LAB_GROUP_ADMIN_SHOW_NOTIFICATION_MODAL',
    RECREATE_LAB_BY_USER_SHOW_NOTIFICATION_MODAL: 'RECREATE_LAB_BY_USER_SHOW_NOTIFICATION_MODAL',
    HARD_RECREATE_LAB_SHOW_NOTIFICATION_MODAL: 'HARD_RECREATE_LAB_SHOW_NOTIFICATION_MODAL',

    SORT_UP_FILTER_USER_ID: 'SORT_UP_FILTER_USER_ID',
    SORT_DOWN_FILTER_USER_ID: 'SORT_DOWN_FILTER_USER_ID',
    SORT_UP_FILTER_USER_ROLE: 'SORT_UP_FILTER_USER_ROLE',
    SORT_DOWN_FILTER_USER_ROLE: 'SORT_DOWN_FILTER_USER_ROLE',
    SORT_UP_FILTER_USER_NAME: 'SORT_UP_FILTER_USER_NAME',
    SORT_DOWN_FILTER_USER_NAME: 'SORT_DOWN_FILTER_USER_NAME',
    SORT_UP_FILTER_USER_LOGIN: 'SORT_UP_FILTER_USER_LOGIN',
    SORT_DOWN_FILTER_USER_LOGIN: 'SORT_DOWN_FILTER_USER_LOGIN',
    SORT_UP_FILTER_USER_MAINTENANCE: 'SORT_UP_FILTER_USER_MAINTENANCE',
    SORT_DOWN_FILTER_USER_MAINTENANCE: 'SORT_DOWN_FILTER_USER_MAINTENANCE',
    SORT_UP_FILTER_USER_MAIL: 'SORT_UP_FILTER_USER_MAIL',
    SORT_DOWN_FILTER_USER_MAIL: 'SORT_DOWN_FILTER_USER_MAIL',

    HANDLE_CLICK: 'HANDLE_CLICK',
    HANDLE_CLICK_LAB: 'HANDLE_CLICK_LAB',

    LOGOUT_USER: 'LOGOUT_USER',
    HANDLE_SELECT_ALL_CLICK: 'HANDLE_SELECT_ALL_CLICK',
    HANDLE_SELECT_ALL_CLICK_LAB: 'HANDLE_SELECT_ALL_CLICK_LAB',

    CLEAN_LABS_REDUCER: 'CLEAN_LABS_REDUCER',

    SET_DATE_ACTION: 'SET_DATE_ACTION',
    CHANGE_ACTION: 'CHANGE_ACTION',
    CHANGE_SIZE: 'CHANGE_SIZE',
    CHANGE_PAGE: 'CHANGE_PAGE',
    CHANGE_SORTING: 'CHANGE_SORTING',
    CHANGE_SEARCH: 'CHANGE_SEARCH',

    GET_USER_ACTIONS: 'GET_USER_ACTIONS',
    GET_LABS_ACTIONS: 'GET_LABS_ACTIONS',
    GET_GROUPS_ACTIONS: 'GET_GROUPS_ACTIONS',
    GET_TEMPLATES_ACTIONS: 'GET_TEMPLATES_ACTIONS',
    GET_SEMINARS_ACTIONS: 'GET_SEMINARS_ACTIONS',

    TOGGLE_HISTORY_DETAILS_MODAL: 'TOGGLE_HISTORY_DETAILS_MODAL',
    CLEAR_PAGE_COUNTER: 'CLEAR_PAGE_COUNTER',
    CLEAN_CHECKBOXES: 'CLEAN_CHECKBOXES',

    CLEAN_LABS_REDUCER_NEED_TO_GET_LABS: 'CLEAN_LABS_REDUCER_NEED_TO_GET_LABS',

    RESET_LABS_REDUCER: 'RESET_LABS_REDUCER',

    PERFORM_HISTORY_SEARCH: 'PERFORM_HISTORY_SEARCH',
    DISABLE_HISTORY_SEARCH: 'DISABLE_HISTORY_SEARCH',

    CHANGE_TAB: 'CHANGE_TAB',

    GET_USER_HISTORY_REQUEST: 'GET_USER_HISTORY_REQUEST',
    GET_USER_HISTORY_SUCCESS: 'GET_USER_HISTORY_SUCCESS',
    GET_USER_HISTORY_ERROR: 'GET_USER_HISTORY_ERROR',
    GET_LABS_HISTORY_REQUEST: 'GET_LABS_HISTORY_REQUEST',
    GET_LABS_HISTORY_SUCCESS: 'GET_LABS_HISTORY_SUCCESS',
    GET_LABS_HISTORY_ERROR: 'GET_LABS_HISTORY_ERROR',
    GET_GROUPS_HISTORY_REQUEST: 'GET_GROUPS_HISTORY_REQUEST',
    GET_GROUPS_HISTORY_SUCCESS: 'GET_GROUPS_HISTORY_SUCCESS',
    GET_GROUPS_HISTORY_ERROR: 'GET_GROUPS_HISTORY_ERROR',
    GET_TEMPLATE_HISTORY_REQUEST: 'GET_TEMPLATE_HISTORY_REQUEST',
    GET_TEMPLATE_HISTORY_SUCCESS: 'GET_TEMPLATE_HISTORY_SUCCESS',
    GET_TEMPLATE_HISTORY_ERROR: 'GET_TEMPLATE_HISTORY_ERROR',
    GET_SEMINARS_HISTORY_REQUEST: 'GET_SEMINARS_HISTORY_REQUEST',
    GET_SEMINARS_HISTORY_SUCCESS: 'GET_SEMINARS_HISTORY_SUCCESS',
    GET_SEMINARS_HISTORY_ERROR: 'GET_SEMINARS_HISTORY_ERROR',

    CLEAN_USER_HISTORY_REDUCER: 'CLEAN_USER_HISTORY_REDUCER',
    CLEAN_GROUPS_REDUCERS: 'CLEAN_GROUPS_REDUCERS',
    SET_CURRENT_HISTORY_SIZE: 'SET_CURRENT_HISTORY_SIZE',
    TOGGLE_MAINTENANCE: 'TOGGLE_MAINTENANCE',

    GET_SUBSCRIPTIONS_REQUEST: 'GET_SUBSCRIPTIONS_REQUEST',
    GET_SUBSCRIPTIONS_SUCCESS: 'GET_SUBSCRIPTIONS_SUCCESS',
    GET_SUBSCRIPTIONS_ERROR: 'GET_SUBSCRIPTIONS_ERROR',

    TOGGLE_MAINTENANCE_REQUEST: 'TOGGLE_MAINTENANCE_REQUEST',
    TOGGLE_MAINTENANCE_SUCCESS: 'TOGGLE_MAINTENANCE_SUCCESS',
    TOGGLE_MAINTENANCE_ERROR: 'TOGGLE_MAINTENANCE_ERROR',
    GET_MAINTENANCE_STATUS_REQUEST: 'GET_MAINTENANCE_STATUS_REQUEST',
    GET_MAINTENANCE_STATUS_SUCCESS: 'GET_MAINTENANCE_STATUS_SUCCESS',
    GET_MAINTENANCE_STATUS_ERROR: 'GET_MAINTENANCE_STATUS_ERROR',

    FILTER_USER_NAME: 'FILTER_USER_NAME',

    CHANGE_USERS_PAGE: 'CHANGE_USERS_PAGE',
    CHANGE_USERS_SIZE: 'CHANGE_USERS_SIZE',
    CHANGE_USERS_SEARCH: 'CHANGE_USERS_SEARCH',
    PERFORM_USERS_SEARCH: 'PERFORM_USERS_SEARCH',
    DISABLE_USERS_SEARCH: 'DISABLE_USERS_SEARCH',
    CLEAR_USER_SEARCH: 'CLEAR_USER_SEARCH',
    CLEAR_USERS_PAGE_COUNTER: 'CLEAR_USERS_PAGE_COUNTER',
    TOGGLE_MAINTENANCE_MAINTENANCE: 'TOGGLE_MAINTENANCE_MAINTENANCE',

    DELETE_USERS_MULTIPLE_STATUS_REQUEST: 'DELETE_USERS_MULTIPLE_STATUS_REQUEST',
    DELETE_USERS_MULTIPLE_STATUS_SUCCESS: 'DELETE_USERS_MULTIPLE_STATUS_SUCCESS',
    DELETE_USERS_MULTIPLE_STATUS_ERROR: 'DELETE_USERS_MULTIPLE_STATUS_ERROR',

    CLEAR_USERS_PAGE_CHECKED_ID: 'CLEAR_USERS_PAGE_CHECKED_ID',
    CLEAN_LABS_PAGE_CHECKBOXES: 'CLEAN_LABS_PAGE_CHECKBOXES',

    SET_MAINTENANCE_FILTER: 'SET_MAINTENANCE_FILTER',
    SET_ROLE_FILTER: 'SET_ROLE_FILTER',

    CHANGE_USER_ASSIGN_PAGE: 'CHANGE_USER_ASSIGN_PAGE',
    CHANGE_USER_ASSIGN_SIZE: 'CHANGE_USER_ASSIGN_SIZE',
    USER_ASSIGN_RESET_PAGE: 'USER_ASSIGN_RESET_PAGE',

    GET_LAB_TEMPLATE_BY_ID_SUCCESS: 'GET_LAB_TEMPLATE_BY_ID_SUCCESS',
    GET_LAB_TEMPLATE_BY_ID_ERROR: 'GET_LAB_TEMPLATE_BY_ID_ERROR',
    GET_BLUEPRINT_BY_ID_SUCCESS: 'GET_BLUEPRINT_BY_ID_SUCCESS',
    GET_BLUEPRINT_BY_ID_ERROR: 'GET_BLUEPRINT_BY_ID_ERROR',

    SET_SHARED_FILTER: 'SET_SHARED_FILTER',
    SET_STATUS_FILTER: 'SET_STATUS_FILTER',
    SAVE_CURRENT_PAGE: 'SAVE_CURRENT_PAGE',
    TOGGLE_INCLUDE_TEST_DATA: 'TOGGLE_INCLUDE_TEST_DATA',
    SET_COLUMN_FILTER: 'SET_COLUMN_FILTER',
    CLEAR_COLUMN_FILTERS: 'CLEAR_COLUMN_FILTERS'
};
