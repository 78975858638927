import { urlConstant } from '../Constants';
import { versionErrorRequests } from '../Handlers';
import Auth from '../Modules/auth.module';
import IPManagement from '../Modules/ipManagement.module';
import Maintenance from '../Modules/maintenance.module';
import {AxiosRequest} from "./AxiosApi";

const isRepair = Maintenance.getMaintenance();

export const versionControlService = {
    getVersion,
    getComputerIP,
};

function check() {
    return new Promise((resolve) => {
        let sendIP;
        if (IPManagement.getCurrentIP() && IPManagement.getCurrentIP().includes('.')) {
            sendIP = IPManagement.getCurrentIP();
            resolve(sendIP);
        } else {
            getComputerIP().then(responseIP => {
                sendIP = responseIP;
                resolve(sendIP);
            });
        }
    });
}

function getVersion() {
    return new Promise((resolve, reject) => {
        check().then(sendIP => {
            AxiosRequest({
                method: 'get',
                url: `${urlConstant}/version`,
                params: {
                    ip: sendIP,
                },
                headers: Auth.isUserAuthenticated()
                    ? {
                          Authorization: `Bearer ${Auth.getToken()}`,
                      }
                    : {},
                additionalConfigOptions: !Auth.isUserAuthenticated() ? {
                    headers: null
                } : {}
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    const errorMessage = versionErrorRequests('get version', error);
                    console.error(errorMessage, error);
                    reject(errorMessage);
                });
        });
    });
}

function getComputerIP() {
    return new Promise((resolve) => {
        AxiosRequest({
            method: 'get',
            url: 'https://jsonip.com/',
            additionalConfigOptions: {
                headers: null
            }
        })
            .then(response => {
                isRepair && console.log('getComputerIP', response);
                IPManagement.setCurrentIP(response.data.ip);
                resolve(response.data.ip);
            })
            .catch(error => {
                isRepair && console.error(error);
                // IPManagement.setCurrentIP('IP undefined');
                // resolve('IP undefined');
                resolve(getSecondComputerIP());
            });
    });
}

function getSecondComputerIP() {
    return new Promise((resolve) => {
        AxiosRequest({
            method: 'get',
            url: 'https://ipinfo.io/ip',
            additionalConfigOptions: {
                headers: null
            }
        })
            .then(response => {
                isRepair && console.log('getSecondComputerIP', response);
                IPManagement.setCurrentIP(response.data);
                resolve(response.data);
            })
            .catch(error => {
                isRepair && console.error(error);
                resolve(getThirdComputerIP());
            });
    });
}

function getThirdComputerIP() {
    return new Promise((resolve) => {
        AxiosRequest({
            method: 'get',
            url: 'https://ipecho.net/plain',
            additionalConfigOptions: {
                headers: null
            }
        })
            .then(response => {
                isRepair && console.log('getThirdComputerIP', response);
                IPManagement.setCurrentIP(response.data);
                resolve(response.data);
            })
            .catch(error => {
                isRepair && console.error(error);
                IPManagement.setCurrentIP('Third IP undefined');
                resolve('Third IP undefined');
            });
    });
}
