import { modalConstants } from '../../Constants';
import { reducersUtil } from '../../Utils';
import _ from "lodash";

const initialState = {
    requestIsActive: false,
    isOpen: false,
    info: {
        acceptedFile: '',
        name: '',
        fileName: '',
    },
    warningMessages: {
        errorMessage: '',
        wrongFileError: '',
    },
};

export function addLabTemplateModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.TOGGLE_ADD_TEMPLATE_MODAL: {
            if (state.isOpen) {
                return initialState;
            } else {
                return {
                    ...initialState,
                    isOpen: true,
                };
            }
        }
        case modalConstants.UPLOAD_LAB_TEMPLATE_FILE:
            return {
                ...state,
                info: {
                    ...state.info,
                    acceptedFile: action.payload.acceptedFile,
                    fileName: action.payload.acceptedFile.name,
                },
                warningMessages: {
                    ...state.warningMessages,
                    wrongFileError: '',
                },
            };
        case modalConstants.CHANGE_LAB_TEMPLATE_NAME:
            return {
                ...state,
                info: {
                    ...state.info,
                    name: action.payload.name,
                },
            };
        case modalConstants.ADD_LAB_TEMPLATE_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case modalConstants.ADD_LAB_TEMPLATE_SUCCESS:
            return initialState;
        case modalConstants.ADD_LAB_TEMPLATE_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case modalConstants.ADD_LAB_TEMEPLATE_SHOW_ERROR_MESSAGE: {
            const { value } = action.payload.event.target;
            return {
                ...state,
                warningMessages: {
                    ...state.warningMessages,
                    errorMessage: reducersUtil.generateLabTemplateManagementErrorMessage(value),
                },
            };
        }
        case modalConstants.ADD_LAB_TEMEPLATE_HIDE_ERROR_MESSAGE:
            return {
                ...state,
                warningMessages: {
                    wrongFileError: '',
                    errorMessage: '',
                },
            };
        case modalConstants.SHOW_ERROR_WRONG_FILE:
            return {
                ...state,
                warningMessages: {
                    ...state.warningMessages,
                    wrongFileError: action.payload.message,
                },
            };
        default:
            return state;
    }
}
