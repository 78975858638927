import React from 'react';
import { Provider } from 'react-redux';
import { store } from './Utils';
import App from './Components/App/App';
import { createRoot } from 'react-dom/client';

import {SnackbarProvider} from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
}
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <SnackbarProvider
            ref={notistackRef}
            preventDuplicate={true}
            dense={true}
            maxSnack={20}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            action={(key) => (
                <IconButton onClick={onClickDismiss(key)} color="inherit">
                    <CloseIcon/>
                </IconButton>
            )}
        >
            <App />
        </SnackbarProvider>
    </Provider>
);
