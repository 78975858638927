import {notifierConstants} from "../../Constants/Actions/notifier.constants";

const initialState = {
    message: '',
    options: {},
    enqueue: false
};

export function notification(state = initialState, action) {
    switch (action.type) {
        case notifierConstants.ENQUEUE_NOTIFICATION:
            return {
                message: action.payload.message,
                options: action.payload.options || {},
                enqueue: true
            }

        case notifierConstants.CLEAR_ENQUEUE_NOTIFICATION:
            return {
                ...state,
                enqueue: false
            }

        default:
            return state;
    }
}