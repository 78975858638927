import { modalConstants } from '../../Constants';
import { reducersUtil } from '../../Utils';
import _ from "lodash";

const initialState = {
    isEditUserModalOpen: false,
    info: {
        userID: '',
        email: '',
        oldEmail: '',
        userName: '',
        oldUserName: '',
        limitationMinutes: '',
        oldLimitationMinutes: '',
        maintenance: false,
        oldMaintenance: false,
    },
    requestIsActive: false,
    warningMessage: {
        errorMessage: '',
        emailErrorMessage: '',
        wrongEmail: '',
        checkInput: '',
    },
};

export function editUserModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.TOGGLE_EDIT_USER_MODAL: {
            if (state.isEditUserModalOpen) {
                return initialState;
            } else {
                return {
                    ...state,
                    isEditUserModalOpen: true,
                    info: {
                        userID: action.payload.userID,
                        email: action.payload.userEmail,
                        oldEmail: action.payload.userEmail,
                        userName: action.payload.username,
                        oldUserName: action.payload.username,
                        limitationMinutes: action.payload.limitationMinutes,
                        oldLimitationMinutes: action.payload.limitationMinutes,
                        maintenance: action.payload.maintenance,
                        oldMaintenance: action.payload.maintenance,
                    },
                };
            }
        }
        case modalConstants.EDIT_USER_MODAL_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case modalConstants.EDIT_USER_MODAL_SUCCESS:
            return initialState;
        case modalConstants.EDIT_USER_MODAL_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case modalConstants.EDIT_USER_MODAL_CHANGE_FORM: {
            const { value, name } = action.payload.event.target;
            let parsedValue = action.payload.event.target.value;
            if (name === 'limitationMinutes') {
                parsedValue = value.replace(/[^0-9]+/gi, '');
            }
            else if (name === 'email' || name === 'username') {
                parsedValue = value.replace(/(^\s*)|(\s*)$/g, '');
            }
            return {
                ...state,
                info: {
                    ...state.info,
                    [name]: parsedValue,
                },
            };
        }
        case modalConstants.EDIT_USER_MODAL_EMPTY_FIELDS:
            return {
                ...state,
                warningMessage: {
                    emailErrorMessage: "Email field wasn't changed",
                    errorMessage: "User name field wasn't changed",
                },
            };
        case modalConstants.EDIT_USER_MODAL_SHOW_ERROR_MESSAGE: {
            const { value, name } = action.payload.event.target;
            const { wrongEmail: getWrongEmail } = state.warningMessage;
            return {
                ...state,
                warningMessage: {
                    ...state.warningMessage,
                    checkInput: true,
                    ...reducersUtil.editUserErrorMessage(value, name, getWrongEmail),
                },
            };
        }
        case modalConstants.EDIT_USER_MODAL_HIDE_ERROR_MESSAGE: {
            const { name } = action.payload.event.target;
            let errorMessage;
            let emailErrorMessage;

            if (name === 'email') {
                emailErrorMessage = '';

                return {
                    ...state,
                    warningMessage: {
                        ...state.warningMessage,
                        emailErrorMessage,
                    },
                };
            } else if (name === 'userName') {
                errorMessage = '';
                return {
                    ...state,
                    warningMessage: {
                        ...state.warningMessage,
                        errorMessage,
                    },
                };
            } else return state;
        }
        default:
            return state;
    }
}
