import { urlConstant } from '../Constants';
import { labErrorRequests } from '../Handlers';
import Maintenance from '../Modules/maintenance.module';
import {AxiosRequest} from "./AxiosApi";

const isRepair = Maintenance.getMaintenance();

export const labDetailsService = {
    adminGetLabInfoByID,
    userGetLabInfoByID,
    restartInstanceByAdmin,
    restartSeminartInstanceByAdmin,
    restartInstanceByUser,
    restartSeminarInstanceByUser,
    getGroupAdminLabDetailsByID,
    restartInstanceByGroupAdmin,
    restartSeminarInstanceByGroupAdmin,
    getLabTemplateInstanceById,
    getTemplateLabsById,
    getNetworkTopology
};

function restartInstanceByUser(labId, instanceId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'PUT',
            url: `${urlConstant}/user/instance/restart`,
            data: {
                labId,
                instanceId,
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                // never get error from server...
                const errorMessage = labErrorRequests('restart lab instance', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function restartSeminarInstanceByUser(labId, instanceId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'PUT',
            url: `${urlConstant}/seminar/user/instance/restart`,
            data: {
                labId,
                instanceId,
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                // never get error from server...
                const errorMessage = labErrorRequests('restart seminar lab instance', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function adminGetLabInfoByID(labID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/labs/${labID}/instances`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get lab info', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function userGetLabInfoByID(labID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/user/${labID}/instances`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get lab info', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function restartInstanceByAdmin(labId, instanceId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/labs/instance/restart`,
            data: {
                labId,
                instanceId,
            },
        })
            .then(response => {
                console.log('Restart instance', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('restart instance', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function restartSeminartInstanceByAdmin(labId, instanceId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/labs/seminar/instance/restart`,
            data: {
                labId,
                instanceId,
            },
        })
            .then(response => {
                console.log('Restart seminar instance', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('restart seminar instance', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function restartInstanceByGroupAdmin(labId, instanceId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/labs/instance/restart`,
            data: {
                labId,
                instanceId,
            },
        })
            .then(response => {
                console.log('Restart instance', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('restart instance', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function restartSeminarInstanceByGroupAdmin(labId, instanceId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/seminar/instance/restart`,
            data: {
                labId,
                instanceId,
            },
        })
            .then(response => {
                console.log('Restart seminar instance', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('restart seminar instance', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupAdminLabDetailsByID(labID, labType) {
    let url =
        labType === 'my' ? `${urlConstant}/groupAdmin/${labID}/instances` : `${urlConstant}/groupAdmin/${labID}/current/instances`;


    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get lab info for GA', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getLabTemplateInstanceById(id, activeInstanceId) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/templates/${id}/labs`,
        })
            .then(res => {
                res.data.sort((a,b) => b.id - a.id);

                let instanceId = res.data[0] ? res.data[0].id : null;
                if (activeInstanceId) {
                    instanceId = activeInstanceId;
                }

                if (instanceId) {
                    AxiosRequest({
                        method: 'get',
                        url: `${urlConstant}/admin/labs/${instanceId}/instances`,
                    })
                        .then(response => {
                            resolve(response);
                        })
                        .catch(error => {
                            const errorMessage = labErrorRequests('get lab info', error);
                            isRepair && console.error(errorMessage, error);
                            reject(errorMessage);
                        });
                }
                else {
                    reject('No instance data');
                }
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get lab info', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    })
    //
}

function getTemplateLabsById(id) {


    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/templates/${id}/labs`,
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get lab info', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    })
}

function getNetworkTopology(id) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/user/lab/${id}/networkTopology`
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get lab topology', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    })
}