import { modalConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    modalIsOpen: false,
    requestIsActive: false,
    info: {
        userId: '',
        instanceTypeID: [],
        instanceTypeName: [],
        templates: []
    },
};

export function assignLabTypeModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.TOGGLE_ASSIGN_LAB_TYPE_MODAL: {
            if (state.modalIsOpen) {
                return initialState;
            } else {
                return {
                    ...state,
                    modalIsOpen: true,
                    info: {
                        ...state.info,
                        userId: action.payload.userId,
                    },
                };
            }
        }
        case modalConstants.ASSIGN_LAB_TYPE_CHANGE_FORM: {
            const { instanceTypeName } = action.payload.info;
            let resultArray = [];

            instanceTypeName.forEach(item => {
                if (!(instanceTypeName.length > 1 && item === 'default')) {
                    resultArray.push(item);
                }
            });

            return {
                ...state,
                info: {
                    ...state.info,
                    instanceTypeID: action.payload.info.instanceTypeID,
                    instanceTypeName: resultArray,
                },
            };
        }
        case modalConstants.ASSIGN_LAB_TYPE_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case modalConstants.ASSIGN_LAB_TYPE_SUCCESS:
            return initialState;
        case modalConstants.GET_ASSIGNED_LAB_TYPES_SUCCESS: {
            let object = action.payload.labs;
            let namesArray = object.map(item => item.name);
            let idArray = object.map(item => item.id);
            let templates = action.payload.labs.map(lab => ({
                id: lab.id,
                name: lab.name
            }))
            return {
                ...state,
                modalIsOpen: true,
                info: {
                    ...state.info,
                    userId: action.payload.userId,
                    instanceTypeName: namesArray,
                    instanceTypeID: idArray,
                    templates
                },
            };
        }
        case modalConstants.ASSIGN_LAB_TYPE_SET_LAB_NAME:
            return {
                ...state,
                info: {
                    ...state.info,
                    instanceTypeName: action.payload.data,
                },
            };
        default:
            return state;
    }
}
