import { errorRequest } from '../Constants';
import Auth from '../Modules/auth.module';
import { transformUtil } from '../Utils';
// import _ from 'lodash';

export const seminarErrorRequests = (requestType, error) => {
    if (error.response) {
        const errorRes = error.response;

        switch (requestType) {
            case 'remove seminar': {
                switch (errorRes.status) {
                    case 500: {
                        return errorRequest.DEFAULT;
                    }
                    case 400: {
                        return error.response.data.cause.includes('labs in transition state')
                            ? 'Error to delete seminar, labs in this seminar are in transition state.'
                            : transformUtil.toUpperCase(error.response.data.cause);
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'delete user':
            case 'edit seminar': {
                switch (errorRes.status) {
                    case 403:
                    case 400: {
                        return transformUtil.toUpperCase(error.response.data.cause);
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'create seminar': {
                switch (errorRes.status) {
                    case 403:
                    case 400: {
                        return error.response.data.cause;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            default: {
                switch (errorRes.status) {
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
        }
    } else {
        Auth.deauthenticateUser();
        return errorRequest.DEFAULT;
    }
};
