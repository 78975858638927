import { urlConstant } from '../Constants';
import { groupErrorRequests } from '../Handlers';
import Maintenance from '../Modules/maintenance.module';
import { groupsUtil, transformUtil } from '../Utils';
import {AxiosRequest} from "./AxiosApi";

const isRepair = Maintenance.getMaintenance();

export const groupsManagementService = {
    getAll,
    getAllDropdown,
    createOne,
    deleteOne,
    updateGroup,
    checkGroup,
    updateTime,
    getGroupAdminGroups,
    getGroupAdminGroupsDropdown,
    updateTimeFrame,
};

function getAll(page, size, search) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/groups?page=${page}&size=${size}`,
            data: {
                query: search,
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('get all groups', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getAllDropdown() {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/groups`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('get all groups for dropdown', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function createOne(data) {
    // const {groupName, subscriptionID, subscriptionName, fixedNetID} = data;


    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            // url: `${urlConstant}/admin/group/${groupName}/${subscriptionID}/${subscriptionName}/${fixedNetID}`,
            url: `${urlConstant}/admin/groups/create`,
            data: {
                id: data.id,
                groupName: data.groupName,
                subscriptionId: data.subscriptionID,
                subscriptionName: data.subscriptionName,
                fixedNetId: data.fixedNetID,
                // minutesToStopLab: transformUtil.parseInterval(data.timeToStopLab),
                minutesToStopLab: transformUtil.getMinutes(data.timeToStopLab),
                // minutesToStopLab: +data.timeToStopLab,
                // minutesToDeleteLab: transformUtil.parseInterval(data.timeToDeleteLab),
                minutesToDeleteLab: transformUtil.getMinutesFromDays(data.timeToDeleteLab),
                limitationMinutes: transformUtil.getMinutes(data.limitation),
            },
        })
            .then(response => {
                // isRepair && console.log('New group created successfully', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('create group', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function deleteOne(groupName) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/admin/groups/${groupName}`,
        })
            .then(response => {
                // isRepair && console.log('Group deleted successfully', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('delete group', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function updateGroup(id, groupName, fixedNetID, timeToStopLab, timeToDeleteLab, limitation) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/groups/edit`,
            data: {
                id,
                newName: groupName,
                fixedNetId: fixedNetID,
                timeToStopLab,
                timeToDeleteLab,
                limitationMinutes: limitation,
            },
        })
            .then(response => {
                // isRepair && console.log('Group updated successfully', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('update group', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function checkGroup(id, groupName, fixedNetID, timeToStopLab, timeToDeleteLab, updating, limitation) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/groups/check`,
            data: {
                id,
                newName: groupName,
                fixedNetId: fixedNetID,
                timeToStopLab: transformUtil.getMinutes(timeToStopLab),
                timeToDeleteLab: transformUtil.getMinutesFromDays(timeToDeleteLab),
                limitationMinutes: transformUtil.getMinutes(limitation),
            },
        })
            .then(response => {
                // isRepair && console.log('Group checked successfully', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('check group', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function updateTime(id, groupName, fixedNetID, timeToStopLab, timeToDeleteLab) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/admin/labs/time`,
            data: {
                id,
                newName: groupName,
                fixedNetId: fixedNetID,
                timeToStopLab,
                timeToDeleteLab,
                updating: true,
            },
        })
            .then(response => {
                // isRepair && console.log('Time updated successfully', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('update time group', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupAdminGroups() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/groups?page=0&size=30`,
            data: {
                query: '',
            },
        })
            .then(response => {
                // isRepair && console.log('GA Groups', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('get group admin groups', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupAdminGroupsDropdown() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/groups`,
            data: {
                query: '',
            },
        })
            .then(response => {
                // isRepair && console.log('GA Groups dropdown', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('get group admin groups', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function updateTimeFrame(groupId, dateFrom, dateTo, timeStart, timeStop, daysOfWeek, isActive) {

    // groupsUtil.modifyDates(dateFrom, dateTo, timeStart, timeStop);
    const dates = groupsUtil.modifyDates(dateFrom, dateTo, timeStart, timeStop);
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/limitation/period`,
            data: {
                groupId,
                dateFrom: dates.dateFrom,
                dateTo: dates.dateTo,
                // timeStart: +moment(timeStart).format('X'),
                // timeStop: +moment(timeStop).format('X'),
                daysOfWeek,
                isActive,
            },
        })
            .then(response => {
                // isRepair && console.log('Time frame updated successfully', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('update time frame group', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}