import { groupsManagementService, userManagementService, userService } from '../Services';
import { dataListsManagementConstants, errorRequest, modalConstants, notificationModalActions } from '../Constants';
// import { statusModalActions } from './statusModal.actions';
import {notifierActions} from "./notifier.actions";
import {transformUtil} from "../Utils";

const {enqueueNotification} = notifierActions;
// const { openStatusModal } = statusModalActions;

export const groupsManagementActions = {
    getAllGroups,
    getAllGroupsDropdown,
    addNewGroupChangeForm,
    setSubscription,
    changeFixedNetID,
    addNewGroup,
    deleteGroupShowNotificationModal,
    deleteGroup,
    deleteUsersStatus,
    deleteUsers,
    setCurrentGroup,
    updateGroupChangeForm,
    updaterGroupNoChanges,
    updateGroupChangeFixedNetID,
    updateGroup,
    checkGroup,
    updateTime,
    getGroupAdminGroups,
    getGroupAdminGroupsDropdown,
    updateTimeFrame,
    changePage,
    changeSize,
    changeSearch,
    performGroupsSearch,
};

function getAllGroups(page, size, search) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        groupsManagementService
            .getAll(page, size, search)
            .then(groups => {
                dispatch(success(groups.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_REQUEST,
        };
    }
    function success(groups) {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_SUCCESS,
            payload: {
                groups,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_ERROR,
        };
    }
}

function getAllGroupsDropdown() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        groupsManagementService
            .getAllDropdown()
            .then(groups => {
                dispatch(success(groups.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_DROPDOWN_REQUEST,
        };
    }
    function success(groups) {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_DROPDOWN_SUCCESS,
            payload: {
                groups,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_DROPDOWN_ERROR,
        };
    }
}

function addNewGroupChangeForm(event) {
    return {
        type: modalConstants.ADD_NEW_GROUP_CHANGE_FORM,
        payload: {
            event,
        },
    };
}

function setSubscription(value) {
    return {
        type: modalConstants.ADD_GROUP_MODAL_SET_SUBSCRIPTION,
        payload: {
            value,
        },
    };
}

function changeFixedNetID() {
    return {
        type: modalConstants.ADD_GROUP_MODAL_CHANGE_FIXED_ID,
    };
}

function addNewGroup(data) {
    return (dispatch, getState) => {
        main(dispatch, getState());
    };
    function main(dispatch, state) {
        const {
            addUserModal: { addUserModalIsOpen },
            dropUsersFileModal: { dropUsersFileModalIsOpen },
        } = state;
        dispatch(request());
        groupsManagementService
            .createOne(data)
            .then(newGroup => {
                const newGroupResponse = newGroup.data;
                dispatch(success());
                dispatch(addNewBulkUsersNewGroup([newGroupResponse.name], [newGroupResponse.id]));

                dispatch(enqueueNotification('New group added successfully', {variant: 'success'}));

                dispatch(requestGetAllGroups());
                groupsManagementService
                    .getAll(data.page, data.size)
                    .then(groups => {
                        dispatch(successGetAllGroups(groups.data));
                        if (addUserModalIsOpen) {
                            dispatch(addNewGroupSetGroupName([newGroup.data.id]));
                            dispatch(getAllGroupsDropdown());
                        } else if (dropUsersFileModalIsOpen) {
                            dispatch(addNewBulkUsersSetGroupName([data.groupName]));
                            dispatch(getAllGroupsDropdown());
                        }
                    })
                    .catch((error) => {
                        dispatch(failureGetAllGroups());
                        dispatch(enqueueNotification(String(error), {variant: 'error'}));
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    // dispatch(
                    //     openStatusModal({
                    //         text: 'Group with this name already exists',
                    //         status: false,
                    //     }),
                    // );
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.ADD_NEW_GROUP_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.ADD_NEW_GROUP_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.ADD_NEW_GROUP_ERROR,
        };
    }
    function requestGetAllGroups() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_REQUEST,
        };
    }
    function successGetAllGroups(groups) {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_SUCCESS,
            payload: {
                groups,
            },
        };
    }
    function failureGetAllGroups() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_ERROR,
        };
    }

    function addNewBulkUsersNewGroup(name, id) {
        return {
            type: modalConstants.ADD_BULK_FILE_CHANGE_GROUP_NAME,
            payload: {
                name,
                id,
            },
        };
    }
}

function addNewGroupSetGroupName(groupName) {
    return {
        type: modalConstants.ADD_NEW_USER_SET_GROUP_NAME,
        payload: {
            groupName,
        },
    };
}

function addNewBulkUsersSetGroupName(groupName) {
    return {
        type: modalConstants.ADD_BULK_FILE_SET_GROUP_NAME,
        payload: {
            groupName,
        },
    };
}

function deleteGroupShowNotificationModal(groupName) {
    return {
        type: modalConstants.DELETE_GROUP_SHOW_NOTIFICATION_MODAL,
        payload: {
            groupName,
            text: 'Are you sure you want to delete group?',
            method: notificationModalActions.DELETE_GROUP,
        },
    };
}

function deleteGroup(groupName, page, size) {
    return (dispatch, getState) => {
        main(dispatch, getState);
    };
    function main(dispatch, getState) {
        dispatch(request());
        groupsManagementService
            .deleteOne(groupName)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Group deleted successfully', {variant: 'success'}));
                dispatch(requestGetAllGroups());

                const {search} = getState().groupsManagement;
                groupsManagementService
                    .getAll(page, size, search)
                    .then(groups => {
                        dispatch(successGetAllGroups(groups.data));
                    })
                    .catch(() => {
                        dispatch(failureGetAllGroups());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());

                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.DELETE_GROUP_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.DELETE_GROUP_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.DELETE_GROUP_ERROR,
        };
    }
    function requestGetAllGroups() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_REQUEST,
        };
    }
    function successGetAllGroups(groups) {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_SUCCESS,
            payload: {
                groups,
            },
        };
    }
    function failureGetAllGroups() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_ERROR,
        };
    }
}

function deleteUsersStatus(groupID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .deleteUsersStatus(groupID)
            .then(response => {
                dispatch(success(response.data, groupID));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.DELETE_USERS_STATUS_REQUEST,
        };
    }
    function success(successUserDelete, groupID) {
        return {
            type: modalConstants.DELETE_USERS_STATUS_SUCCESS,
            payload: {
                successUserDelete,
                groupID,
            },
        };
    }
    function failure(error) {
        return {
            type: modalConstants.DELETE_USERS_STATUS_ERROR,
            payload: {
                text: error,
            },
        };
    }
}

function deleteUsers(groupID, usersId) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .deleteUsers(groupID, usersId)
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Users deleted successfully', {variant: 'success'}));

            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.DELETE_USERS_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.DELETE_USERS_SUCCESS,
        };
    }
    function failure() {
        return {
            type: modalConstants.DELETE_USERS_ERROR,
        };
    }
}

function setCurrentGroup(group) {
    // console.log('Set current group', group);
    return {
        type: dataListsManagementConstants.SET_CURRENT_GROUP,
        payload: {
            groupName: group.name,
            groupID: group.id,
            groupType: group.type,
            isGroupEmpty: group.empty,
        },
    };
}

function updateGroupChangeForm(event) {
    return {
        type: modalConstants.UPDATE_GROUP_CHANGE_FORM,
        payload: {
            event,
        },
    };
}

function updaterGroupNoChanges() {
    return {
        type: modalConstants.UPDATE_GROUP_MODAL_EMPTY_FIELDS,
        payload: {
            errorMessage: 'Group name and fixed netID properties were not changed',
        },
    };
}

function updateGroupChangeFixedNetID() {
    return {
        type: modalConstants.UPDATE_GROUP_MODAL_CHANGE_FIXED_ID,
    };
}

function checkGroup(info, page, size) {
    return dispatch => {
        main(dispatch);
    };

    function main(dispatch) {
        dispatch(request());
        groupsManagementService
            // .checkGroup(info.id, info.groupName, info.fixedNetID, Number(info.timeToStopLab), Number(info.timeToDeleteLab), false, Number(info.limitation))
            .checkGroup(
                info.id,
                info.groupName,
                info.fixedNetID,
                info.timeToStopLab,
                info.timeToDeleteLab,
                false,
                Number(info.limitation),
            )
            .then(res => {

                    groupsManagementService
                        .updateGroup(
                            info.id,
                            info.groupName,
                            info.fixedNetID,
                            transformUtil.getMinutes(info.timeToStopLab),
                            transformUtil.getMinutesFromDays(info.timeToDeleteLab),
                            transformUtil.getMinutes(info.limitation),
                        )
                        .then(() => {
                            // dispatch(requestGetAllGroups());

                            // groupsManagementService
                            //     .getAll(page, size)
                            //     .then(groups => {
                            //         dispatch(successGetAllGroups(groups.data));
                            //     })
                            //     .catch(error => {
                            //         dispatch(failureGetAllGroups(error));
                            //     });
                            dispatch(success(res));

                            dispatch(enqueueNotification('Group updated successfully', {variant: 'success'}));
                        })
                        .catch(error => {
                            dispatch(failure());

                            dispatch(enqueueNotification(String(error), {variant: 'error'}));
                        });
            })
            // .then(() => {
            //     dispatch(requestGetAllGroups());
            //     groupsManagementService
            //         .getAll(page, size)
            //         .then(groups => {
            //             dispatch(successGetAllGroups(groups.data));
            //         })
            //         .catch(error => {
            //             dispatch(failureGetAllGroups(error));
            //             dispatch(enqueueNotification(String(error), {variant: 'error'}));
            //         });
            // })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());

                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }

    function request() {
        return {
            type: modalConstants.CHECK_GROUP_REQUEST,
        };
    }

    function success(info) {
        return {
            type: modalConstants.CHECK_GROUP_SUCCESS,
            payload: {
                info,
            },
        };
    }

    function failure() {
        return {
            type: modalConstants.CHECK_GROUP_ERROR,
        };
    }

    // function requestGetAllGroups() {
    //     return {
    //         type: dataListsManagementConstants.GET_ALL_GROUPS_REQUEST,
    //     };
    // }

    // function successGetAllGroups(groups) {
    //     return {
    //         type: dataListsManagementConstants.GET_ALL_GROUPS_SUCCESS,
    //         payload: {
    //             groups,
    //         },
    //     };
    // }

    // function failureGetAllGroups() {
    //     return {
    //         type: dataListsManagementConstants.GET_ALL_GROUPS_ERROR,
    //     };
    // }
}

function updateGroup(info) {
    return dispatch => {
        // if (info.timeToStopLab < info.defaultTimeToStopLab || info.timeToDeleteLab < info.defaultTimeToDeleteLab) {
        //     check(dispatch)
        // } else {
        //     main(dispatch)
        // }
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        groupsManagementService
            .updateGroup(
                info.id,
                info.groupName,
                info.fixedNetID,
                Number(info.timeToStopLab),
                Number(info.timeToDeleteLab),
                info.updating,
            )
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Group updated successfully', {variant: 'success'}));
                dispatch(requestGetAllGroups());

                groupsManagementService
                    .getAll()
                    .then(groups => {
                        dispatch(successGetAllGroups(groups.data));
                    })
                    .catch(error => {
                        dispatch(failureGetAllGroups(error));
                        dispatch(enqueueNotification(String(error), {variant: 'error'}));
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());

                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }

    function request() {
        return {
            type: modalConstants.UPDATE_GROUP_REQUEST,
        };
    }

    function success() {
        return {
            type: modalConstants.UPDATE_GROUP_SUCCESS,
        };
    }

    function failure() {
        return {
            type: modalConstants.UPDATE_GROUP_ERROR,
        };
    }

    function requestGetAllGroups() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_REQUEST,
        };
    }

    function successGetAllGroups(groups) {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_SUCCESS,
            payload: {
                groups,
            },
        };
    }

    function failureGetAllGroups() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_ERROR,
        };
    }
}

// function updateGroupShowNotificationModal(info) {
//     return {
//         type: modalConstants.UPDATE_GROUP_SHOW_NOTIFICATION_MODAL,
//         payload: {
//             info: info.data,
//             text: 'Timer changes makes some labs in group change status automatically. Are you sure?',
//             method: notificationModalActions.UPDATE_GROUP,
//         },
//     };
// }

function updateTime(info) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        groupsManagementService
            .updateTime(
                info.id,
                info.newName,
                info.fixedNetId,
                Number(info.timeToStopLab),
                Number(info.timeToDeleteLab),
                info.updating,
            )
            .then(() => {
                dispatch(success());

                dispatch(enqueueNotification('Group updated successfully', {variant: 'success'}));
            })
            .then(() => {
                dispatch(requestGetAllGroups());
                groupsManagementService
                    .getAll()
                    .then(groups => {
                        dispatch(successGetAllGroups(groups.data));
                    })
                    .catch(error => {
                        dispatch(failureGetAllGroups(error));
                        dispatch(enqueueNotification(String(error), {variant: 'error'}));
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());

                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.UPDATE_GROUP_REQUEST,
        };
    }

    function success() {
        return {
            type: modalConstants.UPDATE_GROUP_SUCCESS,
        };
    }

    function failure() {
        return {
            type: modalConstants.UPDATE_GROUP_ERROR,
        };
    }

    function requestGetAllGroups() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_REQUEST,
        };
    }

    function successGetAllGroups(groups) {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_SUCCESS,
            payload: {
                groups,
            },
        };
    }

    function failureGetAllGroups() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_ERROR,
        };
    }
}

function getGroupAdminGroups() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        groupsManagementService
            .getGroupAdminGroups()
            .then(groups => {
                dispatch(success(groups.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_REQUEST,
        };
    }
    function success(groups) {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_SUCCESS,
            payload: {
                groups,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_ERROR,
        };
    }
}

function getGroupAdminGroupsDropdown() {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        groupsManagementService
            .getGroupAdminGroupsDropdown()
            .then(groups => {
                dispatch(success(groups.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_DROPDOWN_REQUEST,
        };
    }
    function success(groups) {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_DROPDOWN_SUCCESS,
            payload: {
                groups,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_DROPDOWN_ERROR,
        };
    }
}

function updateTimeFrame(groupId, dateFrom, dateTo, startTime, endTime, daysOfWeek, isActive, page, size) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        groupsManagementService
            .updateTimeFrame(groupId, dateFrom, dateTo, startTime, endTime, daysOfWeek, isActive)
            .then(() => {
                dispatch(success());

                dispatch(requestGetAllGroups());
                groupsManagementService
                    .getAll(page, size)
                    .then(groups => {
                        dispatch(successGetAllGroups(groups.data));
                    })
                    .catch((error) => {
                        dispatch(failureGetAllGroups());
                        dispatch(enqueueNotification(String(error), {variant: 'error'}));
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.UPDATE_TIME_FRAME_REQUEST,
        };
    }
    function success() {
        return {
            type: modalConstants.UPDATE_TIME_FRAME_SUCCESS,
            payload: {},
        };
    }
    function failure() {
        return {
            type: modalConstants.UPDATE_TIME_FRAME_ERROR,
        };
    }
    function requestGetAllGroups() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_REQUEST,
        };
    }
    function successGetAllGroups(groups) {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_SUCCESS,
            payload: {
                groups,
            },
        };
    }
    function failureGetAllGroups() {
        return {
            type: dataListsManagementConstants.GET_ALL_GROUPS_ERROR,
        };
    }
}

function changePage(value) {
    return {
        type: dataListsManagementConstants.CHANGE_GROUPS_PAGE,
        payload: {
            value,
        },
    };
}

function changeSize(value) {
    return {
        type: dataListsManagementConstants.CHANGE_GROUPS_SIZE,
        payload: {
            value,
        },
    };
}

function changeSearch(value) {
    return {
        type: dataListsManagementConstants.CHANGE_GROUPS_SEARCH,
        payload: {
            value,
        },
    };
}

function performGroupsSearch() {
    return {
        type: dataListsManagementConstants.PERFORM_GROUPS_SEARCH,
        payload: {},
    };
}
