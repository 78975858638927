export const addLabTemplateConstants = {
    CHANGE_BLUEPRINT_NAME: 'CHANGE_BLUEPRINT_NAME',
    CHANGE_BLUEPRINT_NAME_SUCCESS: 'CHANGE_BLUEPRINT_NAME_SUCCESS',
    CHANGE_BLUEPRINT_NAME_ERROR: 'CHANGE_BLUEPRINT_NAME_ERROR',
    CHANGE_JSON_EDIT_BLUEPRINT_STATE: 'CHANGE_JSON_EDIT_BLUEPRINT_STATE',
    UPLOAD_LAB_TEMPLATE_JSON_FILE: 'UPLOAD_LAB_TEMPLATE_JSON_FILE',
    PARSE_JSON_FILE: 'PARSE_JSON_FILE',
    CHANGE_JSON_FILE: 'CHANGE_JSON_FILE',
    CHANGE_LAB_TYPE: 'CHANGE_LAB_TYPE',
    DISCARD_TEMPLATE: 'DISCARD_TEMPLATE',

    GET_JSON_BY_ID_REQUEST: 'GET_JSON_BY_ID_REQUEST',
    GET_JSON_BY_ID_SUCCESS: 'GET_JSON_BY_ID_SUCCESS',
    GET_JSON_BY_ID_ERROR: 'GET_JSON_BY_ID_ERROR',

    GET_ALL_BLUEPRINTS_REQUEST: 'GET_ALL_BLUEPRINTS_REQUEST',
    GET_ALL_BLUEPRINTS_SUCCESS: 'GET_ALL_BLUEPRINTS_SUCCESS',
    GET_ALL_BLUEPRINTS_ERROR: 'GET_ALL_BLUEPRINTS_ERROR',

    VERIFY_BLUEPRINT_REQUEST: 'VERIFY_BLUEPRINT_REQUEST',
    VERIFY_BLUEPRINT_SUCCESS: 'VERIFY_BLUEPRINT_SUCCESS',
    VERIFY_BLUEPRINT_ERROR: 'VERIFY_BLUEPRINT_ERROR',

    SAVE_AS_DRAFT_REQUEST: 'SAVE_AS_DRAFT_REQUEST',
    SAVE_AS_DRAFT_SUCCESS: 'SAVE_AS_DRAFT_SUCCESS',
    SAVE_AS_DRAFT_ERROR: 'SAVE_AS_DRAFT_ERROR',

    CHANGE_VERSION: 'CHANGE_VERSION',
    CHANGE_COMMENT: 'CHANGE_COMMENT',
    CLEAR_JSON: 'CLEAR_JSON',
    CHANGE_DESCRIPTION: 'CHANGE_DESCRIPTION',

    OPEN_CONFIRM_LOAD_FILE_MODAL_NOTIFICATION_MODAL: 'OPEN_CONFIRM_LOAD_FILE_MODAL_NOTIFICATION_MODAL',
    OPEN_CONFIRM_SELECT_FILE_MODAL_NOTIFICATION_MODAL: 'OPEN_CONFIRM_SELECT_FILE_MODAL_NOTIFICATION_MODAL',
};
