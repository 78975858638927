export const addSeminarModalConstants = {
    TOGGLE_MODAL: 'TOGGLE_ADD_SEMINAR_MODAL',

    GET_MODAL_DATA_REQUEST: 'GET_ADD_SEMINAR_MODAL_DATA_REQUEST',
    GET_MODAL_DATA_SUCCESS: 'GET_ADD_SEMINAR_MODAL_DATA_SUCCESS',
    GET_MODAL_DATA_ERROR: 'GET_ADD_SEMINAR_MODAL_DATA_ERROR',

    REQUEST: 'ADD_SEMINAR_MODAL_REQUEST',

    GET_ALL_SUBSCRIPTIONS_SUCCESS: 'ADD_SEMINAR_MODAL_GET_ALL_SUBSCRIPTIONS_SUCCESS',
    GET_ALL_SUBSCRIPTIONS_ERROR: 'ADD_SEMINAR_MODAL_GET_ALL_SUBSCRIPTIONS_ERROR',

    CHANGE_SUBSCRIPTION: 'ADD_SEMINAR_MODAL_CHANGE_SUBSCRIPTION',
    CHANGE_LAB_TEMPLATE: 'ADD_SEMINAR_MODAL_CHANGE_LAB_TEMPLATE',
    CHANGE_FIXED_NET_ID: 'CHANGE_FIXED_NET_ID',
    CHANGE_FORM_DATA: 'ADD_SEMINAR_MODAL_CHANGE_FORM_DATA',
    CHANGE_DATE: 'ADD_SEMINAR_MODAL_CHANGE_DATE',

    GET_ALL_GROUP_ADMINS_SUCCESS: 'ADD_SEMINAR_MODAL_GET_ALL_GROUP_ADMINS_SUCCESS',
    GET_ALL_GROUP_ADMINS_ERROR: 'ADD_SEMINAR_MODAL_GET_ALL_GROUP_ADMINS_ERROR',
    CHANGE_GROUP_ADMIN: 'ADD_SEMINAR_MODAL_CHANGE_GROUP_ADMIN',

    ADD_SEMINAR_SUCCESS: 'ADD_SEMINAR_SUCCESS',
    ADD_SEMINAR_ERROR: 'ADD_SEMINAR_ERROR',

    SHOW_DROP_DOWNS_ERROR: 'ADD_SEMINAR_MODAL_SHOW_DROP_DOWNS_ERROR',
    SHOW_ERROR: 'ADD_SEMINAR_MODAL_SHOW_ERROR',
    HIDE_ERROR: 'ADD_SEMINAR_MODAL_HIDE_ERROR',
};
