import { reportsManagementConstants } from '../../Constants';
import { reducersUtil } from '../../Utils/reducers.util';
import moment from 'moment/moment';
import _ from "lodash";

const initialState = {
    needToUpdate: false,
    date: {
        from: moment().subtract(7, 'days'),
        to: moment().add(1, 'days'),
    },
    users: ['default'],
    usersList: [],
    groups: ['default'],
    groupsList: [],
    templates: ['default'],
    templatesList: [],
    reports: [],
    showEmptyValue: false,
    requestIsActive: false,
    includeTestData: false,
    filters: {
        items: [
            {
                name: 'User',
                id: 1,
                ident: 'user_id',
            },
            {
                name: 'Group',
                id: 2,
                ident: 'group_id',
            },
            {
                name: 'Template',
                id: 3,
                ident: 'template_id',
            },
        ],
    },
    size: 20,
    sizeList: [10, 20, 30],
    page: 0,
    total: 3,
    totalElements: 0,
    expanded: false,
};

export function reportsManagement(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case reportsManagementConstants.GET_REPORTS_FILTERS_SUCCESS:
            return {
                ...state,
                usersList: action.payload.users,
                groupsList: action.payload.groups,
                templatesList: action.payload.templates,
            };
        case reportsManagementConstants.CHANGE_USER_FILTER:
            return {
                ...state,
                users: action.payload.value,
            };
        case reportsManagementConstants.CHANGE_GROUP_FILTER:
            return {
                ...state,
                groups: action.payload.value,
            };
        case reportsManagementConstants.CHANGE_TEMPLATE_FILTER:
            return {
                ...state,
                templates: action.payload.value,
            };
        case reportsManagementConstants.SET_REPORTS_DATE_ACTION: {
            let date = state.date;
            date[action.payload.action] = action.payload.value;
            return {
                ...state,
                date: date,
            };
        }
        case reportsManagementConstants.GET_REPORTS_REQUEST: {
            return {
                ...state,
                requestIsActive: true,
            };
        }
        case reportsManagementConstants.GET_REPORTS_ERROR: {
            return {
                ...state,
                requestIsActive: false,
            };
        }
        case reportsManagementConstants.GET_REPORTS_SUCCESS: {
            let page = action.payload.reports.totalPages === 1 ? 0 : state.page;
            return {
                ...state,
                needToUpdate: false,
                reports: action.payload.reports.content,
                requestIsActive: false,
                totalElements: action.payload.reports.totalElements,
                totalPages: action.payload.reports.totalPages,
                page: page,
            };
        }
        case reportsManagementConstants.SET_FILTERS_ORDER: {
            return {
                ...state,
                filters: action.payload.filters,
            };
        }
        case reportsManagementConstants.GET_SUB_REPORTS_SUCCESS: {
            const res = reducersUtil.findActiveReport(state.reports, action.payload.itemId, action.payload.subReports);
            return {
                ...state,
                // reports: reducersUtil.findActiveReport(state.reports, action.payload.firstGroupId, action.payload.subReports),
                reports: JSON.parse(JSON.stringify(res)),
            };
        }
        case reportsManagementConstants.GET_SUB_SUB_REPORTS_SUCCESS: {
            const subSubReports = reducersUtil.findActiveSubReport(
                state.reports,
                action.payload.firstGroupId,
                action.payload.secondGroupId,
                action.payload.subSubReports,
            );
            return {
                ...state,
                // reports: reducersUtil.findActiveReport(state.reports, action.payload.firstGroupId, action.payload.subReports),
                reports: JSON.parse(JSON.stringify(subSubReports)),
            };
        }
        case reportsManagementConstants.CLEAR_REPORTS: {
            return {
                ...state,
                reports: initialState.reports,
                expanded: false,
            };
        }
        case reportsManagementConstants.CHANGE_REPORTS_PAGE: {
            return {
                ...state,
                page: action.payload.value,
            };
        }
        case reportsManagementConstants.CHANGE_REPORTS_SIZE: {
            return {
                ...state,
                size: action.payload.value,
            };
        }
        case reportsManagementConstants.CLOSE_SUB_REPORTS: {
            return {
                ...state,
                needToUpdate: true,
            };
        }
        case reportsManagementConstants.GET_EMPTY_VALUE: {
            const { showEmptyValue } = state;
            if (!showEmptyValue) {
                return {
                    ...state,
                    showEmptyValue: true,
                };
            } else {
                return {
                    ...state,
                    showEmptyValue: false,
                };
            }
        }

        case reportsManagementConstants.TOGGLE_INCLUDE_TEST_DATA:
            return {
                ...state,
                includeTestData: !state.includeTestData
            }

        case reportsManagementConstants.SET_EXPANDED: {
            const { expanded } = state;
            if (!expanded) {
                return {
                    ...state,
                    expanded: true,
                };
            } else {
                return {
                    ...state,
                    expanded: false,
                };
            }
        }

        default:
            return state;
    }
}
