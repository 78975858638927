import { errorRequest, addSeminarModalConstants, seminarConstants } from '../../Constants';
import { userService, seminarService } from '../../Services';
import {notifierActions} from "../notifier.actions";

const {enqueueNotification} = notifierActions;

export const addSeminarModalActions = {
    toggleAddSeminarModal,
    changeSubscription,
    changeLabTemplate,
    changeFixedNetId,
    changeForm,
    showError,
    hideError,
    changeDate,
    changeGroupAdmin,
    addSeminar,
    showDropDownsError,
};

function toggleAddSeminarModal() {
    return (dispatch, getState) => {
        const {
            addSeminarModal: { modalIsOpen },
        } = getState();
        toggle(dispatch);
        if (!modalIsOpen) {
            main(dispatch);
        }
    };
    function main(dispatch) {
        dispatch(request());
        seminarService
            .toggleAddSeminarModal()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log('Error', error);
                dispatch(failure());
            });
    }
    function request() {
        return {
            type: addSeminarModalConstants.GET_MODAL_DATA_REQUEST,
        };
    }
    function success(data) {
        return {
            type: addSeminarModalConstants.GET_MODAL_DATA_SUCCESS,
            payload: {
                data,
            },
        };
    }
    function failure() {
        return {
            type: addSeminarModalConstants.GET_MODAL_DATA_ERROR,
        };
    }
    function toggle(dispatch) {
        dispatch({
            type: addSeminarModalConstants.TOGGLE_MODAL,
        });
    }
}

function changeSubscription(value) {
    return {
        type: addSeminarModalConstants.CHANGE_SUBSCRIPTION,
        payload: {
            value,
        },
    };
}

function changeLabTemplate(value) {
    return {
        type: addSeminarModalConstants.CHANGE_LAB_TEMPLATE,
        payload: {
            value,
        },
    };
}

function changeFixedNetId() {
    return {
        type: addSeminarModalConstants.CHANGE_FIXED_NET_ID,
        payload: {},
    };
}

function changeForm(target) {
    return {
        type: addSeminarModalConstants.CHANGE_FORM_DATA,
        payload: {
            target,
        },
    };
}

function showError(target) {
    return {
        type: addSeminarModalConstants.SHOW_ERROR,
        payload: {
            target,
        },
    };
}

function hideError() {
    return {
        type: addSeminarModalConstants.HIDE_ERROR,
    };
}

function changeDate(type, value) {
    return {
        type: addSeminarModalConstants.CHANGE_DATE,
        payload: {
            type,
            value,
        },
    };
}

function changeGroupAdmin(value) {
    return {
        type: addSeminarModalConstants.CHANGE_GROUP_ADMIN,
        payload: {
            value,
        },
    };
}

function addSeminar(page, size, labTemplateIDs, adminIds) {
    return (dispatch, getState) => {
        const { addSeminarModal } = getState();
        main(dispatch, addSeminarModal);
    };
    function main(dispatch, addSeminarModal) {
        dispatch(request());
        seminarService
            .create(addSeminarModal, labTemplateIDs, adminIds)
            .then(() => {
                dispatch(successAddSeminar());

                dispatch(enqueueNotification('Seminar added successfully', {variant: 'success'}));

                seminarService
                    .getAll(page, size)
                    .then(response => {
                        dispatch(successGetSeminars(response.data.content, response.data.totalElements));
                    })
                    .catch(() => {
                        dispatch(failureGetSeminars());
                    });
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failureAddSeminar());
                    dispatch(enqueueNotification(String(error), {variant: 'error'}));
                }
            });
    }
    function request() {
        return {
            type: addSeminarModalConstants.REQUEST,
        };
    }
    function successAddSeminar() {
        return {
            type: addSeminarModalConstants.ADD_SEMINAR_SUCCESS,
        };
    }
    function failureAddSeminar() {
        return {
            type: addSeminarModalConstants.ADD_SEMINAR_ERROR,
        };
    }
    function successGetSeminars(seminars, totalElements) {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_SUCCESS,
            payload: {
                seminars,
                totalElements,
            },
        };
    }
    function failureGetSeminars() {
        return {
            type: seminarConstants.GET_ALL_SEMINARS_ERROR,
        };
    }
}

function showDropDownsError() {
    return {
        type: addSeminarModalConstants.SHOW_DROP_DOWNS_ERROR,
    };
}
