export const seminarConstants = {
    REQUEST: 'SEMINARS_REQUEST',

    GET_ALL_SEMINARS_SUCCESS: 'GET_ALL_SEMINARS_SUCCESS',
    GET_ALL_SEMINARS_ERROR: 'GET_ALL_SEMINARS_ERROR',
    LOAD_ALL_SEMINARS_SUCCESS: 'LOAD_ALL_SEMINARS_SUCCESS',
    LOAD_ALL_SEMINARS_ERROR: 'LOAD_ALL_SEMINARS_ERROR',

    DELETE_SEMINAR_SUCCESS: 'DELETE_SEMINAR_SUCCESS',
    DELETE_SEMINAR_ERROR: 'DELETE_SEMINAR_ERROR',

    DELETE_SEMINAR_SHOW_NOTIFICATION_MODAL: 'DELETE_SEMINAR_SHOW_NOTIFICATION_MODAL',
    EMD_SEMINAR_SHOW_NOTIFICATION_MODAL: 'EMD_SEMINAR_SHOW_NOTIFICATION_MODAL',
    END_SEMINAR_GROUP_ADMIN_SHOW_NOTIFICATION_MODAL: 'END_SEMINAR_GROUP_ADMIN_SHOW_NOTIFICATION_MODAL',
    CHANGE_STATUS_SEMINAR_SUCCESS: 'CHANGE_STATUS_SEMINAR_SUCCESS',
    CHANGE_STATUS_SEMINAR_ERROR: 'CHANGE_STATUS_SEMINAR_ERROR',

    CHANGE_SEMINAR_PAGE: 'CHANGE_SEMINAR_PAGE',
    CHANGE_SEMINAR_SIZE: 'CHANGE_SEMINAR_SIZE',
    CHANGE_SEMINAR_SEARCH: 'CHANGE_SEMINAR_SEARCH',
    PERFORM_SEMINAR_SEARCH: 'PERFORM_SEMINAR_SEARCH',
};
