import { modalConstants } from '../../Constants';
import { reducersUtil } from '../../Utils';
import _ from "lodash";

const initialState = {
    info: {
        groupName: '',
        subscriptionID: '',
        limitation: '',
        timeToStopLab: 8,
        // timeToStopLab: '8 hours',
        timeToDeleteLab: 9,
        // timeToDeleteLab: '9 hours',
        fixedNetID: false,
    },
    warningMessagesStuff: {
        errorMessage: '',
    },
    requestIsActive: false,
    addGroupModalIsOpen: false,
    subscriptions: [],
};

export function addGroupModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.TOGGLE_ADD_GROUP_MODAL: {
            if (state.addGroupModalIsOpen) {
                return initialState;
            } else {
                return {
                    ...initialState,
                    addGroupModalIsOpen: true,
                    subscriptions: action.payload.subscriptions,
                };
            }
        }
        case modalConstants.ADD_NEW_GROUP_REQUEST:
            return {
                ...state,
                warningMessagesStuff: {},
                requestIsActive: true,
            };
        case modalConstants.ADD_NEW_GROUP_SUCCESS:
            return initialState;
        case modalConstants.ADD_NEW_GROUP_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case modalConstants.ADD_NEW_GROUP_CHANGE_FORM: {
            const { name, value } = action.payload.event.target;
            let parsedValue = value;
            if (name === 'limitation' || name === 'timeToStopLab' || name === 'timeToDeleteLab') {
                parsedValue = value.replace(/[^0-9.]+/gi, '');
            }
            return {
                ...state,
                info: {
                    ...state.info,
                    [name]: parsedValue,
                },
            };
        }
        case modalConstants.ADD_NEW_GROUP_SHOW_ERROR_MESSAGE: {
            const { value, name } = action.payload.event.target;
            return {
                ...state,
                warningMessagesStuff: {
                    ...state.warningMessagesStuff,
                    errorMessage: reducersUtil.generateGroupManagementErrorMessage(value, name),
                },
            };
        }
        case modalConstants.ADD_NEW_GROUP_HIDE_ERROR_MESSAGE:
            return {
                ...state,
                warningMessagesStuff: {
                    ...state.warningMessagesStuff,
                    errorMessage: '',
                },
            };
        case modalConstants.ADD_GROUP_MODAL_SET_SUBSCRIPTION:
            return {
                ...state,
                info: {
                    ...state.info,
                    subscriptionID: action.payload.value,
                },
            };
        case modalConstants.ADD_GROUP_MODAL_CHANGE_FIXED_ID:
            return {
                ...state,
                info: {
                    ...state.info,
                    fixedNetID: !state.info.fixedNetID,
                },
            };
        default:
            return state;
    }
}
