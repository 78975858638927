import { addLabTemplateConstants, modalConstants } from '../../Constants';
import _ from "lodash";

// const initialState = {
//     isRequestActive: false,
//     blueprintName: '',
//     instanceTypeID: '',
//     instanceTypeName: '',
//     json: '',
//     info: {
//         acceptedFile: '',
//         name: '',
//     },
//     warningMessages: {
//         errorMessage: '',
//         wrongFileError: '',
//     },
//     version: 1,
//     comment: '',
//     confirmLoadFileModal: {
//         text: '',
//         isOpen: false,
//         method: '',
//     },
//     confirmSelectFileModal: {
//         text: '',
//         isOpen: false,
//         method: '',
//     },
//     blueprintId: '',
// };

const initialState = {
    isRequestActive: false,
    blueprintName: '',
    blueprintId: '',
    templateId: null,
    path: '',
    json: {},
    fileName: '',
    comment: '',
    status: undefined,
    editable: false,
    isJsonEdited: false,
    error: null,
    confirmLoadFileModal: {
        text: '',
        isOpen: false,
        method: '',
        templateId: null
    },
    confirmSelectFileModal: {
        text: '',
        isOpen: false,
        method: '',
    },
    currentVersion: null,
    versions: [],
    instanceVersion: null,
    isNameChanged: null,
    description: '',
    isSaving: false
};

export function addLabTemplate(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case addLabTemplateConstants.CHANGE_BLUEPRINT_NAME: {
            return {
                ...state,
                blueprintName: action.payload.blueprintName
            };
        }
        case addLabTemplateConstants.CHANGE_JSON_EDIT_BLUEPRINT_STATE: {
            return {
                ...state,
                isJsonEdited: action.payload.isJsonEdited,
            };
        }
        case addLabTemplateConstants.CHANGE_BLUEPRINT_NAME_SUCCESS: {
            return {
                ...state,
                isNameChanged: true
            }
        }
        case addLabTemplateConstants.CHANGE_BLUEPRINT_NAME_ERROR: {
            return {
                ...state,
                isNameChanged: false
            }
        }
        case addLabTemplateConstants.UPLOAD_LAB_TEMPLATE_JSON_FILE: {
            return {
                ...state,
                // blueprintName: action.payload.blueprintName,
                fileName: action.payload.fileName,
                path: action.payload.path,
                currentVersion: null,
                templateId: action.payload.templateId,
                confirmLoadFileModal: {
                    text: '',
                    isOpen: false,
                    method: '',
                },
                json: {},
                comment: '',
            };
        }
        case addLabTemplateConstants.PARSE_JSON_FILE: {
            return {
                ...state,
                json: action.payload.file,
                isJsonEdited: true,
            };
        }
        case addLabTemplateConstants.CHANGE_JSON_FILE: {
            return {
                ...state,
                json: action.payload.file,
                isJsonEdited: true,
            };
        }
        case addLabTemplateConstants.CHANGE_LAB_TYPE: {
            return {
                ...state,
                fileName: action.payload.fileName,
                path: action.payload.path,
                isJsonEdited: true,
            };
        }
        case addLabTemplateConstants.GET_JSON_BY_ID_REQUEST: {
            return {
                ...state,
                json: {},
                isRequestActive: true,
            };
        }
        case addLabTemplateConstants.GET_JSON_BY_ID_SUCCESS: {
            return {
                ...state,
                isRequestActive: false,
                isJsonEdited: true,
                json: action.payload.json,
                instanceVersion: action.payload.currentVersion.id,
                versions: action.payload.versions,
                confirmSelectFileModal: {
                    text: '',
                    isOpen: '',
                    method: '',
                    info: '',
                },
            };
        }
        case addLabTemplateConstants.GET_JSON_BY_ID_ERROR: {
            return {
                ...state,
                isRequestActive: false,
                isJsonEdited: false,
                confirmSelectFileModal: {
                    text: '',
                    isOpen: '',
                    method: '',
                    info: '',
                },
            };
        }
        case addLabTemplateConstants.DISCARD_TEMPLATE: {
            return initialState;
        }
        case addLabTemplateConstants.VERIFY_BLUEPRINT_REQUEST: {
            return {
                ...state,
                isRequestActive: true,
                isJsonEdited: false,
            };
        }
        case addLabTemplateConstants.VERIFY_BLUEPRINT_SUCCESS: {
            return initialState;
        }
        case addLabTemplateConstants.VERIFY_BLUEPRINT_ERROR: {
            return {
                ...state,
                isRequestActive: false,
                isJsonEdited: false,
            };
        }
        case addLabTemplateConstants.CHANGE_VERSION: {
            let versionObj = state.versions.find(version => version.id === action.payload);
            let newJson = JSON.parse(versionObj.json, null, 2);
            return {
                ...state,
                instanceVersion: action.payload,
                json: newJson,
                isJsonEdited: true,
            };
        }
        case addLabTemplateConstants.CHANGE_COMMENT: {
            return {
                ...state,
                comment: action.payload.comment,
            };
        }
        case addLabTemplateConstants.CHANGE_DESCRIPTION:
            return {
                ...state,
                description: action.payload,
                isJsonEdited: true
            }

        case addLabTemplateConstants.CLEAR_JSON:
            return {
                ...state,
                json: {},
                isJsonEdited: true,
            };

        case addLabTemplateConstants.OPEN_CONFIRM_SELECT_FILE_MODAL_NOTIFICATION_MODAL: {
            return {
                ...state,
                confirmSelectFileModal: {
                    text: action.payload.text,
                    isOpen: action.payload.isOpen,
                    method: action.payload.method,
                    info: action.payload.info,
                },
            };
        }
        case addLabTemplateConstants.OPEN_CONFIRM_LOAD_FILE_MODAL_NOTIFICATION_MODAL: {
            return {
                ...state,
                confirmLoadFileModal: {
                    text: action.payload.text,
                    isOpen: action.payload.isOpen,
                    method: action.payload.method,
                    acceptedFile: action.payload.acceptedFile,
                    templateId: action.payload.templateId
                },
            };
        }
        case modalConstants.CLOSE_NOTIFICATION_MODAL: {
            return {
                ...state,
                confirmLoadFileModal: {
                    text: '',
                    isOpen: false,
                    method: '',
                },
                confirmSelectFileModal: {
                    text: '',
                    isOpen: false,
                    method: '',
                    info: '',
                },
            };
        }

        case addLabTemplateConstants.SAVE_AS_DRAFT_REQUEST:
            return {
                ...state,
                isSaving: true
            }

        case addLabTemplateConstants.SAVE_AS_DRAFT_SUCCESS:
            return {
                ...state,
                blueprintId: action.payload.blueprintId,
                blueprintName: action.payload.name,
                path: action.payload.path,
                fileName: action.payload.fileName,
                status: action.payload.state,
                isJsonEdited: false,
                description: action.payload.currentVersion ? action.payload.currentVersion.description || '' : '',
                currentVersion: action.payload.currentVersion ? action.payload.currentVersion.id : null,
                templateId: action.payload.id,
                isSaving: false
            };
        default:
            return state;
    }
}
