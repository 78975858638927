import { modalConstants } from '../../Constants';

export function countdownModal(
    state = {
        list: [],
        countdownModalIsOpen: false,
    },
    action,
) {
    switch (action.type) {
        case modalConstants.SHOW_COUNTDOWN_MODAL:
            return {
                ...state,
                countdownModalIsOpen: true,
            };
        case modalConstants.UPDATE_LIST_OF_COUNTDOWN_LABS:
            return {
                ...state,
                countdownModalIsOpen: true,
                list: action.payload.list,
            };
        case modalConstants.HIDE_COUNTDOWN_MODAL:
            return {
                ...state,
                countdownModalIsOpen: false,
            };
        default:
            return state;
    }
}
