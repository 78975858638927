import {confirmModalConstants} from "../../Constants/Actions/confirmModal.constants";
import _ from "lodash";

const initialState = {
    open: false,
    title: '',
    message: '',
    onCancel: () => {},
    onConfirm: () => {},
    cancelLabel: 'No',
    confirmLabel: 'Yes'
};

export function confirmModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case confirmModalConstants.OPEN_CONFIRM_MODAL:
            return {
                ...state,
                open: true,
                title: action.payload.title,
                message: action.payload.message,
                onCancel: action.payload.onCancel,
                onConfirm: action.payload.onConfirm,
                cancelLabel: action.payload.cancelLabel || initialState.cancelLabel,
                confirmLabel: action.payload.confirmLabel || initialState.confirmLabel
            }

        case confirmModalConstants.CLOSE_CONFIRM_MODAL:
            return {
                ...state,
                open: false
            }

        default:
            return state;
    }
}