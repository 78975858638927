import {labTemplateInstancesConstants} from "../../Constants";

const initialState = {
    labs: [],
    activeInstanceId: null
};

export function labTemplateInstances(state = initialState, action) {
    switch (action.type) {
        case labTemplateInstancesConstants.GET_LAB_TEMPLATE_INSTANCES_SUCCESS:
            const labs = action.payload.sort((a,b) => b.id - a.id);
            return {
                ...state,
                activeInstanceId: labs[0].id,
                labs: action.payload
            }

        case labTemplateInstancesConstants.SET_LAB_TEMPLATE_ACTIVE_INSTANCE:
            return {
                ...state,
                activeInstanceId: action.payload
            }

        default:
            return state;
    }
}