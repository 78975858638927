import { errorRequest, notificationModalActions } from '../Constants';
import { modalConstants, dataListsManagementConstants } from '../Constants';
import {
    userService,
    subscriptionsManagementService,
    usersAssignmentService,
    userManagementService,
    instancesTypesManagementService,
} from '../Services';
import { statusModalActions } from './statusModal.actions';
import { instancesTypesActions } from './instancesTypesManagement.actions';
import {notifierActions} from "./notifier.actions";

const {enqueueNotification} = notifierActions;
const { getAllInstancesTypes } = instancesTypesActions;
const { openStatusModal } = statusModalActions;

export const modalActions = {
    toggleAddUserModal,
    toggleAddUsersBulkModal,
    toggleAddGroupModal,
    closeNotificationModal,
    showSignOutNotificationModal,
    toggleUpdateGroupModal,
    toggleAddLabModal,
    toggleShowUsersModal,
    closeShowUsersModal,
    toggleEditUserModal,
    toggleAddLabTemplateModal,
    toggleUpdateLabTemplateModal,
    openAssignGroupModal,
    toggleAssignGroupModal,
    openAssignLabTypeModal,
    toggleAssignLabTypeModal,
    showForgotPasswordModal,
    toggleMaintenanceNotificationModal,
    toggleLabLimitationModal,
    editLabLimitation,
    toggleTimeFrameModal,
    toggleTimeFrame,
    selectDay,
    changeDate,
    setTimeFrameDate,
    setTimeFrameTime,
    openTemplateVersionModal,
    closeTemplateVersionModal,
    setTemplateVersion,
    changeTemplateVersion,
    openTemplateShareVersionModal,
    closeTemplateShareVersionModal,
    setTemplateShare
};

function toggleAddUserModal() {
    return {
        type: modalConstants.TOGGLE_ADD_USER_MODAL,
    };
}

function toggleAddUsersBulkModal() {
    return {
        type: modalConstants.TOGGLE_ADD_USERS_BULK_MODAL,
    };
}

function toggleAddGroupModal(openModal, undisabledBtns = ()=>{}) {
    if (openModal) {
        return dispatch => {
            main(dispatch);
        };
    } else {
        undisabledBtns()
        return {
            type: modalConstants.TOGGLE_ADD_GROUP_MODAL,
        };
    }
    function main(dispatch) {
        dispatch(request());
        subscriptionsManagementService
            .getAllSubscriptions()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            }).finally(() => {
                undisabledBtns()
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.GET_SUBSCRIPTIONS_REQUEST,
        };
    }
    function success(subscriptions) {
        return {
            type: modalConstants.TOGGLE_ADD_GROUP_MODAL,
            payload: {
                subscriptions,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.GET_SUBSCRIPTIONS_ERROR,
        };
    }
}

function closeNotificationModal() {
    return {
        type: modalConstants.CLOSE_NOTIFICATION_MODAL,
    };
}

function showSignOutNotificationModal() {
    return {
        type: modalConstants.SHOW_SIGN_OUT_NOTIFICATION_MODAL,
        payload: {
            text: 'Are you sure you want to sign out?',
            method: notificationModalActions.SIGN_OUT,
        },
    };
}

function toggleUpdateGroupModal(id, groupName, fixedNetID, timeToStopLab, timeToDeleteLab, limitation, type) {
    return {
        type: modalConstants.TOGGLE_UPDATE_GROUP_MODAL,
        payload: {
            id,
            groupName,
            fixedNetID,
            timeToStopLab,
            timeToDeleteLab,
            limitation,
            type
        },
    };
}

function toggleAddLabModal(userID) {
    if (userID) {
        return {
            type: modalConstants.ADD_LAB_TOGGLE_MODAL,
            payload: {
                userID,
            },
        };
    } else {
        return {
            type: modalConstants.ADD_LAB_TOGGLE_MODAL,
        };
    }
}

function toggleShowUsersModal(groupID) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        usersAssignmentService
            .openShowUsersModal(groupID)
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure());
                }
            });
    }
    function request() {
        return {
            type: dataListsManagementConstants.TOGGLE_SHOW_USER_MODAL_REQUEST,
        };
    }
    function success(data) {
        return {
            type: dataListsManagementConstants.TOGGLE_SHOW_USER_MODAL_SUCCESS,
            payload: {
                data,
            },
        };
    }
    function failure() {
        return {
            type: dataListsManagementConstants.TOGGLE_SHOW_USER_MODAL_ERROR,
        };
    }
}

function closeShowUsersModal() {
    return {
        type: modalConstants.SHOW_USERS_MODAL_CLOSE,
    };
}

function toggleEditUserModal(userID, userEmail, username, limitation, maintenance) {
    return {
        type: modalConstants.TOGGLE_EDIT_USER_MODAL,
        payload: {
            userID,
            userEmail,
            username,
            limitationMinutes: limitation,
            maintenance,
        },
    };
}

function toggleAddLabTemplateModal() {
    return {
        type: modalConstants.TOGGLE_ADD_TEMPLATE_MODAL,
    };
}

function toggleUpdateLabTemplateModal(labTemplateID, labName) {
    return {
        type: modalConstants.TOGGLE_UPDATE_LAB_TEMPLATE_MODAL,
        payload: {
            labTemplateID,
            labName,
        },
    };
}

function openAssignGroupModal(userId) {
    // console.log('userId', userId);
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .getAssignedGroupsToGroupAdmin(userId)
            .then(response => {
                // console.log(1111);
                dispatch(success(response.data, userId));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    dispatch(failure());
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.GET_ASSIGNED_GROUPS_REQUEST,
        };
    }
    function success(groups, userId) {
        // console.log(1111111111111111111);
        return {
            type: modalConstants.GET_ASSIGNED_GROUPS_SUCCESS,
            payload: {
                userId,
                groups,
            },
        };
    }
    function failure() {
        return {
            type: modalConstants.GET_ASSIGNED_GROUPS_ERROR,
        };
    }
}

function toggleAssignGroupModal(userId) {
    return {
        type: modalConstants.TOGGLE_ASSIGN_GROUP_MODAL,
        payload: {
            userId,
        },
    };
}

function openAssignLabTypeModal(userId) {
    return dispatch => {
        main(dispatch);
    };
    function main(dispatch) {
        dispatch(request());
        userManagementService
            .getAssignedLabTypeToGroupAdmin(userId)
            .then(response => {
                dispatch(success(response.data, userId));
            })
            .catch(error => {
                if (error === errorRequest.UNAUTHORIZED) {
                    userService.refreshToken().then(() => main(dispatch));
                } else {
                    dispatch(failure(error));
                }
            });
    }
    function request() {
        return {
            type: modalConstants.GET_ASSIGNED_LAB_TYPES_REQUEST,
        };
    }
    function success(labs, userId) {
        return {
            type: modalConstants.GET_ASSIGNED_LAB_TYPES_SUCCESS,
            payload: {
                userId,
                labs,
            },
        };
    }
    function failure() {
        return {
            type: modalConstants.GET_ASSIGNED_LAB_TYPES_ERROR,
        };
    }
}

function toggleAssignLabTypeModal(userId) {
    return {
        type: modalConstants.TOGGLE_ASSIGN_LAB_TYPE_MODAL,
        payload: {
            userId,
        },
    };
}

function showForgotPasswordModal() {
    return dispatch => {
        dispatch(
            openStatusModal({
                text: 'If this email exists in the system, a reset link for your password will be sent there',
                status: true,
                forgotPassword: true,
            }),
        );
    };
}

function toggleMaintenanceNotificationModal() {
    return {
        type: modalConstants.TOGGLE_MAINTENANCE_NOTIFICATION_MODAL,
        payload: {
            method: notificationModalActions.TOGGLE_MAINTENANCE,
        },
    };
}

function toggleLabLimitationModal(labId, userId, groupId, limitation) {
    return {
        type: modalConstants.TOGGLE_LAB_LIMITATION_MODAL,
        payload: {
            labId,
            userId,
            groupId,
            limitation,
        },
    };
}

function editLabLimitation(limitation) {
    return {
        type: modalConstants.CHANGE_LAB_LIMITATION,
        payload: {
            limitation,
        },
    };
}

function toggleTimeFrameModal(groupId, period) {
    return {
        type: modalConstants.TOGGLE_TIME_FRAME_MODAL,
        payload: {
            groupId,
            period,
        },
    };
}

function toggleTimeFrame() {
    return {
        type: modalConstants.TOGGLE_TIME_FRAME,
        payload: {},
    };
}

function selectDay(day) {
    return {
        type: modalConstants.SELECT_TIME_FRAME_DAY,
        payload: {
            day,
        },
    };
}

function changeDate(type, value) {
    return {
        type: modalConstants.CHANGE_TIME_FRAME_DATE,
        payload: {
            type,
            value,
        },
    };
}

function setTimeFrameDate(action, value, date) {
    return {
        type: modalConstants.SET_TIME_FRAME_DATE,
        payload: {
            action,
            value,
            date,
        },
    };
}

function setTimeFrameTime(type, value) {
    return {
        type: modalConstants.SET_TIME_FRAME_TIME,
        payload: {
            type,
            value,
        },
    };
}

function changeTemplateVersion(versionId, versionNumber) {
    return {
        type: modalConstants.CHANGE_TEMPLATE_VERSION_SUCCESS,
        payload: { versionId, versionNumber },
    };
}

function setTemplateVersion(data, search, showNotification = true) {
    return (dispatch, getState) => {
        // Request pending
        dispatch({ type: modalConstants.CHANGE_TEMPLATE_VERSION_REQUEST });

        const { page, size } = getState().labTemplates.pagination;

        // Init request
        instancesTypesManagementService
            .setTemplateVersion(data)
            .then(() => {
                // Update Lab Templates table data after changing template version
                getAllInstancesTypes(page, size, search)(dispatch); // You should manually put dispatch in order to make things works.
                dispatch(closeTemplateVersionModal());

                showNotification && dispatch(enqueueNotification('Selected JSON template version will be applied on lab instance after template assigning', {variant: 'info'}));
            })
            .catch(err => {
                dispatch({ type: modalConstants.CHANGE_TEMPLATE_VERSION_ERROR });
                dispatch(enqueueNotification(err, {variant: 'error'}));
                console.log('Change version error: ', err);
            });
    };
}

function setTemplateShare(data, search, shared) {
    return (dispatch, getState) => {
        dispatch({
            type: modalConstants.CHANGE_TEMPLATE_SHARE_VERSION_REQUEST
        });

        const { page, size } = getState().labTemplates.pagination;

        instancesTypesManagementService
            .setTemplateShare(data)
            .then(() => {
                getAllInstancesTypes(page, size, search, shared)(dispatch);
                dispatch(closeTemplateShareVersionModal());
            })
            .catch(error => {
                dispatch({
                    type: modalConstants.CHANGE_TEMPLATE_SHARE_VERSION_ERROR
                });
                dispatch(enqueueNotification(error, {variant: 'error'}));
                console.error('Change shared error: ', error);
            })
    }
}

function openTemplateVersionModal(lab) {
    return {
        type: modalConstants.OPEN_TEMPLATE_VERSION_MODAL,
        payload: lab,
    };
}

function closeTemplateVersionModal() {
    return { type: modalConstants.CLOSE_TEMPLATE_VERSION_MODAL };
}

function openTemplateShareVersionModal(lab) {
    return {
        type: modalConstants.OPEN_TEMPLATE_SHARE_VERSION_MODAL,
        payload: lab
    }
}

function closeTemplateShareVersionModal() {
    return {
        type: modalConstants.CLOSE_TEMPLATE_SHARE_VERSION_MODAL
    }
}