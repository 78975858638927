import { urlConstant } from '../Constants';
import Maintenance from '../Modules/maintenance.module';
import {AxiosRequest} from "./AxiosApi";

const isRepair = Maintenance.getMaintenance();

export const subscriptionsManagementService = {
    getAllSubscriptions,
};

function getAllSubscriptions() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/groups/subscription`,
        })
            .then(response => {
                isRepair && console.log('getAllSubscriptions successful', response);
                resolve(response);
            })
            .catch(error => {
                // const errorMessage = subscriptionErrorRequests('get all subscriptions', error);
                // console.error(errorMessage, error);
                // reject(errorMessage);
            });
    });
}
