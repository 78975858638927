import { dataListsManagementConstants, modalConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    addRoleModalIsOpen: false,
    userID: '',
    groupId: '',
    requestIsActive: false,
    info: {
        value: 'SET_VALUE',
    },
};

export function addRoleModal(state = _.cloneDeep(initialState), action) {
    switch (action.type) {
        case modalConstants.TOGGLE_ADD_ROLE_MODAL: {
            if (state.addRoleModalIsOpen) {
                return initialState;
            } else {
                return {
                    ...state,
                    addRoleModalIsOpen: true,
                    userID: action.payload.userID,
                    groupId: action.payload.groupID,
                    info: {
                        value: action.payload.role,
                    },
                };
            }
        }
        case modalConstants.ADD_ROLE_CHANGE_FORM:
            return {
                ...state,
                info: {
                    ...state.info,
                    value: action.payload.value,
                },
            };
        case dataListsManagementConstants.SET_USER_ROLE_REQUEST:
            return {
                ...state,
                requestIsActive: true,
            };
        case dataListsManagementConstants.SET_USER_ROLE_SUCCESS:
            return initialState;
        case dataListsManagementConstants.SET_USER_ROLE_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        default:
            return state;
    }
}
