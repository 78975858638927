import { addLabTemplateConstants } from '../Constants/Actions/addLabTemplate.constants';
import { addLabTemplateService, instancesTypesManagementService, userService } from '../Services';
import { errorRequest, notificationModalActions } from '../Constants';
import { history } from '../Utils';
import {notifierActions} from "./notifier.actions";

const {enqueueNotification} = notifierActions;

export const addLabTemplateActions = {
    changeBluePrintName,
    uploadFile,
    parseJson,
    changeJson,
    changeLabType,
    discardTemplate,
    addBlueprint,
    saveAsDraft,
    changeVersion,
    changeComment,
    clearJson,
    openConfirmLoadFileModal,
    openConfirmSelectFileModal,
    discardBlueprintAndCreateNewDraft,
    changeJsonEditBlueprintState,
    changeDescription,
    saveAsDraftThenValidate
};

function changeJsonEditBlueprintState(isJsonEdited) {
    return {
        type: addLabTemplateConstants.CHANGE_JSON_EDIT_BLUEPRINT_STATE,
        payload: {
            isJsonEdited,
        },
    };
}

function changeBluePrintName(id, blueprintName, onSuccess, onError) {
    return dispatch => {
        mainRequest(dispatch);

        function mainRequest(dispatch) {
            instancesTypesManagementService
                .setBlueprintName(id, blueprintName)
                .then((res) => {
                    dispatch({
                        type: addLabTemplateConstants.CHANGE_BLUEPRINT_NAME_SUCCESS
                    });
                    onSuccess(res)
                })
                .catch(error => {
                    dispatch({
                        type: addLabTemplateConstants.CHANGE_BLUEPRINT_NAME_ERROR
                    })
                    onError(error);
                    if (error === errorRequest.UNAUTHORIZED) {
                        // Retry
                        userService.refreshToken().then(() => mainRequest(dispatch));
                    }
                    else {
                        dispatch(enqueueNotification(String(error), {variant: 'error'}));
                    }
                })
        }
    }
}

function uploadFile(acceptedFile) {
    return {
        type: addLabTemplateConstants.UPLOAD_LAB_TEMPLATE_JSON_FILE,
        payload: acceptedFile,
    };
}

function parseJson(file) {
    return {
        type: addLabTemplateConstants.PARSE_JSON_FILE,
        payload: {
            file,
        },
    };
}

function changeJson(file) {
    return {
        type: addLabTemplateConstants.CHANGE_JSON_FILE,
        payload: {
            file,
        },
    };
}

function changeLabType(fileInfo) {
    return dispatch => {
        // Request pending
        dispatch({ type: addLabTemplateConstants.GET_JSON_BY_ID_REQUEST });

        // Init request
        main(dispatch);

        // Request
        function main(dispatch) {
            addLabTemplateService
                .getJsonById(fileInfo.templateId)
                .then(response => {
                    // Request success
                    dispatch({
                        type: addLabTemplateConstants.CHANGE_LAB_TYPE,
                        payload: fileInfo,
                    });
                    dispatch({
                        type: addLabTemplateConstants.GET_JSON_BY_ID_SUCCESS,
                        payload: {
                            json: JSON.parse(response.data.currentVersion.json),
                            currentVersion: response.data.currentVersion,
                            versions: response.data.versions,
                        },
                    });
                })
                .catch(error => {
                    if (error === errorRequest.UNAUTHORIZED) {
                        // Retry
                        userService.refreshToken().then(() => main(dispatch));
                    } else {
                        // Request failed
                        dispatch({
                            type: addLabTemplateConstants.GET_JSON_BY_ID_ERROR,
                            payload: {
                                text: error,
                            },
                        });
                        dispatch(enqueueNotification(String(error), {variant: 'error'}));
                    }
                });
        }
    };
}

// Delete blueprint from DB and discard changes
function discardTemplate(blueprintId) {
    return dispatch => {
        instancesTypesManagementService
            .deleteDraftTemplate(blueprintId)
            .then(res => dispatch({ type: addLabTemplateConstants.DISCARD_TEMPLATE }))
            .catch(err => {
                if (err === errorRequest.UNAUTHORIZED) {
                }
                else {
                    dispatch(enqueueNotification(err, {variant: 'error'}));
                }
            });
    };
}

// Just remove blueprint data from redux and create new empty draft
function discardBlueprintAndCreateNewDraft() {
    return { type: addLabTemplateConstants.DISCARD_TEMPLATE };
}

function addBlueprint(blueprint) {
    return dispatch => {
        // Request pending
        dispatch({ type: addLabTemplateConstants.VERIFY_BLUEPRINT_REQUEST });

        // Init request
        main(dispatch);

        // Request
        function main(dispatch) {
            addLabTemplateService
                .addBlueprint(blueprint)
                .then(response => {
                    // TODO: дописать логику
                    dispatch({ type: addLabTemplateConstants.VERIFY_BLUEPRINT_SUCCESS });
                    history.push('lab-templates-management');
                })
                .catch(error => {
                    if (error === errorRequest.UNAUTHORIZED) {
                        // Retry
                        userService.refreshToken().then(() => main(dispatch));
                    } else {
                        // Request failed
                        dispatch({
                            type: addLabTemplateConstants.VERIFY_BLUEPRINT_ERROR,
                            payload: {
                                text: error,
                            },
                        });
                        dispatch(enqueueNotification(String(error), {variant: 'error'}));
                    }
                });
        }
    };
}

function saveAsDraft(draft, onSuccess) {
    return dispatch => {
        // Request pending
        dispatch({ type: addLabTemplateConstants.SAVE_AS_DRAFT_REQUEST });

        // Init request
        main(dispatch);

        // Request
        function main(dispatch) {
            instancesTypesManagementService
                .saveAsDraft(draft)
                .then(response => {
                    // Request success
                    dispatch({
                        type: addLabTemplateConstants.SAVE_AS_DRAFT_SUCCESS,
                        payload: response.data,
                    });
                    onSuccess && onSuccess(response.data);
                })
                .catch(error => {
                    if (error === errorRequest.UNAUTHORIZED) {
                        // Retry
                        userService.refreshToken().then(() => main(dispatch));
                    } else {
                        // Request failed
                        dispatch({
                            type: addLabTemplateConstants.SAVE_AS_DRAFT_ERROR,
                            payload: { text: error },
                        });
                        dispatch(enqueueNotification(String(error), {variant: 'error'}));
                    }
                });
        }
    };
}

function saveAsDraftThenValidate(draft) {
    return dispatch => {
        // Request pending
        dispatch({ type: addLabTemplateConstants.SAVE_AS_DRAFT_REQUEST });

        // Init request
        main(dispatch);

        // Request
        function main(dispatch) {
            instancesTypesManagementService
                .saveAsDraft(draft)
                .then(response => {
                    // Request success
                    dispatch({
                        type: addLabTemplateConstants.SAVE_AS_DRAFT_SUCCESS,
                        payload: response.data,
                    });

                    //
                    addLabTemplateService
                        .addBlueprint({templateId: response.data.id, verificationNeeded: draft.verificationNeeded})
                        .then(response => {
                            // TODO: дописать логику
                            dispatch({ type: addLabTemplateConstants.VERIFY_BLUEPRINT_SUCCESS });
                            history.push('lab-templates-management');
                        })
                        .catch(error => {
                            if (error === errorRequest.UNAUTHORIZED) {
                                // Retry
                                userService.refreshToken().then(() => main(dispatch));
                            } else {
                                // Request failed
                                dispatch({
                                    type: addLabTemplateConstants.VERIFY_BLUEPRINT_ERROR,
                                    payload: {
                                        text: error,
                                    },
                                });
                                dispatch(enqueueNotification(String(error), {variant: 'error'}));
                            }
                        });
                })
                .catch(error => {
                    if (error === errorRequest.UNAUTHORIZED) {
                        // Retry
                        userService.refreshToken().then(() => main(dispatch));
                    } else {
                        // Request failed
                        dispatch({
                            type: addLabTemplateConstants.SAVE_AS_DRAFT_ERROR,
                            payload: { text: error },
                        });
                        dispatch(enqueueNotification(String(error), {variant: 'error'}));
                    }
                });
        }
    };
}

function changeVersion(version) {
    return {
        type: addLabTemplateConstants.CHANGE_VERSION,
        payload: version,
    };
}

function changeComment(comment) {
    return {
        type: addLabTemplateConstants.CHANGE_COMMENT,
        payload: {
            comment,
        },
    };
}

function changeDescription(description) {
    return {
        type: addLabTemplateConstants.CHANGE_DESCRIPTION,
        payload: description
    }
}

function clearJson() {
    return { type: addLabTemplateConstants.CLEAR_JSON };
}

function openConfirmLoadFileModal(acceptedFile, templateId) {
    return {
        type: addLabTemplateConstants.OPEN_CONFIRM_LOAD_FILE_MODAL_NOTIFICATION_MODAL,
        payload: {
            text: 'Are you sure you want discard your JSON?',
            isOpen: true,
            method: notificationModalActions.UPLOAD_NEW_JSON,
            acceptedFile,
            templateId
        },
    };
}

function openConfirmSelectFileModal(info) {
    return {
        type: addLabTemplateConstants.OPEN_CONFIRM_SELECT_FILE_MODAL_NOTIFICATION_MODAL,
        payload: {
            text: 'Are you sure you want discard your JSON?',
            isOpen: true,
            method: notificationModalActions.SELECT_NEW_JSON,
            info,
        },
    };
}
