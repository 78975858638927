import { errorRequest } from '../Constants';
import Auth from '../Modules/auth.module';
import moment from 'moment';

export const userErrorRequests = (requestType, error) => {
    if (error.response) {
        const errorRes = error.response;

        switch (requestType) {
            case 'forgot password': {
                switch (errorRes.status) {
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'create password': {
                switch (errorRes.status) {
                    case 400: {
                        return 'Password reset link invalid';
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'sign up': {
                switch (errorRes.status) {
                    case 400: {
                        return errorRes.data.cause;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'sign in': {
                switch (errorRes.status) {
                    case 400: {
                        if (errorRes.data.error_description === 'The site is under repair') {
                            return 'The site is under maintenance, please try again later.';
                        } else if (errorRes.data.error_description === 'Bad credentials') {
                            return 'Wrong email or password.';
                        } else if (errorRes.data.error_description === 'User is disabled') {
                            return 'The site is under maintenance, please try again later.';
                        } else if (errorRes.data.error_description === 'User account is locked') {
                            return 'Account access blocked due to 5 invalid access attempts. Please try again later.';
                        } else {
                            return 'Wrong email or password';
                        }
                    }
                    case 401: {
                        return 'Wrong email or password';
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'sign out': {
                Auth.deauthenticateUser();
                return errorRequest.DEFAULT;
            }
            case 'sign up batch status': {
                switch (errorRes.status) {
                    case 400: {
                        return errorRes.data.cause;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    case 413:
                        return 'Maximum upload size exceeded: \n The field file exceeds its maximum permitted size of 1048576 bytes.';
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'sign up batch': {
                switch (errorRes.status) {
                    case 400: {
                        // wrong csv file
                        return error.response.data.cause;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'delete user': {
                switch (errorRes.status) {
                    case 400: {
                        return 'Can not delete user who has created lab instance';
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'delete user from group': {
                switch (errorRes.status) {
                    case 400: {
                        // return `Error to ${requestType}`
                        return `${errorRes.data.cause}`;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }

            case 'maintenance status':
            case 'maintenance switch':
            case 'delete users':
            case 'validate password token':
            case 'get all users by group':
            case 'update users in group': {
                switch (errorRes.status) {
                    case 400: {
                        return `Error to ${requestType}`;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'delete users status': {
                switch (errorRes.status) {
                    case 406: {
                        return errorRes.data;
                    }
                    case 400: {
                        return `Error to ${requestType}`;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'password reset status': {
                switch (errorRes.status) {
                    case 500: {
                        return errorRes.data.error;
                    }
                    case 400: {
                        return `Error to ${requestType}`;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'refresh token': {
                switch (errorRes.status) {
                    case 400: {
                        Auth.deauthenticateUser();
                        return `Error to ${requestType}`;
                    }
                    case 401: {
                        Auth.deauthenticateUser();
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'edit user error': {
                switch (errorRes.status) {
                    case 400: {
                        return error.response.data.cause;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'get user': {
                switch (errorRes.status) {
                    case 400: {
                        return error.response.data.cause;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            }
            case 'verify user without email':
                switch (errorRes.status) {
                    case 400: {
                        const { cause } = errorRes.data;
                        if (cause === 'Seminar allowed users count exception') {
                            return 'Reached maximum seminar users';
                        } else {
                            return cause;
                        }
                    }
                    case 401:
                        return errorRequest.UNAUTHORIZED;
                    default:
                        return errorRequest.DEFAULT;
                }
            case 'verify seminar':
                switch (errorRes.status) {
                    case 400: {
                        const { cause } = errorRes.data;
                        if (cause.includes('Seminar don`t start yet')) {
                            const array = cause.split('at ');
                            const date = new Date(+array[1]);
                            const secondPart = moment(date).format('LLLL');
                            const firstPart = `${array[0]} at `;
                            return {
                                modal: true,
                                message: firstPart + secondPart,
                            };
                        } else {
                            return cause;
                        }
                    }
                    case 404:
                        return errorRes.data.cause;
                    case 401:
                        return errorRequest.UNAUTHORIZED;
                    default:
                        return errorRequest.DEFAULT;
                }
            case 'verify user with email':
                switch (errorRes.status) {
                    case 400: {
                        const { cause } = errorRes.data;
                        return cause;
                    }
                    case 401:
                        return errorRequest.UNAUTHORIZED;
                    default:
                        return errorRequest.DEFAULT;
                }
            case 'set role':
                switch (errorRes.status) {
                    case 400: {
                        return error.response.data;
                    }
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        return errorRequest.DEFAULT;
                    }
                }
            case 'edit users':
                switch (errorRes.status) {
                    case 400:
                        return errorRes.data.cause;
                    default:
                        return errorRequest.DEFAULT;
                }
            case 'edit email':
                switch (errorRes.status) {
                    case 400:
                        return errorRes.data.cause;

                    default:
                        return errorRes.data.cause;
                }
            case 'add user':
            case 'get all':
                switch (errorRes.status) {
                    case 400:
                        return errorRes.data.cause;

                    default:
                        return errorRequest.DEFAULT;
                }
            default: {
                switch (errorRes.status) {
                    case 401: {
                        return errorRequest.UNAUTHORIZED;
                    }
                    default: {
                        Auth.deauthenticateUser();
                        return errorRequest.DEFAULT;
                    }
                }
            }
        }
    } else {
        Auth.deauthenticateUser();
        return errorRequest.DEFAULT;
    }
};
