export const sortUtil = {
    sortUp,
    sortDown,
};

function sortUp(data, field) {
    let array = data.map(item => item);
    array.sort((a, b) => {
        if (a[field] > b[field]) return -1;
        if (a[field] < b[field]) return 1;
        return 0;
    });
    return array;
}

function sortDown(data, field) {
    let array = data.map(item => item);
    array.sort((a, b) => {
        if (a[field] < b[field]) return -1;
        if (a[field] > b[field]) return 1;
        return 0;
    });
    return array;
}
