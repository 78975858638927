import { seminarHistoryConstants } from '../../Constants';
import _ from "lodash";

const initialState = {
    date: {
        from: null,
        to: null,
    },
    dateToSend: {
        from: null,
        to: null,
    },
    sortField: 'date',
    sortDirection: 'desc',
    size: 20,
    sizeList: [10, 20, 30],
    page: 0,
    total: 0,
    requestIsActive: false,
    action: ['default'],
    actionList: [],
    search: '',
    history: [],
    detailsModal: {
        isModalOpen: false,
        item: {},
    },
    needToSearch: false,
    currentTab: 'seminars',
    includeTestData: false
};

const seminarHistory = (state = _.cloneDeep(initialState), action) => {
    const { type, payload } = action;
    switch (type) {
        case seminarHistoryConstants.GET_SEMINARS_HISTORY_REQUEST:
        case seminarHistoryConstants.GET_SEMINAR_USER_HISTORY_REQUEST:
        case seminarHistoryConstants.GET_SEMINAR_LAB_HISTORY_REQUEST:
            return {
                ...state,
                requestIsActive: true
            };
        case seminarHistoryConstants.GET_SEMINARS_HISTORY_ERROR:
        case seminarHistoryConstants.GET_SEMINAR_USER_HISTORY_ERROR:
        case seminarHistoryConstants.GET_SEMINAR_LAB_HISTORY_ERROR:
            return {
                ...state,
                requestIsActive: false,
            };
        case seminarHistoryConstants.GET_SEMINARS_HISTORY_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                history: payload.history.content,
                total: payload.history.totalElements,
            };
        case seminarHistoryConstants.GET_SEMINAR_USER_HISTORY_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                history: payload.history.content,
                total: payload.history.totalElements,
            };
        case seminarHistoryConstants.GET_SEMINAR_LAB_HISTORY_SUCCESS:
            return {
                ...state,
                requestIsActive: false,
                history: payload.history.content,
                total: payload.history.totalElements,
            };
        case seminarHistoryConstants.CHANGE_SEMINAR_HISTORY_TAB:
            return {
                ...state,
                page: 0,
                currentTab: action.payload.value,
                action: ['default'],
            };
        case seminarHistoryConstants.CHANGE_SEMINAR_HISTORY_PAGE:
            return {
                ...state,
                page: action.payload.value,
            };
        case seminarHistoryConstants.CHANGE_SEMINAR_HISTORY_SIZE:
            return {
                ...state,
                size: action.payload.value,
            };
        case seminarHistoryConstants.GET_SEMINARS_HISTORY_ACTIONS_SUCCESS:
        case seminarHistoryConstants.GET_SEMINAR_USER_HISTORY_ACTIONS_SUCCESS:
        case seminarHistoryConstants.GET_SEMINAR_LAB_HISTORY_ACTIONS_SUCCESS:
            return {
                ...state,
                actionList: action.payload.list,
            };
        case seminarHistoryConstants.CHANGE_ACTION:
            return {
                ...state,
                action: action.payload.value,
            };
        case seminarHistoryConstants.CHANGE_SEARCH:
            return {
                ...state,
                search: action.payload.value,
            };
        case seminarHistoryConstants.PERFORM_HISTORY_SEARCH:
            return {
                ...state,
                needToSearch: true,
            };
        case seminarHistoryConstants.DISABLE_SEMINAR_HISTORY_SEARCH:
            return {
                ...state,
                needToSearch: false,
            };
        case seminarHistoryConstants.SET_DATE_ACTION: {
            let date = state.date;
            date[action.payload.action] = action.payload.value;
            return {
                ...state,
                date: date,
            };
        }
        case seminarHistoryConstants.CHANGE_SEMINAR_SORTING: {
            return {
                ...state,
                sortField: action.payload.field,
                sortDirection: action.payload.direction,
            };
        }

        case seminarHistoryConstants.TOGGLE_INCLUDE_TEST_DATA:
            return {
                ...state,
                includeTestData: !state.includeTestData,
                needToSearch: true
            }
        default:
            return state;
    }
};

export default seminarHistory;