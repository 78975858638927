export const groupsUtil = {
    getGroupParams,
    isGroupExists,
    getGroupIndex,
    getGroupAdminID,
    filterGroupAdminNames,
    modifyDates,
    convertReceivedDates,
    setDefaultDates,
};

function getGroupParams(groups, groupName) {
    let groupParams;
    if (groupName === 'allGroups') {
        groupParams = {
            groupID: '',
            groupType: 'REGULAR',
            isGroupEmpty: false,
        };
    } else {
        for (let i = 0; i < groups.length; i++) {
            if (groups[i].name === groupName) {
                groupParams = {
                    groupID: groups[i].id,
                    groupType: groups[i].default,
                    isGroupEmpty: groups[i].empty,
                };
                break;
            }
        }
    }
    return groupParams;
}

function isGroupExists(groups, groupName) {
    let isGroupExists = false;

    if (groupName === 'local-Freelance' || groupName === 'dev-Freelance' || groupName === 'Freelance') {
        return true;
    }
    for (let i = 0; i < groups.length; i++) {
        if (groups[i].name === groupName) {
            isGroupExists = true;
            break;
        }
    }
    return isGroupExists;
}

function getGroupIndex(groups, groupID) {
    let groupIndex;
    for (let i = 0; i < groups.length; i++) {
        if (groups[i].id === +groupID) {
            groupIndex = i;
            break;
        }
    }
    if (!groupIndex) {
        groupIndex = 0;
    }
    return groupIndex;
}

function getGroupAdminID(groupAdmins, groupAdminName) {
    let groupAdminIDs = [];
    for (let i = 0; i < groupAdmins.length; i++) {
        for (let j = 0; j < groupAdminName.length; j++) {
            if (groupAdmins[i].username === groupAdminName[j]) {
                groupAdminIDs.push(groupAdmins[i].id);
                break;
            }
        }
    }
    return groupAdminIDs;
}

function filterGroupAdminNames(array) {
    let result = [];
    array.forEach(item => {
        if (!(array.length > 1 && item === '')) {
            result.push(item);
        }
    });
    if (!array.length && !result.length) {
        result.push('');
    }
    return result;
}

function convertReceivedDates(dateFrom, dateTo) {
    const convertedTime = {
        startTime: dateFrom,
        endTime: dateTo,
    };

    if (dateFrom && dateTo) {
        convertedTime.startTime = new Date(dateFrom);
        convertedTime.endTime = new Date(dateTo);
    } else {
        console.log('Else');
        convertedTime.startTime = new Date();
        convertedTime.endTime = new Date();
    }

    return convertedTime;
}

function setDefaultDates() {
    const convertedDates = {
        dateFrom: new Date(),
        dateTo: new Date(),
        startTime: new Date(),
        endTime: new Date(),
    };
    convertedDates.dateFrom.setDate(convertedDates.dateFrom.getDate() - 7);
    convertedDates.dateFrom.setHours(0);
    convertedDates.dateFrom.setMinutes(0);
    convertedDates.dateFrom.setSeconds(0);
    convertedDates.dateTo.setDate(convertedDates.dateTo.getDate() + 7);
    convertedDates.dateTo.setHours(0);
    convertedDates.dateTo.setMinutes(0);
    convertedDates.dateTo.setSeconds(0);

    convertedDates.startTime = convertedDates.dateFrom;
    convertedDates.endTime = convertedDates.dateTo;

    return convertedDates;
}

function modifyDates(dateFrom, dateTo, timeStart, timeStop) {
    const dates = {
        dateFrom,
        dateTo,
    };
    dates.dateFrom.setHours(timeStart.getHours());
    dates.dateFrom.setMinutes(timeStart.getMinutes());
    dates.dateFrom.setSeconds(0);
    dates.dateTo.setHours(timeStop.getHours());
    dates.dateTo.setMinutes(timeStop.getMinutes());
    dates.dateTo.setSeconds(0);

    dates.dateFrom = dates.dateFrom.getTime();
    dates.dateTo = dates.dateTo.getTime();

    return dates;
}
