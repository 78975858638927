import { urlConstant } from '../Constants';
import { labErrorRequests, groupErrorRequests } from '../Handlers';
import Maintenance from '../Modules/maintenance.module';
import {AxiosRequest} from "./AxiosApi";
const isRepair = Maintenance.getMaintenance();

export const instancesTypesManagementService = {
    getAllInstancesTypes,
    getAllInstancesTypesDropdown,
    addLabTemplate,
    deleteLabTemplate,
    updateLabTemplate,
    getGroupAdminInstancesTypes,
    getGroupAdminInstancesTypesDropdown,
    getBlueprintsList,
    getEditableTemplate,
    getEditableBlueprint,
    deleteBlueprint,
    saveAsDraft,
    setTemplateVersion,
    renameLabTemplate,
    setBlueprintName,
    setTemplateShare,
    deleteDraftTemplate,
    getTemplateVersions,
    setHideVersions,
    getAssignedAdmins
};

function getAllInstancesTypes(page, size, search, shared) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/templates?page=${page}&size=${size}&sort=createdTime,desc`,
            data: {
                query: search,
                shared
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get all instances types', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getAllInstancesTypesDropdown() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/templates/shared`
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get all instances types', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function addLabTemplate(acceptedFile, name) {

    return new Promise((resolve, reject) => {
        let bodyFormData = new FormData();
        bodyFormData.set('file', acceptedFile);
        // bodyFormData.set('name', name);

        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/templates/newTemplate/${name}`,
            data: bodyFormData,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('add lab template', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function deleteLabTemplate(labTemplateID) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/admin/templates/${labTemplateID}`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('delete lab template', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function updateLabTemplate(templateId, newName, oldName) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/templates/rename`,
            data: {
                templateId,
                newName
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('update lab template', error);
                isRepair && console.error(errorMessage, error);

                let message = error.response.data.errors && error.response.data.errors[0] && error.response.data.errors[0].defaultMessage;
                reject(message || errorMessage);
            });
    });
}

function renameLabTemplate(templateId, newName, oldName) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/templates/rename`,
            data: {
                templateId,
                newName
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('set blueprint name', error);
                isRepair && console.error(errorMessage, error);

                let message = error.response.data.errors && error.response.data.errors[0] && error.response.data.errors[0].defaultMessage;
                reject(message || errorMessage);
            });
    });
}

function getGroupAdminInstancesTypes(page, size) {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/templates?page=${page}&size=${size}`,
            data: {
                query: '',
            },
        })
            .then(response => {
                isRepair && console.log('Instances got successfully', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get all instances types', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getGroupAdminInstancesTypesDropdown() {

    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'put',
            url: `${urlConstant}/groupAdmin/templates`,
        })
            .then(response => {
                isRepair && console.log('Instances got successfully', response);
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('get all instances types', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getBlueprintsList(page, size, search) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/templates/blueprints?page=${page}&size=${size}&sort=createdTime,desc`,
            data: {
                query: search,
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('get blueprints', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getEditableTemplate(id) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/templates/${id}`,
            data: {},
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('get editable template', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function getEditableBlueprint(id) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/templates/blueprint/${id}`,
            data: {},
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('get editable blueprint', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function deleteBlueprint(id) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/admin/templates/blueprint/delete/${id}`,
            data: {},
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('delete blueprint', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function saveAsDraft(draft) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/templates/version/draft`,
            data: {
                name: draft.blueprintName || null,
                json: JSON.stringify(draft.json),
                path: draft.path,
                fileName: draft.fileName || null,
                blueprintId: draft.blueprintId || null,
                templateId: draft.templateId || null,
                comment: draft.comment,
                parentVersionId: draft.currentVersion || null,
                editable: draft.editable,
                description: draft.description
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('delete blueprint', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function setTemplateVersion(data) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/templates/setVersion/${data.templateId}/${data.versionId}`,
            data,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('set template version', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    });
}

function setTemplateShare({templateId, shared}) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/templates/share/${templateId}/${String(Boolean(shared))}`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = groupErrorRequests('update group', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            });
    })
}

function setBlueprintName(id, name) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/templates/blueprint/${id}/name/${name}`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('set blueprint name', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            })
    })
}

function deleteDraftTemplate(id) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'delete',
            url: `${urlConstant}/admin/templates/${id}/draft`,
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            })
    })
}

function getTemplateVersions(id) {


    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/templates/versions/${id}`
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            })
    })
}

function setHideVersions(versions = []) {
    return new Promise((resolve, reject) => {
        AxiosRequest({
            method: 'post',
            url: `${urlConstant}/admin/templates/hideVersions`,
            data: versions
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                const errorMessage = labErrorRequests('', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            })
    })
}

function getAssignedAdmins(id) {
    return new Promise(((resolve, reject) => {
        AxiosRequest({
            method: 'get',
            url: `${urlConstant}/admin/templates/${id}/assignedAdmins`
        })
            .then(response => resolve(response.data))
            .catch(error => {
                const errorMessage = labErrorRequests('', error);
                isRepair && console.error(errorMessage, error);
                reject(errorMessage);
            })
    }))
}